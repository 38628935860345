import React from 'react';
import PropTypes from 'prop-types';

const MobileSelect = ({ style, value, onChange, items, placeholder }) => (
  <select
    className="selectContainer"
    style={style}
    value={value || ''}
    onChange={event => onChange(event.target.value)}
  >
    <option value="">{placeholder}</option>
    {items.map((item, index) => (
      <option
        key={index} // eslint-disable-line react/no-array-index-key
        value={item.value}
      >
        {item.label}
      </option>
    ))}
  </select>
);

MobileSelect.propTypes = {
  style: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  placeholder: PropTypes.string.isRequired,
};

export default MobileSelect;
