// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var Record = require("../Record/index.js");
var ZAP_Dart_Client_Components_Banner = require("../ZAP.Dart.Client.Components.Banner/index.js");
var ZAP_Dart_Client_Components_FullPageSpinner = require("../ZAP.Dart.Client.Components.FullPageSpinner/index.js");
var ZAP_Dart_Client_Components_NetworkErrorPage = require("../ZAP.Dart.Client.Components.NetworkErrorPage/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var NetworkError = (function () {
    function NetworkError() {

    };
    NetworkError.value = new NetworkError();
    return NetworkError;
})();
var asyncIdC = React_Basic.createComponent("AsyncPageC");
var withAsyncId = function (dictLacks) {
    return function (comp) {
        return function (infoR) {
            var initialState = {
                res: Data_Maybe.Nothing.value,
                loading: false,
                error: Data_Maybe.Nothing.value
            };
            var fetchData = function (self) {
                var onStart = self.setState(function (v) {
                    return {
                        error: Data_Maybe.Nothing.value,
                        loading: true,
                        res: v.res
                    };
                });
                var onResolve = function (i) {
                    return self.setState(function (v) {
                        return {
                            error: v.error,
                            loading: false,
                            res: new Data_Maybe.Just(i)
                        };
                    });
                };
                var onNetworkError = self.setState(function (v) {
                    return {
                        error: new Data_Maybe.Just(NetworkError.value),
                        loading: false,
                        res: v.res
                    };
                });
                var apiCall = ZAP_Dart_Client_Screens_Utils.mkLApi(infoR.initD)(onResolve);
                return Control_Apply.applySecond(Effect.applyEffect)(onStart)(self.props.runLApi(apiCall)(onNetworkError));
            };
            var refetchData = function (v) {
                return function (v1) {
                    var isFocus = v.props.focusState.isFocusing || v.props.focusState.isFocused;
                    var focusHasChanged = v.props.focusState.isFocusing !== v1.prevProps.focusState.isFocusing || v.props.focusState.isFocused !== v1.prevProps.focusState.isFocused;
                    return Control_Applicative.when(Effect.applicativeEffect)(isFocus && (focusHasChanged && !v.state.loading))(fetchData(v));
                };
            };
            var render = function (v) {
                if (v.state.res instanceof Data_Maybe.Nothing && !v.state.loading) {
                    if (v.state.error instanceof Data_Maybe.Just) {
                        return ZAP_Dart_Client_Components_NetworkErrorPage.networkErrorPage(fetchData(v));
                    };
                    if (v.state.error instanceof Data_Maybe.Nothing) {
                        return Data_Monoid.mempty(React_Basic.monoidJSX);
                    };
                    throw new Error("Failed pattern match at ReactNavigation.Utils.HOCs.WithAsyncId (line 81, column 23 - line 83, column 24): " + [ v.state.error.constructor.name ]);
                };
                return React_Basic.fragment([ ZAP_Dart_Client_Components_FullPageSpinner.fullPageSpinner({
                    show: v.state.loading
                }), React_Basic_Native_Generated.view()({
                    style: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1) ]),
                    children: [ ZAP_Dart_Client_Components_Banner.banner({
                        show: Data_Maybe.isJust(v.state.error),
                        message: ZAP_Dart_Client_Components_Banner.BMNetworkError.value
                    }), (function () {
                        if (v.state.res instanceof Data_Maybe.Nothing) {
                            return Data_Monoid.mempty(React_Basic.monoidJSX);
                        };
                        if (v.state.res instanceof Data_Maybe.Just) {
                            return comp(Record["delete"](new Data_Symbol.IsSymbol(function () {
                                return "initD";
                            }))()()(Data_Symbol.SProxy.value)(infoR))(Record.insert(new Data_Symbol.IsSymbol(function () {
                                return "initD";
                            }))()()(Data_Symbol.SProxy.value)(v.state.res.value0)(v.props));
                        };
                        throw new Error("Failed pattern match at ReactNavigation.Utils.HOCs.WithAsyncId (line 94, column 17 - line 97, column 139): " + [ v.state.res.constructor.name ]);
                    })() ]
                }) ]);
            };
            var didUpdate = function (self) {
                return function (prevSelf) {
                    return refetchData(self)(prevSelf);
                };
            };
            var didMount = function (self) {
                return fetchData(self);
            };
            return React_Basic.make()(asyncIdC)({
                initialState: initialState,
                render: render,
                didUpdate: didUpdate,
                didMount: didMount
            });
        };
    };
};
module.exports = {
    withAsyncId: withAsyncId
};
