// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var ReactNavigation = require("../ReactNavigation/index.js");
var ReactNavigation_AuthFlow = require("../ReactNavigation.AuthFlow/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ReactNavigation_Utils_HOCs_WithPageProps = require("../ReactNavigation.Utils.HOCs.WithPageProps/index.js");
var ReactNavigation_Utils_NavBarCreators = require("../ReactNavigation.Utils.NavBarCreators/index.js");
var ReactNavigation_Utils_PageCreators = require("../ReactNavigation.Utils.PageCreators/index.js");
var ReactNavigation_Utils_RouterConfigs = require("../ReactNavigation.Utils.RouterConfigs/index.js");
var ZAP_Dart_Client_Components_NavBar_CartMgtNavBar = require("../ZAP.Dart.Client.Components.NavBar.CartMgtNavBar/index.js");
var ZAP_Dart_Client_Screens_Catalog = require("../ZAP.Dart.Client.Screens.Catalog/index.js");
var ZAP_Dart_Client_Screens_CatalogItem = require("../ZAP.Dart.Client.Screens.CatalogItem/index.js");
var ZAP_Dart_Client_Screens_Types = require("../ZAP.Dart.Client.Screens.Types/index.js");
var rs = ReactNavigation.mkRoutes(ReactNavigation.mkRoutesI()(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLNil)()()(new Data_Symbol.IsSymbol(function () {
    return "catalogS";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "catalogItem";
}))));
var navBar = ReactNavigation_Utils_NavBarCreators.mkNavBarContent_(ZAP_Dart_Client_Components_NavBar_CartMgtNavBar.cartMgtNavBar)(ReactNavigation_Types.NBTotalPoints.value);
var flow = (function () {
    var initRouteInfoF = function (v) {
        return ReactNavigation_AuthFlow.processInfos()(ReactNavigation_AuthFlow.processInfosRLConsapiCalls(ReactNavigation_AuthFlow.processInfosRLNil)(new Data_Symbol.IsSymbol(function () {
            return "apiCalls";
        }))()(ReactNavigation_AuthFlow.applyAuthRLCons(ReactNavigation_AuthFlow.applyAuthRLCons(ReactNavigation_AuthFlow.applyAuthRLNil)(new Data_Symbol.IsSymbol(function () {
            return "updateCart";
        }))()()())(new Data_Symbol.IsSymbol(function () {
            return "listCatalogItems";
        }))()()())()()()())({
            apiCalls: {
                updateCart: v.clientApi.updateCart,
                listCatalogItems: v.clientApi.listCatalogItems
            }
        })(v.authToken);
    };
    var defaultNavigationOptions = ReactNavigation.navigationOptions()()({
        header: Data_Nullable.notNull(navBar)
    });
    var configBuilder = ReactNavigation_Utils_RouterConfigs.stackRcBuilder(ReactNavigation.isRouterConfigOpts()()())()(ReactNavigation.isRouterConfigOpts()()())({
        defaultNavigationOptions: defaultNavigationOptions
    });
    var catalogItemInfo = function (pi) {
        return function (v) {
            return {
                initD: new ReactNavigation_AuthFlow.InitD(v.clientApi.getCatalogItem, {
                    productId: pi
                }),
                apiCalls: {
                    updateCart: v.clientApi.updateCart
                }
            };
        };
    };
    var afterInitF = function (v) {
        if (v.value instanceof ZAP_Dart_Client_Screens_Types.GoToCatalogItem) {
            return Control_Bind.discard(Control_Bind.discardUnit)(ReactNavigation_AuthFlow.bindAuthNavM)(ReactNavigation_AuthFlow.navPushA(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                return "catalogItem";
            }))())(ReactNavigation_AuthFlow.monadReaderAuthNavM)(ReactNavigation_AuthFlow.monadNMAuthNavM)(ReactNavigation_AuthFlow.plusAuthNavM)()(ReactNavigation_AuthFlow.processInfosRLConsapiCalls(ReactNavigation_AuthFlow.processInfosRLConsInitD(ReactNavigation_AuthFlow.processInfosRLNil)()(new Data_Symbol.IsSymbol(function () {
                return "initD";
            }))()()())(new Data_Symbol.IsSymbol(function () {
                return "apiCalls";
            }))()(ReactNavigation_AuthFlow.applyAuthRLCons(ReactNavigation_AuthFlow.applyAuthRLNil)(new Data_Symbol.IsSymbol(function () {
                return "updateCart";
            }))()()())()()()())(rs.catalogItem)(catalogItemInfo(v.value.value0)(v.info)))(function () {
                return Control_Applicative.pure(ReactNavigation_AuthFlow.applicativeAuthNavM)(ZAP_Dart_Client_Screens_Types.ManageCart.value);
            });
        };
        if (v.value instanceof ZAP_Dart_Client_Screens_Types.GoToCart) {
            return Control_Applicative.pure(ReactNavigation_AuthFlow.applicativeAuthNavM)(ZAP_Dart_Client_Screens_Types.ManageCart.value);
        };
        throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.Catalog (line 44, column 5 - line 48, column 34): " + [ v.value.constructor.name ]);
    };
    return ReactNavigation_AuthFlow.createAuthFlow(new Data_Symbol.IsSymbol(function () {
        return "catalogS";
    }))(ReactNavigation.navCreatorStack)(ReactNavigation_AuthFlow.runAuthNavMfirstRouteAsync)(rs)(rs.catalogS)(initRouteInfoF)(configBuilder)(afterInitF);
})();
var catalogScreen = ReactNavigation.createFlowScreen()(flow)({
    catalogS: ReactNavigation.routeUiOpts_(ReactNavigation_Utils_PageCreators.routeUiPage(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherClientErr)(ZAP_Dart_Client_Screens_Catalog.catalog)),
    catalogItem: ReactNavigation.routeUiOpts_(ReactNavigation_Utils_PageCreators.routeUiInitD(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherClientErr)()(ZAP_Dart_Client_Screens_CatalogItem.catalogItem))
});
module.exports = {
    catalogScreen: catalogScreen
};
