// Generated by purs version 0.13.8
"use strict";
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var ZAP_Dart_Client_Components_Svg = require("../ZAP.Dart.Client.Components.Svg/index.js");
var scanQr = function (props) {
    return ZAP_Dart_Client_Components_Svg.svg()({
        width: props.width,
        height: props.height,
        fill: React_Basic_Native_PropTypes_Color.rgba(0)(0)(0)(0.0),
        viewBox: "0 0 59 59",
        children: [ ZAP_Dart_Client_Components_Svg.path()({
            fill: "black",
            d: "M10 49.1613H26.7099V32.4512H10V49.1613ZM12.7417 35.1935H23.9678V46.3905H12.7417V35.1935Z"
        }), ZAP_Dart_Client_Components_Svg.path()({
            fill: "black",
            d: "M20.2263 38.9355H16.4844V42.678H20.2263V38.9355Z"
        }), ZAP_Dart_Client_Components_Svg.path()({
            fill: "black",
            d: "M42.6784 45.4199H38.9355V49.1619H42.6784V45.4199Z"
        }), ZAP_Dart_Client_Components_Svg.path()({
            fill: "black",
            d: "M49.1617 45.4199H45.4189V49.1619H49.1617V45.4199Z"
        }), ZAP_Dart_Client_Components_Svg.path()({
            fill: "black",
            d: "M46.4193 35.1935H42.6776V32.4512H32.4512V49.1613H35.1935V38.9352H38.9349V42.6776H49.1613V32.4512H46.4193V35.1935Z"
        }), ZAP_Dart_Client_Components_Svg.path()({
            fill: "black",
            d: "M10 26.7103H26.7099V10H10V26.7103ZM12.7417 12.7417H23.9678V23.9678H12.7417V12.7417Z"
        }), ZAP_Dart_Client_Components_Svg.path()({
            fill: "black",
            d: "M20.2263 16.4844H16.4844V20.2266H20.2263V16.4844Z"
        }), ZAP_Dart_Client_Components_Svg.path()({
            fill: "black",
            d: "M32.4512 10V26.7103H49.1616V10H32.4512ZM46.4193 23.9678H35.1935V12.7417H46.4193V23.9678Z"
        }), ZAP_Dart_Client_Components_Svg.path()({
            fill: "black",
            d: "M42.6784 16.4844H38.9355V20.2266H42.6784V16.4844Z"
        }), ZAP_Dart_Client_Components_Svg.path()({
            d: "M1 50.0833V58H8.91667M1 8.91667V1H8.91667M58 50.0833V58H50.0833M58 8.91667V1H50.0833",
            stroke: props.color
        }) ]
    });
};
module.exports = {
    scanQr: scanQr
};
