// Generated by purs version 0.13.8
"use strict";
var $foreign = require("./foreign.js");
var Data_Show = require("../Data.Show/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var ResizeMode = function (x) {
    return x;
};
var Position = function (x) {
    return x;
};
var Percent = function (x) {
    return x;
};
var Overflow = function (x) {
    return x;
};
var MarginAuto = function (x) {
    return x;
};
var BorderStyle = function (x) {
    return x;
};
var BackfaceVisibility = function (x) {
    return x;
};
var Width = function (width) {
    this.width = width;
};
var Top = function (top) {
    this.top = top;
};
var Right = function (right) {
    this.right = right;
};
var PaddingVertical = function (paddingVertical) {
    this.paddingVertical = paddingVertical;
};
var PaddingTop = function (paddingTop) {
    this.paddingTop = paddingTop;
};
var PaddingRight = function (paddingRight) {
    this.paddingRight = paddingRight;
};
var PaddingLeft = function (paddingLeft) {
    this.paddingLeft = paddingLeft;
};
var PaddingHorizontal = function (paddingHorizontal) {
    this.paddingHorizontal = paddingHorizontal;
};
var PaddingBottom = function (paddingBottom) {
    this.paddingBottom = paddingBottom;
};
var Padding = function (padding) {
    this.padding = padding;
};
var MkPercent = function (percent) {
    this.percent = percent;
};
var MinWidth = function (minWidth) {
    this.minWidth = minWidth;
};
var MinHeight = function (minHeight) {
    this.minHeight = minHeight;
};
var MaxWidth = function (maxWidth) {
    this.maxWidth = maxWidth;
};
var MaxHeight = function (maxHeight) {
    this.maxHeight = maxHeight;
};
var MarginVertical = function (marginVertical) {
    this.marginVertical = marginVertical;
};
var MarginTop = function (marginTop) {
    this.marginTop = marginTop;
};
var MarginRight = function (marginRight) {
    this.marginRight = marginRight;
};
var MarginLeft = function (marginLeft) {
    this.marginLeft = marginLeft;
};
var MarginHorizontal = function (marginHorizontal) {
    this.marginHorizontal = marginHorizontal;
};
var MarginBottom = function (marginBottom) {
    this.marginBottom = marginBottom;
};
var Margin = function (margin) {
    this.margin = margin;
};
var Left = function (left) {
    this.left = left;
};
var Height = function (height) {
    this.height = height;
};
var FlexBasis = function (flexBasis) {
    this.flexBasis = flexBasis;
};
var Bottom = function (bottom) {
    this.bottom = bottom;
};
var zIndex = $foreign.unsafeStyleProp("zIndex");
var width = function (dict) {
    return dict.width;
};
var visibleBackface = new React_Basic_Native_PropTypes.VisibilityEnum("hidden", "visible");
var top = function (dict) {
    return dict.top;
};
var styles$prime = Unsafe_Coerce.unsafeCoerce;
var stretch = "stretch";
var solid = "solid";
var shadowRadius = $foreign.unsafeStyleProp("shadowRadius");
var shadowOpacity = $foreign.unsafeStyleProp("shadowOpacity");
var shadowOffset = $foreign.unsafeStyleProp("shadowOffset");
var shadowColor = $foreign.unsafeStyleProp("shadowColor");
var scroll = "scroll";
var rmCenter = new React_Basic_Native_PropTypes.CenterEnum("center");
var right = function (dict) {
    return dict.right;
};
var resizeMode = $foreign.unsafeStyleProp("resizeMode");
var repeat = "repeat";
var relative = "relative";
var position = $foreign.unsafeStyleProp("position");
var percentWidth = new Width($foreign.unsafeStyleProp("width"));
var percentTop = new Top($foreign.unsafeStyleProp("top"));
var percentRight = new Right($foreign.unsafeStyleProp("right"));
var percentPaddingVertical = new PaddingVertical($foreign.unsafeStyleProp("paddingVertical"));
var percentPaddingTop = new PaddingTop($foreign.unsafeStyleProp("paddingTop"));
var percentPaddingRight = new PaddingRight($foreign.unsafeStyleProp("paddingRight"));
var percentPaddingLeft = new PaddingLeft($foreign.unsafeStyleProp("paddingLeft"));
var percentPaddingHorizontal = new PaddingHorizontal($foreign.unsafeStyleProp("paddingHorizontal"));
var percentPaddingBottom = new PaddingBottom($foreign.unsafeStyleProp("paddingBottom"));
var percentPadding = new Padding($foreign.unsafeStyleProp("padding"));
var percentNumber = new MkPercent(function (a) {
    return Data_Show.show(Data_Show.showNumber)(a) + "%";
});
var percentMinWidth = new MinWidth($foreign.unsafeStyleProp("minWidth"));
var percentMinHeight = new MinHeight($foreign.unsafeStyleProp("minHeight"));
var percentMaxWidth = new MaxWidth($foreign.unsafeStyleProp("maxWidth"));
var percentMaxHeight = new MaxHeight($foreign.unsafeStyleProp("maxHeight"));
var percentMarginVertical = new MarginVertical($foreign.unsafeStyleProp("marginVertical"));
var percentMarginTop = new MarginTop($foreign.unsafeStyleProp("marginTop"));
var percentMarginRight = new MarginRight($foreign.unsafeStyleProp("marginRight"));
var percentMarginLeft = new MarginLeft($foreign.unsafeStyleProp("marginLeft"));
var percentMarginHorizontal = new MarginHorizontal($foreign.unsafeStyleProp("marginHorizontal"));
var percentMarginBottom = new MarginBottom($foreign.unsafeStyleProp("marginBottom"));
var percentMargin = new Margin($foreign.unsafeStyleProp("margin"));
var percentLeft = new Left($foreign.unsafeStyleProp("left"));
var percentInt = new MkPercent(function (a) {
    return Data_Show.show(Data_Show.showInt)(a) + "%";
});
var percentHeight = new Height($foreign.unsafeStyleProp("height"));
var percentFlexBasis = new FlexBasis($foreign.unsafeStyleProp("flexBasis"));
var percentBottom = new Bottom($foreign.unsafeStyleProp("bottom"));
var percent = function (dict) {
    return dict.percent;
};
var paddingVertical = function (dict) {
    return dict.paddingVertical;
};
var paddingTop = function (dict) {
    return dict.paddingTop;
};
var paddingRight = function (dict) {
    return dict.paddingRight;
};
var paddingLeft = function (dict) {
    return dict.paddingLeft;
};
var paddingHorizontal = function (dict) {
    return dict.paddingHorizontal;
};
var paddingBottom = function (dict) {
    return dict.paddingBottom;
};
var padding = function (dict) {
    return dict.padding;
};
var overflowV = new React_Basic_Native_PropTypes.VisibilityEnum("hidden", "visible");
var overflow = $foreign.unsafeStyleProp("overflow");
var opacity = $foreign.unsafeStyleProp("opacity");
var numberWidth = new Width($foreign.unsafeStyleProp("width"));
var numberTop = new Top($foreign.unsafeStyleProp("top"));
var numberRight = new Right($foreign.unsafeStyleProp("right"));
var numberPaddingVertical = new PaddingVertical($foreign.unsafeStyleProp("paddingVertical"));
var numberPaddingTop = new PaddingTop($foreign.unsafeStyleProp("paddingTop"));
var numberPaddingRight = new PaddingRight($foreign.unsafeStyleProp("paddingRight"));
var numberPaddingLeft = new PaddingLeft($foreign.unsafeStyleProp("paddingLeft"));
var numberPaddingHorizontal = new PaddingHorizontal($foreign.unsafeStyleProp("paddingHorizontal"));
var numberPaddingBottom = new PaddingBottom($foreign.unsafeStyleProp("paddingBottom"));
var numberPadding = new Padding($foreign.unsafeStyleProp("padding"));
var numberMinWidth = new MinWidth($foreign.unsafeStyleProp("minWidth"));
var numberMinHeight = new MinHeight($foreign.unsafeStyleProp("minHeight"));
var numberMaxWidth = new MaxWidth($foreign.unsafeStyleProp("maxWidth"));
var numberMaxHeight = new MaxHeight($foreign.unsafeStyleProp("maxHeight"));
var numberMarginVertical = new MarginVertical($foreign.unsafeStyleProp("marginVertical"));
var numberMarginTop = new MarginTop($foreign.unsafeStyleProp("marginTop"));
var numberMarginRight = new MarginRight($foreign.unsafeStyleProp("marginRight"));
var numberMarginLeft = new MarginLeft($foreign.unsafeStyleProp("marginLeft"));
var numberMarginHorizontal = new MarginHorizontal($foreign.unsafeStyleProp("marginHorizontal"));
var numberMarginBottom = new MarginBottom($foreign.unsafeStyleProp("marginBottom"));
var numberMargin = new Margin($foreign.unsafeStyleProp("margin"));
var numberLeft = new Left($foreign.unsafeStyleProp("left"));
var numberHeight = new Height($foreign.unsafeStyleProp("height"));
var numberFlexBasis = new FlexBasis($foreign.unsafeStyleProp("flexBasis"));
var numberBottom = new Bottom($foreign.unsafeStyleProp("bottom"));
var minWidth = function (dict) {
    return dict.minWidth;
};
var minHeight = function (dict) {
    return dict.minHeight;
};
var maxWidth = function (dict) {
    return dict.maxWidth;
};
var maxHeight = function (dict) {
    return dict.maxHeight;
};
var marginVertical = function (dict) {
    return dict.marginVertical;
};
var marginTop = function (dict) {
    return dict.marginTop;
};
var marginRight = function (dict) {
    return dict.marginRight;
};
var marginLeft = function (dict) {
    return dict.marginLeft;
};
var marginHorizontal = function (dict) {
    return dict.marginHorizontal;
};
var marginBottom = function (dict) {
    return dict.marginBottom;
};
var marginAuto = "auto";
var margin = function (dict) {
    return dict.margin;
};
var left = function (dict) {
    return dict.left;
};
var intWidth = new Width($foreign.unsafeStyleProp("width"));
var intTop = new Top($foreign.unsafeStyleProp("top"));
var intRight = new Right($foreign.unsafeStyleProp("right"));
var intPaddingVertical = new PaddingVertical($foreign.unsafeStyleProp("paddingVertical"));
var intPaddingTop = new PaddingTop($foreign.unsafeStyleProp("paddingTop"));
var intPaddingRight = new PaddingRight($foreign.unsafeStyleProp("paddingRight"));
var intPaddingLeft = new PaddingLeft($foreign.unsafeStyleProp("paddingLeft"));
var intPaddingHorizontal = new PaddingHorizontal($foreign.unsafeStyleProp("paddingHorizontal"));
var intPaddingBottom = new PaddingBottom($foreign.unsafeStyleProp("paddingBottom"));
var intPadding = new Padding($foreign.unsafeStyleProp("padding"));
var intMinWidth = new MinWidth($foreign.unsafeStyleProp("minWidth"));
var intMinHeight = new MinHeight($foreign.unsafeStyleProp("minHeight"));
var intMaxWidth = new MaxWidth($foreign.unsafeStyleProp("maxWidth"));
var intMaxHeight = new MaxHeight($foreign.unsafeStyleProp("maxHeight"));
var intMarginVertical = new MarginVertical($foreign.unsafeStyleProp("marginVertical"));
var intMarginTop = new MarginTop($foreign.unsafeStyleProp("marginTop"));
var intMarginRight = new MarginRight($foreign.unsafeStyleProp("marginRight"));
var intMarginLeft = new MarginLeft($foreign.unsafeStyleProp("marginLeft"));
var intMarginHorizontal = new MarginHorizontal($foreign.unsafeStyleProp("marginHorizontal"));
var intMarginBottom = new MarginBottom($foreign.unsafeStyleProp("marginBottom"));
var intMargin = new Margin($foreign.unsafeStyleProp("margin"));
var intLeft = new Left($foreign.unsafeStyleProp("left"));
var intHeight = new Height($foreign.unsafeStyleProp("height"));
var intFlexBasis = new FlexBasis($foreign.unsafeStyleProp("flexBasis"));
var intBottom = new Bottom($foreign.unsafeStyleProp("bottom"));
var height = function (dict) {
    return dict.height;
};
var flexBasis = function (dict) {
    return dict.flexBasis;
};
var flex = $foreign.unsafeStyleProp("flex");
var elevation = $foreign.unsafeStyleProp("elevation");
var dotted = "dotted";
var dashed = "dashed";
var cover = "cover";
var contain = "contain";
var bottom = function (dict) {
    return dict.bottom;
};
var borderWidth = $foreign.unsafeStyleProp("borderWidth");
var borderTopWidth = $foreign.unsafeStyleProp("borderTopWidth");
var borderTopRightRadius = $foreign.unsafeStyleProp("borderTopRightRadius");
var borderTopLeftRadius = $foreign.unsafeStyleProp("borderTopLeftRadius");
var borderTopColor = $foreign.unsafeStyleProp("borderTopColor");
var borderStyle = $foreign.unsafeStyleProp("borderStyle");
var borderRightWidth = $foreign.unsafeStyleProp("borderRightWidth");
var borderRightColor = $foreign.unsafeStyleProp("borderRightColor");
var borderRadius = $foreign.unsafeStyleProp("borderRadius");
var borderLeftWidth = $foreign.unsafeStyleProp("borderLeftWidth");
var borderLeftColor = $foreign.unsafeStyleProp("borderLeftColor");
var borderColor = $foreign.unsafeStyleProp("borderColor");
var borderBottomWidth = $foreign.unsafeStyleProp("borderBottomWidth");
var borderBottomRightRadius = $foreign.unsafeStyleProp("borderBottomRightRadius");
var borderBottomLeftRadius = $foreign.unsafeStyleProp("borderBottomLeftRadius");
var borderBottomColor = $foreign.unsafeStyleProp("borderBottomColor");
var backgroundColor = $foreign.unsafeStyleProp("backgroundColor");
var backfaceVisibility = $foreign.unsafeStyleProp("backfaceVisibility");
var autoMargin = new Margin($foreign.unsafeStyleProp("margin"));
var absolute = "absolute";
module.exports = {
    "styles'": styles$prime,
    absolute: absolute,
    relative: relative,
    position: position,
    solid: solid,
    dotted: dotted,
    dashed: dashed,
    borderStyle: borderStyle,
    backgroundColor: backgroundColor,
    flex: flex,
    FlexBasis: FlexBasis,
    flexBasis: flexBasis,
    Left: Left,
    Right: Right,
    Top: Top,
    Bottom: Bottom,
    left: left,
    right: right,
    top: top,
    bottom: bottom,
    Margin: Margin,
    MarginBottom: MarginBottom,
    MarginHorizontal: MarginHorizontal,
    MarginLeft: MarginLeft,
    MarginRight: MarginRight,
    MarginTop: MarginTop,
    MarginVertical: MarginVertical,
    margin: margin,
    marginBottom: marginBottom,
    marginHorizontal: marginHorizontal,
    marginLeft: marginLeft,
    marginRight: marginRight,
    marginTop: marginTop,
    marginVertical: marginVertical,
    MarginAuto: MarginAuto,
    marginAuto: marginAuto,
    Width: Width,
    Height: Height,
    width: width,
    height: height,
    MaxHeight: MaxHeight,
    maxHeight: maxHeight,
    MaxWidth: MaxWidth,
    maxWidth: maxWidth,
    MinHeight: MinHeight,
    minHeight: minHeight,
    MinWidth: MinWidth,
    minWidth: minWidth,
    scroll: scroll,
    overflow: overflow,
    Padding: Padding,
    PaddingBottom: PaddingBottom,
    PaddingHorizontal: PaddingHorizontal,
    PaddingLeft: PaddingLeft,
    PaddingRight: PaddingRight,
    PaddingTop: PaddingTop,
    PaddingVertical: PaddingVertical,
    padding: padding,
    paddingBottom: paddingBottom,
    paddingHorizontal: paddingHorizontal,
    paddingLeft: paddingLeft,
    paddingRight: paddingRight,
    paddingTop: paddingTop,
    paddingVertical: paddingVertical,
    backfaceVisibility: backfaceVisibility,
    borderBottomWidth: borderBottomWidth,
    borderLeftWidth: borderLeftWidth,
    borderRightWidth: borderRightWidth,
    borderTopWidth: borderTopWidth,
    borderWidth: borderWidth,
    borderBottomColor: borderBottomColor,
    borderBottomLeftRadius: borderBottomLeftRadius,
    borderBottomRightRadius: borderBottomRightRadius,
    borderColor: borderColor,
    borderLeftColor: borderLeftColor,
    borderRadius: borderRadius,
    borderRightColor: borderRightColor,
    borderTopColor: borderTopColor,
    borderTopLeftRadius: borderTopLeftRadius,
    borderTopRightRadius: borderTopRightRadius,
    opacity: opacity,
    elevation: elevation,
    zIndex: zIndex,
    cover: cover,
    contain: contain,
    stretch: stretch,
    repeat: repeat,
    resizeMode: resizeMode,
    shadowColor: shadowColor,
    shadowOffset: shadowOffset,
    shadowOpacity: shadowOpacity,
    shadowRadius: shadowRadius,
    MkPercent: MkPercent,
    percent: percent,
    intLeft: intLeft,
    numberLeft: numberLeft,
    percentLeft: percentLeft,
    intRight: intRight,
    numberRight: numberRight,
    percentRight: percentRight,
    intTop: intTop,
    numberTop: numberTop,
    percentTop: percentTop,
    intBottom: intBottom,
    numberBottom: numberBottom,
    percentBottom: percentBottom,
    intFlexBasis: intFlexBasis,
    numberFlexBasis: numberFlexBasis,
    percentFlexBasis: percentFlexBasis,
    intMargin: intMargin,
    numberMargin: numberMargin,
    percentMargin: percentMargin,
    autoMargin: autoMargin,
    intMarginBottom: intMarginBottom,
    numberMarginBottom: numberMarginBottom,
    percentMarginBottom: percentMarginBottom,
    intMarginHorizontal: intMarginHorizontal,
    numberMarginHorizontal: numberMarginHorizontal,
    percentMarginHorizontal: percentMarginHorizontal,
    intMarginLeft: intMarginLeft,
    numberMarginLeft: numberMarginLeft,
    percentMarginLeft: percentMarginLeft,
    intMarginRight: intMarginRight,
    numberMarginRight: numberMarginRight,
    percentMarginRight: percentMarginRight,
    intMarginTop: intMarginTop,
    numberMarginTop: numberMarginTop,
    percentMarginTop: percentMarginTop,
    intMarginVertical: intMarginVertical,
    numberMarginVertical: numberMarginVertical,
    percentMarginVertical: percentMarginVertical,
    percentInt: percentInt,
    percentNumber: percentNumber,
    intWidth: intWidth,
    numberWidth: numberWidth,
    percentWidth: percentWidth,
    intHeight: intHeight,
    numberHeight: numberHeight,
    percentHeight: percentHeight,
    intMaxHeight: intMaxHeight,
    numberMaxHeight: numberMaxHeight,
    percentMaxHeight: percentMaxHeight,
    intMaxWidth: intMaxWidth,
    numberMaxWidth: numberMaxWidth,
    percentMaxWidth: percentMaxWidth,
    intMinHeight: intMinHeight,
    numberMinHeight: numberMinHeight,
    percentMinHeight: percentMinHeight,
    intMinWidth: intMinWidth,
    numberMinWidth: numberMinWidth,
    percentMinWidth: percentMinWidth,
    overflowV: overflowV,
    intPadding: intPadding,
    numberPadding: numberPadding,
    percentPadding: percentPadding,
    intPaddingBottom: intPaddingBottom,
    numberPaddingBottom: numberPaddingBottom,
    percentPaddingBottom: percentPaddingBottom,
    intPaddingHorizontal: intPaddingHorizontal,
    numberPaddingHorizontal: numberPaddingHorizontal,
    percentPaddingHorizontal: percentPaddingHorizontal,
    intPaddingLeft: intPaddingLeft,
    numberPaddingLeft: numberPaddingLeft,
    percentPaddingLeft: percentPaddingLeft,
    intPaddingRight: intPaddingRight,
    numberPaddingRight: numberPaddingRight,
    percentPaddingRight: percentPaddingRight,
    intPaddingTop: intPaddingTop,
    numberPaddingTop: numberPaddingTop,
    percentPaddingTop: percentPaddingTop,
    intPaddingVertical: intPaddingVertical,
    numberPaddingVertical: numberPaddingVertical,
    percentPaddingVertical: percentPaddingVertical,
    visibleBackface: visibleBackface,
    rmCenter: rmCenter,
    styles: $foreign.styles,
    staticStyles: $foreign.staticStyles,
    unsafeStyleProp: $foreign.unsafeStyleProp,
    hairlineWidth: $foreign.hairlineWidth
};
