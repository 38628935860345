import React from 'react';
import { BackHandler, Keyboard } from 'react-native';
import { shape, func } from 'prop-types';
import withGlobal from './withGlobal';
import {
  SHOW_SPINNER,
  HIDE_SPINNER,
  CREATE_DIALOG,
  DESTROY_DIALOG,
} from './navWrapper';

export default function rootNavWrapper(RootNavigator) {
  class Root extends React.Component {
    componentDidMount() {
      BackHandler.addEventListener('hardwareBackPress', this.handleAndroidBack);
    }

    componentWillUnmount() {
      BackHandler.removeEventListener(
        'hardwareBackPress',
        this.handleAndroidBack
      );
    }

    shouldDismissKB = action => {
      switch (action.type) {
        case SHOW_SPINNER:
        case CREATE_DIALOG:
          Keyboard.dismiss();
          break;
        default:
          break;
      }
    };

    updateGlobalState = action => {
      const {
        global: { setLoading, setDialog },
      } = this.props;
      switch (action.type) {
        case SHOW_SPINNER:
          setLoading(true)();
          break;
        case HIDE_SPINNER:
          setLoading(false)();
          break;
        case CREATE_DIALOG:
          setDialog(action.component)();
          break;
        case DESTROY_DIALOG:
          setDialog(null)();
          break;
        default:
          break;
      }
    };

    handleNavigationChange = (navPrevState, navNewState, action) => {
      this.updateGlobalState(action);
      this.shouldDismissKB(action);
    };

    handleAndroidBack = () => {
      const {
        global: { loading },
      } = this.props;
      return loading;
    };

    render() {
      return (
        <RootNavigator onNavigationStateChange={this.handleNavigationChange} />
      );
    }
  }

  Root.propTypes = {
    global: shape({
      setLoading: func.isRequired,
      setDialog: func.isRequired,
    }).isRequired,
  };

  return withGlobal(Root);
}
