// Generated by purs version 0.13.8
"use strict";
var $foreign = require("./foreign.js");
var Data_DateTime = require("../Data.DateTime/index.js");
var Data_DateTime_Instant = require("../Data.DateTime.Instant/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Effect = require("../Effect/index.js");
var nowTime = Data_Functor.map(Effect.functorEffect)(function ($0) {
    return Data_DateTime.time(Data_DateTime_Instant.toDateTime($0));
})($foreign.now);
var nowDateTime = Data_Functor.map(Effect.functorEffect)(Data_DateTime_Instant.toDateTime)($foreign.now);
var nowDate = Data_Functor.map(Effect.functorEffect)(function ($1) {
    return Data_DateTime.date(Data_DateTime_Instant.toDateTime($1));
})($foreign.now);
module.exports = {
    nowDateTime: nowDateTime,
    nowDate: nowDate,
    nowTime: nowTime,
    now: $foreign.now
};
