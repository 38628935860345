// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Data_Bounded = require("../Data.Bounded/index.js");
var Data_Date = require("../Data.Date/index.js");
var Data_Date_Component = require("../Data.Date.Component/index.js");
var Data_DateTime = require("../Data.DateTime/index.js");
var Data_Enum = require("../Data.Enum/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Time = require("../Data.Time/index.js");
var Data_Time_Component = require("../Data.Time.Component/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var mkTime = function (h) {
    return function (m) {
        return function (s) {
            var second = Data_Maybe.fromMaybe(Data_Bounded.bottom(Data_Time_Component.boundedSecond))(Data_Enum.toEnum(Data_Time_Component.boundedEnumSecond)(s));
            var min = Data_Maybe.fromMaybe(Data_Bounded.bottom(Data_Time_Component.boundedMinute))(Data_Enum.toEnum(Data_Time_Component.boundedEnumMinute)(m));
            var hour = Data_Maybe.fromMaybe(Data_Bounded.bottom(Data_Time_Component.boundedHour))(Data_Enum.toEnum(Data_Time_Component.boundedEnumHour)(h));
            return new Data_Time.Time(hour, min, second, Data_Bounded.bottom(Data_Time_Component.boundedMillisecond));
        };
    };
};
var mkDate = function (y) {
    return function (mo) {
        return function (d) {
            var year = Data_Maybe.fromMaybe(2019)(Data_Enum.toEnum(Data_Date_Component.boundedEnumYear)(y));
            var month = Data_Maybe.fromMaybe(Data_Date_Component.January.value)(Data_Enum.toEnum(Data_Date_Component.boundedEnumMonth)(mo));
            var day = Data_Maybe.fromMaybe(Data_Bounded.bottom(Data_Date_Component.boundedDay))(Data_Enum.toEnum(Data_Date_Component.boundedEnumDay)(d));
            return Data_Date.canonicalDate(year)(month)(day);
        };
    };
};
var mkDateTime = function (y) {
    return function (mo) {
        return function (d) {
            return function (h) {
                return function (m) {
                    return function (s) {
                        return new Data_DateTime.DateTime(mkDate(y)(mo)(d), mkTime(h)(m)(s));
                    };
                };
            };
        };
    };
};
var mkDateTime_ = function (y) {
    return function (mo) {
        return function (d) {
            return new Data_DateTime.DateTime(mkDate(y)(mo)(d), Data_Bounded.bottom(Data_Time.boundedTime));
        };
    };
};
var createAff = function (a) {
    return Control_Apply.applySecond(Effect_Aff.applyAff)(Effect_Aff.delay(1000.0))(Control_Applicative.pure(Effect_Aff.applicativeAff)(a));
};
module.exports = {
    createAff: createAff,
    mkDateTime: mkDateTime,
    mkDateTime_: mkDateTime_
};
