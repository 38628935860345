// dummy since there is no app settings

export const openAppSettings = () => Promise.resolve();

export const requestCameraPermission = () => {
  if (
    navigator &&
    navigator.mediaDevices &&
    navigator.mediaDevices.getUserMedia
  ) {
    return navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(mediaStream => {
        if (mediaStream.active) {
          mediaStream.getVideoTracks().forEach(value => {
            value.stop();
          });
          return { status: 'granted' };
        }
        return {
          status: 'denied',
        };
      })
      .catch(domExceptionErr => {
        switch (domExceptionErr.name) {
          case 'NotAllowedError':
            if (
              domExceptionErr.message &&
              domExceptionErr.message.includes('dismissed')
            ) {
              return {
                status: 'undetermined',
              };
            }
            return {
              status: 'denied',
            };
          default:
            return {
              status: 'denied',
            };
        }
      });
  }
  return Promise.resolve({
    status: 'denied',
  });
};

export const checkCameraPermission = requestCameraPermission;
