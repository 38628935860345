// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var ZAP_Dart_Client_Components_External_DatePicker = require("../ZAP.Dart.Client.Components.External.DatePicker/index.js");
var ZAP_Dart_Client_Components_Icon = require("../ZAP.Dart.Client.Components.Icon/index.js");
var ZAP_Dart_Client_Components_Input_DateSelect = require("../ZAP.Dart.Client.Components.Input.DateSelect/index.js");
var ZAP_Dart_Client_Components_Input_Styles = require("../ZAP.Dart.Client.Components.Input.Styles/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Utilities_HasDateInfo = require("../ZAP.Dart.Client.Utilities.HasDateInfo/index.js");
var sheet = {
    iconContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.position(React_Basic_Native_Styles.absolute), React_Basic_Native_Styles.right(React_Basic_Native_Styles.intRight)(10), React_Basic_Native_Styles.height(React_Basic_Native_Styles.percentHeight)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles.zIndex(-1 | 0) ])
};
var datepicker = function (props) {
    if (!ZAP_Dart_Client_Utilities_HasDateInfo.hasDateInput) {
        return ZAP_Dart_Client_Components_Input_DateSelect.dateSelect(props);
    };
    if (ZAP_Dart_Client_Utilities_HasDateInfo.hasDateInput) {
        return React_Basic_Native_Generated.view_([ ZAP_Dart_Client_Components_External_DatePicker["datepicker'"]({
            style: ZAP_Dart_Client_Components_Input_Styles.container,
            date: Data_Nullable.toNullable(Data_Functor.map(Data_Maybe.functorMaybe)(ZAP_Dart_Client_Screens_Utils.fromDate)(props.value)),
            onDateChange: function ($1) {
                return props.onChange(Data_JSDate.toDate($1))();
            }
        }), React_Basic_Native_Generated.view()({
            style: sheet.iconContainer,
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(ZAP_Dart_Client_Components_Icon.icon({
                name: "calendar",
                size: 20,
                color: ZAP_Dart_Client_Styles_Colors.altFg
            }))
        }) ]);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Client.Components.Input.DatePicker (line 34, column 20 - line 52, column 6): " + [ ZAP_Dart_Client_Utilities_HasDateInfo.hasDateInput.constructor.name ]);
};
module.exports = {
    datepicker: datepicker
};
