// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var ReactNavigation = require("../ReactNavigation/index.js");
var ReactNavigation_Utils_TabBarCreators = require("../ReactNavigation.Utils.TabBarCreators/index.js");
var ZAP_Dart_Client_Components_TabBar = require("../ZAP.Dart.Client.Components.TabBar/index.js");
var ZAP_Dart_Client_Flows_About = require("../ZAP.Dart.Client.Flows.About/index.js");
var ZAP_Dart_Client_Flows_Catalog = require("../ZAP.Dart.Client.Flows.Catalog/index.js");
var ZAP_Dart_Client_Flows_Profile = require("../ZAP.Dart.Client.Flows.Profile/index.js");
var ZAP_Dart_Client_Flows_Rewards = require("../ZAP.Dart.Client.Flows.Rewards/index.js");
var tabBarComponent = ReactNavigation_Utils_TabBarCreators.mkTabBarContent_(ZAP_Dart_Client_Components_TabBar.tabBarContent);
var rs = ReactNavigation.mkRoutes(ReactNavigation.mkRoutesI()(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLNil)()()(new Data_Symbol.IsSymbol(function () {
    return "rewards";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "profile";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "catalog";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "about";
}))));
var flow = (function () {
    var afterInitF = function (x) {
        return Control_Applicative.pure(ReactNavigation.navmApplicative)(x.value);
    };
    return ReactNavigation.createFlow(new Data_Symbol.IsSymbol(function () {
        return "catalog";
    }))(ReactNavigation.navCreatorBottomTab)(ReactNavigation.isRouterConfigOpts()()())(rs)(rs.catalog)(Control_Category.identity(Control_Category.categoryFn))({
        tabBarComponent: tabBarComponent
    })(afterInitF);
})();
var homeScreen = ReactNavigation.createFlowScreen()(flow)({
    catalog: ReactNavigation.routeUiOpts_(ZAP_Dart_Client_Flows_Catalog.catalogScreen),
    rewards: ReactNavigation.routeUiOpts_(ZAP_Dart_Client_Flows_Rewards.rewardsScreen),
    about: ReactNavigation.routeUiOpts_(ZAP_Dart_Client_Flows_About.aboutScreen),
    profile: ReactNavigation.routeUiOpts_(ZAP_Dart_Client_Flows_Profile.profileScreen)
});
module.exports = {
    homeScreen: homeScreen
};
