// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var ZAP_Dart_Client_Components_FormSection = require("../ZAP.Dart.Client.Components.FormSection/index.js");
var ZAP_Dart_Client_Components_Input_Dropdown = require("../ZAP.Dart.Client.Components.Input.Dropdown/index.js");
var ZAP_Dart_Client_Components_Input_InputGroup = require("../ZAP.Dart.Client.Components.Input.InputGroup/index.js");
var ZAP_Dart_Client_Components_Input_TextField = require("../ZAP.Dart.Client.Components.Input.TextField/index.js");
var ZAP_Dart_Common = require("../ZAP.Dart.Common/index.js");
var Confirmed = (function () {
    function Confirmed(value0) {
        this.value0 = value0;
    };
    Confirmed.create = function (value0) {
        return new Confirmed(value0);
    };
    return Confirmed;
})();
var Unconfirmed = (function () {
    function Unconfirmed(value0) {
        this.value0 = value0;
    };
    Unconfirmed.create = function (value0) {
        return new Unconfirmed(value0);
    };
    return Unconfirmed;
})();
var Unsubmitted = (function () {
    function Unsubmitted(value0) {
        this.value0 = value0;
    };
    Unsubmitted.create = function (value0) {
        return new Unsubmitted(value0);
    };
    return Unsubmitted;
})();
var updateProfileErrDialog = function (upErr) {
    if (upErr instanceof ZAP_Dart_Common.UpdateProfileErrInvalidEmail) {
        return {
            title: "Invalid Email",
            message: "The email you entered is invalid.",
            actionLabel: "Okay"
        };
    };
    if (upErr instanceof ZAP_Dart_Common.UpdateProfileErrExistingEmail) {
        return {
            title: "Existing Email",
            message: "The email you entered is already being used.",
            actionLabel: "Okay"
        };
    };
    if (upErr instanceof ZAP_Dart_Common.UpdateProfileErrMissingFields) {
        return {
            title: "Your profile is incomplete",
            message: "You haven\u2019t answered: " + (Data_String_Common.joinWith(", ")(upErr.value0.fieldNames) + "."),
            actionLabel: "Okay"
        };
    };
    if (upErr instanceof ZAP_Dart_Common.UpdateProfileErrOthers) {
        return {
            title: "Update Failed",
            message: "Unable to update profile",
            actionLabel: "Okay"
        };
    };
    throw new Error("Failed pattern match at ZAP.Dart.Client.Components.ProfileForm (line 237, column 3 - line 257, column 8): " + [ upErr.constructor.name ]);
};
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(40) ]),
    inputGroup: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(25) ])
};
var component = React_Basic.createComponent("ProfileForm");
var profileForm = (function () {
    var render = function (self) {
        var writeGender = function (v) {
            if (v instanceof ZAP_Dart_Common.Female) {
                return "Female";
            };
            if (v instanceof ZAP_Dart_Common.Male) {
                return "Male";
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Components.ProfileForm (line 202, column 23 - line 204, column 25): " + [ v.constructor.name ]);
        };
        var renderRegistrationField = function (defaultType) {
            return function (getComponent) {
                var v = Data_Foldable.find(Data_Foldable.foldableArray)(function (f) {
                    return Data_Eq.eq(ZAP_Dart_Common.eqInputType)(f.input)(new ZAP_Dart_Common.ZAPDefault({
                        "default": defaultType
                    }));
                })(self.props.registrationFields);
                if (v instanceof Data_Maybe.Just) {
                    return getComponent(v.value0.isRequired);
                };
                if (v instanceof Data_Maybe.Nothing) {
                    return Data_Monoid.mempty(React_Basic.monoidJSX);
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Components.ProfileForm (line 183, column 61 - line 185, column 28): " + [ v.constructor.name ]);
            };
        };
        var readGender = function (v) {
            if (v === "Female") {
                return new Data_Maybe.Just(ZAP_Dart_Common.Female.value);
            };
            if (v === "Male") {
                return new Data_Maybe.Just(ZAP_Dart_Common.Male.value);
            };
            return Data_Maybe.Nothing.value;
        };
        var handleChange = function (f) {
            return self.props.onProfileChange(f(self.props.profile));
        };
        var onCityChange = function (value) {
            return function __do() {
                handleChange(function (v) {
                    return {
                        firstName: v.firstName,
                        lastName: v.lastName,
                        mobile: v.mobile,
                        email: v.email,
                        location: value,
                        gender: v.gender
                    };
                })();
                return self.setState(function (v) {
                    return {
                        city: value,
                        region: v.region
                    };
                })();
            };
        };
        var onRegionChange = function (value) {
            return self.setStateThen(function (v) {
                return {
                    region: value,
                    city: Data_Maybe.Nothing.value
                };
            })(handleChange(function (v) {
                return {
                    firstName: v.firstName,
                    lastName: v.lastName,
                    mobile: v.mobile,
                    email: v.email,
                    location: value,
                    gender: v.gender
                };
            }));
        };
        var getRegionItems = Data_Functor.mapFlipped(Data_Functor.functorArray)(self.props.locations)(function (r) {
            return {
                value: r.id,
                label: r.name
            };
        });
        var getEmailValue = function (v) {
            if (v instanceof Confirmed) {
                return v.value0;
            };
            if (v instanceof Unconfirmed) {
                return v.value0;
            };
            if (v instanceof Unsubmitted) {
                return v.value0;
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Components.ProfileForm (line 190, column 9 - line 190, column 45): " + [ v.constructor.name ]);
        };
        var getCityItems = (function () {
            var region = Data_Foldable.find(Data_Foldable.foldableArray)(function (r) {
                return Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(new Data_Maybe.Just(r.id))(self.state.region);
            })(self.props.locations);
            var cities = Data_Maybe.fromMaybe([  ])(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(region)(function (v) {
                return v.cities;
            }));
            return Data_Functor.mapFlipped(Data_Functor.functorArray)(cities)(function (c) {
                return {
                    value: c.id,
                    label: c.name
                };
            });
        })();
        return ZAP_Dart_Client_Components_FormSection.formSection({
            style: sheet.container,
            title: "Personal Information",
            children: [ renderRegistrationField(ZAP_Dart_Common.FullName.value)(function (isRequired) {
                return ZAP_Dart_Client_Components_Input_InputGroup.inputGroup({
                    style: sheet.inputGroup,
                    label: "First Name",
                    isRequired: isRequired,
                    input: ZAP_Dart_Client_Components_Input_TextField.textField({
                        onChangeText: function (text) {
                            return handleChange(function (v) {
                                return {
                                    firstName: text,
                                    lastName: v.lastName,
                                    mobile: v.mobile,
                                    email: v.email,
                                    location: v.location,
                                    gender: v.gender
                                };
                            });
                        },
                        value: self.props.profile.firstName,
                        disabled: false
                    })
                });
            }), renderRegistrationField(ZAP_Dart_Common.FullName.value)(function (isRequired) {
                return ZAP_Dart_Client_Components_Input_InputGroup.inputGroup({
                    style: sheet.inputGroup,
                    label: "Last Name",
                    isRequired: isRequired,
                    input: ZAP_Dart_Client_Components_Input_TextField.textField({
                        onChangeText: function (text) {
                            return handleChange(function (v) {
                                return {
                                    firstName: v.firstName,
                                    lastName: text,
                                    mobile: v.mobile,
                                    email: v.email,
                                    location: v.location,
                                    gender: v.gender
                                };
                            });
                        },
                        value: self.props.profile.lastName,
                        disabled: false
                    })
                });
            }), ZAP_Dart_Client_Components_Input_InputGroup.inputGroup({
                style: sheet.inputGroup,
                label: "Mobile Number",
                isRequired: true,
                input: ZAP_Dart_Client_Components_Input_TextField.textField({
                    onChangeText: function (v) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                    },
                    value: new Data_Maybe.Just("+" + self.props.profile.mobile),
                    disabled: true
                })
            }), renderRegistrationField(ZAP_Dart_Common.EmailAddress.value)(function (isRequired) {
                return ZAP_Dart_Client_Components_Input_InputGroup.inputGroup({
                    style: sheet.inputGroup,
                    label: "Email",
                    isRequired: isRequired,
                    input: ZAP_Dart_Client_Components_Input_TextField.textField({
                        onChangeText: function (text) {
                            return handleChange(function (v) {
                                return {
                                    firstName: v.firstName,
                                    lastName: v.lastName,
                                    mobile: v.mobile,
                                    email: Data_Functor.map(Data_Maybe.functorMaybe)(Unsubmitted.create)(text),
                                    location: v.location,
                                    gender: v.gender
                                };
                            });
                        },
                        value: Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(self.props.profile.email)(getEmailValue),
                        disabled: false
                    })
                });
            }), renderRegistrationField(ZAP_Dart_Common.Gender.value)(function (isRequired) {
                return ZAP_Dart_Client_Components_Input_InputGroup.inputGroup({
                    style: sheet.inputGroup,
                    label: "Gender",
                    isRequired: isRequired,
                    input: ZAP_Dart_Client_Components_Input_Dropdown.dropdown_({
                        value: Data_Functor.map(Data_Maybe.functorMaybe)(writeGender)(self.props.profile.gender),
                        onChange: function (value) {
                            return handleChange(function (v) {
                                return {
                                    firstName: v.firstName,
                                    lastName: v.lastName,
                                    mobile: v.mobile,
                                    email: v.email,
                                    location: v.location,
                                    gender: Control_Bind.bind(Data_Maybe.bindMaybe)(value)(readGender)
                                };
                            });
                        },
                        items: [ "Female", "Male" ],
                        placeholder: "Select an item..."
                    })
                });
            }), renderRegistrationField(ZAP_Dart_Common.Location.value)(function (isRequired) {
                return ZAP_Dart_Client_Components_Input_InputGroup.inputGroup({
                    style: sheet.inputGroup,
                    label: "Region",
                    isRequired: isRequired,
                    input: ZAP_Dart_Client_Components_Input_Dropdown.dropdown({
                        value: self.state.region,
                        onChange: onRegionChange,
                        items: getRegionItems,
                        placeholder: "Select an item..."
                    })
                });
            }), renderRegistrationField(ZAP_Dart_Common.Location.value)(function (isRequired) {
                return ZAP_Dart_Client_Components_Input_InputGroup.inputGroup({
                    style: sheet.inputGroup,
                    label: "City",
                    isRequired: isRequired,
                    input: ZAP_Dart_Client_Components_Input_Dropdown.dropdown({
                        value: self.state.city,
                        onChange: onCityChange,
                        items: getCityItems,
                        placeholder: "Select an item..."
                    })
                });
            }) ]
        });
    };
    var initialState = {
        region: Data_Maybe.Nothing.value,
        city: Data_Maybe.Nothing.value
    };
    var didMount = function (self) {
        var region = Data_Foldable.find(Data_Foldable.foldableArray)(function (r) {
            return Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(new Data_Maybe.Just(r.id))(self.props.profile.location);
        })(self.props.locations);
        var cities = Control_Bind.bind(Control_Bind.bindArray)(self.props.locations)(function (r) {
            return Data_Functor.mapFlipped(Data_Functor.functorArray)(r.cities)(function (c) {
                return {
                    regionId: r.id,
                    cityId: c.id
                };
            });
        });
        var city = Data_Foldable.find(Data_Foldable.foldableArray)(function (r) {
            return Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(new Data_Maybe.Just(r.cityId))(self.props.profile.location);
        })(cities);
        if (region instanceof Data_Maybe.Just) {
            return self.setState(function (v) {
                var $29 = {};
                for (var $30 in v) {
                    if ({}.hasOwnProperty.call(v, $30)) {
                        $29[$30] = v[$30];
                    };
                };
                $29.region = new Data_Maybe.Just(region.value0.id);
                return $29;
            });
        };
        if (city instanceof Data_Maybe.Just) {
            return self.setState(function (v) {
                var $33 = {};
                for (var $34 in v) {
                    if ({}.hasOwnProperty.call(v, $34)) {
                        $33[$34] = v[$34];
                    };
                };
                $33.region = new Data_Maybe.Just(city.value0.regionId);
                $33.city = new Data_Maybe.Just(city.value0.cityId);
                return $33;
            });
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
    return React_Basic.make()(component)({
        initialState: initialState,
        didMount: didMount,
        render: render
    });
})();
module.exports = {
    Confirmed: Confirmed,
    Unconfirmed: Unconfirmed,
    Unsubmitted: Unsubmitted,
    profileForm: profileForm,
    updateProfileErrDialog: updateProfileErrDialog
};
