// Generated by purs version 0.13.8
"use strict";
var $foreign = require("./foreign.js");
var React_Basic = require("../React.Basic/index.js");
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var swiper = function (dictCoercible) {
    var $2 = React_Basic.element($foreign["_swiper"]);
    var $3 = Untagged_Coercible.coerce();
    return function ($4) {
        return $2($3($4));
    };
};
var controlsProps = function (dictCoercible) {
    return Untagged_Coercible.coerce();
};
module.exports = {
    swiper: swiper,
    controlsProps: controlsProps
};
