// Generated by purs version 0.13.8
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native = require("../React.Basic.Native/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var Untagged_Coercible = require("../Untagged.Coercible/index.js");
var Untagged_Union = require("../Untagged.Union/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(8), React_Basic_Native_Styles_Flex.flexShrink(1) ]),
    listBulletContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingRight(React_Basic_Native_Styles.intPaddingRight)(10) ]),
    listBullet: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.regular), React_Basic_Native_Styles_Text.color(ZAP_Dart_Client_Styles_Colors.altFg) ])
};
var renderListBullet = function (ordered, index) {
    return React_Basic_Native_Generated.text()({
        style: sheet.listBulletContainer,
        children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_Native_Generated.text()({
            style: sheet.listBullet,
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_Native.string((function () {
                if (ordered) {
                    return Data_Show.show(Data_Show.showInt)(index + 1 | 0) + ".";
                };
                if (!ordered) {
                    return "\u2022";
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Components.External.Markdown (line 67, column 21 - line 69, column 35): " + [ ordered.constructor.name ]);
            })()))
        }))
    });
};
var renderLink = function (href, v, c) {
    return React_Basic_Native_Generated.text()({
        onPress: React_Basic_Events.handler_(ZAP_Dart_Client_Screens_Utils.openUrl(href)),
        children: Control_Applicative.pure(Control_Applicative.applicativeArray)(c)
    });
};
var markdown$prime = function (dictCoercible) {
    var $4 = React_Basic.element($foreign["_markdown"]);
    var $5 = Untagged_Coercible.coerce();
    return function ($6) {
        return $4($5($6));
    };
};
var defaultMarkdownStyles = function (textStyle) {
    return {
        text: React_Basic_Native_Styles.styles(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.regular), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(0.7), React_Basic_Native_Styles_Text.color(ZAP_Dart_Client_Styles_Colors.altFg) ])(textStyle)),
        list: React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(0) ]),
        listItem: React_Basic_Native_Styles.styles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_Styles_Flex.flexStart(React_Basic_Native_Styles_Flex.justifyContentFlexSE)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_Styles_Flex.flexStart(React_Basic_Native_Styles_Flex.alignmentStartEnd)), React_Basic_Native_Styles.marginVertical(React_Basic_Native_Styles.intMarginVertical)(4) ]),
        link: React_Basic_Native_Styles.styles([ React_Basic_Native_Styles_Text.textDecorationLine(React_Basic_Native_Styles_Text.underline), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_Styles_Flex.flexStart(React_Basic_Native_Styles_Flex.alignmentStartEnd)), React_Basic_Native_Styles_Text.color(ZAP_Dart_Client_Styles_Colors.secondaryLink) ])
    };
};
var render = function (props) {
    var markdownStyles = defaultMarkdownStyles(Untagged_Union.fromUndefinedOr([  ])(props.textStyle));
    return markdown$prime()({
        style: sheet.container,
        markdownStyles: markdownStyles,
        renderListBullet: renderListBullet,
        renderLink: renderLink,
        children: props.value
    });
};
var markdown = function (dictCoercible) {
    var $7 = Untagged_Coercible.coerce();
    return function ($8) {
        return render($7($8));
    };
};
module.exports = {
    markdown: markdown
};
