// Generated by purs version 0.13.8
"use strict";
var Data_Function = require("../Data.Function/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var ReactNavigation = require("../ReactNavigation/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var mkTabBarContentHelper = function (f) {
    return function (props) {
        var nav = ReactNavigation.getNavigator(props.navigation);
        var state = ReactNavigation.getState(nav);
        var cbi = ReactNavigation.getParam(props.navigation);
        var info = (Data_Newtype.unwrap(ReactNavigation_Types.navCbiNewtype)(cbi)).infoF(nav);
        var onAction = (Data_Newtype.unwrap(ReactNavigation_Types.navCbiNewtype)(cbi)).cb(nav);
        return f(info)({
            onAction: onAction,
            onTabPress: props.onTabPress,
            onTabLongPress: props.onTabLongPress,
            state: state
        });
    };
};
var mkTabBarContent = function ($0) {
    return ReactNavigation.tabBar(mkTabBarContentHelper($0));
};
var mkTabBarContent_ = function (c) {
    return mkTabBarContent(Data_Function["const"](c));
};
module.exports = {
    mkTabBarContent: mkTabBarContent,
    mkTabBarContent_: mkTabBarContent_
};
