// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var ReactNavigation = require("../ReactNavigation/index.js");
var ReactNavigation_AuthFlow = require("../ReactNavigation.AuthFlow/index.js");
var ReactNavigation_Class_MonadNavM = require("../ReactNavigation.Class.MonadNavM/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ReactNavigation_Utils_HOCs_WithPageProps = require("../ReactNavigation.Utils.HOCs.WithPageProps/index.js");
var ReactNavigation_Utils_NavBarCreators = require("../ReactNavigation.Utils.NavBarCreators/index.js");
var ReactNavigation_Utils_PageCreators = require("../ReactNavigation.Utils.PageCreators/index.js");
var ReactNavigation_Utils_RouterConfigs = require("../ReactNavigation.Utils.RouterConfigs/index.js");
var ZAP_Dart_Client_Components_NavBar_SimpleNavBar = require("../ZAP.Dart.Client.Components.NavBar.SimpleNavBar/index.js");
var ZAP_Dart_Client_Dialogs_Template_Alert = require("../ZAP.Dart.Client.Dialogs.Template.Alert/index.js");
var ZAP_Dart_Client_Flows_AuthorizedResetPin = require("../ZAP.Dart.Client.Flows.AuthorizedResetPin/index.js");
var ZAP_Dart_Client_Flows_Utils = require("../ZAP.Dart.Client.Flows.Utils/index.js");
var ZAP_Dart_Client_Screens_CartMgt = require("../ZAP.Dart.Client.Screens.CartMgt/index.js");
var ZAP_Dart_Client_Screens_CompleteProfile = require("../ZAP.Dart.Client.Screens.CompleteProfile/index.js");
var ZAP_Dart_Client_Screens_EnterPin = require("../ZAP.Dart.Client.Screens.EnterPin/index.js");
var ZAP_Dart_Client_Screens_PurchaseReceipt = require("../ZAP.Dart.Client.Screens.PurchaseReceipt/index.js");
var ZAP_Dart_Client_Screens_RewardItem = require("../ZAP.Dart.Client.Screens.RewardItem/index.js");
var ZAP_Dart_Client_Screens_Types = require("../ZAP.Dart.Client.Screens.Types/index.js");
var ZAP_Dart_Common = require("../ZAP.Dart.Common/index.js");
var rs = ReactNavigation.mkRoutes(ReactNavigation.mkRoutesI()(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLNil)()()(new Data_Symbol.IsSymbol(function () {
    return "rewardItem";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "resetPin";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "purchaseReceipt";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "enterPin";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "completeProfile";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "cartMgt";
}))));
var navBar = ReactNavigation_Utils_NavBarCreators.mkNavBarContent_(ZAP_Dart_Client_Components_NavBar_SimpleNavBar.simpleNavBar)(ReactNavigation_Types.NBTotalPoints.value);
var flow = (function () {
    var showErrorDialog = function (v) {
        return Control_Apply.applySecond(ReactNavigation_AuthFlow.applyAuthNavM)(ReactNavigation_Class_MonadNavM.navDialogI(ReactNavigation_AuthFlow.monadNMAuthNavM)(ZAP_Dart_Client_Dialogs_Template_Alert.alertDialog)({
            title: v.title,
            message: v.message,
            actionLabel: "Try Again"
        }))(ReactNavigation_Class_MonadNavM.navPopToTop(ReactNavigation_AuthFlow.monadNMAuthNavM));
    };
    var initRouteF = function (v) {
        return ReactNavigation_AuthFlow.processInfos()(ReactNavigation_AuthFlow.processInfosRLConsapiCalls(ReactNavigation_AuthFlow["processInfosRLConsInitD'"](ReactNavigation_AuthFlow.processInfosRLNil)()(new Data_Symbol.IsSymbol(function () {
            return "initD";
        }))()()())(new Data_Symbol.IsSymbol(function () {
            return "apiCalls";
        }))()(ReactNavigation_AuthFlow.applyAuthRLCons(ReactNavigation_AuthFlow.applyAuthRLCons(ReactNavigation_AuthFlow.applyAuthRLCons(ReactNavigation_AuthFlow.applyAuthRLNil)(new Data_Symbol.IsSymbol(function () {
            return "updateCart";
        }))()()())(new Data_Symbol.IsSymbol(function () {
            return "getCart";
        }))()()())(new Data_Symbol.IsSymbol(function () {
            return "deleteFromCart";
        }))()()())()()()())({
            initD: v.clientApi.getCart,
            apiCalls: {
                updateCart: v.clientApi.updateCart,
                deleteFromCart: v.clientApi.deleteFromCart,
                getCart: v.clientApi.getCart
            }
        })(v.authToken);
    };
    var defaultNavigationOptions = ReactNavigation.navigationOptions()()({
        header: Data_Nullable.notNull(navBar)
    });
    var getCheckoutErrMsg = function (e) {
        return {
            title: "Checkout Failed",
            message: (function () {
                if (e instanceof ZAP_Dart_Common.CheckoutErrInvalidProductCode) {
                    return "Invalid product code/s";
                };
                if (e instanceof ZAP_Dart_Common.CheckoutErrReservationFailed) {
                    return "Failed to reserve product/s";
                };
                if (e instanceof ZAP_Dart_Common.CheckoutErrTransactionAlreadyUsed) {
                    return "Transaction already used";
                };
                if (e instanceof ZAP_Dart_Common.CheckoutErrInvalidPurchase) {
                    return "Invalid purchase";
                };
                if (e instanceof ZAP_Dart_Common.CheckoutErrInvalidProducts) {
                    return "Invalid product/s";
                };
                return "Unable to process your request";
            })()
        };
    };
    var configBuilder = ReactNavigation_Utils_RouterConfigs.stackRcBuilder(ReactNavigation.isRouterConfigOpts()()())()(ReactNavigation.isRouterConfigOpts()()())({
        defaultNavigationOptions: defaultNavigationOptions
    });
    var afterInitF = function (v) {
        var verifyF = function (v1) {
            return function (ci) {
                return function (auth) {
                    return function (pin) {
                        var processor = function (res) {
                            if (res instanceof Data_Either.Right) {
                                return Data_Either.Right.create(new Data_Either.Right(res.value0));
                            };
                            if (res instanceof Data_Either.Left && res.value0 instanceof ZAP_Dart_Common.CheckoutErrInvalidPin) {
                                return new Data_Either.Left(ZAP_Dart_Client_Screens_Types.PinErrInvalidPin.value);
                            };
                            if (res instanceof Data_Either.Left && res.value0 instanceof ZAP_Dart_Common.CheckoutErrAccountLocked) {
                                return new Data_Either.Left(ZAP_Dart_Client_Screens_Types.PinErrAccountLocked.value);
                            };
                            if (res instanceof Data_Either.Left) {
                                return Data_Either.Right.create(new Data_Either.Left(res.value0));
                            };
                            throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.CartMgt (line 112, column 25 - line 116, column 35): " + [ res.constructor.name ]);
                        };
                        var items = Data_Array.filter(function (item) {
                            return Data_Maybe.fromMaybe(0)(item.quantity) !== 0;
                        })(ci);
                        return Data_Functor.mapFlipped(Control_Monad_Except_Trans.functorExceptT(Effect_Aff.functorAff))(v1.clientApi.checkout(auth)({
                            items: items,
                            pin: pin
                        }))(processor);
                    };
                };
            };
        };
        var rewardItemInfo = function (v1) {
            return function (productId) {
                return function (productCodeId) {
                    return {
                        initD: new ReactNavigation_AuthFlow.InitD(v1.clientApi.getReward, {
                            productId: productId,
                            productCodeId: productCodeId
                        }),
                        apiCalls: {}
                    };
                };
            };
        };
        var completeProfileInfo = function (v1) {
            return {
                initD: v1.clientApi.getProfile,
                apiCalls: {
                    updateProfile: v1.clientApi.updateProfile
                }
            };
        };
        var shouldGoToCompProfile = function (v1) {
            if (!v1) {
                return Control_Bind.bind(ReactNavigation_AuthFlow.bindAuthNavM)(ReactNavigation_AuthFlow.navPushA(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                    return "completeProfile";
                }))())(ReactNavigation_AuthFlow.monadReaderAuthNavM)(ReactNavigation_AuthFlow.monadNMAuthNavM)(ReactNavigation_AuthFlow.plusAuthNavM)()(ReactNavigation_AuthFlow.processInfosRLConsapiCalls(ReactNavigation_AuthFlow["processInfosRLConsInitD'"](ReactNavigation_AuthFlow.processInfosRLNil)()(new Data_Symbol.IsSymbol(function () {
                    return "initD";
                }))()()())(new Data_Symbol.IsSymbol(function () {
                    return "apiCalls";
                }))()(ReactNavigation_AuthFlow.applyAuthRLCons(ReactNavigation_AuthFlow.applyAuthRLNil)(new Data_Symbol.IsSymbol(function () {
                    return "updateProfile";
                }))()()())()()()())(rs.completeProfile)(completeProfileInfo(v.info)))(function (cp) {
                    if (cp instanceof ZAP_Dart_Client_Screens_Types.CompleteProfileMaybeLater) {
                        return ReactNavigation_Class_MonadNavM.navGoBack(ReactNavigation_AuthFlow.monadNMAuthNavM);
                    };
                    if (cp instanceof ZAP_Dart_Client_Screens_Types.CompleteProfileSuccess) {
                        return Control_Applicative.pure(ReactNavigation_AuthFlow.applicativeAuthNavM)(Data_Unit.unit);
                    };
                    throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.CartMgt (line 90, column 9 - line 92, column 46): " + [ cp.constructor.name ]);
                });
            };
            if (v1) {
                return Control_Applicative.pure(ReactNavigation_AuthFlow.applicativeAuthNavM)(Data_Unit.unit);
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.CartMgt (line 87, column 29 - line 93, column 24): " + [ v1.constructor.name ]);
        };
        if (v.value instanceof Data_Either.Right) {
            return Control_Bind.discard(Control_Bind.discardUnit)(ReactNavigation_AuthFlow.bindAuthNavM)(shouldGoToCompProfile(v.value.value0.value0))(function () {
                return Control_Bind.bind(ReactNavigation_AuthFlow.bindAuthNavM)(ReactNavigation_AuthFlow.navPushA(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                    return "enterPin";
                }))())(ReactNavigation_AuthFlow.monadReaderAuthNavM)(ReactNavigation_AuthFlow.monadNMAuthNavM)(ReactNavigation_AuthFlow.plusAuthNavM)()(ReactNavigation_AuthFlow.processInfosRLConsapiCalls(ReactNavigation_AuthFlow.processInfosRLNil)(new Data_Symbol.IsSymbol(function () {
                    return "apiCalls";
                }))()(ReactNavigation_AuthFlow.applyAuthRLCons(ReactNavigation_AuthFlow.applyAuthRLNil)(new Data_Symbol.IsSymbol(function () {
                    return "verifyPin";
                }))()()())()()()())(rs.enterPin)({
                    apiCalls: {
                        verifyPin: verifyF(v.info)(v.value.value0.value1)
                    }
                }))(function (checkoutRes) {
                    return Control_Bind.bind(ReactNavigation_AuthFlow.bindAuthNavM)((function () {
                        if (checkoutRes instanceof Data_Either.Right && (checkoutRes.value0 instanceof ZAP_Dart_Client_Screens_Types.EPAReturnVerifiedPin && checkoutRes.value0.value0 instanceof Data_Either.Right)) {
                            return Control_Applicative.pure(ReactNavigation_AuthFlow.applicativeAuthNavM)(checkoutRes.value0.value0.value0);
                        };
                        if (checkoutRes instanceof Data_Either.Right && (checkoutRes.value0 instanceof ZAP_Dart_Client_Screens_Types.EPAReturnVerifiedPin && checkoutRes.value0.value0 instanceof Data_Either.Left)) {
                            return showErrorDialog(getCheckoutErrMsg(checkoutRes.value0.value0.value0));
                        };
                        if (checkoutRes instanceof Data_Either.Right && checkoutRes.value0 instanceof ZAP_Dart_Client_Screens_Types.EPAResetPin) {
                            return Control_Bind.bind(ReactNavigation_AuthFlow.bindAuthNavM)(ReactNavigation_Class_MonadNavM.navPushIK(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                                return "resetPin";
                            }))())(ReactNavigation_AuthFlow.monadNMAuthNavM)(rs.resetPin)(v.info))(function (v1) {
                                return ReactNavigation_Class_MonadNavM.navGoBackToKey(ReactNavigation_AuthFlow.monadNMAuthNavM)(v1.value0);
                            });
                        };
                        if (checkoutRes instanceof Data_Either.Left) {
                            return showErrorDialog(checkoutRes.value0);
                        };
                        throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.CartMgt (line 68, column 26 - line 74, column 36): " + [ checkoutRes.constructor.name ]);
                    })())(function (checkoutSuccess) {
                        return Control_Bind.bind(ReactNavigation_AuthFlow.bindAuthNavM)(ReactNavigation_Class_MonadNavM.navPushI(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                            return "purchaseReceipt";
                        }))())(ReactNavigation_AuthFlow.monadNMAuthNavM)(rs.purchaseReceipt)({
                            items: checkoutSuccess.purchasedProducts,
                            totalPoints: checkoutSuccess.totalPoints
                        }))(function (pra) {
                            if (pra instanceof ZAP_Dart_Client_Screens_Types.PRGoToRewardDetails) {
                                return Control_Bind.discard(Control_Bind.discardUnit)(ReactNavigation_AuthFlow.bindAuthNavM)(ReactNavigation_AuthFlow.navPushA(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                                    return "rewardItem";
                                }))())(ReactNavigation_AuthFlow.monadReaderAuthNavM)(ReactNavigation_AuthFlow.monadNMAuthNavM)(ReactNavigation_AuthFlow.plusAuthNavM)()(ReactNavigation_AuthFlow.processInfosRLConsapiCalls(ReactNavigation_AuthFlow.processInfosRLConsInitD(ReactNavigation_AuthFlow.processInfosRLNil)()(new Data_Symbol.IsSymbol(function () {
                                    return "initD";
                                }))()()())(new Data_Symbol.IsSymbol(function () {
                                    return "apiCalls";
                                }))()(ReactNavigation_AuthFlow.applyAuthRLNil)()()()())(rs.rewardItem)(rewardItemInfo(v.info)(pra.value0)(pra.value1)))(function () {
                                    return Control_Applicative.pure(ReactNavigation_AuthFlow.applicativeAuthNavM)(ZAP_Dart_Client_Screens_Types.PopToTop.value);
                                });
                            };
                            if (pra instanceof ZAP_Dart_Client_Screens_Types.PRGoBackToCatalog) {
                                return Control_Applicative.pure(ReactNavigation_AuthFlow.applicativeAuthNavM)(ZAP_Dart_Client_Screens_Types.PopToTop.value);
                            };
                            throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.CartMgt (line 80, column 7 - line 84, column 43): " + [ pra.constructor.name ]);
                        });
                    });
                });
            });
        };
        if (v.value instanceof Data_Either.Left) {
            return Control_Applicative.pure(ReactNavigation_AuthFlow.applicativeAuthNavM)(ZAP_Dart_Client_Screens_Types.ScanQr.value);
        };
        throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.CartMgt (line 64, column 90 - line 85, column 26): " + [ v.value.constructor.name ]);
    };
    return ReactNavigation_AuthFlow.createAuthFlow(new Data_Symbol.IsSymbol(function () {
        return "cartMgt";
    }))(ReactNavigation.navCreatorStack)(ReactNavigation_AuthFlow.runAuthNavMfirstRouteAsync)(rs)(rs.cartMgt)(initRouteF)(configBuilder)(afterInitF);
})();
var cartMgtScreen = (function () {
    var resetPin = ReactNavigation.routeUiOpts()()({
        navigationOptions: ReactNavigation.navigationOptions()()({
            header: Data_Nullable.notNull(ZAP_Dart_Client_Flows_Utils.titleNavBar("RESET PIN"))
        }),
        screen: ZAP_Dart_Client_Flows_AuthorizedResetPin.authorizedResetPinScreen
    });
    var enterPin = ReactNavigation.routeUiOpts()()({
        navigationOptions: ReactNavigation.navigationOptions()()({
            header: Data_Nullable.notNull(ZAP_Dart_Client_Flows_Utils.titleNavBar("CHECKOUT CART"))
        }),
        screen: ReactNavigation_Utils_PageCreators.routeUiPage(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherClientErr)(ZAP_Dart_Client_Screens_EnterPin.enterPin(new Data_Tuple.Tuple("", "")))
    });
    return ReactNavigation.createFlowScreen()(flow)({
        cartMgt: ReactNavigation.routeUiOpts_(ReactNavigation_Utils_PageCreators.routeUiInitD(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherClientErr)()(ZAP_Dart_Client_Screens_CartMgt.cartMgt)),
        enterPin: enterPin,
        purchaseReceipt: ReactNavigation.routeUiOpts_(ReactNavigation_Utils_PageCreators.routeUiPage(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherFallback)(ZAP_Dart_Client_Screens_PurchaseReceipt.purchaseReceipt)),
        completeProfile: ReactNavigation.routeUiOpts_(ReactNavigation_Utils_PageCreators.routeUiInitD(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherClientErr)()(ZAP_Dart_Client_Screens_CompleteProfile.completeProfile)),
        rewardItem: ReactNavigation.routeUiOpts_(ReactNavigation_Utils_PageCreators.routeUiInitD(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherClientErr)()(ZAP_Dart_Client_Screens_RewardItem.rewardItem)),
        resetPin: resetPin
    });
})();
module.exports = {
    cartMgtScreen: cartMgtScreen
};
