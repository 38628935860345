// Generated by purs version 0.13.8
"use strict";
var VisibilityEnum = function (hidden, visible) {
    this.hidden = hidden;
    this.visible = visible;
};
var NormalEnum = function (normal) {
    this.normal = normal;
};
var NoneEnum = function (none) {
    this.none = none;
};
var CenterEnum = function (center) {
    this.center = center;
};
var AutoEnum = function (auto) {
    this.auto = auto;
};
var visible = function (dict) {
    return dict.visible;
};
var normal = function (dict) {
    return dict.normal;
};
var none = function (dict) {
    return dict.none;
};
var hidden = function (dict) {
    return dict.hidden;
};
var center = function (dict) {
    return dict.center;
};
var auto = function (dict) {
    return dict.auto;
};
module.exports = {
    auto: auto,
    center: center,
    hidden: hidden,
    none: none,
    normal: normal,
    visible: visible,
    AutoEnum: AutoEnum,
    NormalEnum: NormalEnum,
    CenterEnum: CenterEnum,
    VisibilityEnum: VisibilityEnum,
    NoneEnum: NoneEnum
};
