// Generated by purs version 0.13.8
"use strict";
var Data_Unit = require("../Data.Unit/index.js");
var IxApplicative = function (IxApply0, ipure) {
    this.IxApply0 = IxApply0;
    this.ipure = ipure;
};
var ipure = function (dict) {
    return dict.ipure;
};
var iunless = function (dictIxApplicative) {
    return function (v) {
        return function (v1) {
            if (!v) {
                return v1;
            };
            if (v) {
                return ipure(dictIxApplicative)(Data_Unit.unit);
            };
            throw new Error("Failed pattern match at Control.Applicative.Indexed (line 19, column 1 - line 19, column 69): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
var iwhen = function (dictIxApplicative) {
    return function (v) {
        return function (v1) {
            if (v) {
                return v1;
            };
            if (!v) {
                return ipure(dictIxApplicative)(Data_Unit.unit);
            };
            throw new Error("Failed pattern match at Control.Applicative.Indexed (line 15, column 1 - line 15, column 67): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
module.exports = {
    IxApplicative: IxApplicative,
    ipure: ipure,
    iwhen: iwhen,
    iunless: iunless
};
