// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_String_Regex = require("../Data.String.Regex/index.js");
var Data_String_Regex_Flags = require("../Data.String.Regex.Flags/index.js");
var Data_String_Regex_Unsafe = require("../Data.String.Regex.Unsafe/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNative_TextInput = require("../ReactNative.TextInput/index.js");
var ZAP_Dart_Client_Components_Icon = require("../ZAP.Dart.Client.Components.Icon/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var ZAP_Dart_Client_Styles_Utils = require("../ZAP.Dart.Client.Styles.Utils/index.js");
var Default = (function () {
    function Default() {

    };
    Default.value = new Default();
    return Default;
})();
var Numeric = (function () {
    function Numeric() {

    };
    Numeric.value = new Numeric();
    return Numeric;
})();
var AlphaNumeric = (function () {
    function AlphaNumeric() {

    };
    AlphaNumeric.value = new AlphaNumeric();
    return AlphaNumeric;
})();
var unfocusedColor = React_Basic_Native_PropTypes_Color.rgb(191)(191)(191);
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles.borderWidth(React_Basic_Native_Styles.hairlineWidth), React_Basic_Native_Styles.borderRadius(5), React_Basic_Native_Styles.borderColor(ZAP_Dart_Client_Styles_Colors.fgMuted) ]),
    input: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.regular), React_Basic_Native_Styles_Text.color(ZAP_Dart_Client_Styles_Colors.fg), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(15), React_Basic_Native_Styles.borderRightWidth(React_Basic_Native_Styles.hairlineWidth), React_Basic_Native_Styles.borderColor(ZAP_Dart_Client_Styles_Colors.fgMuted), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    iconContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ])
};
var getDInputProps = (function () {
    var numericRegex = Data_String_Regex_Unsafe.unsafeRegex("^[0-9]*$")(Data_String_Regex_Flags.global);
    var alphaNumericRegex = Data_String_Regex_Unsafe.unsafeRegex("^[a-zA-Z0-9]*$")(Data_String_Regex_Flags.global);
    return function (v) {
        if (v instanceof Default) {
            return {
                keyboardType: ReactNative_TextInput["default"],
                validate: function (v1) {
                    return true;
                }
            };
        };
        if (v instanceof Numeric) {
            return {
                keyboardType: ReactNative_TextInput.numeric,
                validate: function (text) {
                    return Data_String_Regex.test(numericRegex)(text);
                }
            };
        };
        if (v instanceof AlphaNumeric) {
            return {
                keyboardType: ReactNative_TextInput["default"],
                validate: function (text) {
                    return Data_String_Regex.test(alphaNumericRegex)(text);
                }
            };
        };
        throw new Error("Failed pattern match at ZAP.Dart.Client.Components.InputWithIconButton (line 129, column 18 - line 141, column 6): " + [ v.constructor.name ]);
    };
})();
var component = React_Basic.createComponent("InputWithIconButton");
var inputWithIconButton = (function () {
    var render = function (props) {
        var iconWidth = props.containerWidth - props.inputWidth | 0;
        var dInputProps = getDInputProps(props.inputType);
        return React_Basic_Native_Generated.view()({
            style: React_Basic_Native_Styles["styles'"]([ sheet.container, React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(props.containerWidth), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(props.containerHeight) ]) ]),
            children: [ React_Basic_Native_Generated.textInput()({
                style: React_Basic_Native_Styles["styles'"]([ sheet.input, React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(props.inputWidth), React_Basic_Native_Styles_Text.fontSize(props.fontSize), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.intLetterSpacing)(props.letterSpacing) ]) ]),
                placeholderTextColor: ZAP_Dart_Client_Styles_Utils.fromColor(unfocusedColor),
                placeholder: props.placeholderText,
                keyboardType: dInputProps.keyboardType,
                autoCapitalize: ReactNative_TextInput.none,
                spellCheck: false,
                autoCorrect: false,
                secureTextEntry: props.secureTextEntry,
                autoFocus: props.autoFocus,
                value: props.value,
                onChangeText: function (v) {
                    return Control_Applicative.when(Effect.applicativeEffect)(dInputProps.validate(v))(props.onChange(v))();
                },
                onSubmitEditing: React_Basic_Events.handler_(props.onSubmitEditing)
            }), React_Basic_Native_Generated.touchableOpacity()({
                style: React_Basic_Native_Styles["styles'"]([ sheet.iconContainer, React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(iconWidth) ]) ]),
                onPress: React_Basic_Events.handler_(props.onIconPress),
                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_Native_Generated.view()({
                    style: ZAP_Dart_Client_Styles_Utils.noStyle,
                    children: Control_Applicative.pure(Control_Applicative.applicativeArray)(ZAP_Dart_Client_Components_Icon.icon({
                        name: props.iconName,
                        size: props.iconSize,
                        color: ZAP_Dart_Client_Styles_Colors.primary
                    }))
                }))
            }) ]
        });
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    inputWithIconButton: inputWithIconButton,
    Default: Default,
    Numeric: Numeric,
    AlphaNumeric: AlphaNumeric
};
