// Generated by purs version 0.13.8
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad = require("../Control.Monad/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Aff_Class = require("../Effect.Aff.Class/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Effect_Uncurried = require("../Effect.Uncurried/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Foreign = require("../Foreign/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var React_Basic = require("../React.Basic/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var Record = require("../Record/index.js");
var Record_Builder = require("../Record.Builder/index.js");
var Type_Data_RowList = require("../Type.Data.RowList/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var ZAP_Dart_Client_Dialogs_Template_SingleAction = require("../ZAP.Dart.Client.Dialogs.Template.SingleAction/index.js");
var ZAP_Dart_Client_Dialogs_Template_Wrapper = require("../ZAP.Dart.Client.Dialogs.Template.Wrapper/index.js");
var TransitionConfig = function (x) {
    return x;
};
var TabViewLayout = function (x) {
    return x;
};
var TabBar$prime = function (x) {
    return x;
};
var Route = (function () {
    function Route() {

    };
    Route.value = new Route();
    return Route;
})();
var NavigationTransitionProps = function (x) {
    return x;
};
var NavTypeProxy = (function () {
    function NavTypeProxy() {

    };
    NavTypeProxy.value = new NavTypeProxy();
    return NavTypeProxy;
})();
var NavM = function (x) {
    return x;
};
var NavBar$prime = function (x) {
    return x;
};
var RouterConfigBuilder = function (x) {
    return x;
};
var Flow = function (x) {
    return x;
};
var RoutesCheck = {};
var RouterConfigOpts = {};
var NavigationOptionsSpec = {};
var NavCreator = function (createNavigatorFn) {
    this.createNavigatorFn = createNavigatorFn;
};
var MkRoutesRL = function (mkRoutesRL) {
    this.mkRoutesRL = mkRoutesRL;
};
var MkRoutes = function (mkRoutes) {
    this.mkRoutes = mkRoutes;
};
var MkRouteUiMapRl = {};
var MkRouteUiMap = {};
var IsRouterConfig = function (mkRouterConfig$prime) {
    this["mkRouterConfig'"] = mkRouterConfig$prime;
};
var HasTop = {};
var HasRoute = function (IsSymbol0) {
    this.IsSymbol0 = IsSymbol0;
};
var HasNone = {};
var HasBottom = {};
var AllSameParams = {};
var viewConfigOptsStack = RouterConfigOpts;
var unsafeGetParamData = function (unav) {
    return $foreign["_recursivelyGetParamData"](unav);
};
var uikit = "uikit";
var top = function (dictHasTop) {
    return "top";
};
var tabBarComponent = React_Basic.createComponent("TabBar");
var tabBar = function (f) {
    var render = function (self) {
        return f(self.props);
    };
    return TabBar$prime(React_Basic.toReactComponent()(Control_Category.identity(Control_Category.categoryFn))(tabBarComponent)({
        render: render
    }));
};
var switchRoutesCheck = RoutesCheck;
var switchNT = NavTypeProxy.value;
var stackRoutesCheck = RoutesCheck;
var stackNT = NavTypeProxy.value;
var showSpinner = function (v) {
    return $foreign["_showSpinner"](v);
};
var screen = "screen";
var routerConfigOptsSwitch = RouterConfigOpts;
var routerConfigOptsBottomTab = RouterConfigOpts;
var routeUiOpts_ = Unsafe_Coerce.unsafeCoerce;
var routeUiOpts = function (dictCons) {
    return function (dictUnion) {
        return Unsafe_Coerce.unsafeCoerce;
    };
};
var routeUiMap$prime = function (dictMkRouteUiMap) {
    return Unsafe_Coerce.unsafeCoerce;
};
var routeUiMap = function (dictMkRouteUiMap) {
    return function (v) {
        return routeUiMap$prime();
    };
};
var routeName = function (dictIsSymbol) {
    return function (v) {
        return Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
    };
};
var rootNT = NavTypeProxy.value;
var popToTop = function (v) {
    return $foreign["_popToTop"](v);
};
var pop = function (v) {
    return function (count) {
        return function () {
            return $foreign["_pop"](v)(count);
        };
    };
};
var none = function (dictHasNone) {
    return "none";
};
var nilAllSameParams = AllSameParams;
var navmMonad = new Control_Monad.Monad(function () {
    return navmApplicative;
}, function () {
    return navmBind;
});
var navmBind = new Control_Bind.Bind(function () {
    return navmApply;
}, function (v) {
    return function (f) {
        return function (nav) {
            return function (cb) {
                return v(nav)(function (nav$prime) {
                    return function (a) {
                        var v1 = f(a);
                        return v1(nav$prime)(function (nav$prime$prime) {
                            return function (b) {
                                return cb(nav$prime$prime)(b);
                            };
                        });
                    };
                });
            };
        };
    };
});
var navmApply = new Control_Apply.Apply(function () {
    return navmFunctor;
}, Control_Monad.ap(navmMonad));
var navmApplicative = new Control_Applicative.Applicative(function () {
    return navmApply;
}, function (a) {
    return function (nav) {
        return function (cb) {
            return cb(nav)(a);
        };
    };
});
var navmFunctor = new Data_Functor.Functor(Control_Applicative.liftA1(navmApplicative));
var navmMonadEffect = new Effect_Class.MonadEffect(function () {
    return navmMonad;
}, function (e) {
    return function (nav) {
        return function (cb) {
            return Control_Bind.bind(Effect.bindEffect)(e)(cb(nav));
        };
    };
});
var navigationOptionsSwitch = NavigationOptionsSpec;
var navigationOptionsStack = NavigationOptionsSpec;
var navigationOptionsBottomTab = NavigationOptionsSpec;
var navigationOptions = function (dictNavigationOptionsSpec) {
    return function (dictUnion) {
        return Unsafe_Coerce.unsafeCoerce;
    };
};
var navPopToTop = function (nav) {
    return function (cb) {
        return popToTop(nav);
    };
};
var navPop = function (count) {
    return function (nav) {
        return function (cb) {
            return pop(nav)(count);
        };
    };
};
var navCreatorSwitch = new NavCreator(function (v) {
    return $foreign["_createSwitchNavigator"];
});
var navCreatorStack = new NavCreator(function (v) {
    return $foreign["_createStackNavigator"];
});
var navCreatorBottomTab = new NavCreator(function (v) {
    return $foreign["_createBottomTabNavigator"];
});
var modal = "modal";
var mkRoutesRLNil = new MkRoutesRL(function (v) {
    return Control_Category.identity(Record_Builder.categoryBuilder);
});
var mkRoutesRL = function (dict) {
    return dict.mkRoutesRL;
};
var mkRoutesRLCons = function (dictMkRoutesRL) {
    return function (dictCons) {
        return function (dictLacks) {
            return function (dictIsSymbol) {
                return new MkRoutesRL(function (v) {
                    return Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder)(Record_Builder.insert()()(dictIsSymbol)(Data_Symbol.SProxy.value)(Route.value))(mkRoutesRL(dictMkRoutesRL)(Type_Data_RowList.RLProxy.value));
                });
            };
        };
    };
};
var mkRoutesI = function (dictRowToList) {
    return function (dictMkRoutesRL) {
        return new MkRoutes((function () {
            var builder = mkRoutesRL(dictMkRoutesRL)(Type_Data_RowList.RLProxy.value);
            return Record_Builder.build(builder)({});
        })());
    };
};
var mkRoutes = function (dict) {
    return dict.mkRoutes;
};
var mkRouterConfig$prime = function (dict) {
    return dict["mkRouterConfig'"];
};
var mkRouteUiMapRlNil = MkRouteUiMapRl;
var mkRouteUiMapRlCons = function (dictIsSymbol) {
    return function (dictCons) {
        return function (dictMkRouteUiMapRl) {
            return MkRouteUiMapRl;
        };
    };
};
var mkRouteUiMapI = function (dictRowToList) {
    return function (dictRoutesCheck) {
        return function (dictMkRouteUiMapRl) {
            return MkRouteUiMap;
        };
    };
};
var left = "left";
var isRouterConfigOpts = function (dictUnion) {
    return function (dictRouterConfigOpts) {
        return function (dictUnion1) {
            return new IsRouterConfig(function (o) {
                return function (i) {
                    return Record.union()(o)(i);
                };
            });
        };
    };
};
var initialRouteB = "initialRoute";
var initNavCbI = function (infoF) {
    return function (onComplete) {
        return function (initialF) {
            return function (f) {
                var initialInfoF = function ($174) {
                    return initialF(infoF($174));
                };
                var cb = function (nav) {
                    return function (a) {
                        var v = f({
                            info: infoF(nav),
                            value: a
                        });
                        return v(nav)(onComplete);
                    };
                };
                return {
                    cb: cb,
                    infoF: initialInfoF
                };
            };
        };
    };
};
var hideSpinner = function (v) {
    return $foreign["_hideSpinner"](v);
};
var runLoadAff = function (nav) {
    return function (aff) {
        return function (cb) {
            return function __do() {
                showSpinner(nav)();
                return Effect_Aff.runAff_(function (res) {
                    return Control_Apply.applySecond(Effect.applyEffect)(hideSpinner(nav))(cb(res));
                })(aff)();
            };
        };
    };
};
var stackNavmMonadAff = new Effect_Aff_Class.MonadAff(function () {
    return navmMonadEffect;
}, function (aff) {
    return function (nav) {
        return function (cb) {
            return runLoadAff(nav)(aff)(function (v) {
                if (v instanceof Data_Either.Right) {
                    return cb(nav)(v.value0);
                };
                if (v instanceof Data_Either.Left) {
                    return Effect_Class_Console.warnShow(Effect_Class.monadEffectEffect)(Effect_Exception.showError)(v.value0);
                };
                throw new Error("Failed pattern match at ReactNavigation (line 921, column 11 - line 923, column 33): " + [ v.constructor.name ]);
            });
        };
    };
});
var hasTopTabBarPosition = HasTop;
var hasRouteI = function (dictIsSymbol) {
    return function (dictCons) {
        return new HasRoute(function () {
            return dictIsSymbol;
        });
    };
};
var hasNoneHeaderMode = HasNone;
var hasNoneBackBehavior = HasNone;
var hasBottomTabBarPosition = HasBottom;
var goBackToKey = function (key) {
    return function (v) {
        return function () {
            return $foreign["_goBack"](v)(key);
        };
    };
};
var navGoBackToKey = function (key) {
    return function (nav) {
        return function (cb) {
            return goBackToKey(key)(nav);
        };
    };
};
var navGoBack = navGoBackToKey("");
var goBack = function (n) {
    return goBackToKey("")(n);
};
var getState = function (v) {
    return $foreign["_state"](v);
};
var getParam = function (v) {
    return Effect_Unsafe.unsafePerformEffect(unsafeGetParamData(v));
};
var parentCallNavCbI = function (subNav) {
    return function (getParent) {
        return getParam(getParent(subNav));
    };
};
var getNavigator = function (v) {
    return v;
};
var getIsFocused = function (v) {
    return v.isFocused;
};
var getCbInfo = function (nav) {
    var v = getParam(nav);
    return v.infoF(getNavigator(nav));
};
var $$float = "float";
var fadeInPlace = "fade-in-place";
var errorC = React_Basic.createComponent("ErrorDialog");
var errorUi = (function () {
    var render = function (v) {
        return ZAP_Dart_Client_Dialogs_Template_Wrapper.wrapper({
            onClose: v.onClose,
            children: [ ZAP_Dart_Client_Dialogs_Template_SingleAction.singleActionUi({
                title: v.title,
                message: v.message,
                primaryAction: {
                    label: "Try Again",
                    onPress: v.onClose
                }
            }) ]
        });
    };
    return React_Basic.makeStateless(errorC)(render);
})();
var dialogUiC = React_Basic.createComponent("DialogUi");
var destroyDialog = function (v) {
    return $foreign["_destroyDialog"](v);
};
var dialogUi = function (dialogComp) {
    return function (onClose) {
        var render = function (v) {
            var onAction = function (v1) {
                if (v1 instanceof ReactNavigation_Types.DgSubmit) {
                    return Control_Apply.applySecond(Effect.applyEffect)(v.cb(v.navigation)(v1.value0))(destroyDialog(v.navigation));
                };
                if (v1 instanceof ReactNavigation_Types.DgClose) {
                    return Control_Apply.applySecond(Effect.applyEffect)(onClose)(destroyDialog(v.navigation));
                };
                throw new Error("Failed pattern match at ReactNavigation (line 1127, column 7 - line 1127, column 44): " + [ v1.constructor.name ]);
            };
            return ZAP_Dart_Client_Dialogs_Template_Wrapper.wrapper({
                onClose: onAction(ReactNavigation_Types.DgClose.value),
                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(dialogComp({
                    onAction: onAction,
                    info: v.info
                }))
            });
        };
        return React_Basic.makeStateless(dialogUiC)(render);
    };
};
var dataFieldName = "_data";
var paramToParamObject = function (param) {
    return Foreign_Object.singleton(dataFieldName)(Foreign.unsafeToForeign(param));
};
var mkRouterConfig = function (dictIsRouterConfig) {
    return function (dictIsSymbol) {
        return function (o) {
            return function (i) {
                var initialRouteParams = paramToParamObject(i.initialRouteParam);
                var initialRouteName = routeName(dictIsSymbol)(i.initialRoute);
                return mkRouterConfig$prime(dictIsRouterConfig)(o)({
                    initialRouteName: initialRouteName,
                    initialRouteParams: initialRouteParams
                });
            };
        };
    };
};
var mkRouterConfigBuilder = function (dictIsRouterConfig) {
    return function (o) {
        return function (dictIsSymbol) {
            return mkRouterConfig(dictIsRouterConfig)(dictIsSymbol)(o);
        };
    };
};
var navigate$prime = function (dictIsSymbol) {
    return function (f) {
        return function (r) {
            return function (param) {
                return function () {
                    return f(routeName(dictIsSymbol)(r), paramToParamObject(param));
                };
            };
        };
    };
};
var navigate = function (dictHasRoute) {
    return function (v) {
        return navigate$prime(dictHasRoute.IsSymbol0())($foreign["_navigate"](v));
    };
};
var navNavigateI = function (dictHasRoute) {
    return function (r) {
        return function (info) {
            return function (nav) {
                return function (cb) {
                    return navigate(dictHasRoute)(nav)(r)({
                        cb: cb,
                        infoF: Data_Function["const"](info)
                    });
                };
            };
        };
    };
};
var navNavigate = function (dictHasRoute) {
    return function (r) {
        return navNavigateI(dictHasRoute)(r)(Data_Unit.unit);
    };
};
var push = function (dictHasRoute) {
    return function (v) {
        return navigate$prime(dictHasRoute.IsSymbol0())($foreign["_push"](v));
    };
};
var navPushI = function (dictHasRoute) {
    return function (r) {
        return function (info) {
            return function (nav) {
                return function (cb) {
                    return push(dictHasRoute)(nav)(r)({
                        cb: cb,
                        infoF: Data_Function["const"](info)
                    });
                };
            };
        };
    };
};
var navPush = function (dictHasRoute) {
    return function (r) {
        return navPushI(dictHasRoute)(r)(Data_Unit.unit);
    };
};
var navPushIK = function (dictHasRoute) {
    return function (r) {
        return function (info) {
            return Control_Bind.bind(navmBind)(navPushI(dictHasRoute)(r)(info))(function (a) {
                return function (nav) {
                    return function (cb) {
                        var st = getState(nav);
                        return cb(nav)(new Data_Tuple.Tuple(st.key, a));
                    };
                };
            });
        };
    };
};
var navPushK = function (dictHasRoute) {
    return function (r) {
        return navPushIK(dictHasRoute)(r)(Data_Unit.unit);
    };
};
var replace = function (dictHasRoute) {
    return function (v) {
        return navigate$prime(dictHasRoute.IsSymbol0())($foreign["_replace"](v));
    };
};
var navReplaceI = function (dictHasRoute) {
    return function (r) {
        return function (info) {
            return function (nav) {
                return function (cb) {
                    return replace(dictHasRoute)(nav)(r)({
                        cb: cb,
                        infoF: Data_Function["const"](info)
                    });
                };
            };
        };
    };
};
var navReplace = function (dictHasRoute) {
    return function (r) {
        return navReplaceI(dictHasRoute)(r)(Data_Unit.unit);
    };
};
var createNavigatorFn = function (dict) {
    return dict.createNavigatorFn;
};
var createFlowRawScreen = function (dictMkRouteUiMap) {
    return function (v) {
        return function (i) {
            return function (onComplete) {
                return function (opts) {
                    return v(routeUiMap$prime()(opts))(Data_Function["const"](i))(Data_Function["const"](onComplete));
                };
            };
        };
    };
};
var createDialog = function (v) {
    return function (comp) {
        return function () {
            return $foreign["_createDialog"](v)(comp);
        };
    };
};
var createErrDialog = function (nav) {
    return function (v) {
        return function (eff) {
            return createDialog(nav)(errorUi({
                title: v.title,
                message: v.message,
                onClose: Control_Apply.applySecond(Effect.applyEffect)(eff)(destroyDialog(nav))
            }));
        };
    };
};
var navErrDialog = function (ed) {
    return function (nav) {
        return function (cb) {
            return createErrDialog(nav)(ed)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
        };
    };
};
var navmMonadAff = new Effect_Aff_Class.MonadAff(function () {
    return navmMonadEffect;
}, function (aff) {
    return function (nav) {
        return function (cb) {
            return Effect_Aff.runAff_(function (v) {
                if (v instanceof Data_Either.Right) {
                    return cb(nav)(v.value0);
                };
                if (v instanceof Data_Either.Left) {
                    return function __do() {
                        Effect_Class_Console.warnShow(Effect_Class.monadEffectEffect)(Effect_Exception.showError)(v.value0)();
                        return createErrDialog(nav)({
                            title: "Oops, something's not right.",
                            message: "We are unable to process your request right now."
                        })(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))();
                    };
                };
                throw new Error("Failed pattern match at ReactNavigation (line 930, column 11 - line 938, column 28): " + [ v.constructor.name ]);
            })(aff);
        };
    };
});
var navDialogIC = function (dialogComp) {
    return function (info) {
        return function (onClose) {
            var navDialogComp = dialogUi(dialogComp)(onClose);
            return function (nav) {
                return function (cb) {
                    return createDialog(nav)(navDialogComp({
                        navigation: nav,
                        cb: cb,
                        info: info
                    }));
                };
            };
        };
    };
};
var navDialogI = function (dialogComp) {
    return function (info) {
        return navDialogIC(dialogComp)(info)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
    };
};
var navDialog = function (comp) {
    return navDialogI(comp)(Data_Unit.unit);
};
var consAllSameParams = function (dictAllSameParams) {
    return AllSameParams;
};
var center = "center";
var card = "card";
var callNavCbI = function (v) {
    return v.cb;
};
var callCb = function (nav) {
    return function (b) {
        var v = getParam(nav);
        return v.cb(getNavigator(nav))(b);
    };
};
var createFlowScreen = function (dictMkRouteUiMap) {
    return function (v) {
        return function (opts) {
            return v(routeUiMap$prime()(opts))(getCbInfo)(callCb);
        };
    };
};
var buildRouterConfig = function (dictIsSymbol) {
    return function (v) {
        return function (i) {
            return v(dictIsSymbol)(i);
        };
    };
};
var createNavigator$prime = function (dictIsSymbol) {
    return function (dictNavCreator) {
        return function (v) {
            return function (uis) {
                return function (optsF) {
                    return function (rcBuilder) {
                        var getParent = function (v1) {
                            var parent = Effect_Unsafe.unsafePerformEffect($foreign["_dangerouslyGetParent"](v1));
                            return ReactNavigation_Types.RNavNavigator(parent);
                        };
                        var createFn = $foreign.navWrapper(createNavigatorFn(dictNavCreator)(NavTypeProxy.value));
                        var config = buildRouterConfig(dictIsSymbol)(rcBuilder)(optsF(getParent));
                        return createFn(uis, config);
                    };
                };
            };
        };
    };
};
var createNavigator = function (dictIsSymbol) {
    return function (dictNavCreator) {
        return function (dictIsRouterConfig) {
            return function (px) {
                return function (rs) {
                    return function (uis) {
                        return function (opts) {
                            return function (rcOpts) {
                                return createNavigator$prime(dictIsSymbol)(dictNavCreator)(px)(uis)(Data_Function["const"](opts))(mkRouterConfigBuilder(dictIsRouterConfig)(rcOpts));
                            };
                        };
                    };
                };
            };
        };
    };
};
var createBottomTabNavigator = function (dictIsSymbol) {
    return function (dictIsRouterConfig) {
        return createNavigator(dictIsSymbol)(navCreatorBottomTab)(dictIsRouterConfig)(Type_Proxy["Proxy"].value);
    };
};
var createStackNavigator = function (dictIsSymbol) {
    return function (dictIsRouterConfig) {
        return createNavigator(dictIsSymbol)(navCreatorStack)(dictIsRouterConfig)(Type_Proxy["Proxy"].value);
    };
};
var createSwitchNavigator = function (dictIsSymbol) {
    return function (dictIsRouterConfig) {
        return createNavigator(dictIsSymbol)(navCreatorSwitch)(dictIsRouterConfig)(Type_Proxy["Proxy"].value);
    };
};
var createSubNavigator$prime = function (dictIsSymbol) {
    return function (dictNavCreator) {
        return function (v) {
            return function (initialRoute) {
                return function (initRouteF) {
                    return function (onA) {
                        return function (uis) {
                            return function (config) {
                                return function (infoP) {
                                    return function (onCompleteP) {
                                        return createNavigator$prime(dictIsSymbol)(dictNavCreator)(Type_Proxy["Proxy"].value)(uis)(function (g) {
                                            return {
                                                initialRoute: initialRoute,
                                                initialRouteParam: initNavCbI(function ($175) {
                                                    return infoP(g($175));
                                                })(function ($176) {
                                                    return onCompleteP(g($176));
                                                })(initRouteF)(onA)
                                            };
                                        })(config);
                                    };
                                };
                            };
                        };
                    };
                };
            };
        };
    };
};
var createFlow$prime = function (dictIsSymbol) {
    return function (dictNavCreator) {
        return function (rs) {
            return function (initialRoute) {
                return function (initRouteF) {
                    return function (rcB) {
                        return function (onA) {
                            return function (uis) {
                                return createSubNavigator$prime(dictIsSymbol)(dictNavCreator)(rs)(initialRoute)(initRouteF)(onA)(uis)(rcB);
                            };
                        };
                    };
                };
            };
        };
    };
};
var createFlow = function (dictIsSymbol) {
    return function (dictNavCreator) {
        return function (dictIsRouterConfig) {
            return function (rs) {
                return function (initialRoute) {
                    return function (initRouteF) {
                        return function (rcOpts) {
                            return function (onA) {
                                return createFlow$prime(dictIsSymbol)(dictNavCreator)(rs)(initialRoute)(initRouteF)(mkRouterConfigBuilder(dictIsRouterConfig)(rcOpts))(onA);
                            };
                        };
                    };
                };
            };
        };
    };
};
var createFlow_ = function (dictIsSymbol) {
    return function (dictNavCreator) {
        return function (dictIsRouterConfig) {
            return function (rs) {
                return function (initialRoute) {
                    return function (initRouteF) {
                        return function (onA) {
                            return createFlow(dictIsSymbol)(dictNavCreator)(dictIsRouterConfig)(rs)(initialRoute)(initRouteF)({})(onA);
                        };
                    };
                };
            };
        };
    };
};
var createSubNavigator = function (dictIsSymbol) {
    return function (dictNavCreator) {
        return function (dictIsRouterConfig) {
            return function (rs) {
                return function (initialRoute) {
                    return function (onA) {
                        return function (uis) {
                            return function (rcOpts) {
                                return createSubNavigator$prime(dictIsSymbol)(dictNavCreator)(rs)(initialRoute)(Control_Category.identity(Control_Category.categoryFn))(onA)(uis)(mkRouterConfigBuilder(dictIsRouterConfig)(rcOpts))(getCbInfo)(callCb);
                            };
                        };
                    };
                };
            };
        };
    };
};
var bottomTabRoutesCheck = function (dictAllSameParams) {
    return RoutesCheck;
};
var bottomTabNT = NavTypeProxy.value;
var bottom = function (dictHasBottom) {
    return "bottom";
};
var addListener = function (v) {
    return function (evt) {
        return function (listener) {
            return Data_Functor.mapFlipped(Effect.functorEffect)(function () {
                return $foreign["_addListener"](v)(ReactNavigation_Types.eventTypeName(evt), Effect_Uncurried.mkEffectFn1(Data_Function["const"](listener)));
            })(function (v1) {
                return v1.remove;
            });
        };
    };
};
module.exports = {
    Route: Route,
    routeName: routeName,
    callNavCbI: callNavCbI,
    MkRoutes: MkRoutes,
    mkRoutes: mkRoutes,
    MkRoutesRL: MkRoutesRL,
    mkRoutesRL: mkRoutesRL,
    switchNT: switchNT,
    stackNT: stackNT,
    bottomTabNT: bottomTabNT,
    HasRoute: HasRoute,
    getNavigator: getNavigator,
    getParam: getParam,
    navigate: navigate,
    goBack: goBack,
    addListener: addListener,
    push: push,
    pop: pop,
    popToTop: popToTop,
    showSpinner: showSpinner,
    hideSpinner: hideSpinner,
    getState: getState,
    getIsFocused: getIsFocused,
    RouterConfigOpts: RouterConfigOpts,
    HasNone: HasNone,
    none: none,
    HasTop: HasTop,
    top: top,
    HasBottom: HasBottom,
    bottom: bottom,
    initialRouteB: initialRouteB,
    card: card,
    modal: modal,
    "float": $$float,
    screen: screen,
    fadeInPlace: fadeInPlace,
    uikit: uikit,
    left: left,
    center: center,
    NavigationTransitionProps: NavigationTransitionProps,
    TransitionConfig: TransitionConfig,
    TabViewLayout: TabViewLayout,
    IsRouterConfig: IsRouterConfig,
    "mkRouterConfig'": mkRouterConfig$prime,
    mkRouterConfig: mkRouterConfig,
    mkRouterConfigBuilder: mkRouterConfigBuilder,
    NavCreator: NavCreator,
    createNavigatorFn: createNavigatorFn,
    createNavigator: createNavigator,
    "createNavigator'": createNavigator$prime,
    createSubNavigator: createSubNavigator,
    "createSubNavigator'": createSubNavigator$prime,
    createSwitchNavigator: createSwitchNavigator,
    createStackNavigator: createStackNavigator,
    createBottomTabNavigator: createBottomTabNavigator,
    navigationOptions: navigationOptions,
    NavigationOptionsSpec: NavigationOptionsSpec,
    routeUiOpts: routeUiOpts,
    routeUiOpts_: routeUiOpts_,
    routeUiMap: routeUiMap,
    MkRouteUiMap: MkRouteUiMap,
    MkRouteUiMapRl: MkRouteUiMapRl,
    RoutesCheck: RoutesCheck,
    AllSameParams: AllSameParams,
    tabBar: tabBar,
    "NavBar'": NavBar$prime,
    NavM: NavM,
    navNavigate: navNavigate,
    navNavigateI: navNavigateI,
    navGoBack: navGoBack,
    navGoBackToKey: navGoBackToKey,
    navReplace: navReplace,
    navReplaceI: navReplaceI,
    navPush: navPush,
    navPushK: navPushK,
    navPushI: navPushI,
    navPushIK: navPushIK,
    navPop: navPop,
    navPopToTop: navPopToTop,
    navDialog: navDialog,
    navDialogI: navDialogI,
    navDialogIC: navDialogIC,
    navErrDialog: navErrDialog,
    createErrDialog: createErrDialog,
    Flow: Flow,
    createFlow: createFlow,
    createFlow_: createFlow_,
    "createFlow'": createFlow$prime,
    createFlowScreen: createFlowScreen,
    createFlowRawScreen: createFlowRawScreen,
    createDialog: createDialog,
    destroyDialog: destroyDialog,
    runLoadAff: runLoadAff,
    mkRoutesI: mkRoutesI,
    mkRoutesRLNil: mkRoutesRLNil,
    mkRoutesRLCons: mkRoutesRLCons,
    hasRouteI: hasRouteI,
    navCreatorSwitch: navCreatorSwitch,
    navCreatorStack: navCreatorStack,
    navCreatorBottomTab: navCreatorBottomTab,
    routerConfigOptsSwitch: routerConfigOptsSwitch,
    hasNoneBackBehavior: hasNoneBackBehavior,
    viewConfigOptsStack: viewConfigOptsStack,
    hasNoneHeaderMode: hasNoneHeaderMode,
    routerConfigOptsBottomTab: routerConfigOptsBottomTab,
    hasTopTabBarPosition: hasTopTabBarPosition,
    hasBottomTabBarPosition: hasBottomTabBarPosition,
    isRouterConfigOpts: isRouterConfigOpts,
    mkRouteUiMapI: mkRouteUiMapI,
    mkRouteUiMapRlNil: mkRouteUiMapRlNil,
    mkRouteUiMapRlCons: mkRouteUiMapRlCons,
    stackRoutesCheck: stackRoutesCheck,
    switchRoutesCheck: switchRoutesCheck,
    bottomTabRoutesCheck: bottomTabRoutesCheck,
    nilAllSameParams: nilAllSameParams,
    consAllSameParams: consAllSameParams,
    navigationOptionsSwitch: navigationOptionsSwitch,
    navigationOptionsStack: navigationOptionsStack,
    navigationOptionsBottomTab: navigationOptionsBottomTab,
    navmFunctor: navmFunctor,
    navmApply: navmApply,
    navmApplicative: navmApplicative,
    navmBind: navmBind,
    navmMonad: navmMonad,
    navmMonadEffect: navmMonadEffect,
    stackNavmMonadAff: stackNavmMonadAff,
    navmMonadAff: navmMonadAff,
    createAppContainer: $foreign.createAppContainer,
    "_navigate": $foreign["_navigate"],
    "_dangerouslyGetParent": $foreign["_dangerouslyGetParent"]
};
