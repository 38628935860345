// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var InvalidDate = (function () {
    function InvalidDate() {

    };
    InvalidDate.value = new InvalidDate();
    return InvalidDate;
})();
var handleError = function (self) {
    return function (err) {
        var errConfig = {
            title: "Invalid Date",
            message: "The date you entered is invalid.",
            actionLabel: "Okay"
        };
        return self.props.showDialog(new ReactNavigation_Types.Alert(errConfig))(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
    };
};
module.exports = {
    InvalidDate: InvalidDate,
    handleError: handleError
};
