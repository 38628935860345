// Generated by purs version 0.13.8
"use strict";
var $foreign = require("./foreign.js");
var React_Basic = require("../React.Basic/index.js");
var upDirection = "up";
var rightDirection = "right";
var leftDirection = "left";
var enhancedModal_ = function (dictOptional) {
    return React_Basic.element($foreign["_enhancedModal"]);
};
var downDirection = "down";
module.exports = {
    upDirection: upDirection,
    downDirection: downDirection,
    leftDirection: leftDirection,
    rightDirection: rightDirection,
    enhancedModal_: enhancedModal_,
    "_enhancedModal": $foreign["_enhancedModal"]
};
