import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import hasDateInput from '../hasDateInput.web';

const DatePicker = ({ style, date, onDateChange }) =>
  hasDateInput ? (
    <input
      style={style}
      type="date"
      value={date ? format(date, 'yyyy-MM-dd') : ''}
      onChange={e => onDateChange(new Date(e.target.value))}
    />
  ) : (
    <div style={style}>date input not supported</div>
  );

DatePicker.propTypes = {
  style: PropTypes.any.isRequired, // eslint-disable-line
  date: PropTypes.instanceOf(Date),
  onDateChange: PropTypes.func.isRequired,
};

DatePicker.defaultProps = {
  date: null,
};

export default DatePicker;
