import React from 'react';
import { GlobalContext } from './GlobalContext';

const withGlobal = Component => () => (
  <GlobalContext.Consumer>
    {global => <Component global={global} />}
  </GlobalContext.Consumer>
);

export default withGlobal;
