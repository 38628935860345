// Generated by purs version 0.13.8
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Control_Monad_Except = require("../Control.Monad.Except/index.js");
var Control_Monad_Maybe_Trans = require("../Control.Monad.Maybe.Trans/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Data_Argonaut_Core = require("../Data.Argonaut.Core/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Foreign_JSON = require("../Foreign.JSON/index.js");
var React_Basic = require("../React.Basic/index.js");
var ReactNative_Alert = require("../ReactNative.Alert/index.js");
var ReactNative_AsyncStorage = require("../ReactNative.AsyncStorage/index.js");
var ReactNative_Platform = require("../ReactNative.Platform/index.js");
var ReactNative_ToastAndroid = require("../ReactNative.ToastAndroid/index.js");
var ZAP_Dart_Client_ApiClient = require("../ZAP.Dart.Client.ApiClient/index.js");
var ZAP_Dart_Client_Components_External_TapGestureHandler = require("../ZAP.Dart.Client.Components.External.TapGestureHandler/index.js");
var ZAP_Dart_Client_Constants = require("../ZAP.Dart.Client.Constants/index.js");
var ZAP_Dart_Client_Utilities_Persistence = require("../ZAP.Dart.Client.Utilities.Persistence/index.js");
var ZRPC_ZRPCRead = require("../ZRPC.ZRPCRead/index.js");
var ZRPC_ZRPCWrite = require("../ZRPC.ZRPCWrite/index.js");
var saveAdvancedOpts = function (devO) {
    return Control_Monad_Error_Class.catchError(Effect_Aff.monadErrorAff)(ZAP_Dart_Client_Utilities_Persistence.setItem(ZAP_Dart_Client_Utilities_Persistence.AdvancedOptsKey.value)(Data_Argonaut_Core.stringify(ZRPC_ZRPCWrite.zrpcWrite(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "usePreprod";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteBoolean))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)()))(devO))))(Data_Function["const"](Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit)));
};
var loadAdvancedOpts = (function () {
    var read = function (forgn) {
        var v = Control_Monad_Except.runExcept(ZRPC_ZRPCRead.zrpcRead(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "usePreprod";
        }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))(forgn));
        if (v instanceof Data_Either.Right) {
            return Control_Applicative.pure(Control_Monad_Maybe_Trans.applicativeMaybeT(Effect_Aff.monadAff))(v.value0);
        };
        if (v instanceof Data_Either.Left) {
            return Control_Plus.empty(Control_Monad_Maybe_Trans.plusMaybeT(Effect_Aff.monadAff));
        };
        throw new Error("Failed pattern match at ZAP.Dart.Client.Contexts.AdvancedOpts (line 181, column 16 - line 183, column 20): " + [ v.constructor.name ]);
    };
    var maybeT = (function () {
        var $32 = Control_Applicative.pure(Effect_Aff.applicativeAff);
        return function ($33) {
            return Control_Monad_Maybe_Trans.MaybeT($32($33));
        };
    })();
    var parseJ = function (s) {
        return maybeT(Data_Either.hush(Control_Monad_Except.runExcept(Foreign_JSON.parseJSON(s))));
    };
    var getFromAS = Control_Monad_Maybe_Trans.MaybeT(Control_Monad_Error_Class.catchError(Effect_Aff.monadErrorAff)(ZAP_Dart_Client_Utilities_Persistence.getItem(ZAP_Dart_Client_Utilities_Persistence.AdvancedOptsKey.value))(Data_Function["const"](Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Maybe.Nothing.value))));
    return Control_Monad_Maybe_Trans.runMaybeT(Control_Bind.bind(Control_Monad_Maybe_Trans.bindMaybeT(Effect_Aff.monadAff))(Control_Bind.bind(Control_Monad_Maybe_Trans.bindMaybeT(Effect_Aff.monadAff))(getFromAS)(parseJ))(read));
})();
var toDevOF = function (dof) {
    return {
        loadAdvancedOpts: loadAdvancedOpts,
        turnOnAdvancedOpts: dof.turnOnAdvancedOpts,
        value: Data_Nullable.toNullable(dof.value),
        setValue: dof.setValue,
        clearAStorage: dof.clearAStorage
    };
};
var getClientApi = Data_Functor.mapFlipped(Effect_Aff.functorAff)(loadAdvancedOpts)(function (v) {
    if (v instanceof Data_Maybe.Just && (v.value0.usePreprod && Data_Eq.notEq(ReactNative_Platform.eqPlatform)(ReactNative_Platform.os)(ReactNative_Platform.Web.value))) {
        return ZAP_Dart_Client_ApiClient.configClientApi(ZAP_Dart_Client_Constants.preprodApiUrl);
    };
    return ZAP_Dart_Client_ApiClient.configClientApi(ZAP_Dart_Client_Constants.apiUrl);
});
var fromDevOF = function (dof) {
    return {
        loadAdvancedOpts: loadAdvancedOpts,
        turnOnAdvancedOpts: dof.turnOnAdvancedOpts,
        value: Data_Nullable.toMaybe(dof.value),
        setValue: dof.setValue,
        clearAStorage: dof.clearAStorage
    };
};
var component = React_Basic.createComponent("AdvancedOptsProvider");
var clearAdvancedOpts = ZAP_Dart_Client_Utilities_Persistence.removeItem(ZAP_Dart_Client_Utilities_Persistence.AdvancedOptsKey.value);
var syncAdvancedOpts = function (currState) {
    return function (prevState) {
        return Control_Applicative.when(Effect.applicativeEffect)(Data_Eq.notEq(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
            return "usePreprod";
        }))(Data_Eq.eqBoolean))))(currState.value)(prevState.value))((function () {
            if (currState.value instanceof Data_Maybe.Just) {
                return Effect_Aff.launchAff_(saveAdvancedOpts(currState.value.value0));
            };
            if (currState.value instanceof Data_Maybe.Nothing) {
                return Effect_Aff.launchAff_(clearAdvancedOpts);
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Contexts.AdvancedOpts (line 205, column 7 - line 207, column 50): " + [ currState.value.constructor.name ]);
        })());
    };
};
var advancedOptsProvider = (function () {
    var setValue = function (self) {
        return function (newDevVal) {
            return self.setState(function (v) {
                return {
                    value: Data_Maybe.Just.create(newDevVal),
                    clearAStorage: v.clearAStorage,
                    loadAdvancedOpts: v.loadAdvancedOpts,
                    setValue: v.setValue,
                    turnOnAdvancedOpts: v.turnOnAdvancedOpts
                };
            });
        };
    };
    var notify = (function () {
        if (ReactNative_Platform.os instanceof ReactNative_Platform.IOS) {
            return ReactNative_Alert.alert("")("\ud83d\udc68\u200d\ud83d\udcbb")([ {
                text: "OK",
                onPress: Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)
            } ]);
        };
        if (ReactNative_Platform.os instanceof ReactNative_Platform.Android) {
            return ReactNative_ToastAndroid.show("\ud83d\udc68\u200d\ud83d\udcbb")(ReactNative_ToastAndroid["long"]);
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    })();
    var turnOnAdvancedOpts = function (self) {
        if (ReactNative_Platform.os instanceof ReactNative_Platform.Web) {
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
        };
        return self.setStateThen(function (v) {
            return {
                value: new Data_Maybe.Just({
                    usePreprod: false
                }),
                clearAStorage: v.clearAStorage,
                loadAdvancedOpts: v.loadAdvancedOpts,
                setValue: v.setValue,
                turnOnAdvancedOpts: v.turnOnAdvancedOpts
            };
        })(notify);
    };
    var render = function (self) {
        var devOpts = toDevOF({
            loadAdvancedOpts: self.state.loadAdvancedOpts,
            turnOnAdvancedOpts: turnOnAdvancedOpts(self),
            value: self.state.value,
            clearAStorage: Effect_Aff.runAff_(function (v1) {
                if (v1 instanceof Data_Either.Left) {
                    return ReactNative_Alert.alert("Clear Async Storage Fail")(Data_Show.show(Effect_Exception.showError)(v1.value0))([ {
                        text: "OK",
                        onPress: Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)
                    } ]);
                };
                if (v1 instanceof Data_Either.Right) {
                    return ReactNative_Alert.alert("Clear Async Storage Success")("You have successfully cleared the app's cache")([ {
                        text: "OK",
                        onPress: Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)
                    } ]);
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Contexts.AdvancedOpts (line 122, column 21 - line 124, column 156): " + [ v1.constructor.name ]);
            })(ReactNative_AsyncStorage.clear),
            setValue: setValue(self)
        });
        return React_Basic.provider($foreign.advancedOptsContext)(devOpts)([ React_Basic.keyed("child")(self.props.children) ]);
    };
    var initialState = {
        loadAdvancedOpts: loadAdvancedOpts,
        turnOnAdvancedOpts: Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit),
        value: Data_Nullable.toMaybe($foreign.initialAdvancedOpts.value),
        setValue: function (v) {
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
        },
        clearAStorage: Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)
    };
    var didUpdate = function (self) {
        return function (v) {
            return syncAdvancedOpts(self.state)(v.prevState);
        };
    };
    var didMount = function (self) {
        return Effect_Aff.runAff_(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Either.foldableEither)(function (r) {
            return self.setState(function (v) {
                var $27 = {};
                for (var $28 in v) {
                    if ({}.hasOwnProperty.call(v, $28)) {
                        $27[$28] = v[$28];
                    };
                };
                $27.value = r;
                return $27;
            });
        }))(loadAdvancedOpts);
    };
    return React_Basic.toReactComponent()(Control_Category.identity(Control_Category.categoryFn))(component)({
        initialState: initialState,
        render: render,
        didMount: didMount,
        didUpdate: didUpdate
    });
})();
var advancedOptsConsumer = function (ui) {
    return React_Basic.consumer($foreign.advancedOptsContext)(function (devO) {
        return Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic.keyed("advancedOptsConsumerChild")(ui(fromDevOF(devO))));
    });
};
var activator = function (ui) {
    return advancedOptsConsumer(function (v) {
        return ZAP_Dart_Client_Components_External_TapGestureHandler.tapGestureHandler()({
            children: ui,
            onHandlerStateChange: function (e) {
                return Control_Applicative.when(Effect.applicativeEffect)(e.nativeEvent.state === 5)(v.turnOnAdvancedOpts)();
            },
            numberOfTaps: 10
        });
    });
};
module.exports = {
    advancedOptsConsumer: advancedOptsConsumer,
    advancedOptsProvider: advancedOptsProvider,
    activator: activator,
    loadAdvancedOpts: loadAdvancedOpts,
    getClientApi: getClientApi
};
