// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNative_Linking = require("../ReactNative.Linking/index.js");
var ZAP_Dart_Client_Assets = require("../ZAP.Dart.Client.Assets/index.js");
var ZAP_Dart_Client_BuildConfig = require("../ZAP.Dart.Client.BuildConfig/index.js");
var ZAP_Dart_Client_Components_Image = require("../ZAP.Dart.Client.Components.Image/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Utils = require("../ZAP.Dart.Client.Styles.Utils/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(30) ]),
    containerBg: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.altBg) ]),
    containerBgInverted: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.primary) ]),
    buttonContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(8), React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row) ]),
    linkLogo: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(125), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(60), React_Basic_Native_Styles.marginHorizontal(React_Basic_Native_Styles.intMarginHorizontal)(8) ]),
    downloadTitle: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(26) ])
};
var component = React_Basic.createComponent("StoreLinkFooter");
var storeLinkFooter = (function () {
    var titleColor = function (inverted) {
        if (inverted) {
            return ZAP_Dart_Client_Styles_Colors.bg;
        };
        if (!inverted) {
            return ZAP_Dart_Client_Styles_Colors.primary;
        };
        throw new Error("Failed pattern match at ZAP.Dart.Client.Components.StoreLinkFooter (line 83, column 25 - line 85, column 28): " + [ inverted.constructor.name ]);
    };
    var containerBg = function (inverted) {
        if (inverted) {
            return sheet.containerBgInverted;
        };
        if (!inverted) {
            return sheet.containerBg;
        };
        throw new Error("Failed pattern match at ZAP.Dart.Client.Components.StoreLinkFooter (line 79, column 26 - line 81, column 31): " + [ inverted.constructor.name ]);
    };
    var render = function (props) {
        if (!ZAP_Dart_Client_BuildConfig.hasStoreLinks) {
            return Data_Monoid.mempty(React_Basic.monoidJSX);
        };
        if (ZAP_Dart_Client_BuildConfig.hasStoreLinks) {
            return React_Basic_Native_Generated.view()({
                style: React_Basic_Native_Styles["styles'"]([ containerBg(props.inverted), sheet.container, props.style ]),
                children: [ ZAP_Dart_Client_Components_Typography.h3({
                    style: sheet.downloadTitle,
                    value: "DOWNLOAD THE APP",
                    color: titleColor(props.inverted)
                }), React_Basic_Native_Generated.view()({
                    style: sheet.buttonContainer,
                    children: [ React_Basic_Native_Generated.touchableOpacity()({
                        style: ZAP_Dart_Client_Styles_Utils.noStyle,
                        onPress: React_Basic_Events.handler_(ReactNative_Linking.openURL(ZAP_Dart_Client_BuildConfig.buildConfig.storeLinks.ios)),
                        children: Control_Applicative.pure(Control_Applicative.applicativeArray)(ZAP_Dart_Client_Components_Image.image({
                            style: sheet.linkLogo,
                            source: ZAP_Dart_Client_Assets.downloadAppstore,
                            resizeMode: React_Basic_Native_Styles.contain
                        }))
                    }), React_Basic_Native_Generated.touchableOpacity()({
                        style: ZAP_Dart_Client_Styles_Utils.noStyle,
                        onPress: React_Basic_Events.handler_(ReactNative_Linking.openURL(ZAP_Dart_Client_BuildConfig.buildConfig.storeLinks.android)),
                        children: Control_Applicative.pure(Control_Applicative.applicativeArray)(ZAP_Dart_Client_Components_Image.image({
                            style: sheet.linkLogo,
                            source: ZAP_Dart_Client_Assets.downloadGooglePlay,
                            resizeMode: React_Basic_Native_Styles.contain
                        }))
                    }) ]
                }) ]
            });
        };
        throw new Error("Failed pattern match at ZAP.Dart.Client.Components.StoreLinkFooter (line 34, column 18 - line 77, column 8): " + [ ZAP_Dart_Client_BuildConfig.hasStoreLinks.constructor.name ]);
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    storeLinkFooter: storeLinkFooter
};
