// Generated by purs version 0.13.8
"use strict";
var Data_Unit = require("../Data.Unit/index.js");
var ZAP_Dart_Client_Dialogs_Template_SingleAction = require("../ZAP.Dart.Client.Dialogs.Template.SingleAction/index.js");
var alertDialog = (function () {
    var config = function (info) {
        return function (v) {
            return {
                title: info.title,
                message: info.message,
                primaryAction: {
                    label: info.actionLabel,
                    onPress: v.onAction(Data_Unit.unit)
                }
            };
        };
    };
    return ZAP_Dart_Client_Dialogs_Template_SingleAction.singleActionDialog(config);
})();
module.exports = {
    alertDialog: alertDialog
};
