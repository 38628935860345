// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HugeNum = require("../Data.HugeNum/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Number_Format = require("../Data.Number.Format/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_BuildConfig = require("../ZAP.Dart.Client.BuildConfig/index.js");
var ZAP_Dart_Client_Components_Divider = require("../ZAP.Dart.Client.Components.Divider/index.js");
var ZAP_Dart_Client_Components_Icon = require("../ZAP.Dart.Client.Components.Icon/index.js");
var ZAP_Dart_Client_Components_StoreLinkFooter = require("../ZAP.Dart.Client.Components.StoreLinkFooter/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Icons_ScanQr = require("../ZAP.Dart.Client.Icons.ScanQr/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var ZAP_Dart_Client_Styles_Utils = require("../ZAP.Dart.Client.Styles.Utils/index.js");
var ZAP_Dart_Common = require("../ZAP.Dart.Common/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1) ]),
    scrollContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    contentContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexGrow(1), React_Basic_Native_Styles_Flex.flexShrink(0), React_Basic_Native_Styles.flexBasis(React_Basic_Native_Styles.intFlexBasis)("auto"), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(72) ]),
    headerContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(16), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(10) ]),
    headerText: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.regular), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(8) ]),
    headerValue: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(60), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(8) ]),
    buttonContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles.marginHorizontal(React_Basic_Native_Styles.intMarginHorizontal)(36) ]),
    orDivider: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.flexGrow(1), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.maxWidth(React_Basic_Native_Styles.intMaxWidth)(300) ]),
    lineDivider: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(2) ]),
    dividerTitle: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.flex(1) ]),
    actionButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.borderRadius(8), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.altHighlight), React_Basic_Native_Styles.marginVertical(React_Basic_Native_Styles.intMarginVertical)(16), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.padding(React_Basic_Native_Styles.intPadding)(8), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.shadowOpacity(0.2), React_Basic_Native_Styles.shadowRadius(4.0), React_Basic_Native_Styles.shadowOffset({
        height: 1.0,
        width: 0.0
    }), React_Basic_Native_Styles.shadowColor("black"), React_Basic_Native_Styles.elevation(4), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(116), React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(326) ]),
    actionLogo: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)) ]),
    actionTextContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexWrap(React_Basic_Native_Styles_Flex.wrap), React_Basic_Native_Styles_Flex.flexShrink(1), React_Basic_Native_Styles.flex(2), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(8) ]),
    actionTitle: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.semiBold), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(2) ]),
    actionMessage: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(2) ]),
    footerContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexShrink(0), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)) ]),
    couponContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(25), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    logo: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(103), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(69), React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(24), React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(4), React_Basic_Native_Styles.resizeMode(React_Basic_Native_Styles.contain) ])
};
var component = React_Basic.createComponent("GuestClaimReceipt");
var guestClaimReceipt = function (v) {
    var renderHeader = function (c) {
        return Data_Functor.mapFlipped(Data_Functor.functorArray)(c)(function (v1) {
            if (v1 instanceof ZAP_Dart_Common.Points) {
                return React_Basic_Native_Generated.view()({
                    style: sheet.headerContainer,
                    children: [ ZAP_Dart_Client_Components_Typography.h4({
                        style: sheet.headerText,
                        color: ZAP_Dart_Client_Styles_Colors.fg,
                        value: "You have earned"
                    }), ZAP_Dart_Client_Components_Typography.h1({
                        style: sheet.headerValue,
                        color: ZAP_Dart_Client_Styles_Colors.fg,
                        value: Data_Number_Format.toStringWith(Data_Number_Format.fixed(2))(Data_HugeNum.toNumber(v1.value0.earned))
                    }), ZAP_Dart_Client_Components_Typography.h4({
                        style: sheet.headerText,
                        color: ZAP_Dart_Client_Styles_Colors.fg,
                        value: Data_Maybe.fromMaybe("Points")(v1.value0.currencyName)
                    }) ]
                });
            };
            if (v1 instanceof ZAP_Dart_Common.Coupon) {
                return React_Basic_Native_Generated.view()({
                    style: sheet.headerContainer,
                    children: [ ZAP_Dart_Client_Components_Typography.h4({
                        style: sheet.headerText,
                        color: ZAP_Dart_Client_Styles_Colors.fg,
                        value: "You have earned"
                    }), ZAP_Dart_Client_Components_Typography.h4({
                        style: sheet.headerText,
                        color: ZAP_Dart_Client_Styles_Colors.fg,
                        value: "a coupon"
                    }), React_Basic_Native_Generated.view()({
                        style: sheet.couponContainer,
                        children: [ ZAP_Dart_Client_Components_Icon.icon({
                            name: "coupon",
                            color: ZAP_Dart_Client_Styles_Colors.fg,
                            size: 100
                        }) ]
                    }) ]
                });
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.GuestClaimReceipt (line 107, column 11 - line 154, column 16): " + [ v1.constructor.name ]);
        });
    };
    var renderDivider = ZAP_Dart_Client_Components_Divider["divider'"]({
        style: sheet.lineDivider,
        color: ZAP_Dart_Client_Styles_Colors.separator
    });
    var actionButtonBody = function (v1) {
        return [ React_Basic_Native_Generated.view()({
            style: sheet.actionLogo,
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(v1.icon)
        }), React_Basic_Native_Generated.view()({
            style: sheet.actionTextContainer,
            children: [ ZAP_Dart_Client_Components_Typography.b2({
                style: sheet.actionTitle,
                color: ZAP_Dart_Client_Styles_Colors.primary,
                value: v1.title
            }), ZAP_Dart_Client_Components_Typography.b3({
                style: sheet.actionMessage,
                color: ZAP_Dart_Client_Styles_Colors.altFg,
                value: v1.message
            }) ]
        }) ];
    };
    var actionButton = function (props) {
        return function (action) {
            return React_Basic_Native_Generated.touchableOpacity()({
                style: sheet.actionButton,
                onPress: React_Basic_Events.handler_(props.onAction(ReactNavigation_Types.PgSubmit.create(action))),
                children: (function () {
                    if (action instanceof ZAP_Dart_Common.GuestClaimReceiptBrowseCatalog) {
                        return actionButtonBody({
                            title: "BROWSE CATALOG",
                            message: "to redeem your points on exciting rewards",
                            icon: ZAP_Dart_Client_Components_Icon.icon({
                                name: "sign_in",
                                size: 59,
                                color: ZAP_Dart_Client_Styles_Colors.altFg
                            })
                        });
                    };
                    if (action instanceof ZAP_Dart_Common.GuestClaimReceiptScanAnother) {
                        return actionButtonBody({
                            title: "SCAN ANOTHER",
                            message: "to earn more points",
                            icon: ZAP_Dart_Client_Icons_ScanQr.scanQr({
                                width: 59,
                                height: 59,
                                color: ZAP_Dart_Client_Styles_Colors.primary
                            })
                        });
                    };
                    if (action instanceof ZAP_Dart_Common.GuestClaimReceiptInputAnother) {
                        return actionButtonBody({
                            title: "INPUT ANOTHER",
                            message: "to earn more points",
                            icon: ZAP_Dart_Client_Components_Icon.icon({
                                name: "input_qr",
                                size: 24,
                                color: ZAP_Dart_Client_Styles_Colors.altFg
                            })
                        });
                    };
                    throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.GuestClaimReceipt (line 164, column 9 - line 197, column 16): " + [ action.constructor.name ]);
                })()
            });
        };
    };
    var renderActionButtons = function (props) {
        var mapOver = Data_Functor.map(Data_Functor.functorArray)(actionButton(props));
        var addDivider = Data_Foldable.intercalate(Data_Foldable.foldableArray)(React_Basic.monoidJSX)(React_Basic_Native_Generated.view()({
            style: sheet.orDivider,
            children: [ renderDivider, React_Basic_Native_Generated.view()({
                style: sheet.dividerTitle,
                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(ZAP_Dart_Client_Components_Typography.b2({
                    style: ZAP_Dart_Client_Styles_Utils.noStyle,
                    color: ZAP_Dart_Client_Styles_Colors.fgMuted,
                    value: "OR"
                }))
            }), renderDivider ]
        }));
        var $14 = Control_Applicative.pure(Control_Applicative.applicativeArray);
        return function ($15) {
            return $14(addDivider(mapOver($15)));
        };
    };
    var render = function (props) {
        return React_Basic_Native_Generated.view()({
            style: sheet.container,
            children: [ React_Basic_Native_Generated.scrollView()({
                contentContainerStyle: sheet.scrollContainer,
                children: [ React_Basic_Native_Generated.view()({
                    style: sheet.contentContainer,
                    children: Data_Semigroup.append(Data_Semigroup.semigroupArray)([ React_Basic_Native_Generated.image()({
                        style: sheet.logo,
                        source: ZAP_Dart_Client_BuildConfig.buildConfig.coloredLogo
                    }) ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(renderHeader(v.claims))([ React_Basic_Native_Generated.view()({
                        style: sheet.buttonContainer,
                        children: renderActionButtons(props)(v.actions)
                    }) ]))
                }), ZAP_Dart_Client_Components_StoreLinkFooter.storeLinkFooter({
                    style: sheet.footerContainer,
                    inverted: true
                }) ]
            }) ]
        });
    };
    return React_Basic.makeStateless(component)(render);
};
module.exports = {
    guestClaimReceipt: guestClaimReceipt
};
