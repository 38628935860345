// Generated by purs version 0.13.8
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var ZAP_Dart_Client_Components_Icon = require("../ZAP.Dart.Client.Components.Icon/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.backgroundColor(React_Basic_Native_PropTypes_Color.white), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.borderRadius(12), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(12), React_Basic_Native_Styles.borderColor(React_Basic_Native_PropTypes_Color.rgba(0)(0)(0)(0.1)), React_Basic_Native_Styles.maxWidth(React_Basic_Native_Styles.intMaxWidth)(400), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    topRow: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_Styles_Flex.stretch), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_Styles_Flex.flexEnd(React_Basic_Native_Styles_Flex.alignmentStartEnd)), React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(12) ]),
    clearIconContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(20), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(20), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ])
};
var component = React_Basic.createComponent("DialogWrapper");
var wrapper = (function () {
    var render = function (v) {
        return React_Basic_Native_Generated.view()({
            style: sheet.container,
            children: Data_Semigroup.append(Data_Semigroup.semigroupArray)([ React_Basic_Native_Generated.view()({
                style: sheet.topRow,
                children: Data_Array.singleton(React_Basic_Native_Generated.touchableOpacity()({
                    onPress: React_Basic_Events.handler_(v.onClose),
                    children: Data_Array.singleton(React_Basic_Native_Generated.view()({
                        style: sheet.clearIconContainer,
                        children: [ ZAP_Dart_Client_Components_Icon.icon({
                            name: "exit_outlined",
                            size: 20,
                            color: ZAP_Dart_Client_Styles_Colors.fg
                        }) ]
                    }))
                }))
            }) ])(v.children)
        });
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    wrapper: wrapper
};
