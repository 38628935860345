// Generated by purs version 0.13.8
"use strict";
var Data_Functor = require("../Data.Functor/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var WillFocus = (function () {
    function WillFocus() {

    };
    WillFocus.value = new WillFocus();
    return WillFocus;
})();
var DidFocus = (function () {
    function DidFocus() {

    };
    DidFocus.value = new DidFocus();
    return DidFocus;
})();
var WillBlur = (function () {
    function WillBlur() {

    };
    WillBlur.value = new WillBlur();
    return WillBlur;
})();
var DidBlur = (function () {
    function DidBlur() {

    };
    DidBlur.value = new DidBlur();
    return DidBlur;
})();
var RNavNavigator = function (x) {
    return x;
};
var PgSubmit = (function () {
    function PgSubmit(value0) {
        this.value0 = value0;
    };
    PgSubmit.create = function (value0) {
        return new PgSubmit(value0);
    };
    return PgSubmit;
})();
var PgCancel = (function () {
    function PgCancel() {

    };
    PgCancel.value = new PgCancel();
    return PgCancel;
})();
var PgBack = (function () {
    function PgBack() {

    };
    PgBack.value = new PgBack();
    return PgBack;
})();
var PgErr = (function () {
    function PgErr(value0) {
        this.value0 = value0;
    };
    PgErr.create = function (value0) {
        return new PgErr(value0);
    };
    return PgErr;
})();
var Navigator = function (x) {
    return x;
};
var NavCbI$prime = function (x) {
    return x;
};
var NavBarSubmit = (function () {
    function NavBarSubmit(value0) {
        this.value0 = value0;
    };
    NavBarSubmit.create = function (value0) {
        return new NavBarSubmit(value0);
    };
    return NavBarSubmit;
})();
var NavBarBack = (function () {
    function NavBarBack() {

    };
    NavBarBack.value = new NavBarBack();
    return NavBarBack;
})();
var NBTotalPoints = (function () {
    function NBTotalPoints() {

    };
    NBTotalPoints.value = new NBTotalPoints();
    return NBTotalPoints;
})();
var NBTitle = (function () {
    function NBTitle(value0) {
        this.value0 = value0;
    };
    NBTitle.create = function (value0) {
        return new NBTitle(value0);
    };
    return NBTitle;
})();
var NBNone = (function () {
    function NBNone() {

    };
    NBNone.value = new NBNone();
    return NBNone;
})();
var LApiM$prime = (function () {
    function LApiM$prime(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    LApiM$prime.create = function (value0) {
        return function (value1) {
            return new LApiM$prime(value0, value1);
        };
    };
    return LApiM$prime;
})();
var Alert = (function () {
    function Alert(value0) {
        this.value0 = value0;
    };
    Alert.create = function (value0) {
        return new Alert(value0);
    };
    return Alert;
})();
var Confirm = (function () {
    function Confirm(value0) {
        this.value0 = value0;
    };
    Confirm.create = function (value0) {
        return new Confirm(value0);
    };
    return Confirm;
})();
var Custom = (function () {
    function Custom(value0) {
        this.value0 = value0;
    };
    Custom.create = function (value0) {
        return new Custom(value0);
    };
    return Custom;
})();
var DgClose = (function () {
    function DgClose() {

    };
    DgClose.value = new DgClose();
    return DgClose;
})();
var DgSubmit = (function () {
    function DgSubmit(value0) {
        this.value0 = value0;
    };
    DgSubmit.create = function (value0) {
        return new DgSubmit(value0);
    };
    return DgSubmit;
})();
var navCbiNewtype = new Data_Newtype.Newtype(function (n) {
    return n;
}, NavCbI$prime);
var functorPageAction = new Data_Functor.Functor(function (f) {
    return function (m) {
        if (m instanceof PgSubmit) {
            return new PgSubmit(f(m.value0));
        };
        if (m instanceof PgCancel) {
            return PgCancel.value;
        };
        if (m instanceof PgBack) {
            return PgBack.value;
        };
        if (m instanceof PgErr) {
            return new PgErr(m.value0);
        };
        throw new Error("Failed pattern match at ReactNavigation.Types (line 148, column 1 - line 148, column 56): " + [ m.constructor.name ]);
    };
});
var eventTypeName = function (v) {
    if (v instanceof WillBlur) {
        return "willBlur";
    };
    if (v instanceof DidBlur) {
        return "didBlur";
    };
    if (v instanceof WillFocus) {
        return "willFocus";
    };
    if (v instanceof DidFocus) {
        return "didFocus";
    };
    throw new Error("Failed pattern match at ReactNavigation.Types (line 225, column 1 - line 225, column 43): " + [ v.constructor.name ]);
};
module.exports = {
    WillFocus: WillFocus,
    DidFocus: DidFocus,
    WillBlur: WillBlur,
    DidBlur: DidBlur,
    eventTypeName: eventTypeName,
    "LApiM'": LApiM$prime,
    PgSubmit: PgSubmit,
    PgCancel: PgCancel,
    PgBack: PgBack,
    PgErr: PgErr,
    Alert: Alert,
    Confirm: Confirm,
    Custom: Custom,
    DgClose: DgClose,
    DgSubmit: DgSubmit,
    NavBarSubmit: NavBarSubmit,
    NavBarBack: NavBarBack,
    NBTotalPoints: NBTotalPoints,
    NBTitle: NBTitle,
    NBNone: NBNone,
    Navigator: Navigator,
    RNavNavigator: RNavNavigator,
    "NavCbI'": NavCbI$prime,
    navCbiNewtype: navCbiNewtype,
    functorPageAction: functorPageAction
};
