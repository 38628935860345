// Generated by purs version 0.13.8
"use strict";
var $foreign = require("./foreign.js");
var React_Basic = require("../React.Basic/index.js");
var grayscale = function (component) {
    return React_Basic.element($foreign["_grayscale"])({
        component: component
    });
};
module.exports = {
    grayscale: grayscale
};
