// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var ReactNavigation = require("../ReactNavigation/index.js");
var ReactNavigation_AuthFlow = require("../ReactNavigation.AuthFlow/index.js");
var ReactNavigation_Class_MonadNavM = require("../ReactNavigation.Class.MonadNavM/index.js");
var ReactNavigation_Utils_HOCs_WithPageProps = require("../ReactNavigation.Utils.HOCs.WithPageProps/index.js");
var ReactNavigation_Utils_PageCreators = require("../ReactNavigation.Utils.PageCreators/index.js");
var ReactNavigation_Utils_RouterConfigs = require("../ReactNavigation.Utils.RouterConfigs/index.js");
var ZAP_Dart_Client_ApiClient_Utils = require("../ZAP.Dart.Client.ApiClient.Utils/index.js");
var ZAP_Dart_Client_Dialogs_Template_Alert = require("../ZAP.Dart.Client.Dialogs.Template.Alert/index.js");
var ZAP_Dart_Client_Screens_AuthorizedEnterOtp = require("../ZAP.Dart.Client.Screens.AuthorizedEnterOtp/index.js");
var ZAP_Dart_Client_Screens_ResetPin = require("../ZAP.Dart.Client.Screens.ResetPin/index.js");
var ZAP_Dart_Common = require("../ZAP.Dart.Common/index.js");
var rs = ReactNavigation.mkRoutes(ReactNavigation.mkRoutesI()(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLNil)()()(new Data_Symbol.IsSymbol(function () {
    return "resetPin";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "enterOtp";
}))));
var flow = (function () {
    var showSuccessDialog = Control_Apply.applySecond(ReactNavigation_AuthFlow.applyAuthNavM)(ReactNavigation_Class_MonadNavM.navDialogI(ReactNavigation_AuthFlow.monadNMAuthNavM)(ZAP_Dart_Client_Dialogs_Template_Alert.alertDialog)({
        title: "SUCCESS",
        message: "You have successfully reset your 6-digit PIN!",
        actionLabel: "OKAY"
    }))(Control_Applicative.pure(ReactNavigation_AuthFlow.applicativeAuthNavM)(Data_Unit.unit));
    var showErrorDialog = function (v) {
        return Control_Apply.applySecond(ReactNavigation_AuthFlow.applyAuthNavM)(ReactNavigation_Class_MonadNavM.navDialogI(ReactNavigation_AuthFlow.monadNMAuthNavM)(ZAP_Dart_Client_Dialogs_Template_Alert.alertDialog)({
            title: v.title,
            message: v.message,
            actionLabel: "Try Again"
        }))(Control_Applicative.pure(ReactNavigation_AuthFlow.applicativeAuthNavM)(Data_Unit.unit));
    };
    var resetPinError = function (err) {
        return {
            title: "Reset Pin Failed",
            message: "Invalid user"
        };
    };
    var initRouteF = function (v) {
        return ReactNavigation_AuthFlow.processInfos()(ReactNavigation_AuthFlow.processInfosRLConsapiCalls(ReactNavigation_AuthFlow.processInfosRLConsInitD(ReactNavigation_AuthFlow.processInfosRLNil)()(new Data_Symbol.IsSymbol(function () {
            return "initD";
        }))()()())(new Data_Symbol.IsSymbol(function () {
            return "apiCalls";
        }))()(ReactNavigation_AuthFlow.applyAuthRLConsA(ReactNavigation_AuthFlow.applyAuthRLConsA(ReactNavigation_AuthFlow.applyAuthRLNil)(new Data_Symbol.IsSymbol(function () {
            return "verifyOtp";
        }))()()())(new Data_Symbol.IsSymbol(function () {
            return "resendOtp";
        }))()()())()()()())({
            initD: new ReactNavigation_AuthFlow.InitD(v.clientApi.sendOtp, {
                purpose: ZAP_Dart_Common.ResetPassword.value
            }),
            apiCalls: {
                verifyOtp: v.clientApi.verifyOtp,
                resendOtp: v.clientApi.resendOtp
            }
        })(v.authToken);
    };
    var handleCancel = function (v) {
        return function (v1) {
            return Control_Bind.bind(ReactNavigation_AuthFlow.bindAuthNavM)(ReactNavigation_Class_MonadNavM.navPush(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                return "resetPin";
            }))())(ReactNavigation_AuthFlow.monadNMAuthNavM)(rs.resetPin))(function (res) {
                if (res instanceof Data_Either.Right) {
                    return Control_Bind.bind(ReactNavigation_AuthFlow.bindAuthNavM)(ZAP_Dart_Client_ApiClient_Utils.callApi_(ReactNavigation_AuthFlow.monadNMAuthNavM)(ReactNavigation_AuthFlow.monadAuthNavM)(v1.clientApi.authorizedResetPin(v1.authToken)({
                        pin: res.value0,
                        refId: v.refId,
                        otp: v.otp
                    })))(function (v2) {
                        if (v2 instanceof Data_Either.Right) {
                            return showSuccessDialog;
                        };
                        if (v2 instanceof Data_Either.Left) {
                            return showErrorDialog(resetPinError(v2.value0));
                        };
                        throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.AuthorizedResetPin (line 62, column 15 - line 64, column 58): " + [ v2.constructor.name ]);
                    });
                };
                if (res instanceof Data_Either.Left) {
                    return Control_Applicative.pure(ReactNavigation_AuthFlow.applicativeAuthNavM)(Data_Unit.unit);
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.AuthorizedResetPin (line 57, column 5 - line 65, column 26): " + [ res.constructor.name ]);
            });
        };
    };
    var defaultNavigationOptions = ReactNavigation.navigationOptions()()({
        header: Data_Nullable["null"]
    });
    var configBuilder = ReactNavigation_Utils_RouterConfigs.stackRcBuilder(ReactNavigation.isRouterConfigOpts()()())()(ReactNavigation.isRouterConfigOpts()()())({
        defaultNavigationOptions: defaultNavigationOptions
    });
    var afterInitF = function (v) {
        if (v.value instanceof Data_Either.Right && v.value.value0 instanceof Data_Either.Right) {
            return handleCancel(v.value.value0.value0)(v.info);
        };
        if (v.value instanceof Data_Either.Right && v.value.value0 instanceof Data_Either.Left) {
            return showErrorDialog(v.value.value0.value0);
        };
        if (v.value instanceof Data_Either.Left) {
            return Control_Applicative.pure(ReactNavigation_AuthFlow.applicativeAuthNavM)(Data_Unit.unit);
        };
        throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.AuthorizedResetPin (line 50, column 80 - line 53, column 24): " + [ v.value.constructor.name ]);
    };
    return ReactNavigation_AuthFlow.createAuthFlow(new Data_Symbol.IsSymbol(function () {
        return "enterOtp";
    }))(ReactNavigation.navCreatorStack)(ReactNavigation_AuthFlow.runAuthNavMfirstRouteAsync)(rs)(rs.enterOtp)(initRouteF)(configBuilder)(afterInitF);
})();
var authorizedResetPinScreen = ReactNavigation.createFlowScreen()(flow)({
    enterOtp: ReactNavigation.routeUiOpts_(ReactNavigation_Utils_PageCreators.routeUiInitD(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherClientErr)()(ZAP_Dart_Client_Screens_AuthorizedEnterOtp.authorizedEnterOtp)),
    resetPin: ReactNavigation.routeUiOpts_(ReactNavigation_Utils_PageCreators.routeUiPage_(ZAP_Dart_Client_Screens_ResetPin.resetPin))
});
module.exports = {
    authorizedResetPinScreen: authorizedResetPinScreen
};
