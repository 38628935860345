// Generated by purs version 0.13.8
"use strict";
var Control_Monad_State_Trans = require("../Control.Monad.State.Trans/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var withState = Control_Monad_State_Trans.withStateT;
var runState = function (v) {
    var $16 = Data_Newtype.unwrap(Data_Identity.newtypeIdentity);
    return function ($17) {
        return $16(v($17));
    };
};
var mapState = function (f) {
    return Control_Monad_State_Trans.mapStateT((function () {
        var $18 = Data_Newtype.unwrap(Data_Identity.newtypeIdentity);
        return function ($19) {
            return Data_Identity.Identity(f($18($19)));
        };
    })());
};
var execState = function (v) {
    return function (s) {
        var v1 = v(s);
        return v1.value1;
    };
};
var evalState = function (v) {
    return function (s) {
        var v1 = v(s);
        return v1.value0;
    };
};
module.exports = {
    runState: runState,
    evalState: evalState,
    execState: execState,
    mapState: mapState,
    withState: withState
};
