import React from 'react';

export const initialAdvancedOpts = {
  loadDevOptions: () => {},
  value: null,
  turnOnDevOptions: () => () => {},
  setValue: () => () => {},
  clearAStorage: () => {},
};

export const AdvancedOptsContext = React.createContext(initialAdvancedOpts);
