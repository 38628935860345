const Platform = require('react-native').Platform;
const getApiUrl = require('../../utils').getApiUrl;
const getAboutUrl = require('../../utils').getAboutUrl;
const apiUrl = require('../../utils').apiUrl;
const Constants = require('expo-constants').default;

exports.preprodApiUrl = apiUrl.preprod;

exports.apiUrl =
  Platform.OS === 'web' ? getApiUrl(process.env.ZENV) : getApiUrl();

exports.aboutUrl =
  Platform.OS === 'web' ? getAboutUrl(process.env.ZENV) : getAboutUrl();

exports.version = Constants.manifest.version;
