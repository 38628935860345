// Generated by purs version 0.13.8
"use strict";
var Control_Apply = require("../Control.Apply/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Show = require("../Data.Show/index.js");
var Text_Parsing_Parser = require("../Text.Parsing.Parser/index.js");
var Text_Parsing_Parser_Combinators = require("../Text.Parsing.Parser.Combinators/index.js");
var Text_Parsing_Parser_String = require("../Text.Parsing.Parser.String/index.js");
var printPosition = function (v) {
    return "(line " + (Data_Show.show(Data_Show.showInt)(v.line) + (", col " + (Data_Show.show(Data_Show.showInt)(v.column) + ")")));
};
var printError = function (err) {
    return Text_Parsing_Parser.parseErrorMessage(err) + (" " + printPosition(Text_Parsing_Parser.parseErrorPosition(err)));
};
var runP = function (dictStringLike) {
    return function (p) {
        return function (s) {
            return Data_Bifunctor.lmap(Data_Either.bifunctorEither)(printError)(Text_Parsing_Parser.runParser(s)(Control_Apply.applyFirst(Text_Parsing_Parser.applyParserT(Data_Identity.monadIdentity))(p)(Text_Parsing_Parser_String.eof(dictStringLike)(Data_Identity.monadIdentity))));
        };
    };
};
var oneOfAs = function (dictFunctor) {
    return function (dictFoldable) {
        return function (dictMonad) {
            return function (p) {
                return function (xs) {
                    return Text_Parsing_Parser_Combinators.choice(dictFoldable)(dictMonad)(Data_Functor.map(dictFunctor)(function (v) {
                        return Data_Functor.voidLeft(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(p(v.value0))(v.value1);
                    })(xs));
                };
            };
        };
    };
};
module.exports = {
    oneOfAs: oneOfAs,
    runP: runP
};
