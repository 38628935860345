// Generated by purs version 0.13.8
"use strict";
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Console = require("../Effect.Console/index.js");
var warnShow = function (dictMonadEffect) {
    return function (dictShow) {
        var $14 = Effect_Class.liftEffect(dictMonadEffect);
        var $15 = Effect_Console.warnShow(dictShow);
        return function ($16) {
            return $14($15($16));
        };
    };
};
var warn = function (dictMonadEffect) {
    var $17 = Effect_Class.liftEffect(dictMonadEffect);
    return function ($18) {
        return $17(Effect_Console.warn($18));
    };
};
var timeEnd = function (dictMonadEffect) {
    var $19 = Effect_Class.liftEffect(dictMonadEffect);
    return function ($20) {
        return $19(Effect_Console.timeEnd($20));
    };
};
var time = function (dictMonadEffect) {
    var $21 = Effect_Class.liftEffect(dictMonadEffect);
    return function ($22) {
        return $21(Effect_Console.time($22));
    };
};
var logShow = function (dictMonadEffect) {
    return function (dictShow) {
        var $23 = Effect_Class.liftEffect(dictMonadEffect);
        var $24 = Effect_Console.logShow(dictShow);
        return function ($25) {
            return $23($24($25));
        };
    };
};
var log = function (dictMonadEffect) {
    var $26 = Effect_Class.liftEffect(dictMonadEffect);
    return function ($27) {
        return $26(Effect_Console.log($27));
    };
};
var infoShow = function (dictMonadEffect) {
    return function (dictShow) {
        var $28 = Effect_Class.liftEffect(dictMonadEffect);
        var $29 = Effect_Console.infoShow(dictShow);
        return function ($30) {
            return $28($29($30));
        };
    };
};
var info = function (dictMonadEffect) {
    var $31 = Effect_Class.liftEffect(dictMonadEffect);
    return function ($32) {
        return $31(Effect_Console.info($32));
    };
};
var errorShow = function (dictMonadEffect) {
    return function (dictShow) {
        var $33 = Effect_Class.liftEffect(dictMonadEffect);
        var $34 = Effect_Console.errorShow(dictShow);
        return function ($35) {
            return $33($34($35));
        };
    };
};
var error = function (dictMonadEffect) {
    var $36 = Effect_Class.liftEffect(dictMonadEffect);
    return function ($37) {
        return $36(Effect_Console.error($37));
    };
};
module.exports = {
    log: log,
    logShow: logShow,
    warn: warn,
    warnShow: warnShow,
    error: error,
    errorShow: errorShow,
    info: info,
    infoShow: infoShow,
    time: time,
    timeEnd: timeEnd
};
