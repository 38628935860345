// Generated by purs version 0.13.8
"use strict";
var Data_Unit = require("../Data.Unit/index.js");
var ZAP_Dart_Client_BuildConfig = require("../ZAP.Dart.Client.BuildConfig/index.js");
var ZAP_Dart_Client_Dialogs_Template_SingleAction = require("../ZAP.Dart.Client.Dialogs.Template.SingleAction/index.js");
var ZAP_Dart_Common = require("../ZAP.Dart.Common/index.js");
var Continue = (function () {
    function Continue() {

    };
    Continue.value = new Continue();
    return Continue;
})();
var Close = (function () {
    function Close() {

    };
    Close.value = new Close();
    return Close;
})();
var config = function (mode) {
    return function (v) {
        return function (v1) {
            var label = (function () {
                if (v.claimCode instanceof ZAP_Dart_Common.QRCode) {
                    return "SCAN ANOTHER";
                };
                if (v.claimCode instanceof ZAP_Dart_Common.ShortCode) {
                    return "INPUT ANOTHER";
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Dialogs.ScanError (line 59, column 13 - line 61, column 37): " + [ v.claimCode.constructor.name ]);
            })();
            var content = (function () {
                if (v.error instanceof ZAP_Dart_Common.ClaimErrQrCodeAlreadyClaimed) {
                    return {
                        title: "Nothing's here",
                        message: "Looks like this code has already been used."
                    };
                };
                if (v.error instanceof ZAP_Dart_Common.ClaimErrInvalidData) {
                    return {
                        title: "Sorry, we don't recognize that code.",
                        message: (function () {
                            if (v.claimCode instanceof ZAP_Dart_Common.QRCode) {
                                return "Scan";
                            };
                            if (v.claimCode instanceof ZAP_Dart_Common.ShortCode) {
                                return "Input";
                            };
                            throw new Error("Failed pattern match at ZAP.Dart.Client.Dialogs.ScanError (line 47, column 11 - line 49, column 35): " + [ v.claimCode.constructor.name ]);
                        })() + (" codes found in " + (ZAP_Dart_Client_BuildConfig.buildConfig.name + " products."))
                    };
                };
                if (v.error instanceof ZAP_Dart_Common.ClaimErrOthers) {
                    return {
                        title: "Oops, something's not right.",
                        message: "We are unable to process your request right now."
                    };
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Dialogs.ScanError (line 39, column 15 - line 57, column 10): " + [ v.error.constructor.name ]);
            })();
            return {
                title: content.title,
                message: content.message,
                primaryAction: {
                    label: label,
                    onPress: (function () {
                        if (mode instanceof Continue) {
                            return v1.onAction(Data_Unit.unit);
                        };
                        if (mode instanceof Close) {
                            return v1.close;
                        };
                        throw new Error("Failed pattern match at ZAP.Dart.Client.Dialogs.ScanError (line 68, column 9 - line 70, column 25): " + [ mode.constructor.name ]);
                    })()
                }
            };
        };
    };
};
var scanErrDialog = ZAP_Dart_Client_Dialogs_Template_SingleAction.singleActionDialog(config(Close.value));
module.exports = {
    scanErrDialog: scanErrDialog
};
