import React from 'react';

export const SHOW_SPINNER = 'Navigation/SHOW_SPINNER';
export const HIDE_SPINNER = 'Navigation/HIDE_SPINNER';
export const CREATE_DIALOG = 'Navigation/CREATE_DIALOG';
export const DESTROY_DIALOG = 'Navigation/DESTROY_DIALOG';

const CustomActions = {
  showSpinner: () => ({
    type: SHOW_SPINNER,
  }),
  hideSpinner: () => ({
    type: HIDE_SPINNER,
  }),
  createDialog: component => ({
    type: CREATE_DIALOG,
    component,
  }),
  destroyDialog: () => ({
    type: DESTROY_DIALOG,
  }),
};

export default function navWrapper(navigator) {
  return function(...args) {
    const WrappedNavigator = navigator(...args);
    return class extends React.Component {
      static router = {
        ...WrappedNavigator.router,
        getActionCreators: (route, navStateKey) => ({
          ...CustomActions,
          ...WrappedNavigator.router.getActionCreators(route, navStateKey),
        }),
        getStateForAction: (action, lastState) => {
          const state = WrappedNavigator.router.getStateForAction(
            action,
            lastState
          );
          switch (action.type) {
            case SHOW_SPINNER:
            case HIDE_SPINNER:
            case CREATE_DIALOG:
            case DESTROY_DIALOG:
              // create new state object to force navigation state change
              return {
                ...state,
              };
            default:
              return state;
          }
        },
      };

      render() {
        const { navigation } = this.props; // eslint-disable-line
        return <WrappedNavigator navigation={navigation} />;
      }
    };
  };
}
