// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Control_Monad_Except = require("../Control.Monad.Except/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Argonaut_Core = require("../Data.Argonaut.Core/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Aff_Class = require("../Effect.Aff.Class/index.js");
var ExpressRpc_Types = require("../ExpressRpc.Types/index.js");
var Milkis = require("../Milkis/index.js");
var Milkis_Impl_Window = require("../Milkis.Impl.Window/index.js");
var Record_Builder = require("../Record.Builder/index.js");
var Type_Data_RowList = require("../Type.Data.RowList/index.js");
var ZRPC_ZRPCRead = require("../ZRPC.ZRPCRead/index.js");
var ZRPC_ZRPCWrite = require("../ZRPC.ZRPCWrite/index.js");
var MkVersionedApiRL = function (mkVersionedApiRL) {
    this.mkVersionedApiRL = mkVersionedApiRL;
};
var MkFetcherRL = function (mkFetcherRL) {
    this.mkFetcherRL = mkFetcherRL;
};
var zjson = function (dictMonadAff) {
    return function (response) {
        var affj = Milkis.json(response);
        return Effect_Aff_Class.liftAff(dictMonadAff)(affj);
    };
};
var readJson = function (dictMonadThrow) {
    return function (dictMonadAff) {
        return function (dictZRPCRead) {
            return function (json) {
                var readJ = Control_Monad_Except.runExcept(ZRPC_ZRPCRead.zrpcRead(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(ExpressRpc_Types.zrpcReadServerErr))(ZRPC_ZRPCRead.zrpcReadFieldId(dictZRPCRead)))(json));
                if (readJ instanceof Data_Either.Right && readJ.value0 instanceof Data_Either.Right) {
                    return Control_Applicative.pure(((dictMonadAff.MonadEffect0()).Monad0()).Applicative0())(readJ.value0.value0);
                };
                if (readJ instanceof Data_Either.Right && readJ.value0 instanceof Data_Either.Left) {
                    return Control_Monad_Error_Class.throwError(dictMonadThrow)(new ExpressRpc_Types.Server(readJ.value0.value0));
                };
                if (readJ instanceof Data_Either.Left) {
                    return Control_Monad_Error_Class.throwError(dictMonadThrow)(ExpressRpc_Types.MalformedRes.value);
                };
                throw new Error("Failed pattern match at ExpressRpc.MkApiClient (line 249, column 5 - line 252, column 42): " + [ readJ.constructor.name ]);
            };
        };
    };
};
var mkVersionedApiRLNil = new MkVersionedApiRL(function (v) {
    return function (v1) {
        return {};
    };
});
var mkVersionedApiRL = function (dict) {
    return dict.mkVersionedApiRL;
};
var mkVersionedApiClient = function (dictRowToList) {
    return function (dictMkVersionedApiRL) {
        return function (url) {
            return mkVersionedApiRL(dictMkVersionedApiRL)(Type_Data_RowList.RLProxy.value)(url);
        };
    };
};
var mkFetcherRLNil = new MkFetcherRL(function (v) {
    return function (v1) {
        return {};
    };
});
var mkFetcherRL = function (dict) {
    return dict.mkFetcherRL;
};
var mkVersionedApiRLCons = function (dictIsSymbol) {
    return function (dictMkVersionedApiRL) {
        return function (dictCons) {
            return function (dictLacks) {
                return function (dictRowToList) {
                    return function (dictMkFetcherRL) {
                        return new MkVersionedApiRL(function (v) {
                            return function (v1) {
                                var tail = mkVersionedApiRL(dictMkVersionedApiRL)(Type_Data_RowList.RLProxy.value)(v1);
                                var nameStr$prime = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
                                var nameStr = (function () {
                                    if (nameStr$prime === "default") {
                                        return "";
                                    };
                                    return nameStr$prime;
                                })();
                                var api = mkFetcherRL(dictMkFetcherRL)(Type_Data_RowList.RLProxy.value)(v1 + ("/" + nameStr));
                                var $$new = Record_Builder.insert()()(dictIsSymbol)(Data_Symbol.SProxy.value)(api);
                                return Record_Builder.build($$new)(tail);
                            };
                        });
                    };
                };
            };
        };
    };
};
var mkApiClient = function (dictRowToList) {
    return function (dictMkFetcherRL) {
        return function (url) {
            return mkFetcherRL(dictMkFetcherRL)(Type_Data_RowList.RLProxy.value)(url);
        };
    };
};
var fetch = function (dictUnion) {
    return Milkis.fetch(Milkis_Impl_Window.windowFetch)();
};
var post = function (dictZRPCRead) {
    return function (dictMonadThrow) {
        return function (dictMonadAff) {
            return function (url) {
                return function (rr) {
                    var mainReq = {
                        service: rr.service,
                        payload: rr.payload
                    };
                    var headers = (function () {
                        if (rr.authToken instanceof Data_Maybe.Nothing) {
                            return Milkis.makeHeaders()({
                                "Content-Type": "application/json"
                            });
                        };
                        if (rr.authToken instanceof Data_Maybe.Just) {
                            return Milkis.makeHeaders()({
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + rr.authToken.value0
                            });
                        };
                        throw new Error("Failed pattern match at ExpressRpc.MkApiClient (line 213, column 15 - line 219, column 12): " + [ rr.authToken.constructor.name ]);
                    })();
                    return Control_Bind.bind(((dictMonadAff.MonadEffect0()).Monad0()).Bind1())(Control_Bind.bind(((dictMonadAff.MonadEffect0()).Monad0()).Bind1())(Effect_Aff_Class.liftAff(dictMonadAff)(fetch()(url)({
                        method: Milkis.postMethod,
                        headers: headers,
                        body: Data_Argonaut_Core.stringify(ZRPC_ZRPCWrite.zrpcWrite(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
                            return "payload";
                        }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteZJson))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
                            return "service";
                        }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())()))(mainReq))
                    })))(zjson(dictMonadAff)))(readJson(dictMonadThrow)(dictMonadAff)(dictZRPCRead));
                };
            };
        };
    };
};
var mkFetcherRLCons = function (dictIsSymbol) {
    return function (dictZRPCWrite) {
        return function (dictZRPCRead) {
            return function (dictCons) {
                return function (dictLacks) {
                    return function (dictMkFetcherRL) {
                        return new MkFetcherRL(function (v) {
                            return function (url) {
                                var tail = mkFetcherRL(dictMkFetcherRL)(Type_Data_RowList.RLProxy.value)(url);
                                var fetcher = function (req) {
                                    return post(dictZRPCRead)(Control_Monad_Except_Trans.monadThrowExceptT(Effect_Aff.monadAff))(Effect_Aff_Class.monadAffExceptT(Effect_Aff_Class.monadAffAff))(url)({
                                        service: Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value),
                                        payload: ZRPC_ZRPCWrite.zrpcWrite(dictZRPCWrite)(req),
                                        authToken: Data_Maybe.Nothing.value
                                    });
                                };
                                var $$new = Record_Builder.insert()()(dictIsSymbol)(Data_Symbol.SProxy.value)(fetcher);
                                return Record_Builder.build($$new)(tail);
                            };
                        });
                    };
                };
            };
        };
    };
};
var mkFetcherRLConsAuth = function (dictIsSymbol) {
    return function (dictZRPCWrite) {
        return function (dictZRPCRead) {
            return function (dictMkFetcherRL) {
                return function (dictCons) {
                    return function (dictLacks) {
                        return new MkFetcherRL(function (v) {
                            return function (url) {
                                var tail = mkFetcherRL(dictMkFetcherRL)(Type_Data_RowList.RLProxy.value)(url);
                                var fetcher = function (v1) {
                                    return function (req) {
                                        return post(dictZRPCRead)(Control_Monad_Except_Trans.monadThrowExceptT(Effect_Aff.monadAff))(Effect_Aff_Class.monadAffExceptT(Effect_Aff_Class.monadAffAff))(url)({
                                            service: Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value),
                                            payload: ZRPC_ZRPCWrite.zrpcWrite(dictZRPCWrite)(req),
                                            authToken: new Data_Maybe.Just(v1)
                                        });
                                    };
                                };
                                var $$new = Record_Builder.insert()()(dictIsSymbol)(Data_Symbol.SProxy.value)(fetcher);
                                return Record_Builder.build($$new)(tail);
                            };
                        });
                    };
                };
            };
        };
    };
};
var mkFetcherRLConsAuthNoReq = function (dictIsSymbol) {
    return function (dictZRPCRead) {
        return function (dictMkFetcherRL) {
            return function (dictCons) {
                return function (dictLacks) {
                    return new MkFetcherRL(function (v) {
                        return function (url) {
                            var tail = mkFetcherRL(dictMkFetcherRL)(Type_Data_RowList.RLProxy.value)(url);
                            var fetcher = function (v1) {
                                return post(dictZRPCRead)(Control_Monad_Except_Trans.monadThrowExceptT(Effect_Aff.monadAff))(Effect_Aff_Class.monadAffExceptT(Effect_Aff_Class.monadAffAff))(url)({
                                    service: Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value),
                                    payload: ZRPC_ZRPCWrite.zrpcWrite(ZRPC_ZRPCWrite.zrpcWriteUnit)(Data_Unit.unit),
                                    authToken: new Data_Maybe.Just(v1)
                                });
                            };
                            var $$new = Record_Builder.insert()()(dictIsSymbol)(Data_Symbol.SProxy.value)(fetcher);
                            return Record_Builder.build($$new)(tail);
                        };
                    });
                };
            };
        };
    };
};
var mkFetcherRLConsNoAuthNoReq = function (dictIsSymbol) {
    return function (dictZRPCRead) {
        return function (dictMkFetcherRL) {
            return function (dictCons) {
                return function (dictLacks) {
                    return new MkFetcherRL(function (v) {
                        return function (url) {
                            var tail = mkFetcherRL(dictMkFetcherRL)(Type_Data_RowList.RLProxy.value)(url);
                            var fetcher = post(dictZRPCRead)(Control_Monad_Except_Trans.monadThrowExceptT(Effect_Aff.monadAff))(Effect_Aff_Class.monadAffExceptT(Effect_Aff_Class.monadAffAff))(url)({
                                service: Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value),
                                payload: ZRPC_ZRPCWrite.zrpcWrite(ZRPC_ZRPCWrite.zrpcWriteUnit)(Data_Unit.unit),
                                authToken: Data_Maybe.Nothing.value
                            });
                            var $$new = Record_Builder.insert()()(dictIsSymbol)(Data_Symbol.SProxy.value)(fetcher);
                            return Record_Builder.build($$new)(tail);
                        };
                    });
                };
            };
        };
    };
};
module.exports = {
    post: post,
    MkFetcherRL: MkFetcherRL,
    mkFetcherRL: mkFetcherRL,
    mkApiClient: mkApiClient,
    MkVersionedApiRL: MkVersionedApiRL,
    mkVersionedApiRL: mkVersionedApiRL,
    mkVersionedApiClient: mkVersionedApiClient,
    mkVersionedApiRLNil: mkVersionedApiRLNil,
    mkVersionedApiRLCons: mkVersionedApiRLCons,
    mkFetcherRLNil: mkFetcherRLNil,
    mkFetcherRLConsAuthNoReq: mkFetcherRLConsAuthNoReq,
    mkFetcherRLConsAuth: mkFetcherRLConsAuth,
    mkFetcherRLCons: mkFetcherRLCons,
    mkFetcherRLConsNoAuthNoReq: mkFetcherRLConsNoAuthNoReq
};
