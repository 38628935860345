// Generated by purs version 0.13.8
"use strict";
var $foreign = require("./foreign.js");
var Data_DateTime = require("../Data.DateTime/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Time_Duration = require("../Data.Time.Duration/index.js");
var Effect = require("../Effect/index.js");
var adjustTimezone = function (dt) {
    var adjust$prime = function (offset) {
        var mins = -1.0 * offset;
        var adjustedDt = Data_DateTime.adjust(Data_Time_Duration.durationMinutes)(mins)(dt);
        return Data_Maybe.fromMaybe(dt)(adjustedDt);
    };
    return Data_Functor.mapFlipped(Effect.functorEffect)(Data_JSDate.getTimezoneOffset(Data_JSDate.fromDateTime(dt)))(adjust$prime);
};
module.exports = {
    adjustTimezone: adjustTimezone,
    formatDistanceToNow: $foreign.formatDistanceToNow
};
