exports.enterCode = require('../../src/assets/images/entercode.png');

exports.defaultRewardItem = require('../../src/assets/images/default_reward_item.png');

exports.couponGraphic = require('../../src/assets/images/coupon_graphic.png');
exports.lostConnection = require('../../src/assets/images/lost_connection.png');
exports.emptyContainer = require('../../src/assets/images/empty_container.png');
exports.emptyCatalog = require('../../src/assets/images/empty_catalog.png');
exports.emptyCart = require('../../src/assets/images/empty_cart.png');
exports.emptyRewards = require('../../src/assets/images/empty_rewards.png');
exports.emptyTransactions = require('../../src/assets/images/empty_transactions.png');
exports.noSearchResults = require('../../src/assets/images/no_search_results.png');
exports.generalError = require('../../src/assets/images/general_error.png');

exports.adchoices = require('../../src/assets/images/adchoices.png');

exports.downloadBlack = require('../../src/assets/images/download_black.png');
exports.downloadWhite = require('../../src/assets/images/download_white.png');

exports.downloadGooglePlay = require('../../src/assets/images/download_googleplay.png');
exports.downloadAppstore = require('../../src/assets/images/download_appstore.png');

exports.confettiDesktop = require('../../src/assets/images/confetti_desktop.png');
exports.confettiMobileLeft = require('../../src/assets/images/confetti_mobile_left.png');
exports.confettiMobileRight = require('../../src/assets/images/confetti_mobile_right.png');
