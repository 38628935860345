// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var ReactNative_ActivityIndicator = require("../ReactNative.ActivityIndicator/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.backgroundColor(React_Basic_Native_PropTypes_Color.rgba(255)(255)(255)(0.4)), React_Basic_Native_Styles.height(React_Basic_Native_Styles.percentHeight)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.position(React_Basic_Native_Styles.absolute), React_Basic_Native_Styles.top(React_Basic_Native_Styles.intTop)(0), React_Basic_Native_Styles.zIndex(999) ])
};
var component = React_Basic.createComponent("FullPageSpinner");
var fullPageSpinner = (function () {
    var render = function (props) {
        if (props.show) {
            return React_Basic_Native_Generated.view()({
                style: sheet.container,
                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_Native_Generated.activityIndicator()({
                    size: ReactNative_ActivityIndicator.large,
                    animating: props.show
                }))
            });
        };
        return Data_Monoid.mempty(React_Basic.monoidJSX);
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    fullPageSpinner: fullPageSpinner
};
