// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Reader_Class = require("../Control.Monad.Reader.Class/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var ExpressRpc_Types = require("../ExpressRpc.Types/index.js");
var ReactNavigation = require("../ReactNavigation/index.js");
var ReactNavigation_AuthFlow = require("../ReactNavigation.AuthFlow/index.js");
var ReactNavigation_Class_MonadNavM = require("../ReactNavigation.Class.MonadNavM/index.js");
var networkErrD = {
    title: "No internet connection",
    message: "Please check your connection"
};
var callApi = function (dictMonadNavM) {
    return function (am) {
        return function (release) {
            var func = function (nav) {
                return function (navCb) {
                    var cb$prime = function (res) {
                        if (res instanceof Data_Either.Right) {
                            return navCb(nav)(res.value0);
                        };
                        if (res instanceof Data_Either.Left) {
                            return ReactNavigation.createErrDialog(nav)(networkErrD)(release);
                        };
                        throw new Error("Failed pattern match at ZAP.Dart.Client.ApiClient.Utils (line 90, column 17 - line 92, column 58): " + [ res.constructor.name ]);
                    };
                    var aff = ExpressRpc_Types.runApiM(am);
                    return ReactNavigation.runLoadAff(nav)(aff)(cb$prime);
                };
            };
            return ReactNavigation_Class_MonadNavM.liftNavM(dictMonadNavM)(Control_Bind.bind(ReactNavigation.navmBind)(func)(ReactNavigation_AuthFlow.processClientErr(ReactNavigation_Class_MonadNavM.monadNMNavM)));
        };
    };
};
var callApi_ = function (dictMonadNavM) {
    return function (dictMonad) {
        return function (am) {
            return callApi(dictMonadNavM)(am)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
        };
    };
};
var acallApi$prime$prime = function (dictMonadReader) {
    return function (dictMonadNavM) {
        return function (dictPlus) {
            return function (apiF) {
                return function (req) {
                    var func = function (at) {
                        return function (nav) {
                            return function (navCb) {
                                var cb$prime = function (res) {
                                    if (res instanceof Data_Either.Right) {
                                        return navCb(nav)(res.value0);
                                    };
                                    if (res instanceof Data_Either.Left) {
                                        return ReactNavigation.createErrDialog(nav)(networkErrD)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
                                    };
                                    throw new Error("Failed pattern match at ZAP.Dart.Client.ApiClient.Utils (line 62, column 17 - line 64, column 62): " + [ res.constructor.name ]);
                                };
                                var aff = ExpressRpc_Types.runApiM(apiF(at)(req));
                                return ReactNavigation.runLoadAff(nav)(aff)(cb$prime);
                            };
                        };
                    };
                    return Control_Bind.bind((dictMonadNavM.Monad0()).Bind1())(Control_Monad_Reader_Class.ask(dictMonadReader.MonadAsk0()))(function (authctx) {
                        return ReactNavigation_Class_MonadNavM.liftNavM(dictMonadNavM)(func((Data_Newtype.unwrap(ReactNavigation_AuthFlow.authCtxNewType)(authctx)).authToken));
                    });
                };
            };
        };
    };
};
var acallApi$prime = function (dictMonadReader) {
    return function (dictMonadNavM) {
        return function (dictPlus) {
            return function (apiF) {
                return function (req) {
                    return Control_Bind.bind((dictMonadNavM.Monad0()).Bind1())(acallApi$prime$prime(dictMonadReader)(dictMonadNavM)(dictPlus)(apiF)(req))(ReactNavigation_AuthFlow["processClientErr'"](dictMonadNavM)(dictPlus));
                };
            };
        };
    };
};
var acallApi_ = function (dictMonadReader) {
    return function (dictMonadNavM) {
        return function (dictPlus) {
            return function (api) {
                return acallApi$prime(dictMonadReader)(dictMonadNavM)(dictPlus)(function (v) {
                    return function (v1) {
                        return api;
                    };
                })(Data_Unit.unit);
            };
        };
    };
};
var acallApi = function (dictMonadReader) {
    return function (dictMonadNavM) {
        return function (dictPlus) {
            return function (apiF) {
                return acallApi$prime(dictMonadReader)(dictMonadNavM)(dictPlus)(function (at) {
                    return function (v) {
                        return apiF(at);
                    };
                })(Data_Unit.unit);
            };
        };
    };
};
module.exports = {
    acallApi: acallApi,
    acallApi_: acallApi_,
    "acallApi'": acallApi$prime,
    "acallApi''": acallApi$prime$prime,
    callApi_: callApi_,
    callApi: callApi,
    networkErrD: networkErrD
};
