// Generated by purs version 0.13.8
"use strict";
var Data_Maybe = require("../Data.Maybe/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var ZAP_Dart_Client_Components_Input_Styles = require("../ZAP.Dart.Client.Components.Input.Styles/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var component = React_Basic.createComponent("TextField");
var textField = (function () {
    var render = function (v) {
        return React_Basic_Native_Generated.textInput()({
            style: React_Basic_Native_Styles["styles'"]([ ZAP_Dart_Client_Components_Input_Styles.container, ZAP_Dart_Client_Components_Input_Styles.styleDisabled(v.disabled) ]),
            value: Data_Maybe.fromMaybe("")(v.value),
            onChangeText: function ($5) {
                return v.onChangeText(ZAP_Dart_Client_Screens_Utils.getNonEmpty($5))();
            },
            editable: !v.disabled
        });
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    textField: textField
};
