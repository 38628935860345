// Generated by purs version 0.13.8
"use strict";
var ubuntuMono = {
    regular: "UbuntuMono-Regular",
    italic: "UbuntuMono-Italic",
    bold: "UbuntuMono-Bold"
};
var manRope = {
    regular: "ManRope-Regular",
    bold: "ManRope-Bold",
    semiBold: "ManRope-Semibold",
    extraBold: "ManRope-Extrabold",
    light: "ManRope-Light",
    italic: "ManRope-Regular"
};
module.exports = {
    manRope: manRope,
    ubuntuMono: ubuntuMono
};
