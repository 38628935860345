// Generated by purs version 0.13.8
"use strict";
var Data_Foldable = require("../Data.Foldable/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Constants = require("../ZAP.Dart.Client.Constants/index.js");
var ZAP_Dart_Client_Contexts_AdvancedOpts = require("../ZAP.Dart.Client.Contexts.AdvancedOpts/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Utils = require("../ZAP.Dart.Client.Styles.Utils/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.bg), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(20), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(30) ]),
    row: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(12) ]),
    succeedingRow: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(24) ]),
    key: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1) ])
};
var c = React_Basic.createComponent("DevOpsScreen");
var advancedOpts = (function () {
    var render = function (props) {
        return ZAP_Dart_Client_Contexts_AdvancedOpts.advancedOptsConsumer(function (v) {
            return Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(React_Basic.monoidJSX)(function (devOptV) {
                return React_Basic_Native_Generated.view()({
                    style: sheet.container,
                    children: [ React_Basic_Native_Generated.view()({
                        style: sheet.row,
                        children: [ ZAP_Dart_Client_Components_Typography.b1({
                            style: sheet.key,
                            color: ZAP_Dart_Client_Styles_Colors.altFg,
                            value: "Join Beta Program"
                        }), React_Basic_Native_Generated["switch"]()({
                            onValueChange: function (bool) {
                                return v.setValue({
                                    usePreprod: bool
                                })();
                            },
                            value: devOptV.usePreprod
                        }) ]
                    }), React_Basic_Native_Generated.touchableOpacity()({
                        style: sheet.succeedingRow,
                        onPress: React_Basic_Events.handler_(v.clearAStorage),
                        children: [ ZAP_Dart_Client_Components_Typography.b1({
                            style: sheet.key,
                            color: ZAP_Dart_Client_Styles_Colors.altFg,
                            value: "Clear Storage"
                        }) ]
                    }), React_Basic_Native_Generated.view()({
                        style: sheet.succeedingRow,
                        children: [ ZAP_Dart_Client_Components_Typography.b1({
                            style: sheet.key,
                            color: ZAP_Dart_Client_Styles_Colors.altFg,
                            value: "Version Number"
                        }), ZAP_Dart_Client_Components_Typography.b1({
                            style: ZAP_Dart_Client_Styles_Utils.noStyle,
                            color: ZAP_Dart_Client_Styles_Colors.altFg,
                            value: ZAP_Dart_Client_Constants.version
                        }) ]
                    }) ]
                });
            })(v.value);
        });
    };
    return React_Basic.makeStateless(c)(render);
})();
module.exports = {
    c: c,
    advancedOpts: advancedOpts,
    sheet: sheet
};
