var RNavCore = require('@react-navigation/core');
var RNavStack = require('react-navigation-stack');
var RNavNative = require('@react-navigation/native');
var RNavTabs = require('react-navigation-tabs');
var navWrapper = require('../../src/js/client/navWrapper.js').default;

var navMethod = function(methodName) {
  return function(nav) {
    return nav[methodName].bind(nav);
  };
};

var navValue = function(methodName) {
  return function(nav) {
    return nav[methodName];
  };
};

function getFirstSiblingParam(nav) {
  if (nav.dangerouslyGetParent) {
    var parent = nav.dangerouslyGetParent();
    if (
      parent &&
      parent.state.routes &&
      parent.state.routes[0] &&
      parent.state.routes[0].params &&
      parent.state.routes[0].params._data
    ) {
      return parent.state.routes[0].params._data;
    }
  }
  console.warn('_data not found from first sibling');
  return null;
}

exports._navigate = navMethod('navigate');
exports._goBack = navMethod('goBack');
exports._addListener = navMethod('addListener');
exports._getParam = navMethod('getParam');
exports._dangerouslyGetParent = navMethod('dangerouslyGetParent');
exports._replace = navMethod('replace');
exports._push = navMethod('push');
exports._pop = navMethod('pop');
exports._popToTop = navMethod('popToTop');
exports._createDialog = navMethod('createDialog');
exports._destroyDialog = navMethod('destroyDialog');
exports._showSpinner = navMethod('showSpinner');
exports._hideSpinner = navMethod('hideSpinner');
exports._state = navValue('state');

// Workaround for assigning the flow aka "initialRouteParam" to all tabs in TabNavigator
// Not sure if this is 100% correct
exports._recursivelyGetParamData = function(nav) {
  return function() {
    if (nav) {
      return nav.getParam('_data', getFirstSiblingParam(nav));
    } else {
      console.warn('_data not found!');
      return undefined;
    }
  };
};

exports._createSwitchNavigator = RNavCore.createSwitchNavigator;

exports._createStackNavigator = RNavStack.createStackNavigator;

exports.createAppContainer = RNavNative.createAppContainer;

exports._createBottomTabNavigator = RNavTabs.createBottomTabNavigator;

exports.navWrapper = navWrapper;
