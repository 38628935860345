// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var ReactNative_Image = require("../ReactNative.Image/index.js");
var ZAP_Dart_Client_Components_Image = require("../ZAP.Dart.Client.Components.Image/index.js");
var ZAP_Dart_Client_Constants = require("../ZAP.Dart.Client.Constants/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var sheet = {
    profilePhotoContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.borderRadius(100), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.bg), React_Basic_Native_Styles.shadowOpacity(0.2), React_Basic_Native_Styles.shadowRadius(8.0), React_Basic_Native_Styles.shadowOffset({
        height: 4.0,
        width: 0.0
    }), React_Basic_Native_Styles.shadowColor("black"), React_Basic_Native_Styles.elevation(4) ]),
    profilePhoto: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.height(React_Basic_Native_Styles.percentHeight)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.borderRadius(100), React_Basic_Native_Styles.overflow(React_Basic_Native_PropTypes.hidden(React_Basic_Native_Styles.overflowV)) ])
};
var profilePictureComponent = React_Basic.createComponent("ProfilePicture");
var profilePicture = (function () {
    var render = function (v) {
        return React_Basic_Native_Generated.view()({
            style: React_Basic_Native_Styles["styles'"]([ sheet.profilePhotoContainer, React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(v.height), React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(v.width) ]) ]),
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(ZAP_Dart_Client_Components_Image.image({
                style: sheet.profilePhoto,
                source: ReactNative_Image.imageUri(Data_Maybe.fromMaybe(ZAP_Dart_Client_Constants.defaultAvatarUrl)(v.avatarUrl)),
                resizeMode: React_Basic_Native_Styles.cover
            }))
        });
    };
    return React_Basic.makeStateless(profilePictureComponent)(render);
})();
module.exports = {
    profilePicture: profilePicture
};
