// Generated by purs version 0.13.8
"use strict";
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var ZAP_Dart_Client_Icons_CheckBox = require("../ZAP.Dart.Client.Icons.CheckBox/index.js");
var component = React_Basic.createComponent("CheckBox");
var checkBox = (function () {
    var renderIconCheckBox = function (color) {
        return function (v) {
            if (v) {
                return ZAP_Dart_Client_Icons_CheckBox.checkBox({
                    width: 20,
                    height: 20,
                    color: color
                });
            };
            if (!v) {
                return ZAP_Dart_Client_Icons_CheckBox.checkBoxBlank({
                    width: 20,
                    height: 20,
                    color: color
                });
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Components.CheckBox (line 30, column 5 - line 30, column 50): " + [ color.constructor.name, v.constructor.name ]);
        };
    };
    var render = function (props) {
        return React_Basic_Native_Generated.touchableOpacity()({
            onPress: props.onPress,
            children: [ renderIconCheckBox(props.color)(props.isChecked) ]
        });
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    checkBox: checkBox
};
