// Generated by purs version 0.13.8
"use strict";
var Data_Symbol = require("../Data.Symbol/index.js");
var ReactNative_Platform = require("../ReactNative.Platform/index.js");
var ReactNavigation = require("../ReactNavigation/index.js");
var Record = require("../Record/index.js");
var stackRcBuilder = function (dictIsRouterConfig) {
    return function (dictLacks) {
        return function (dictIsRouterConfig1) {
            return function (opts) {
                if (ReactNative_Platform.os instanceof ReactNative_Platform.Web) {
                    var opts$prime = Record.insert(new Data_Symbol.IsSymbol(function () {
                        return "headerMode";
                    }))()()(Data_Symbol.SProxy.value)(ReactNavigation.screen)(opts);
                    return ReactNavigation.mkRouterConfigBuilder(dictIsRouterConfig1)(opts$prime);
                };
                return ReactNavigation.mkRouterConfigBuilder(dictIsRouterConfig)(opts);
            };
        };
    };
};
var stackRcBuilder_ = stackRcBuilder(ReactNavigation.isRouterConfigOpts()()())()(ReactNavigation.isRouterConfigOpts()()())({});
module.exports = {
    stackRcBuilder: stackRcBuilder,
    stackRcBuilder_: stackRcBuilder_
};
