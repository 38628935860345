var Clipboard = require('react-native').Clipboard;

exports._getString = function() {
  return Clipboard.getString();
};

exports.setString = function(content) {
  return function() {
    Clipboard.setString(content);
  };
};
