// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_Components_BabyForm = require("../ZAP.Dart.Client.Components.BabyForm/index.js");
var ZAP_Dart_Client_Components_Button = require("../ZAP.Dart.Client.Components.Button/index.js");
var ZAP_Dart_Client_Components_Divider = require("../ZAP.Dart.Client.Components.Divider/index.js");
var ZAP_Dart_Client_Components_External_KeyboardAwareScrollView = require("../ZAP.Dart.Client.Components.External.KeyboardAwareScrollView/index.js");
var ZAP_Dart_Client_Components_Input_Validation = require("../ZAP.Dart.Client.Components.Input.Validation/index.js");
var ZAP_Dart_Client_Components_ProfileForm = require("../ZAP.Dart.Client.Components.ProfileForm/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Screens_Types = require("../ZAP.Dart.Client.Screens.Types/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Dimensions = require("../ZAP.Dart.Client.Styles.Dimensions/index.js");
var ZAP_Dart_Common = require("../ZAP.Dart.Common/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(40), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), ZAP_Dart_Client_Styles_Dimensions.screenMaxWidth, React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    header: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(50), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(45), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)) ]),
    divider: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(40), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(25) ]),
    submitButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginHorizontal(React_Basic_Native_Styles.intMarginHorizontal)(40) ]),
    laterButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(20) ])
};
var component = React_Basic.createComponent("CompleteProfile");
var completeProfile = function (v) {
    return function (props) {
        var translateProfile = function (p) {
            return {
                firstName: p.firstName,
                lastName: p.lastName,
                mobile: p.mobile,
                email: Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(p.email)(function (v1) {
                    if (v1 instanceof ZAP_Dart_Common.Confirmed) {
                        return new ZAP_Dart_Client_Components_ProfileForm.Confirmed(v1.value0.value);
                    };
                    if (v1 instanceof ZAP_Dart_Common.Unconfirmed) {
                        return new ZAP_Dart_Client_Components_ProfileForm.Unconfirmed(v1.value0.value);
                    };
                    throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.CompleteProfile (line 76, column 13 - line 78, column 57): " + [ v1.constructor.name ]);
                }),
                location: p.location,
                gender: p.gender
            };
        };
        var onSubmit = function (self) {
            var newProfile = {
                firstName: self.state.formProfile.firstName,
                lastName: self.state.formProfile.lastName,
                newAvatar: props.initD.profile.avatarUrl,
                email: Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(self.state.formProfile.email)(function (v1) {
                    if (v1 instanceof ZAP_Dart_Client_Components_ProfileForm.Confirmed) {
                        return v1.value0;
                    };
                    if (v1 instanceof ZAP_Dart_Client_Components_ProfileForm.Unconfirmed) {
                        return v1.value0;
                    };
                    if (v1 instanceof ZAP_Dart_Client_Components_ProfileForm.Unsubmitted) {
                        return v1.value0;
                    };
                    throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.CompleteProfile (line 108, column 17 - line 111, column 37): " + [ v1.constructor.name ]);
                }),
                location: self.state.formProfile.location,
                gender: self.state.formProfile.gender,
                registrationData: self.state.registrationData
            };
            var updateProfile = ZAP_Dart_Client_Screens_Utils.mkLApi(v.apiCalls.updateProfile({
                profile: newProfile
            }))(function (v1) {
                if (v1 instanceof Data_Either.Right) {
                    return self.props.onAction(new ReactNavigation_Types.PgSubmit(ZAP_Dart_Client_Screens_Types.CompleteProfileSuccess.value));
                };
                if (v1 instanceof Data_Either.Left) {
                    return self.props.showDialog(ReactNavigation_Types.Alert.create(ZAP_Dart_Client_Components_ProfileForm.updateProfileErrDialog(v1.value0)))(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.CompleteProfile (line 93, column 11 - line 95, column 115): " + [ v1.constructor.name ]);
            });
            var runUpdate = self.props.loadApiA(updateProfile);
            return runUpdate;
        };
        var render = function (self) {
            return ZAP_Dart_Client_Components_External_KeyboardAwareScrollView.keyboardAwareScrollView()({
                contentContainerStyle: sheet.container,
                children: [ ZAP_Dart_Client_Components_Typography.b2({
                    style: sheet.header,
                    color: ZAP_Dart_Client_Styles_Colors.altFg,
                    value: "Please fill in the following details to\x0d finish registering for your account"
                }), ZAP_Dart_Client_Components_ProfileForm.profileForm({
                    profile: self.state.formProfile,
                    registrationFields: props.initD.registrationFields,
                    locations: props.initD.locations,
                    onProfileChange: function (p) {
                        return self.setState(function (v1) {
                            return {
                                formProfile: p,
                                registrationData: v1.registrationData
                            };
                        });
                    }
                }), ZAP_Dart_Client_Components_Divider.divider(sheet.divider), ZAP_Dart_Client_Components_BabyForm.babyForm({
                    registrationData: self.state.registrationData,
                    registrationFields: props.initD.registrationFields,
                    onChange: function (v1) {
                        return self.setState(function (v2) {
                            return {
                                formProfile: v2.formProfile,
                                registrationData: v1
                            };
                        });
                    },
                    onError: ZAP_Dart_Client_Components_Input_Validation.handleError(self)
                }), ZAP_Dart_Client_Components_Divider.divider(sheet.divider), ZAP_Dart_Client_Components_Button.primaryButton({
                    title: "SUBMIT DETAILS",
                    onPress: onSubmit(self),
                    disabled: false,
                    inverted: false,
                    style: sheet.submitButton,
                    size: ZAP_Dart_Client_Components_Button.md
                }), ZAP_Dart_Client_Components_Button.secondaryButton({
                    title: "MAYBE LATER",
                    onPress: self.props.onAction(new ReactNavigation_Types.PgSubmit(ZAP_Dart_Client_Screens_Types.CompleteProfileMaybeLater.value)),
                    disabled: false,
                    inverted: false,
                    style: sheet.laterButton
                }) ]
            });
        };
        var deriveState = function (v1) {
            return {
                formProfile: translateProfile(v1.initD.profile),
                registrationData: v1.initD.profile.registrationData
            };
        };
        var didUpdate = function (self) {
            return function (v1) {
                return Control_Applicative.when(Effect.applicativeEffect)(Data_Eq.notEq(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                    return "registrationFields";
                }))(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                    return "name";
                }))(Data_Eq.eqString))()(new Data_Symbol.IsSymbol(function () {
                    return "isRequired";
                }))(Data_Eq.eqBoolean))()(new Data_Symbol.IsSymbol(function () {
                    return "input";
                }))(ZAP_Dart_Common.eqInputType))()(new Data_Symbol.IsSymbol(function () {
                    return "id";
                }))(Data_Eq.eqString)))))()(new Data_Symbol.IsSymbol(function () {
                    return "profile";
                }))(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                    return "registrationData";
                }))(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                    return "value";
                }))(ZAP_Dart_Common.eqValue))()(new Data_Symbol.IsSymbol(function () {
                    return "name";
                }))(Data_Eq.eqString))()(new Data_Symbol.IsSymbol(function () {
                    return "id";
                }))(Data_Eq.eqString)))))()(new Data_Symbol.IsSymbol(function () {
                    return "mobile";
                }))(Data_Eq.eqString))()(new Data_Symbol.IsSymbol(function () {
                    return "location";
                }))(Data_Maybe.eqMaybe(Data_Eq.eqString)))()(new Data_Symbol.IsSymbol(function () {
                    return "lastName";
                }))(Data_Maybe.eqMaybe(Data_Eq.eqString)))()(new Data_Symbol.IsSymbol(function () {
                    return "gender";
                }))(Data_Maybe.eqMaybe(ZAP_Dart_Common.eqGender)))()(new Data_Symbol.IsSymbol(function () {
                    return "firstName";
                }))(Data_Maybe.eqMaybe(Data_Eq.eqString)))()(new Data_Symbol.IsSymbol(function () {
                    return "email";
                }))(Data_Maybe.eqMaybe(ZAP_Dart_Common.eqEmail)))()(new Data_Symbol.IsSymbol(function () {
                    return "avatarUrl";
                }))(Data_Maybe.eqMaybe(Data_Eq.eqString)))))()(new Data_Symbol.IsSymbol(function () {
                    return "locations";
                }))(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                    return "name";
                }))(Data_Eq.eqString))()(new Data_Symbol.IsSymbol(function () {
                    return "id";
                }))(Data_Eq.eqString))()(new Data_Symbol.IsSymbol(function () {
                    return "cities";
                }))(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                    return "name";
                }))(Data_Eq.eqString))()(new Data_Symbol.IsSymbol(function () {
                    return "id";
                }))(Data_Eq.eqString)))))))))(self.props.initD)(v1.prevProps.initD))(self.setState(Data_Function["const"](deriveState(self.props))));
            };
        };
        var initialState = deriveState(props);
        return React_Basic.make()(component)({
            initialState: initialState,
            render: render,
            didUpdate: didUpdate
        })(props);
    };
};
module.exports = {
    completeProfile: completeProfile
};
