// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect = require("../Effect/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var React_Basic_Native = require("../React.Basic.Native/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var ZAP_Dart_Client_Components_CheckBox = require("../ZAP.Dart.Client.Components.CheckBox/index.js");
var ZAP_Dart_Client_Components_Divider = require("../ZAP.Dart.Client.Components.Divider/index.js");
var ZAP_Dart_Client_Components_Icon = require("../ZAP.Dart.Client.Components.Icon/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var ZAP_Dart_Client_Styles_Utils = require("../ZAP.Dart.Client.Styles.Utils/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.backgroundColor(React_Basic_Native_PropTypes_Color.white), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.borderRadius(12), React_Basic_Native_Styles.padding(React_Basic_Native_Styles.intPadding)(24), React_Basic_Native_Styles.borderColor(React_Basic_Native_PropTypes_Color.rgba(0)(0)(0)(0.1)), React_Basic_Native_Styles.maxWidth(React_Basic_Native_Styles.intMaxWidth)(322), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    clearIcon: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(24), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(24), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    divider: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_Styles_Flex.stretch) ]),
    topRow: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_Styles_Flex.stretch), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_Styles_Flex.spaceBetween), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(12) ]),
    midRow: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_Styles_Flex.stretch), React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(20) ]),
    bottomRow: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_Styles_Flex.stretch), React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(16) ]),
    filterItem: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_Styles_Flex.stretch), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_Styles_Flex.spaceBetween), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(4), React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(20) ]),
    primaryActionText: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(4), React_Basic_Native_Styles_Text.fontSize(18), React_Basic_Native_Styles_Text.lineHeight(21), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(1.8), React_Basic_Native_Styles_Text.color(ZAP_Dart_Client_Styles_Colors.primary), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.regular) ])
};
var filterItem = function (props) {
    return React_Basic_Native_Generated.view()({
        style: sheet.filterItem,
        children: [ ZAP_Dart_Client_Components_Typography.b1({
            style: ZAP_Dart_Client_Styles_Utils.noStyle,
            color: ZAP_Dart_Client_Styles_Colors.fg,
            value: props.label
        }), ZAP_Dart_Client_Components_CheckBox.checkBox({
            isChecked: props.isChecked,
            onPress: React_Basic_Events.handler_(props.onPress),
            color: ZAP_Dart_Client_Styles_Colors.fg
        }) ]
    });
};
var mkFilterModalRC = React_Basic_Hooks.component()()("FilterModal")(function (props) {
    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(props.filters))(function (v) {
        return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffect(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
            return "redeemed";
        }))(Data_Eq.eqBoolean))()(new Data_Symbol.IsSymbol(function () {
            return "expired";
        }))(Data_Eq.eqBoolean))()(new Data_Symbol.IsSymbol(function () {
            return "available";
        }))(Data_Eq.eqBoolean)))(props.filters)(function __do() {
            Control_Applicative.when(Effect.applicativeEffect)(Data_Eq.notEq(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                return "redeemed";
            }))(Data_Eq.eqBoolean))()(new Data_Symbol.IsSymbol(function () {
                return "expired";
            }))(Data_Eq.eqBoolean))()(new Data_Symbol.IsSymbol(function () {
                return "available";
            }))(Data_Eq.eqBoolean)))(v.value0)(props.filters))(v.value1(function (v1) {
                return props.filters;
            }))();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        }))(function () {
            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_Native_Generated.view()({
                style: sheet.container,
                children: [ React_Basic_Native_Generated.view()({
                    style: sheet.topRow,
                    children: [ ZAP_Dart_Client_Components_Typography.h5({
                        style: ZAP_Dart_Client_Styles_Utils.noStyle,
                        color: ZAP_Dart_Client_Styles_Colors.fg,
                        value: "Filter"
                    }), React_Basic_Native_Generated.touchableOpacity()({
                        onPress: React_Basic_Events.handler_(props.onClose),
                        children: [ React_Basic_Native_Generated.view()({
                            style: sheet.clearIcon,
                            children: [ ZAP_Dart_Client_Components_Icon.icon({
                                name: "exit_outlined",
                                size: 24,
                                color: ZAP_Dart_Client_Styles_Colors.fg
                            }) ]
                        }) ]
                    }) ]
                }), ZAP_Dart_Client_Components_Divider.divider(sheet.divider), React_Basic_Native_Generated.view()({
                    style: sheet.midRow,
                    children: [ filterItem({
                        label: "Available",
                        isChecked: v.value0.available,
                        onPress: v.value1(function (s) {
                            return {
                                available: !s.available,
                                redeemed: s.redeemed,
                                expired: s.expired
                            };
                        })
                    }), filterItem({
                        label: "Redeemed",
                        isChecked: v.value0.redeemed,
                        onPress: v.value1(function (s) {
                            return {
                                available: s.available,
                                redeemed: !s.redeemed,
                                expired: s.expired
                            };
                        })
                    }), filterItem({
                        label: "Expired",
                        isChecked: v.value0.expired,
                        onPress: v.value1(function (s) {
                            return {
                                available: s.available,
                                redeemed: s.redeemed,
                                expired: !s.expired
                            };
                        })
                    }) ]
                }), ZAP_Dart_Client_Components_Divider.divider(sheet.divider), React_Basic_Native_Generated.view()({
                    style: sheet.bottomRow,
                    children: [ React_Basic_Native_Generated.touchableOpacity()({
                        onPress: React_Basic_Events.handler_(function __do() {
                            props.onUpdatePress(v.value0)();
                            return props.onClose();
                        }),
                        children: [ React_Basic_Native_Generated.text()({
                            style: sheet.primaryActionText,
                            children: [ React_Basic_Native.string("Update") ]
                        }) ]
                    }) ]
                }) ]
            }));
        });
    });
});
var filterModalRC = Effect_Unsafe.unsafePerformEffect(mkFilterModalRC);
var filterModal = React_Basic.element(filterModalRC);
module.exports = {
    filterModal: filterModal
};
