// Generated by purs version 0.13.8
"use strict";
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ReactNavigation_Utils_NavBarCreators = require("../ReactNavigation.Utils.NavBarCreators/index.js");
var ZAP_Dart_Client_Components_NavBar_SimpleNavBar = require("../ZAP.Dart.Client.Components.NavBar.SimpleNavBar/index.js");
var titleNavBar = function (title) {
    return ReactNavigation_Utils_NavBarCreators.mkNavBarContent_(ZAP_Dart_Client_Components_NavBar_SimpleNavBar.simpleNavBar)(new ReactNavigation_Types.NBTitle(title));
};
module.exports = {
    titleNavBar: titleNavBar
};
