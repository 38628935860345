// Generated by purs version 0.13.8
"use strict";
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var sheet = {
    image: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.height(React_Basic_Native_Styles.percentHeight)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)) ])
};
var image = function (v) {
    return React_Basic_Native_Generated.view()({
        style: v.style,
        children: [ React_Basic_Native_Generated.image()({
            style: React_Basic_Native_Styles["styles'"]([ sheet.image, React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.resizeMode(v.resizeMode) ]) ]),
            source: v.source
        }) ]
    });
};
module.exports = {
    image: image
};
