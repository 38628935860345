// Generated by purs version 0.13.8
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");
var Data_HugeNum = require("../Data.HugeNum/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var ZRPC_ConsPrefix = require("../ZRPC.ConsPrefix/index.js");
var ZRPC_ZRPCRead = require("../ZRPC.ZRPCRead/index.js");
var ZRPC_ZRPCWrite = require("../ZRPC.ZRPCWrite/index.js");
var FullName = (function () {
    function FullName() {

    };
    FullName.value = new FullName();
    return FullName;
})();
var Gender = (function () {
    function Gender() {

    };
    Gender.value = new Gender();
    return Gender;
})();
var Birthday = (function () {
    function Birthday() {

    };
    Birthday.value = new Birthday();
    return Birthday;
})();
var Location = (function () {
    function Location() {

    };
    Location.value = new Location();
    return Location;
})();
var EmailAddress = (function () {
    function EmailAddress() {

    };
    EmailAddress.value = new EmailAddress();
    return EmailAddress;
})();
var VerifyPinErrInvalidPin = (function () {
    function VerifyPinErrInvalidPin() {

    };
    VerifyPinErrInvalidPin.value = new VerifyPinErrInvalidPin();
    return VerifyPinErrInvalidPin;
})();
var VerifyPinErrAccountLocked = (function () {
    function VerifyPinErrAccountLocked() {

    };
    VerifyPinErrAccountLocked.value = new VerifyPinErrAccountLocked();
    return VerifyPinErrAccountLocked;
})();
var VerifyOtpErrMaxAttemptCountExceeded = (function () {
    function VerifyOtpErrMaxAttemptCountExceeded() {

    };
    VerifyOtpErrMaxAttemptCountExceeded.value = new VerifyOtpErrMaxAttemptCountExceeded();
    return VerifyOtpErrMaxAttemptCountExceeded;
})();
var VerifyOtpErrSessionExpired = (function () {
    function VerifyOtpErrSessionExpired() {

    };
    VerifyOtpErrSessionExpired.value = new VerifyOtpErrSessionExpired();
    return VerifyOtpErrSessionExpired;
})();
var VerifyOtpErrOthers = (function () {
    function VerifyOtpErrOthers() {

    };
    VerifyOtpErrOthers.value = new VerifyOtpErrOthers();
    return VerifyOtpErrOthers;
})();
var Single = (function () {
    function Single(value0) {
        this.value0 = value0;
    };
    Single.create = function (value0) {
        return new Single(value0);
    };
    return Single;
})();
var Multi = (function () {
    function Multi(value0) {
        this.value0 = value0;
    };
    Multi.create = function (value0) {
        return new Multi(value0);
    };
    return Multi;
})();
var UpdateProfileErrInvalidEmail = (function () {
    function UpdateProfileErrInvalidEmail() {

    };
    UpdateProfileErrInvalidEmail.value = new UpdateProfileErrInvalidEmail();
    return UpdateProfileErrInvalidEmail;
})();
var UpdateProfileErrExistingEmail = (function () {
    function UpdateProfileErrExistingEmail() {

    };
    UpdateProfileErrExistingEmail.value = new UpdateProfileErrExistingEmail();
    return UpdateProfileErrExistingEmail;
})();
var UpdateProfileErrMissingFields = (function () {
    function UpdateProfileErrMissingFields(value0) {
        this.value0 = value0;
    };
    UpdateProfileErrMissingFields.create = function (value0) {
        return new UpdateProfileErrMissingFields(value0);
    };
    return UpdateProfileErrMissingFields;
})();
var UpdateProfileErrOthers = (function () {
    function UpdateProfileErrOthers() {

    };
    UpdateProfileErrOthers.value = new UpdateProfileErrOthers();
    return UpdateProfileErrOthers;
})();
var UpdateCartErrInvalidZapperId = (function () {
    function UpdateCartErrInvalidZapperId() {

    };
    UpdateCartErrInvalidZapperId.value = new UpdateCartErrInvalidZapperId();
    return UpdateCartErrInvalidZapperId;
})();
var UpdateCartErrProductDoesNotExist = (function () {
    function UpdateCartErrProductDoesNotExist() {

    };
    UpdateCartErrProductDoesNotExist.value = new UpdateCartErrProductDoesNotExist();
    return UpdateCartErrProductDoesNotExist;
})();
var UpdateCartErrAllocationNotFound = (function () {
    function UpdateCartErrAllocationNotFound() {

    };
    UpdateCartErrAllocationNotFound.value = new UpdateCartErrAllocationNotFound();
    return UpdateCartErrAllocationNotFound;
})();
var UpdateCartErrInsufficientAllocation = (function () {
    function UpdateCartErrInsufficientAllocation(value0) {
        this.value0 = value0;
    };
    UpdateCartErrInsufficientAllocation.create = function (value0) {
        return new UpdateCartErrInsufficientAllocation(value0);
    };
    return UpdateCartErrInsufficientAllocation;
})();
var UpdateCartErrUpdateCartFailed = (function () {
    function UpdateCartErrUpdateCartFailed() {

    };
    UpdateCartErrUpdateCartFailed.value = new UpdateCartErrUpdateCartFailed();
    return UpdateCartErrUpdateCartFailed;
})();
var Earned = (function () {
    function Earned(value0) {
        this.value0 = value0;
    };
    Earned.create = function (value0) {
        return new Earned(value0);
    };
    return Earned;
})();
var Redeemed = (function () {
    function Redeemed(value0) {
        this.value0 = value0;
    };
    Redeemed.create = function (value0) {
        return new Redeemed(value0);
    };
    return Redeemed;
})();
var TxRowTypeManualPoints = (function () {
    function TxRowTypeManualPoints() {

    };
    TxRowTypeManualPoints.value = new TxRowTypeManualPoints();
    return TxRowTypeManualPoints;
})();
var TxRowTypeManualCoupon = (function () {
    function TxRowTypeManualCoupon() {

    };
    TxRowTypeManualCoupon.value = new TxRowTypeManualCoupon();
    return TxRowTypeManualCoupon;
})();
var TxRowTypeCatalogPurchase = (function () {
    function TxRowTypeCatalogPurchase() {

    };
    TxRowTypeCatalogPurchase.value = new TxRowTypeCatalogPurchase();
    return TxRowTypeCatalogPurchase;
})();
var TxRowTypeUnknown = (function () {
    function TxRowTypeUnknown() {

    };
    TxRowTypeUnknown.value = new TxRowTypeUnknown();
    return TxRowTypeUnknown;
})();
var TxRowStatusCleared = (function () {
    function TxRowStatusCleared() {

    };
    TxRowStatusCleared.value = new TxRowStatusCleared();
    return TxRowStatusCleared;
})();
var TxRowStatusPending = (function () {
    function TxRowStatusPending() {

    };
    TxRowStatusPending.value = new TxRowStatusPending();
    return TxRowStatusPending;
})();
var TxRowStatusCancelled = (function () {
    function TxRowStatusCancelled() {

    };
    TxRowStatusCancelled.value = new TxRowStatusCancelled();
    return TxRowStatusCancelled;
})();
var TxRowStatusVoid = (function () {
    function TxRowStatusVoid() {

    };
    TxRowStatusVoid.value = new TxRowStatusVoid();
    return TxRowStatusVoid;
})();
var TxRowStatusUnknown = (function () {
    function TxRowStatusUnknown() {

    };
    TxRowStatusUnknown.value = new TxRowStatusUnknown();
    return TxRowStatusUnknown;
})();
var PointsAllotment = (function () {
    function PointsAllotment(value0) {
        this.value0 = value0;
    };
    PointsAllotment.create = function (value0) {
        return new PointsAllotment(value0);
    };
    return PointsAllotment;
})();
var CouponAllotment = (function () {
    function CouponAllotment(value0) {
        this.value0 = value0;
    };
    CouponAllotment.create = function (value0) {
        return new CouponAllotment(value0);
    };
    return CouponAllotment;
})();
var SendOtpErrInvalidContactNumber = (function () {
    function SendOtpErrInvalidContactNumber() {

    };
    SendOtpErrInvalidContactNumber.value = new SendOtpErrInvalidContactNumber();
    return SendOtpErrInvalidContactNumber;
})();
var SendOtpErrLimitExceeded = (function () {
    function SendOtpErrLimitExceeded() {

    };
    SendOtpErrLimitExceeded.value = new SendOtpErrLimitExceeded();
    return SendOtpErrLimitExceeded;
})();
var SendOtpErrOthers = (function () {
    function SendOtpErrOthers() {

    };
    SendOtpErrOthers.value = new SendOtpErrOthers();
    return SendOtpErrOthers;
})();
var RetryActionUnit = (function () {
    function RetryActionUnit() {

    };
    RetryActionUnit.value = new RetryActionUnit();
    return RetryActionUnit;
})();
var RetryActionBack = (function () {
    function RetryActionBack() {

    };
    RetryActionBack.value = new RetryActionBack();
    return RetryActionBack;
})();
var ResetPinErrInvalidUser = (function () {
    function ResetPinErrInvalidUser() {

    };
    ResetPinErrInvalidUser.value = new ResetPinErrInvalidUser();
    return ResetPinErrInvalidUser;
})();
var ResendOtpErrMaxResendCountExceeded = (function () {
    function ResendOtpErrMaxResendCountExceeded() {

    };
    ResendOtpErrMaxResendCountExceeded.value = new ResendOtpErrMaxResendCountExceeded();
    return ResendOtpErrMaxResendCountExceeded;
})();
var ResendOtpErrSessionExpired = (function () {
    function ResendOtpErrSessionExpired() {

    };
    ResendOtpErrSessionExpired.value = new ResendOtpErrSessionExpired();
    return ResendOtpErrSessionExpired;
})();
var ResendOtpErrOthers = (function () {
    function ResendOtpErrOthers() {

    };
    ResendOtpErrOthers.value = new ResendOtpErrOthers();
    return ResendOtpErrOthers;
})();
var Text = (function () {
    function Text() {

    };
    Text.value = new Text();
    return Text;
})();
var Markdown = (function () {
    function Markdown() {

    };
    Markdown.value = new Markdown();
    return Markdown;
})();
var ProductCodeFilterTypeAvailable = (function () {
    function ProductCodeFilterTypeAvailable() {

    };
    ProductCodeFilterTypeAvailable.value = new ProductCodeFilterTypeAvailable();
    return ProductCodeFilterTypeAvailable;
})();
var ProductCodeFilterTypeRedeemed = (function () {
    function ProductCodeFilterTypeRedeemed() {

    };
    ProductCodeFilterTypeRedeemed.value = new ProductCodeFilterTypeRedeemed();
    return ProductCodeFilterTypeRedeemed;
})();
var ProductCodeFilterTypeExpired = (function () {
    function ProductCodeFilterTypeExpired() {

    };
    ProductCodeFilterTypeExpired.value = new ProductCodeFilterTypeExpired();
    return ProductCodeFilterTypeExpired;
})();
var ProductCodeFilterNoFilter = (function () {
    function ProductCodeFilterNoFilter() {

    };
    ProductCodeFilterNoFilter.value = new ProductCodeFilterNoFilter();
    return ProductCodeFilterNoFilter;
})();
var ProductCodeFilterHasFilters = (function () {
    function ProductCodeFilterHasFilters(value0) {
        this.value0 = value0;
    };
    ProductCodeFilterHasFilters.create = function (value0) {
        return new ProductCodeFilterHasFilters(value0);
    };
    return ProductCodeFilterHasFilters;
})();
var PinActionCreatePin = (function () {
    function PinActionCreatePin() {

    };
    PinActionCreatePin.value = new PinActionCreatePin();
    return PinActionCreatePin;
})();
var PinActionEnterPin = (function () {
    function PinActionEnterPin() {

    };
    PinActionEnterPin.value = new PinActionEnterPin();
    return PinActionEnterPin;
})();
var ResetPassword = (function () {
    function ResetPassword() {

    };
    ResetPassword.value = new ResetPassword();
    return ResetPassword;
})();
var TextField = (function () {
    function TextField() {

    };
    TextField.value = new TextField();
    return TextField;
})();
var TextArea = (function () {
    function TextArea() {

    };
    TextArea.value = new TextArea();
    return TextArea;
})();
var Password = (function () {
    function Password() {

    };
    Password.value = new Password();
    return Password;
})();
var Calendar = (function () {
    function Calendar() {

    };
    Calendar.value = new Calendar();
    return Calendar;
})();
var RadioButton = (function () {
    function RadioButton(value0) {
        this.value0 = value0;
    };
    RadioButton.create = function (value0) {
        return new RadioButton(value0);
    };
    return RadioButton;
})();
var Checkbox = (function () {
    function Checkbox(value0) {
        this.value0 = value0;
    };
    Checkbox.create = function (value0) {
        return new Checkbox(value0);
    };
    return Checkbox;
})();
var Dropdown = (function () {
    function Dropdown(value0) {
        this.value0 = value0;
    };
    Dropdown.create = function (value0) {
        return new Dropdown(value0);
    };
    return Dropdown;
})();
var ZAPDefault = (function () {
    function ZAPDefault(value0) {
        this.value0 = value0;
    };
    ZAPDefault.create = function (value0) {
        return new ZAPDefault(value0);
    };
    return ZAPDefault;
})();
var GuestClaimReceiptScanAnother = (function () {
    function GuestClaimReceiptScanAnother() {

    };
    GuestClaimReceiptScanAnother.value = new GuestClaimReceiptScanAnother();
    return GuestClaimReceiptScanAnother;
})();
var GuestClaimReceiptInputAnother = (function () {
    function GuestClaimReceiptInputAnother() {

    };
    GuestClaimReceiptInputAnother.value = new GuestClaimReceiptInputAnother();
    return GuestClaimReceiptInputAnother;
})();
var GuestClaimReceiptBrowseCatalog = (function () {
    function GuestClaimReceiptBrowseCatalog() {

    };
    GuestClaimReceiptBrowseCatalog.value = new GuestClaimReceiptBrowseCatalog();
    return GuestClaimReceiptBrowseCatalog;
})();
var GetCartErrInvalidZapperId = (function () {
    function GetCartErrInvalidZapperId() {

    };
    GetCartErrInvalidZapperId.value = new GetCartErrInvalidZapperId();
    return GetCartErrInvalidZapperId;
})();
var Male = (function () {
    function Male() {

    };
    Male.value = new Male();
    return Male;
})();
var Female = (function () {
    function Female() {

    };
    Female.value = new Female();
    return Female;
})();
var Confirmed = (function () {
    function Confirmed(value0) {
        this.value0 = value0;
    };
    Confirmed.create = function (value0) {
        return new Confirmed(value0);
    };
    return Confirmed;
})();
var Unconfirmed = (function () {
    function Unconfirmed(value0) {
        this.value0 = value0;
    };
    Unconfirmed.create = function (value0) {
        return new Unconfirmed(value0);
    };
    return Unconfirmed;
})();
var DeleteFromCartErrInvalidZapperId = (function () {
    function DeleteFromCartErrInvalidZapperId() {

    };
    DeleteFromCartErrInvalidZapperId.value = new DeleteFromCartErrInvalidZapperId();
    return DeleteFromCartErrInvalidZapperId;
})();
var DeleteFromCartErrCartDoesNotExist = (function () {
    function DeleteFromCartErrCartDoesNotExist() {

    };
    DeleteFromCartErrCartDoesNotExist.value = new DeleteFromCartErrCartDoesNotExist();
    return DeleteFromCartErrCartDoesNotExist;
})();
var DeleteFromCartErrProductIdNotFound = (function () {
    function DeleteFromCartErrProductIdNotFound(value0) {
        this.value0 = value0;
    };
    DeleteFromCartErrProductIdNotFound.create = function (value0) {
        return new DeleteFromCartErrProductIdNotFound(value0);
    };
    return DeleteFromCartErrProductIdNotFound;
})();
var DeleteFromCartErrUpdateCartFailed = (function () {
    function DeleteFromCartErrUpdateCartFailed() {

    };
    DeleteFromCartErrUpdateCartFailed.value = new DeleteFromCartErrUpdateCartFailed();
    return DeleteFromCartErrUpdateCartFailed;
})();
var CreateSessionErrInvalidPin = (function () {
    function CreateSessionErrInvalidPin() {

    };
    CreateSessionErrInvalidPin.value = new CreateSessionErrInvalidPin();
    return CreateSessionErrInvalidPin;
})();
var CreateSessionErrAccountLocked = (function () {
    function CreateSessionErrAccountLocked() {

    };
    CreateSessionErrAccountLocked.value = new CreateSessionErrAccountLocked();
    return CreateSessionErrAccountLocked;
})();
var CreateSessionErrSessionExpired = (function () {
    function CreateSessionErrSessionExpired() {

    };
    CreateSessionErrSessionExpired.value = new CreateSessionErrSessionExpired();
    return CreateSessionErrSessionExpired;
})();
var CreateSessionErrExistingUser = (function () {
    function CreateSessionErrExistingUser() {

    };
    CreateSessionErrExistingUser.value = new CreateSessionErrExistingUser();
    return CreateSessionErrExistingUser;
})();
var CreateSessionErrOthers = (function () {
    function CreateSessionErrOthers() {

    };
    CreateSessionErrOthers.value = new CreateSessionErrOthers();
    return CreateSessionErrOthers;
})();
var Android = (function () {
    function Android() {

    };
    Android.value = new Android();
    return Android;
})();
var Ios = (function () {
    function Ios() {

    };
    Ios.value = new Ios();
    return Ios;
})();
var Web = (function () {
    function Web() {

    };
    Web.value = new Web();
    return Web;
})();
var Others = (function () {
    function Others(value0) {
        this.value0 = value0;
    };
    Others.create = function (value0) {
        return new Others(value0);
    };
    return Others;
})();
var ClaimErrQrCodeAlreadyClaimed = (function () {
    function ClaimErrQrCodeAlreadyClaimed() {

    };
    ClaimErrQrCodeAlreadyClaimed.value = new ClaimErrQrCodeAlreadyClaimed();
    return ClaimErrQrCodeAlreadyClaimed;
})();
var ClaimErrInvalidData = (function () {
    function ClaimErrInvalidData() {

    };
    ClaimErrInvalidData.value = new ClaimErrInvalidData();
    return ClaimErrInvalidData;
})();
var ClaimErrOthers = (function () {
    function ClaimErrOthers() {

    };
    ClaimErrOthers.value = new ClaimErrOthers();
    return ClaimErrOthers;
})();
var QRCode = (function () {
    function QRCode(value0) {
        this.value0 = value0;
    };
    QRCode.create = function (value0) {
        return new QRCode(value0);
    };
    return QRCode;
})();
var ShortCode = (function () {
    function ShortCode(value0) {
        this.value0 = value0;
    };
    ShortCode.create = function (value0) {
        return new ShortCode(value0);
    };
    return ShortCode;
})();
var Points = (function () {
    function Points(value0) {
        this.value0 = value0;
    };
    Points.create = function (value0) {
        return new Points(value0);
    };
    return Points;
})();
var Coupon = (function () {
    function Coupon(value0) {
        this.value0 = value0;
    };
    Coupon.create = function (value0) {
        return new Coupon(value0);
    };
    return Coupon;
})();
var OMobile = (function () {
    function OMobile(value0) {
        this.value0 = value0;
    };
    OMobile.create = function (value0) {
        return new OMobile(value0);
    };
    return OMobile;
})();
var OOtp = (function () {
    function OOtp(value0) {
        this.value0 = value0;
    };
    OOtp.create = function (value0) {
        return new OOtp(value0);
    };
    return OOtp;
})();
var OPin = (function () {
    function OPin(value0) {
        this.value0 = value0;
    };
    OPin.create = function (value0) {
        return new OPin(value0);
    };
    return OPin;
})();
var OUser = (function () {
    function OUser(value0) {
        this.value0 = value0;
    };
    OUser.create = function (value0) {
        return new OUser(value0);
    };
    return OUser;
})();
var OInit = (function () {
    function OInit(value0) {
        this.value0 = value0;
    };
    OInit.create = function (value0) {
        return new OInit(value0);
    };
    return OInit;
})();
var OReceipt = (function () {
    function OReceipt(value0) {
        this.value0 = value0;
    };
    OReceipt.create = function (value0) {
        return new OReceipt(value0);
    };
    return OReceipt;
})();
var OGuestAction = (function () {
    function OGuestAction(value0) {
        this.value0 = value0;
    };
    OGuestAction.create = function (value0) {
        return new OGuestAction(value0);
    };
    return OGuestAction;
})();
var OCode = (function () {
    function OCode(value0) {
        this.value0 = value0;
    };
    OCode.create = function (value0) {
        return new OCode(value0);
    };
    return OCode;
})();
var OValidatedCode = (function () {
    function OValidatedCode(value0) {
        this.value0 = value0;
    };
    OValidatedCode.create = function (value0) {
        return new OValidatedCode(value0);
    };
    return OValidatedCode;
})();
var OSession = (function () {
    function OSession(value0) {
        this.value0 = value0;
    };
    OSession.create = function (value0) {
        return new OSession(value0);
    };
    return OSession;
})();
var OPrivacyConsent = (function () {
    function OPrivacyConsent(value0) {
        this.value0 = value0;
    };
    OPrivacyConsent.create = function (value0) {
        return new OPrivacyConsent(value0);
    };
    return OPrivacyConsent;
})();
var AskClaimMobile = (function () {
    function AskClaimMobile() {

    };
    AskClaimMobile.value = new AskClaimMobile();
    return AskClaimMobile;
})();
var AskGuestAction = (function () {
    function AskGuestAction(value0) {
        this.value0 = value0;
    };
    AskGuestAction.create = function (value0) {
        return new AskGuestAction(value0);
    };
    return AskGuestAction;
})();
var AskShortCode = (function () {
    function AskShortCode() {

    };
    AskShortCode.value = new AskShortCode();
    return AskShortCode;
})();
var AskLoginOtp = (function () {
    function AskLoginOtp(value0) {
        this.value0 = value0;
    };
    AskLoginOtp.create = function (value0) {
        return new AskLoginOtp(value0);
    };
    return AskLoginOtp;
})();
var AskRegOtp = (function () {
    function AskRegOtp(value0) {
        this.value0 = value0;
    };
    AskRegOtp.create = function (value0) {
        return new AskRegOtp(value0);
    };
    return AskRegOtp;
})();
var AskPin = (function () {
    function AskPin(value0) {
        this.value0 = value0;
    };
    AskPin.create = function (value0) {
        return new AskPin(value0);
    };
    return AskPin;
})();
var AskNewPin = (function () {
    function AskNewPin() {

    };
    AskNewPin.value = new AskNewPin();
    return AskNewPin;
})();
var AskSessionMobile = (function () {
    function AskSessionMobile() {

    };
    AskSessionMobile.value = new AskSessionMobile();
    return AskSessionMobile;
})();
var AskQrCode = (function () {
    function AskQrCode() {

    };
    AskQrCode.value = new AskQrCode();
    return AskQrCode;
})();
var AskPrivacyConsent = (function () {
    function AskPrivacyConsent() {

    };
    AskPrivacyConsent.value = new AskPrivacyConsent();
    return AskPrivacyConsent;
})();
var Push = (function () {
    function Push(value0) {
        this.value0 = value0;
    };
    Push.create = function (value0) {
        return new Push(value0);
    };
    return Push;
})();
var Replace = (function () {
    function Replace(value0) {
        this.value0 = value0;
    };
    Replace.create = function (value0) {
        return new Replace(value0);
    };
    return Replace;
})();
var Retry = (function () {
    function Retry(value0) {
        this.value0 = value0;
    };
    Retry.create = function (value0) {
        return new Retry(value0);
    };
    return Retry;
})();
var Pop = (function () {
    function Pop(value0) {
        this.value0 = value0;
    };
    Pop.create = function (value0) {
        return new Pop(value0);
    };
    return Pop;
})();
var Restart = (function () {
    function Restart(value0) {
        this.value0 = value0;
    };
    Restart.create = function (value0) {
        return new Restart(value0);
    };
    return Restart;
})();
var End = (function () {
    function End(value0) {
        this.value0 = value0;
    };
    End.create = function (value0) {
        return new End(value0);
    };
    return End;
})();
var CheckoutErrInvalidProducts = (function () {
    function CheckoutErrInvalidProducts() {

    };
    CheckoutErrInvalidProducts.value = new CheckoutErrInvalidProducts();
    return CheckoutErrInvalidProducts;
})();
var CheckoutErrReservationFailed = (function () {
    function CheckoutErrReservationFailed() {

    };
    CheckoutErrReservationFailed.value = new CheckoutErrReservationFailed();
    return CheckoutErrReservationFailed;
})();
var CheckoutErrTransactionAlreadyUsed = (function () {
    function CheckoutErrTransactionAlreadyUsed() {

    };
    CheckoutErrTransactionAlreadyUsed.value = new CheckoutErrTransactionAlreadyUsed();
    return CheckoutErrTransactionAlreadyUsed;
})();
var CheckoutErrInvalidPurchase = (function () {
    function CheckoutErrInvalidPurchase() {

    };
    CheckoutErrInvalidPurchase.value = new CheckoutErrInvalidPurchase();
    return CheckoutErrInvalidPurchase;
})();
var CheckoutErrInvalidProductCode = (function () {
    function CheckoutErrInvalidProductCode() {

    };
    CheckoutErrInvalidProductCode.value = new CheckoutErrInvalidProductCode();
    return CheckoutErrInvalidProductCode;
})();
var CheckoutErrOthers = (function () {
    function CheckoutErrOthers() {

    };
    CheckoutErrOthers.value = new CheckoutErrOthers();
    return CheckoutErrOthers;
})();
var CheckoutErrInvalidPin = (function () {
    function CheckoutErrInvalidPin() {

    };
    CheckoutErrInvalidPin.value = new CheckoutErrInvalidPin();
    return CheckoutErrInvalidPin;
})();
var CheckoutErrAccountLocked = (function () {
    function CheckoutErrAccountLocked() {

    };
    CheckoutErrAccountLocked.value = new CheckoutErrAccountLocked();
    return CheckoutErrAccountLocked;
})();
var CheckoutErrUserNotFound = (function () {
    function CheckoutErrUserNotFound() {

    };
    CheckoutErrUserNotFound.value = new CheckoutErrUserNotFound();
    return CheckoutErrUserNotFound;
})();
var AuthToken = function (x) {
    return x;
};
var zrpcWriteFlowAction = function (dictZRPCWrite) {
    return function (dictZRPCWriteRep) {
        return function (dictGeneric) {
            return function (dictConsPrefix) {
                return new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
                    return ZRPC_ZRPCWrite.genericZRPCWrite(dictConsPrefix)(dictGeneric)(dictZRPCWriteRep)(x);
                });
            };
        };
    };
};
var zrpcReadFlowAction = function (dictZRPCReadRep) {
    return function (dictGeneric) {
        return function (dictConsPrefix) {
            return new ZRPC_ZRPCRead.ZRPCRead(function (x) {
                return ZRPC_ZRPCRead.genericZRPCRead(dictConsPrefix)(dictGeneric)(dictZRPCReadRep)(x);
            });
        };
    };
};
var showAuthToken = Data_Show.showString;
var newTypeAuthT = new Data_Newtype.Newtype(function (n) {
    return n;
}, AuthToken);
var getCartErrEq = new Data_Eq.Eq(function (x) {
    return function (y) {
        return true;
    };
});
var genericZAPDefaultType = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof FullName) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof Gender) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof Birthday) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof Location) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
    };
    if (x instanceof EmailAddress) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))));
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 928, column 1 - line 928, column 68): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return FullName.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return Gender.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return Birthday.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return Location.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr))) {
        return EmailAddress.value;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 928, column 1 - line 928, column 68): " + [ x.constructor.name ]);
});
var showZAPDefaultType = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericZAPDefaultType)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "FullName";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Gender";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Birthday";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Location";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "EmailAddress";
}))))))));
var genericVerifyPinErr = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof VerifyPinErrInvalidPin) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof VerifyPinErrAccountLocked) {
        return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 547, column 1 - line 547, column 64): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return VerifyPinErrInvalidPin.value;
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return VerifyPinErrAccountLocked.value;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 547, column 1 - line 547, column 64): " + [ x.constructor.name ]);
});
var showVerifyPinErr = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericVerifyPinErr)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "VerifyPinErrInvalidPin";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "VerifyPinErrAccountLocked";
})))));
var genericVerifyOtpErr = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof VerifyOtpErrMaxAttemptCountExceeded) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof VerifyOtpErrSessionExpired) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof VerifyOtpErrOthers) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 681, column 1 - line 681, column 64): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return VerifyOtpErrMaxAttemptCountExceeded.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return VerifyOtpErrSessionExpired.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
        return VerifyOtpErrOthers.value;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 681, column 1 - line 681, column 64): " + [ x.constructor.name ]);
});
var showVerifyOtpErr = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericVerifyOtpErr)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "VerifyOtpErrMaxAttemptCountExceeded";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "VerifyOtpErrSessionExpired";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "VerifyOtpErrOthers";
}))))));
var genericValue = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Single) {
        return new Data_Generic_Rep.Inl(x.value0);
    };
    if (x instanceof Multi) {
        return new Data_Generic_Rep.Inr(x.value0);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 980, column 1 - line 980, column 48): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return new Single(x.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return new Multi(x.value0);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 980, column 1 - line 980, column 48): " + [ x.constructor.name ]);
});
var showValue = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericValue)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
    return "Single";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showArray(Data_Show.showString)))))(new Data_Symbol.IsSymbol(function () {
    return "Multi";
})))));
var genericUpdateProfileErr = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof UpdateProfileErrInvalidEmail) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof UpdateProfileErrExistingEmail) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof UpdateProfileErrMissingFields) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
    };
    if (x instanceof UpdateProfileErrOthers) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 1061, column 1 - line 1061, column 72): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return UpdateProfileErrInvalidEmail.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return UpdateProfileErrExistingEmail.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return new UpdateProfileErrMissingFields(x.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
        return UpdateProfileErrOthers.value;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 1061, column 1 - line 1061, column 72): " + [ x.constructor.name ]);
});
var showUpdateProfileErr = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericUpdateProfileErr)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "UpdateProfileErrInvalidEmail";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "UpdateProfileErrExistingEmail";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "fieldNames";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showArray(Data_Show.showString)))))(new Data_Symbol.IsSymbol(function () {
    return "UpdateProfileErrMissingFields";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "UpdateProfileErrOthers";
})))))));
var genericUpdateCartErr = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof UpdateCartErrInvalidZapperId) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof UpdateCartErrProductDoesNotExist) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof UpdateCartErrAllocationNotFound) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof UpdateCartErrInsufficientAllocation) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
    };
    if (x instanceof UpdateCartErrUpdateCartFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))));
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 295, column 1 - line 295, column 66): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return UpdateCartErrInvalidZapperId.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return UpdateCartErrProductDoesNotExist.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return UpdateCartErrAllocationNotFound.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return new UpdateCartErrInsufficientAllocation(x.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr))) {
        return UpdateCartErrUpdateCartFailed.value;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 295, column 1 - line 295, column 66): " + [ x.constructor.name ]);
});
var showUpdateCartErr = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericUpdateCartErr)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "UpdateCartErrInvalidZapperId";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "UpdateCartErrProductDoesNotExist";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "UpdateCartErrAllocationNotFound";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "availableQuantity";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "isAlreadyInCart";
}))(Data_Show.showRecordFieldsNil)(Data_Maybe.showMaybe(Data_Show.showBoolean)))(Data_Maybe.showMaybe(Data_Show.showInt)))))(new Data_Symbol.IsSymbol(function () {
    return "UpdateCartErrInsufficientAllocation";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "UpdateCartErrUpdateCartFailed";
}))))))));
var genericTxType = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Earned) {
        return new Data_Generic_Rep.Inl(x.value0);
    };
    if (x instanceof Redeemed) {
        return new Data_Generic_Rep.Inr(x.value0);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 855, column 1 - line 855, column 52): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return new Earned(x.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return new Redeemed(x.value0);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 855, column 1 - line 855, column 52): " + [ x.constructor.name ]);
});
var showTxType = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericTxType)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "amount";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "skuItems";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showArray(Data_Show.showString)))(Data_Show.showNumber))))(new Data_Symbol.IsSymbol(function () {
    return "Earned";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "amount";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "rewardItems";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showArray(Data_Show.showString)))(Data_Show.showNumber))))(new Data_Symbol.IsSymbol(function () {
    return "Redeemed";
})))));
var genericTxRowType = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof TxRowTypeManualPoints) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof TxRowTypeManualCoupon) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof TxRowTypeCatalogPurchase) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof TxRowTypeUnknown) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 814, column 1 - line 814, column 58): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return TxRowTypeManualPoints.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return TxRowTypeManualCoupon.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return TxRowTypeCatalogPurchase.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
        return TxRowTypeUnknown.value;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 814, column 1 - line 814, column 58): " + [ x.constructor.name ]);
});
var showTxRowType = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericTxRowType)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "TxRowTypeManualPoints";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "TxRowTypeManualCoupon";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "TxRowTypeCatalogPurchase";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "TxRowTypeUnknown";
})))))));
var genericTxRowStatus = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof TxRowStatusCleared) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof TxRowStatusPending) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof TxRowStatusCancelled) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof TxRowStatusVoid) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
    };
    if (x instanceof TxRowStatusUnknown) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))));
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 794, column 1 - line 794, column 62): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return TxRowStatusCleared.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return TxRowStatusPending.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return TxRowStatusCancelled.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return TxRowStatusVoid.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr))) {
        return TxRowStatusUnknown.value;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 794, column 1 - line 794, column 62): " + [ x.constructor.name ]);
});
var showTxRowStatus = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericTxRowStatus)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "TxRowStatusCleared";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "TxRowStatusPending";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "TxRowStatusCancelled";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "TxRowStatusVoid";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "TxRowStatusUnknown";
}))))))));
var genericTxRowAllotment = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof PointsAllotment) {
        return new Data_Generic_Rep.Inl(x.value0);
    };
    if (x instanceof CouponAllotment) {
        return new Data_Generic_Rep.Inr(x.value0);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 773, column 1 - line 773, column 68): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return new PointsAllotment(x.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return new CouponAllotment(x.value0);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 773, column 1 - line 773, column 68): " + [ x.constructor.name ]);
});
var showTxRowAllotment = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericTxRowAllotment)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "currencyName";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "earned";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "redeemed";
}))(Data_Show.showRecordFieldsNil)(Data_HugeNum.showHugeNum))(Data_HugeNum.showHugeNum))(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
    return "PointsAllotment";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "earned";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "redeemed";
}))(Data_Show.showRecordFieldsNil)(Data_HugeNum.showHugeNum))(Data_HugeNum.showHugeNum))))(new Data_Symbol.IsSymbol(function () {
    return "CouponAllotment";
})))));
var genericSendOtpErr = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof SendOtpErrInvalidContactNumber) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof SendOtpErrLimitExceeded) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof SendOtpErrOthers) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 579, column 1 - line 579, column 60): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return SendOtpErrInvalidContactNumber.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return SendOtpErrLimitExceeded.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
        return SendOtpErrOthers.value;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 579, column 1 - line 579, column 60): " + [ x.constructor.name ]);
});
var showSendOtpErr = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericSendOtpErr)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "SendOtpErrInvalidContactNumber";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "SendOtpErrLimitExceeded";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "SendOtpErrOthers";
}))))));
var genericRetryAction = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof RetryActionUnit) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof RetryActionBack) {
        return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 1138, column 1 - line 1138, column 62): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return RetryActionUnit.value;
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return RetryActionBack.value;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 1138, column 1 - line 1138, column 62): " + [ x.constructor.name ]);
});
var showRetryAction = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericRetryAction)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "RetryActionUnit";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "RetryActionBack";
})))));
var genericResetPinErr = new Data_Generic_Rep.Generic(function (x) {
    return Data_Generic_Rep.NoArguments.value;
}, function (x) {
    return ResetPinErrInvalidUser.value;
});
var showResetPinErr = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericResetPinErr)(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "ResetPinErrInvalidUser";
}))));
var genericResendOtpErr = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof ResendOtpErrMaxResendCountExceeded) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof ResendOtpErrSessionExpired) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof ResendOtpErrOthers) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 648, column 1 - line 648, column 64): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return ResendOtpErrMaxResendCountExceeded.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return ResendOtpErrSessionExpired.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
        return ResendOtpErrOthers.value;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 648, column 1 - line 648, column 64): " + [ x.constructor.name ]);
});
var showResendOtpErr = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericResendOtpErr)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "ResendOtpErrMaxResendCountExceeded";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "ResendOtpErrSessionExpired";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "ResendOtpErrOthers";
}))))));
var genericRenderType = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Text) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof Markdown) {
        return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 500, column 1 - line 500, column 60): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return Text.value;
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return Markdown.value;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 500, column 1 - line 500, column 60): " + [ x.constructor.name ]);
});
var showRenderType = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericRenderType)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Text";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Markdown";
})))));
var genericProductCodeFilterType = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof ProductCodeFilterTypeAvailable) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof ProductCodeFilterTypeRedeemed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof ProductCodeFilterTypeExpired) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 198, column 1 - line 198, column 80): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return ProductCodeFilterTypeAvailable.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return ProductCodeFilterTypeRedeemed.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
        return ProductCodeFilterTypeExpired.value;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 198, column 1 - line 198, column 80): " + [ x.constructor.name ]);
});
var showProductCodeFilterType = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericProductCodeFilterType)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeFilterTypeAvailable";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeFilterTypeRedeemed";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeFilterTypeExpired";
    })))))(x);
});
var genericProductCodeFilter = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof ProductCodeFilterNoFilter) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof ProductCodeFilterHasFilters) {
        return new Data_Generic_Rep.Inr(x.value0);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 214, column 1 - line 214, column 72): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return ProductCodeFilterNoFilter.value;
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return new ProductCodeFilterHasFilters(x.value0);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 214, column 1 - line 214, column 72): " + [ x.constructor.name ]);
});
var showProductCodeFilter = new Data_Show.Show(function (x) {
    return Data_Generic_Rep_Show.genericShow(genericProductCodeFilter)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeFilterNoFilter";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "filters";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showArray(showProductCodeFilterType)))))(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeFilterHasFilters";
    }))))(x);
});
var genericPinAction = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof PinActionCreatePin) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof PinActionEnterPin) {
        return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 518, column 1 - line 518, column 58): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return PinActionCreatePin.value;
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return PinActionEnterPin.value;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 518, column 1 - line 518, column 58): " + [ x.constructor.name ]);
});
var showPinAction = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericPinAction)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "PinActionCreatePin";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "PinActionEnterPin";
})))));
var genericOtpPurpose = new Data_Generic_Rep.Generic(function (x) {
    return Data_Generic_Rep.NoArguments.value;
}, function (x) {
    return ResetPassword.value;
});
var showOtpPurpose = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericOtpPurpose)(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "ResetPassword";
}))));
var genericInstructions = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof AskClaimMobile) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof AskGuestAction) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
    };
    if (x instanceof AskShortCode) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof AskLoginOtp) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
    };
    if (x instanceof AskRegOtp) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))));
    };
    if (x instanceof AskPin) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))));
    };
    if (x instanceof AskNewPin) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))));
    };
    if (x instanceof AskSessionMobile) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))))));
    };
    if (x instanceof AskQrCode) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))))));
    };
    if (x instanceof AskPrivacyConsent) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)))))))));
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 1212, column 1 - line 1212, column 64): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return AskClaimMobile.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return new AskGuestAction(x.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return AskShortCode.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return new AskLoginOtp(x.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
        return new AskRegOtp(x.value0.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
        return new AskPin(x.value0.value0.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
        return AskNewPin.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))) {
        return AskSessionMobile.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))) {
        return AskQrCode.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))))))) {
        return AskPrivacyConsent.value;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 1212, column 1 - line 1212, column 64): " + [ x.constructor.name ]);
});
var genericInputType = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof TextField) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof TextArea) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof Password) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof Calendar) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
    };
    if (x instanceof RadioButton) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))));
    };
    if (x instanceof Checkbox) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))));
    };
    if (x instanceof Dropdown) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))));
    };
    if (x instanceof ZAPDefault) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)))))));
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 954, column 1 - line 954, column 58): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return TextField.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return TextArea.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return Password.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return Calendar.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
        return new RadioButton(x.value0.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
        return new Checkbox(x.value0.value0.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
        return new Dropdown(x.value0.value0.value0.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))))) {
        return new ZAPDefault(x.value0.value0.value0.value0.value0.value0.value0);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 954, column 1 - line 954, column 58): " + [ x.constructor.name ]);
});
var showInputType = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericInputType)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "TextField";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "TextArea";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Password";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Calendar";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "selection";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showArray(Data_Show.showString)))))(new Data_Symbol.IsSymbol(function () {
    return "RadioButton";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "selection";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showArray(Data_Show.showString)))))(new Data_Symbol.IsSymbol(function () {
    return "Checkbox";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "selection";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showArray(Data_Show.showString)))))(new Data_Symbol.IsSymbol(function () {
    return "Dropdown";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "default";
}))(Data_Show.showRecordFieldsNil)(showZAPDefaultType))))(new Data_Symbol.IsSymbol(function () {
    return "ZAPDefault";
})))))))))));
var genericGuestClaimReceiptAction = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof GuestClaimReceiptScanAnother) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof GuestClaimReceiptInputAnother) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof GuestClaimReceiptBrowseCatalog) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 1234, column 1 - line 1234, column 86): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return GuestClaimReceiptScanAnother.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return GuestClaimReceiptInputAnother.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
        return GuestClaimReceiptBrowseCatalog.value;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 1234, column 1 - line 1234, column 86): " + [ x.constructor.name ]);
});
var showGuestClaimReceiptAction = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericGuestClaimReceiptAction)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "GuestClaimReceiptScanAnother";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "GuestClaimReceiptInputAnother";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "GuestClaimReceiptBrowseCatalog";
}))))));
var genericGetCartErr = new Data_Generic_Rep.Generic(function (x) {
    return Data_Generic_Rep.NoArguments.value;
}, function (x) {
    return GetCartErrInvalidZapperId.value;
});
var showGetCartErr = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericGetCartErr)(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "GetCartErrInvalidZapperId";
}))));
var genericGender = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Male) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof Female) {
        return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 1031, column 1 - line 1031, column 50): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return Male.value;
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return Female.value;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 1031, column 1 - line 1031, column 50): " + [ x.constructor.name ]);
});
var showGender = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericGender)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Male";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Female";
})))));
var genericFlowRef = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof OMobile) {
        return new Data_Generic_Rep.Inl(x.value0);
    };
    if (x instanceof OOtp) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
    };
    if (x instanceof OPin) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
    };
    if (x instanceof OUser) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
    };
    if (x instanceof OInit) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))));
    };
    if (x instanceof OReceipt) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))));
    };
    if (x instanceof OGuestAction) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))));
    };
    if (x instanceof OCode) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))));
    };
    if (x instanceof OValidatedCode) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))));
    };
    if (x instanceof OSession) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))))));
    };
    if (x instanceof OPrivacyConsent) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0))))))))));
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 1186, column 1 - line 1186, column 54): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return new OMobile(x.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return new OOtp(x.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return new OPin(x.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return new OUser(x.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
        return new OInit(x.value0.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
        return new OReceipt(x.value0.value0.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
        return new OGuestAction(x.value0.value0.value0.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))) {
        return new OCode(x.value0.value0.value0.value0.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))) {
        return new OValidatedCode(x.value0.value0.value0.value0.value0.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))))) {
        return new OSession(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr))))))))) {
        return new OPrivacyConsent(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 1186, column 1 - line 1186, column 54): " + [ x.constructor.name ]);
});
var genericFlowAction = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Push) {
        return new Data_Generic_Rep.Inl(x.value0);
    };
    if (x instanceof Replace) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
    };
    if (x instanceof Retry) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
    };
    if (x instanceof Pop) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
    };
    if (x instanceof Restart) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))));
    };
    if (x instanceof End) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)))));
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 1120, column 1 - line 1120, column 62): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return new Push(x.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return new Replace(x.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return new Retry(x.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return new Pop(x.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
        return new Restart(x.value0.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))) {
        return new End(x.value0.value0.value0.value0.value0);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 1120, column 1 - line 1120, column 62): " + [ x.constructor.name ]);
});
var genericEmail = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Confirmed) {
        return new Data_Generic_Rep.Inl(x.value0);
    };
    if (x instanceof Unconfirmed) {
        return new Data_Generic_Rep.Inr(x.value0);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 1000, column 1 - line 1000, column 50): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return new Confirmed(x.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return new Unconfirmed(x.value0);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 1000, column 1 - line 1000, column 50): " + [ x.constructor.name ]);
});
var showEmail = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericEmail)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
    return "Confirmed";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
    return "Unconfirmed";
})))));
var genericDeleteFromCartErr = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof DeleteFromCartErrInvalidZapperId) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof DeleteFromCartErrCartDoesNotExist) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof DeleteFromCartErrProductIdNotFound) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
    };
    if (x instanceof DeleteFromCartErrUpdateCartFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 332, column 1 - line 332, column 74): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return DeleteFromCartErrInvalidZapperId.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return DeleteFromCartErrCartDoesNotExist.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return new DeleteFromCartErrProductIdNotFound(x.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
        return DeleteFromCartErrUpdateCartFailed.value;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 332, column 1 - line 332, column 74): " + [ x.constructor.name ]);
});
var showDeleteFromCartErr = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericDeleteFromCartErr)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "DeleteFromCartErrInvalidZapperId";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "DeleteFromCartErrCartDoesNotExist";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productIds";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showArray(Data_Show.showString)))))(new Data_Symbol.IsSymbol(function () {
    return "DeleteFromCartErrProductIdNotFound";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "DeleteFromCartErrUpdateCartFailed";
})))))));
var genericCreateSessionErr = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof CreateSessionErrInvalidPin) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof CreateSessionErrAccountLocked) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof CreateSessionErrSessionExpired) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof CreateSessionErrExistingUser) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
    };
    if (x instanceof CreateSessionErrOthers) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))));
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 711, column 1 - line 711, column 72): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return CreateSessionErrInvalidPin.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return CreateSessionErrAccountLocked.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return CreateSessionErrSessionExpired.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return CreateSessionErrExistingUser.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr))) {
        return CreateSessionErrOthers.value;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 711, column 1 - line 711, column 72): " + [ x.constructor.name ]);
});
var showCreateSessionErr = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericCreateSessionErr)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "CreateSessionErrInvalidPin";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "CreateSessionErrAccountLocked";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "CreateSessionErrSessionExpired";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "CreateSessionErrExistingUser";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "CreateSessionErrOthers";
}))))))));
var genericClientOs = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Android) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof Ios) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof Web) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof Others) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)));
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 55, column 1 - line 55, column 56): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return Android.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return Ios.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return Web.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
        return new Others(x.value0.value0.value0);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 55, column 1 - line 55, column 56): " + [ x.constructor.name ]);
});
var showClientOs = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericClientOs)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Android";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Ios";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Web";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "device";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
    return "Others";
})))))));
var genericClaimErr = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof ClaimErrQrCodeAlreadyClaimed) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof ClaimErrInvalidData) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof ClaimErrOthers) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 114, column 1 - line 114, column 56): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return ClaimErrQrCodeAlreadyClaimed.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return ClaimErrInvalidData.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
        return ClaimErrOthers.value;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 114, column 1 - line 114, column 56): " + [ x.constructor.name ]);
});
var showClaimErr = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericClaimErr)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "ClaimErrQrCodeAlreadyClaimed";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "ClaimErrInvalidData";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "ClaimErrOthers";
}))))));
var genericClaimCode = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof QRCode) {
        return new Data_Generic_Rep.Inl(x.value0);
    };
    if (x instanceof ShortCode) {
        return new Data_Generic_Rep.Inr(x.value0);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 137, column 1 - line 137, column 56): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return new QRCode(x.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return new ShortCode(x.value0);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 137, column 1 - line 137, column 56): " + [ x.constructor.name ]);
});
var showClaimCode = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericClaimCode)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "val";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
    return "QRCode";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "val";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
    return "ShortCode";
})))));
var genericClaim = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Points) {
        return new Data_Generic_Rep.Inl(x.value0);
    };
    if (x instanceof Coupon) {
        return new Data_Generic_Rep.Inr(x.value0);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 160, column 1 - line 160, column 50): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return new Points(x.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return new Coupon(x.value0);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 160, column 1 - line 160, column 50): " + [ x.constructor.name ]);
});
var showClaim = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericClaim)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "currencyName";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "earned";
}))(Data_Show.showRecordFieldsNil)(Data_HugeNum.showHugeNum))(Data_Maybe.showMaybe(Data_Show.showString)))))(new Data_Symbol.IsSymbol(function () {
    return "Points";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "couponId";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showInt))))(new Data_Symbol.IsSymbol(function () {
    return "Coupon";
})))));
var showFlowRef = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericFlowRef)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "mobile";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
    return "OMobile";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "otp";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "refId";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showString))(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
    return "OOtp";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "clientId";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "pin";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showString))(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
    return "OPin";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "hasPin";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "isRegistered";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "lastAcceptedTermsVersion";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapperId";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showString))(Data_Maybe.showMaybe(Data_Show.showString)))(Data_Show.showBoolean))(Data_Show.showBoolean))))(new Data_Symbol.IsSymbol(function () {
    return "OUser";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "clientOs";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "code";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "mobile";
}))(Data_Show.showRecordFieldsNil)(Data_Maybe.showMaybe(Data_Show.showString)))(Data_Maybe.showMaybe(showClaimCode)))(Data_Maybe.showMaybe(showClientOs)))))(new Data_Symbol.IsSymbol(function () {
    return "OInit";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "claims";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showArray(showClaim)))))(new Data_Symbol.IsSymbol(function () {
    return "OReceipt";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "guestAction";
}))(Data_Show.showRecordFieldsNil)(showGuestClaimReceiptAction))))(new Data_Symbol.IsSymbol(function () {
    return "OGuestAction";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "claimCode";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "clientOs";
}))(Data_Show.showRecordFieldsNil)(Data_Maybe.showMaybe(showClientOs)))(showClaimCode))))(new Data_Symbol.IsSymbol(function () {
    return "OCode";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "validatedClaimCode";
}))(Data_Show.showRecordFieldsNil)(Data_Maybe.showMaybe(showClaimCode)))))(new Data_Symbol.IsSymbol(function () {
    return "OValidatedCode";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "authToken";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
    return "OSession";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "accepted";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showBoolean))))(new Data_Symbol.IsSymbol(function () {
    return "OPrivacyConsent";
}))))))))))))));
var showInstructions = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericInstructions)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "AskClaimMobile";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "actions";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "claims";
}))(Data_Show.showRecordFieldsNil)(Data_Maybe.showMaybe(Data_Show.showArray(showClaim))))(Data_Maybe.showMaybe(Data_Show.showArray(showGuestClaimReceiptAction))))))(new Data_Symbol.IsSymbol(function () {
    return "AskGuestAction";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "AskShortCode";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "error";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "mobile";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "refId";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showString))(Data_Maybe.showMaybe(Data_Show.showString)))(Data_Maybe.showMaybe(Data_Show.showString)))))(new Data_Symbol.IsSymbol(function () {
    return "AskLoginOtp";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "error";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "mobile";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "refId";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showString))(Data_Maybe.showMaybe(Data_Show.showString)))(Data_Maybe.showMaybe(Data_Show.showString)))))(new Data_Symbol.IsSymbol(function () {
    return "AskRegOtp";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "error";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "mobile";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapperId";
}))(Data_Show.showRecordFieldsNil)(Data_Maybe.showMaybe(Data_Show.showString)))(Data_Maybe.showMaybe(Data_Show.showString)))(Data_Maybe.showMaybe(Data_Show.showString)))))(new Data_Symbol.IsSymbol(function () {
    return "AskPin";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "AskNewPin";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "AskSessionMobile";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "AskQrCode";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "AskPrivacyConsent";
})))))))))))));
var showFlowAction = function (dictShow) {
    return new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericFlowAction)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "currentRef";
    }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "instruction";
    }))(Data_Show.showRecordFieldsNil)(showInstructions))(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
        return "Push";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "currentRef";
    }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "instruction";
    }))(Data_Show.showRecordFieldsNil)(showInstructions))(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
        return "Replace";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "action";
    }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "message";
    }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "title";
    }))(Data_Show.showRecordFieldsNil)(Data_Maybe.showMaybe(Data_Show.showString)))(Data_Maybe.showMaybe(Data_Show.showString)))(Data_Maybe.showMaybe(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "effect";
    }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "label";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showString))(showRetryAction)))))))(new Data_Symbol.IsSymbol(function () {
        return "Retry";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "count";
    }))(Data_Show.showRecordFieldsNil)(Data_Show.showInt))))(new Data_Symbol.IsSymbol(function () {
        return "Pop";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "message";
    }))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "title";
    }))(Data_Show.showRecordFieldsNil)(Data_Maybe.showMaybe(Data_Show.showString)))(Data_Maybe.showMaybe(Data_Show.showString)))))(new Data_Symbol.IsSymbol(function () {
        return "Restart";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "value";
    }))(Data_Show.showRecordFieldsNil)(dictShow))))(new Data_Symbol.IsSymbol(function () {
        return "End";
    })))))))));
};
var genericCheckoutErr = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof CheckoutErrInvalidProducts) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof CheckoutErrReservationFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof CheckoutErrTransactionAlreadyUsed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof CheckoutErrInvalidPurchase) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
    };
    if (x instanceof CheckoutErrInvalidProductCode) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
    };
    if (x instanceof CheckoutErrOthers) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))));
    };
    if (x instanceof CheckoutErrInvalidPin) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))));
    };
    if (x instanceof CheckoutErrAccountLocked) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))))));
    };
    if (x instanceof CheckoutErrUserNotFound) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))))))));
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 400, column 1 - line 400, column 62): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return CheckoutErrInvalidProducts.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return CheckoutErrReservationFailed.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return CheckoutErrTransactionAlreadyUsed.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return CheckoutErrInvalidPurchase.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
        return CheckoutErrInvalidProductCode.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
        return CheckoutErrOthers.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
        return CheckoutErrInvalidPin.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))) {
        return CheckoutErrAccountLocked.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr))))))) {
        return CheckoutErrUserNotFound.value;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Common (line 400, column 1 - line 400, column 62): " + [ x.constructor.name ]);
});
var showCheckoutErr = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericCheckoutErr)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrInvalidProducts";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrReservationFailed";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrTransactionAlreadyUsed";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrInvalidPurchase";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrInvalidProductCode";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrOthers";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrInvalidPin";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrAccountLocked";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrUserNotFound";
}))))))))))));
var eqZAPDefaultType = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof FullName && y instanceof FullName) {
            return true;
        };
        if (x instanceof Gender && y instanceof Gender) {
            return true;
        };
        if (x instanceof Birthday && y instanceof Birthday) {
            return true;
        };
        if (x instanceof Location && y instanceof Location) {
            return true;
        };
        if (x instanceof EmailAddress && y instanceof EmailAddress) {
            return true;
        };
        return false;
    };
});
var eqValue = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Single && y instanceof Single) {
            return x.value0.value === y.value0.value;
        };
        if (x instanceof Multi && y instanceof Multi) {
            return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.value)(y.value0.value);
        };
        return false;
    };
});
var eqRenderType = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Text && y instanceof Text) {
            return true;
        };
        if (x instanceof Markdown && y instanceof Markdown) {
            return true;
        };
        return false;
    };
});
var eqProductCodeFilterType = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof ProductCodeFilterTypeAvailable && y instanceof ProductCodeFilterTypeAvailable) {
            return true;
        };
        if (x instanceof ProductCodeFilterTypeRedeemed && y instanceof ProductCodeFilterTypeRedeemed) {
            return true;
        };
        if (x instanceof ProductCodeFilterTypeExpired && y instanceof ProductCodeFilterTypeExpired) {
            return true;
        };
        return false;
    };
});
var eqProductCodeFilter = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof ProductCodeFilterNoFilter && y instanceof ProductCodeFilterNoFilter) {
            return true;
        };
        if (x instanceof ProductCodeFilterHasFilters && y instanceof ProductCodeFilterHasFilters) {
            return Data_Eq.eq(Data_Eq.eqArray(eqProductCodeFilterType))(x.value0.filters)(y.value0.filters);
        };
        return false;
    };
});
var eqInputType = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof TextField && y instanceof TextField) {
            return true;
        };
        if (x instanceof TextArea && y instanceof TextArea) {
            return true;
        };
        if (x instanceof Password && y instanceof Password) {
            return true;
        };
        if (x instanceof Calendar && y instanceof Calendar) {
            return true;
        };
        if (x instanceof RadioButton && y instanceof RadioButton) {
            return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.selection)(y.value0.selection);
        };
        if (x instanceof Checkbox && y instanceof Checkbox) {
            return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.selection)(y.value0.selection);
        };
        if (x instanceof Dropdown && y instanceof Dropdown) {
            return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.selection)(y.value0.selection);
        };
        if (x instanceof ZAPDefault && y instanceof ZAPDefault) {
            return Data_Eq.eq(eqZAPDefaultType)(x["value0"]["default"])(y["value0"]["default"]);
        };
        return false;
    };
});
var eqGender = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Male && y instanceof Male) {
            return true;
        };
        if (x instanceof Female && y instanceof Female) {
            return true;
        };
        return false;
    };
});
var eqEmail = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Confirmed && y instanceof Confirmed) {
            return x.value0.value === y.value0.value;
        };
        if (x instanceof Unconfirmed && y instanceof Unconfirmed) {
            return x.value0.value === y.value0.value;
        };
        return false;
    };
});
var consPrefixZAPDefaultType = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
});
var zrpcReadZAPDefaultType = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixZAPDefaultType)(genericZAPDefaultType)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "FullName";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Gender";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Birthday";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Location";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "EmailAddress";
}))))))));
var zrpcWriteZAPDefaultType = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixZAPDefaultType)(genericZAPDefaultType)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "FullName";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Gender";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Birthday";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Location";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "EmailAddress";
}))))))));
var consPrefixVerifyPinErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "VerifyPinErr";
});
var zrpcReadVerifyPinErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixVerifyPinErr)(genericVerifyPinErr)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "VerifyPinErrInvalidPin";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "VerifyPinErrAccountLocked";
})))));
var zrpcWriteVerifyPinErr = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixVerifyPinErr)(genericVerifyPinErr)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "VerifyPinErrInvalidPin";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "VerifyPinErrAccountLocked";
})))));
var consPrefixVerifyOtpErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "VerifyOtpErr";
});
var zrpcReadVerifyOtpErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixVerifyOtpErr)(genericVerifyOtpErr)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "VerifyOtpErrMaxAttemptCountExceeded";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "VerifyOtpErrSessionExpired";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "VerifyOtpErrOthers";
}))))));
var zrpcWriteVerifyOtpErr = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixVerifyOtpErr)(genericVerifyOtpErr)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "VerifyOtpErrMaxAttemptCountExceeded";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "VerifyOtpErrSessionExpired";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "VerifyOtpErrOthers";
}))))));
var consPrefixValue = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
});
var zrpcReadValue = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixValue)(genericValue)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Single";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Multi";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))));
var zrpcWriteValue = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixValue)(genericValue)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Single";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Multi";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())));
var consPrefixUpdateProfileErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "UpdateProfileErr";
});
var zrpcReadUpdateProfileErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixUpdateProfileErr)(genericUpdateProfileErr)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "UpdateProfileErrInvalidEmail";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "UpdateProfileErrExistingEmail";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "UpdateProfileErrMissingFields";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "fieldNames";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "UpdateProfileErrOthers";
})))))));
var zrpcWriteUpdateProfileErr = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixUpdateProfileErr)(genericUpdateProfileErr)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "UpdateProfileErrInvalidEmail";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "UpdateProfileErrExistingEmail";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "UpdateProfileErrMissingFields";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "fieldNames";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "UpdateProfileErrOthers";
})))))));
var consPrefixUpdateCartErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "UpdateCartErr";
});
var zrpcReadUpdateCartErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixUpdateCartErr)(genericUpdateCartErr)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "UpdateCartErrInvalidZapperId";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "UpdateCartErrProductDoesNotExist";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "UpdateCartErrAllocationNotFound";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "UpdateCartErrInsufficientAllocation";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "availableQuantity";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "isAlreadyInCart";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "UpdateCartErrUpdateCartFailed";
}))))))));
var zrpcWriteUpdateCartErr = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixUpdateCartErr)(genericUpdateCartErr)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "UpdateCartErrInvalidZapperId";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "UpdateCartErrProductDoesNotExist";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "UpdateCartErrAllocationNotFound";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "UpdateCartErrInsufficientAllocation";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "availableQuantity";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "isAlreadyInCart";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteBoolean))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "UpdateCartErrUpdateCartFailed";
}))))))));
var consPrefixTxType = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
});
var zrpcReadTxType = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixTxType)(genericTxType)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Earned";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "amount";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadNumber))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "skuItems";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Redeemed";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "amount";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadNumber))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "rewardItems";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))));
var zrpcWriteTxType = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixTxType)(genericTxType)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Earned";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "amount";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteNumber))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "skuItems";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Redeemed";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "amount";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteNumber))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "rewardItems";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())));
var consPrefixTxRowType = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "TxRowType";
});
var zrpcReadTxRowType = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixTxRowType)(genericTxRowType)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "TxRowTypeManualPoints";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "TxRowTypeManualCoupon";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "TxRowTypeCatalogPurchase";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "TxRowTypeUnknown";
})))))));
var zrpcWriteTxRowType = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixTxRowType)(genericTxRowType)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "TxRowTypeManualPoints";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "TxRowTypeManualCoupon";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "TxRowTypeCatalogPurchase";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "TxRowTypeUnknown";
})))))));
var consPrefixTxRowStatus = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "TxRowStatus";
});
var zrpcReadTxRowStatus = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixTxRowStatus)(genericTxRowStatus)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "TxRowStatusCleared";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "TxRowStatusPending";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "TxRowStatusCancelled";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "TxRowStatusVoid";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "TxRowStatusUnknown";
}))))))));
var zrpcWriteTxRowStatus = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixTxRowStatus)(genericTxRowStatus)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "TxRowStatusCleared";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "TxRowStatusPending";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "TxRowStatusCancelled";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "TxRowStatusVoid";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "TxRowStatusUnknown";
}))))))));
var consPrefixTxRowAllotment = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
});
var zrpcReadTxRowAllotment = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixTxRowAllotment)(genericTxRowAllotment)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "PointsAllotment";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "currencyName";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "earned";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "redeemed";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())))(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "CouponAllotment";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "earned";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "redeemed";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))));
var zrpcWriteTxRowAllotment = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixTxRowAllotment)(genericTxRowAllotment)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "PointsAllotment";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "currencyName";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "earned";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteHugeNum))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "redeemed";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteHugeNum))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())())(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "CouponAllotment";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "earned";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteHugeNum))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "redeemed";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteHugeNum))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())));
var consPrefixSendOtpErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "SendOtpErr";
});
var zrpcReadSendOtpErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixSendOtpErr)(genericSendOtpErr)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "SendOtpErrInvalidContactNumber";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "SendOtpErrLimitExceeded";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "SendOtpErrOthers";
}))))));
var zrpcWriteSendOtpErr = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixSendOtpErr)(genericSendOtpErr)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "SendOtpErrInvalidContactNumber";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "SendOtpErrLimitExceeded";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "SendOtpErrOthers";
}))))));
var consPrefixRetryAction = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "RetryAction";
});
var zrpcReadRetryAction = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixRetryAction)(genericRetryAction)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "RetryActionUnit";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "RetryActionBack";
})))));
var zrpcWriteRetryAction = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixRetryAction)(genericRetryAction)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "RetryActionUnit";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "RetryActionBack";
})))));
var consPrefixResetPinErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "ResetPinErr";
});
var zrpcReadResetPinErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixResetPinErr)(genericResetPinErr)(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "ResetPinErrInvalidUser";
}))));
var zrpcWriteResetPinErr = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixResetPinErr)(genericResetPinErr)(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "ResetPinErrInvalidUser";
}))));
var consPrefixResendOtpErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "ResendOtpErr";
});
var zrpcReadResendOtpErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixResendOtpErr)(genericResendOtpErr)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "ResendOtpErrMaxResendCountExceeded";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "ResendOtpErrSessionExpired";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "ResendOtpErrOthers";
}))))));
var zrpcWriteResendOtpErr = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixResendOtpErr)(genericResendOtpErr)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "ResendOtpErrMaxResendCountExceeded";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "ResendOtpErrSessionExpired";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "ResendOtpErrOthers";
}))))));
var consPrefixRenderType = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
});
var zrpcReadRenderType = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixRenderType)(genericRenderType)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Text";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Markdown";
})))));
var zrpcWriteRenderType = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixRenderType)(genericRenderType)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Text";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Markdown";
})))));
var consPrefixProductCodeFilterType = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "ProductCodeFilterType";
});
var zrpcReadProductCodeFilterType = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixProductCodeFilterType)(genericProductCodeFilterType)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeFilterTypeAvailable";
    })))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeFilterTypeRedeemed";
    })))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeFilterTypeExpired";
    })))))(x);
});
var zrpcWriteProductCodeFilterType = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixProductCodeFilterType)(genericProductCodeFilterType)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeFilterTypeAvailable";
    })))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeFilterTypeRedeemed";
    })))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeFilterTypeExpired";
    })))))(x);
});
var consPrefixProductCodeFilter = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "ProductCodeFilter";
});
var zrpcReadProductCodeFilter = new ZRPC_ZRPCRead.ZRPCRead(function (x) {
    return ZRPC_ZRPCRead.genericZRPCRead(consPrefixProductCodeFilter)(genericProductCodeFilter)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeFilterNoFilter";
    })))(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeFilterHasFilters";
    }))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "filters";
    }))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadProductCodeFilterType))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))))(x);
});
var zrpcWriteProductCodeFilter = new ZRPC_ZRPCWrite.ZRPCWrite(function (x) {
    return ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixProductCodeFilter)(genericProductCodeFilter)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeFilterNoFilter";
    })))(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
        return "ProductCodeFilterHasFilters";
    }))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "filters";
    }))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteProductCodeFilterType))))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())()))(x);
});
var consPrefixPinAction = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "PinAction";
});
var zrpcReadPinAction = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixPinAction)(genericPinAction)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "PinActionCreatePin";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "PinActionEnterPin";
})))));
var zrpcWritePinAction = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixPinAction)(genericPinAction)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "PinActionCreatePin";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "PinActionEnterPin";
})))));
var consPrefixOtpPurpose = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
});
var zrpcReadOtpPurpose = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixOtpPurpose)(genericOtpPurpose)(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "ResetPassword";
}))));
var zrpcWriteOtpPurpose = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixOtpPurpose)(genericOtpPurpose)(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "ResetPassword";
}))));
var consPrefixInstructions = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
});
var consPrefixInputType = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
});
var zrpcReadInputType = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixInputType)(genericInputType)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "TextField";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "TextArea";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Password";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Calendar";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "RadioButton";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "selection";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Checkbox";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "selection";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Dropdown";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "selection";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "ZAPDefault";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "default";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadZAPDefaultType))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))))))))));
var zrpcWriteInputType = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixInputType)(genericInputType)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "TextField";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "TextArea";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Password";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Calendar";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "RadioButton";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "selection";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Checkbox";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "selection";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Dropdown";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "selection";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "ZAPDefault";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "default";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteZAPDefaultType))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())))))))));
var consPrefixGuestClaimReceiptAction = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "GuestClaimReceipt";
});
var zrpcReadGuestClaimReceiptAction = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixGuestClaimReceiptAction)(genericGuestClaimReceiptAction)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "GuestClaimReceiptScanAnother";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "GuestClaimReceiptInputAnother";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "GuestClaimReceiptBrowseCatalog";
}))))));
var zrpcWriteGuestClaimReceiptAction = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixGuestClaimReceiptAction)(genericGuestClaimReceiptAction)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "GuestClaimReceiptScanAnother";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "GuestClaimReceiptInputAnother";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "GuestClaimReceiptBrowseCatalog";
}))))));
var consPrefixGetCartErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "GetCartErr";
});
var zrpcReadGetCartErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixGetCartErr)(genericGetCartErr)(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "GetCartErrInvalidZapperId";
}))));
var zrpcWriteGetCartErr = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixGetCartErr)(genericGetCartErr)(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "GetCartErrInvalidZapperId";
}))));
var consPrefixGender = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
});
var zrpcReadGender = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixGender)(genericGender)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Male";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Female";
})))));
var zrpcWriteGender = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixGender)(genericGender)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Male";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Female";
})))));
var consPrefixFlowRef = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
});
var consPrefixFlowAction = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
});
var consPrefixEmail = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
});
var zrpcReadEmail = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixEmail)(genericEmail)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Confirmed";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Unconfirmed";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))));
var zrpcWriteEmail = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixEmail)(genericEmail)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Confirmed";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Unconfirmed";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "value";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())));
var consPrefixDeleteFromCartErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "DeleteFromCartErr";
});
var zrpcReadDeleteFromCartErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixDeleteFromCartErr)(genericDeleteFromCartErr)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "DeleteFromCartErrInvalidZapperId";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "DeleteFromCartErrCartDoesNotExist";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "DeleteFromCartErrProductIdNotFound";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productIds";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "DeleteFromCartErrUpdateCartFailed";
})))))));
var zrpcWriteDeleteFromCartErr = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixDeleteFromCartErr)(genericDeleteFromCartErr)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "DeleteFromCartErrInvalidZapperId";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "DeleteFromCartErrCartDoesNotExist";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "DeleteFromCartErrProductIdNotFound";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "productIds";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "DeleteFromCartErrUpdateCartFailed";
})))))));
var consPrefixCreateSessionErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "CreateSessionErr";
});
var zrpcReadCreateSessionErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixCreateSessionErr)(genericCreateSessionErr)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CreateSessionErrInvalidPin";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CreateSessionErrAccountLocked";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CreateSessionErrSessionExpired";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CreateSessionErrExistingUser";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CreateSessionErrOthers";
}))))))));
var zrpcWriteCreateSessionErr = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixCreateSessionErr)(genericCreateSessionErr)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CreateSessionErrInvalidPin";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CreateSessionErrAccountLocked";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CreateSessionErrSessionExpired";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CreateSessionErrExistingUser";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CreateSessionErrOthers";
}))))))));
var consPrefixClientOs = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
});
var zrpcReadClientOs = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixClientOs)(genericClientOs)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Android";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Ios";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Web";
})))(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Others";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "device";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))))));
var zrpcWriteClientOs = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixClientOs)(genericClientOs)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Android";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Ios";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "Web";
})))(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Others";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "device";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())))));
var consPrefixClaimErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "ClaimErr";
});
var zrpcReadClaimErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixClaimErr)(genericClaimErr)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "ClaimErrQrCodeAlreadyClaimed";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "ClaimErrInvalidData";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "ClaimErrOthers";
}))))));
var zrpcWriteClaimErr = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixClaimErr)(genericClaimErr)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "ClaimErrQrCodeAlreadyClaimed";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "ClaimErrInvalidData";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "ClaimErrOthers";
}))))));
var consPrefixClaimCode = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
});
var zrpcReadClaimCode = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixClaimCode)(genericClaimCode)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "QRCode";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "val";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "ShortCode";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "val";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))));
var zrpcWriteClaimCode = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixClaimCode)(genericClaimCode)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "QRCode";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "val";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "ShortCode";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "val";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())));
var consPrefixClaim = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
});
var zrpcReadClaim = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixClaim)(genericClaim)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Points";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "currencyName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "earned";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadHugeNum))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Coupon";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "couponId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadInt))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))));
var zrpcReadFlowRef = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixFlowRef)(genericFlowRef)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "OMobile";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "mobile";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "OOtp";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "otp";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "refId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "OPin";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "clientId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "pin";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "OUser";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "hasPin";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "isRegistered";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "lastAcceptedTermsVersion";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapperId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "OInit";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "clientOs";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(zrpcReadClientOs))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "code";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(zrpcReadClaimCode))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "mobile";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "OReceipt";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "claims";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadClaim))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "OGuestAction";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "guestAction";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadGuestClaimReceiptAction))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "OCode";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "claimCode";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadClaimCode))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "clientOs";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(zrpcReadClientOs))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "OValidatedCode";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "validatedClaimCode";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(zrpcReadClaimCode))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "OSession";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "authToken";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "OPrivacyConsent";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "accepted";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadBoolean))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))))))))))))));
var zrpcReadInstructions = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixInstructions)(genericInstructions)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "AskClaimMobile";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "AskGuestAction";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "actions";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadGuestClaimReceiptAction))))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "claims";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadArray(ZRPC_ZRPCRead.zrpcReadFieldId(zrpcReadClaim))))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "AskShortCode";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "AskLoginOtp";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "error";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "mobile";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "refId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "AskRegOtp";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "error";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "mobile";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "refId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "AskPin";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "error";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "mobile";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapperId";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "AskNewPin";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "AskSessionMobile";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "AskQrCode";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "AskPrivacyConsent";
})))))))))))));
var zrpcWriteClaim = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixClaim)(genericClaim)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Points";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "currencyName";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "earned";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteHugeNum))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Coupon";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "couponId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteInt))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())));
var zrpcWriteFlowRef = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixFlowRef)(genericFlowRef)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "OMobile";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "mobile";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "OOtp";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "otp";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "refId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "OPin";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "clientId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "pin";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "OUser";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "hasPin";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteBoolean))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "isRegistered";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteBoolean))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "lastAcceptedTermsVersion";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapperId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "OInit";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "clientOs";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(zrpcWriteClientOs))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "code";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(zrpcWriteClaimCode))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "mobile";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "OReceipt";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "claims";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteClaim))))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "OGuestAction";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "guestAction";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteGuestClaimReceiptAction))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "OCode";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "claimCode";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteClaimCode))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "clientOs";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(zrpcWriteClientOs))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "OValidatedCode";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "validatedClaimCode";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(zrpcWriteClaimCode))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "OSession";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "authToken";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "OPrivacyConsent";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "accepted";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteBoolean))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())()))))))))))));
var zrpcWriteInstructions = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixInstructions)(genericInstructions)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "AskClaimMobile";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "AskGuestAction";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "actions";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteGuestClaimReceiptAction))))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "claims";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteArray(ZRPC_ZRPCWrite.zrpcWriteFieldId(zrpcWriteClaim))))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "AskShortCode";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "AskLoginOtp";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "error";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "mobile";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "refId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "AskRegOtp";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "error";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "mobile";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "refId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "AskPin";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "error";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "mobile";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapperId";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldMaybe(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "AskNewPin";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "AskSessionMobile";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "AskQrCode";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "AskPrivacyConsent";
})))))))))))));
var consPrefixCheckoutErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "CheckoutErr";
});
var zrpcReadCheckoutErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixCheckoutErr)(genericCheckoutErr)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrInvalidProducts";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrReservationFailed";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrTransactionAlreadyUsed";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrInvalidPurchase";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrInvalidProductCode";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrOthers";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrInvalidPin";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrAccountLocked";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrUserNotFound";
}))))))))))));
var zrpcWriteCheckoutErr = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixCheckoutErr)(genericCheckoutErr)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrInvalidProducts";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrReservationFailed";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrTransactionAlreadyUsed";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrInvalidPurchase";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrInvalidProductCode";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrOthers";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrInvalidPin";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrAccountLocked";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "CheckoutErrUserNotFound";
}))))))))))));
module.exports = {
    Android: Android,
    Ios: Ios,
    Web: Web,
    Others: Others,
    AuthToken: AuthToken,
    ClaimErrQrCodeAlreadyClaimed: ClaimErrQrCodeAlreadyClaimed,
    ClaimErrInvalidData: ClaimErrInvalidData,
    ClaimErrOthers: ClaimErrOthers,
    QRCode: QRCode,
    ShortCode: ShortCode,
    Points: Points,
    Coupon: Coupon,
    ProductCodeFilterTypeAvailable: ProductCodeFilterTypeAvailable,
    ProductCodeFilterTypeRedeemed: ProductCodeFilterTypeRedeemed,
    ProductCodeFilterTypeExpired: ProductCodeFilterTypeExpired,
    ProductCodeFilterNoFilter: ProductCodeFilterNoFilter,
    ProductCodeFilterHasFilters: ProductCodeFilterHasFilters,
    UpdateCartErrInvalidZapperId: UpdateCartErrInvalidZapperId,
    UpdateCartErrProductDoesNotExist: UpdateCartErrProductDoesNotExist,
    UpdateCartErrAllocationNotFound: UpdateCartErrAllocationNotFound,
    UpdateCartErrInsufficientAllocation: UpdateCartErrInsufficientAllocation,
    UpdateCartErrUpdateCartFailed: UpdateCartErrUpdateCartFailed,
    DeleteFromCartErrInvalidZapperId: DeleteFromCartErrInvalidZapperId,
    DeleteFromCartErrCartDoesNotExist: DeleteFromCartErrCartDoesNotExist,
    DeleteFromCartErrProductIdNotFound: DeleteFromCartErrProductIdNotFound,
    DeleteFromCartErrUpdateCartFailed: DeleteFromCartErrUpdateCartFailed,
    GetCartErrInvalidZapperId: GetCartErrInvalidZapperId,
    CheckoutErrInvalidProducts: CheckoutErrInvalidProducts,
    CheckoutErrReservationFailed: CheckoutErrReservationFailed,
    CheckoutErrTransactionAlreadyUsed: CheckoutErrTransactionAlreadyUsed,
    CheckoutErrInvalidPurchase: CheckoutErrInvalidPurchase,
    CheckoutErrInvalidProductCode: CheckoutErrInvalidProductCode,
    CheckoutErrOthers: CheckoutErrOthers,
    CheckoutErrInvalidPin: CheckoutErrInvalidPin,
    CheckoutErrAccountLocked: CheckoutErrAccountLocked,
    CheckoutErrUserNotFound: CheckoutErrUserNotFound,
    Text: Text,
    Markdown: Markdown,
    PinActionCreatePin: PinActionCreatePin,
    PinActionEnterPin: PinActionEnterPin,
    VerifyPinErrInvalidPin: VerifyPinErrInvalidPin,
    VerifyPinErrAccountLocked: VerifyPinErrAccountLocked,
    SendOtpErrInvalidContactNumber: SendOtpErrInvalidContactNumber,
    SendOtpErrLimitExceeded: SendOtpErrLimitExceeded,
    SendOtpErrOthers: SendOtpErrOthers,
    ResetPinErrInvalidUser: ResetPinErrInvalidUser,
    ResendOtpErrMaxResendCountExceeded: ResendOtpErrMaxResendCountExceeded,
    ResendOtpErrSessionExpired: ResendOtpErrSessionExpired,
    ResendOtpErrOthers: ResendOtpErrOthers,
    VerifyOtpErrMaxAttemptCountExceeded: VerifyOtpErrMaxAttemptCountExceeded,
    VerifyOtpErrSessionExpired: VerifyOtpErrSessionExpired,
    VerifyOtpErrOthers: VerifyOtpErrOthers,
    CreateSessionErrInvalidPin: CreateSessionErrInvalidPin,
    CreateSessionErrAccountLocked: CreateSessionErrAccountLocked,
    CreateSessionErrSessionExpired: CreateSessionErrSessionExpired,
    CreateSessionErrExistingUser: CreateSessionErrExistingUser,
    CreateSessionErrOthers: CreateSessionErrOthers,
    PointsAllotment: PointsAllotment,
    CouponAllotment: CouponAllotment,
    TxRowStatusCleared: TxRowStatusCleared,
    TxRowStatusPending: TxRowStatusPending,
    TxRowStatusCancelled: TxRowStatusCancelled,
    TxRowStatusVoid: TxRowStatusVoid,
    TxRowStatusUnknown: TxRowStatusUnknown,
    TxRowTypeManualPoints: TxRowTypeManualPoints,
    TxRowTypeManualCoupon: TxRowTypeManualCoupon,
    TxRowTypeCatalogPurchase: TxRowTypeCatalogPurchase,
    TxRowTypeUnknown: TxRowTypeUnknown,
    Earned: Earned,
    Redeemed: Redeemed,
    FullName: FullName,
    Gender: Gender,
    Birthday: Birthday,
    Location: Location,
    EmailAddress: EmailAddress,
    TextField: TextField,
    TextArea: TextArea,
    Password: Password,
    Calendar: Calendar,
    RadioButton: RadioButton,
    Checkbox: Checkbox,
    Dropdown: Dropdown,
    ZAPDefault: ZAPDefault,
    Single: Single,
    Multi: Multi,
    Confirmed: Confirmed,
    Unconfirmed: Unconfirmed,
    Male: Male,
    Female: Female,
    UpdateProfileErrInvalidEmail: UpdateProfileErrInvalidEmail,
    UpdateProfileErrExistingEmail: UpdateProfileErrExistingEmail,
    UpdateProfileErrMissingFields: UpdateProfileErrMissingFields,
    UpdateProfileErrOthers: UpdateProfileErrOthers,
    ResetPassword: ResetPassword,
    Push: Push,
    Replace: Replace,
    Retry: Retry,
    Pop: Pop,
    Restart: Restart,
    End: End,
    RetryActionUnit: RetryActionUnit,
    RetryActionBack: RetryActionBack,
    OMobile: OMobile,
    OOtp: OOtp,
    OPin: OPin,
    OUser: OUser,
    OInit: OInit,
    OReceipt: OReceipt,
    OGuestAction: OGuestAction,
    OCode: OCode,
    OValidatedCode: OValidatedCode,
    OSession: OSession,
    OPrivacyConsent: OPrivacyConsent,
    AskClaimMobile: AskClaimMobile,
    AskGuestAction: AskGuestAction,
    AskShortCode: AskShortCode,
    AskLoginOtp: AskLoginOtp,
    AskRegOtp: AskRegOtp,
    AskPin: AskPin,
    AskNewPin: AskNewPin,
    AskSessionMobile: AskSessionMobile,
    AskQrCode: AskQrCode,
    AskPrivacyConsent: AskPrivacyConsent,
    GuestClaimReceiptScanAnother: GuestClaimReceiptScanAnother,
    GuestClaimReceiptInputAnother: GuestClaimReceiptInputAnother,
    GuestClaimReceiptBrowseCatalog: GuestClaimReceiptBrowseCatalog,
    genericClientOs: genericClientOs,
    consPrefixClientOs: consPrefixClientOs,
    zrpcWriteClientOs: zrpcWriteClientOs,
    zrpcReadClientOs: zrpcReadClientOs,
    showClientOs: showClientOs,
    newTypeAuthT: newTypeAuthT,
    showAuthToken: showAuthToken,
    genericClaimErr: genericClaimErr,
    consPrefixClaimErr: consPrefixClaimErr,
    zrpcWriteClaimErr: zrpcWriteClaimErr,
    zrpcReadClaimErr: zrpcReadClaimErr,
    showClaimErr: showClaimErr,
    genericClaimCode: genericClaimCode,
    consPrefixClaimCode: consPrefixClaimCode,
    zrpcReadClaimCode: zrpcReadClaimCode,
    zrpcWriteClaimCode: zrpcWriteClaimCode,
    showClaimCode: showClaimCode,
    genericClaim: genericClaim,
    consPrefixClaim: consPrefixClaim,
    zrpcWriteClaim: zrpcWriteClaim,
    zrpcReadClaim: zrpcReadClaim,
    showClaim: showClaim,
    eqProductCodeFilterType: eqProductCodeFilterType,
    genericProductCodeFilterType: genericProductCodeFilterType,
    consPrefixProductCodeFilterType: consPrefixProductCodeFilterType,
    showProductCodeFilterType: showProductCodeFilterType,
    zrpcReadProductCodeFilterType: zrpcReadProductCodeFilterType,
    zrpcWriteProductCodeFilterType: zrpcWriteProductCodeFilterType,
    eqProductCodeFilter: eqProductCodeFilter,
    genericProductCodeFilter: genericProductCodeFilter,
    consPrefixProductCodeFilter: consPrefixProductCodeFilter,
    showProductCodeFilter: showProductCodeFilter,
    zrpcReadProductCodeFilter: zrpcReadProductCodeFilter,
    zrpcWriteProductCodeFilter: zrpcWriteProductCodeFilter,
    genericUpdateCartErr: genericUpdateCartErr,
    consPrefixUpdateCartErr: consPrefixUpdateCartErr,
    zrpcWriteUpdateCartErr: zrpcWriteUpdateCartErr,
    zrpcReadUpdateCartErr: zrpcReadUpdateCartErr,
    showUpdateCartErr: showUpdateCartErr,
    genericDeleteFromCartErr: genericDeleteFromCartErr,
    consPrefixDeleteFromCartErr: consPrefixDeleteFromCartErr,
    zrpcWriteDeleteFromCartErr: zrpcWriteDeleteFromCartErr,
    zrpcReadDeleteFromCartErr: zrpcReadDeleteFromCartErr,
    showDeleteFromCartErr: showDeleteFromCartErr,
    getCartErrEq: getCartErrEq,
    genericGetCartErr: genericGetCartErr,
    consPrefixGetCartErr: consPrefixGetCartErr,
    zrpcWriteGetCartErr: zrpcWriteGetCartErr,
    zrpcReadGetCartErr: zrpcReadGetCartErr,
    showGetCartErr: showGetCartErr,
    genericCheckoutErr: genericCheckoutErr,
    consPrefixCheckoutErr: consPrefixCheckoutErr,
    zrpcWriteCheckoutErr: zrpcWriteCheckoutErr,
    zrpcReadCheckoutErr: zrpcReadCheckoutErr,
    showCheckoutErr: showCheckoutErr,
    eqRenderType: eqRenderType,
    genericRenderType: genericRenderType,
    consPrefixRenderType: consPrefixRenderType,
    zrpcWriteRenderType: zrpcWriteRenderType,
    zrpcReadRenderType: zrpcReadRenderType,
    showRenderType: showRenderType,
    genericPinAction: genericPinAction,
    consPrefixPinAction: consPrefixPinAction,
    zrpcWritePinAction: zrpcWritePinAction,
    zrpcReadPinAction: zrpcReadPinAction,
    showPinAction: showPinAction,
    genericVerifyPinErr: genericVerifyPinErr,
    consPrefixVerifyPinErr: consPrefixVerifyPinErr,
    zrpcWriteVerifyPinErr: zrpcWriteVerifyPinErr,
    zrpcReadVerifyPinErr: zrpcReadVerifyPinErr,
    showVerifyPinErr: showVerifyPinErr,
    genericSendOtpErr: genericSendOtpErr,
    consPrefixSendOtpErr: consPrefixSendOtpErr,
    zrpcWriteSendOtpErr: zrpcWriteSendOtpErr,
    zrpcReadSendOtpErr: zrpcReadSendOtpErr,
    showSendOtpErr: showSendOtpErr,
    genericResetPinErr: genericResetPinErr,
    consPrefixResetPinErr: consPrefixResetPinErr,
    zrpcWriteResetPinErr: zrpcWriteResetPinErr,
    zrpcReadResetPinErr: zrpcReadResetPinErr,
    showResetPinErr: showResetPinErr,
    genericResendOtpErr: genericResendOtpErr,
    consPrefixResendOtpErr: consPrefixResendOtpErr,
    zrpcWriteResendOtpErr: zrpcWriteResendOtpErr,
    zrpcReadResendOtpErr: zrpcReadResendOtpErr,
    showResendOtpErr: showResendOtpErr,
    genericVerifyOtpErr: genericVerifyOtpErr,
    consPrefixVerifyOtpErr: consPrefixVerifyOtpErr,
    zrpcWriteVerifyOtpErr: zrpcWriteVerifyOtpErr,
    zrpcReadVerifyOtpErr: zrpcReadVerifyOtpErr,
    showVerifyOtpErr: showVerifyOtpErr,
    genericCreateSessionErr: genericCreateSessionErr,
    consPrefixCreateSessionErr: consPrefixCreateSessionErr,
    zrpcWriteCreateSessionErr: zrpcWriteCreateSessionErr,
    zrpcReadCreateSessionErr: zrpcReadCreateSessionErr,
    showCreateSessionErr: showCreateSessionErr,
    genericTxRowAllotment: genericTxRowAllotment,
    consPrefixTxRowAllotment: consPrefixTxRowAllotment,
    zrpcWriteTxRowAllotment: zrpcWriteTxRowAllotment,
    zrpcReadTxRowAllotment: zrpcReadTxRowAllotment,
    showTxRowAllotment: showTxRowAllotment,
    genericTxRowStatus: genericTxRowStatus,
    consPrefixTxRowStatus: consPrefixTxRowStatus,
    zrpcWriteTxRowStatus: zrpcWriteTxRowStatus,
    zrpcReadTxRowStatus: zrpcReadTxRowStatus,
    showTxRowStatus: showTxRowStatus,
    genericTxRowType: genericTxRowType,
    consPrefixTxRowType: consPrefixTxRowType,
    zrpcWriteTxRowType: zrpcWriteTxRowType,
    zrpcReadTxRowType: zrpcReadTxRowType,
    showTxRowType: showTxRowType,
    genericTxType: genericTxType,
    consPrefixTxType: consPrefixTxType,
    zrpcWriteTxType: zrpcWriteTxType,
    zrpcReadTxType: zrpcReadTxType,
    showTxType: showTxType,
    eqZAPDefaultType: eqZAPDefaultType,
    genericZAPDefaultType: genericZAPDefaultType,
    consPrefixZAPDefaultType: consPrefixZAPDefaultType,
    zrpcWriteZAPDefaultType: zrpcWriteZAPDefaultType,
    zrpcReadZAPDefaultType: zrpcReadZAPDefaultType,
    showZAPDefaultType: showZAPDefaultType,
    eqInputType: eqInputType,
    genericInputType: genericInputType,
    consPrefixInputType: consPrefixInputType,
    zrpcWriteInputType: zrpcWriteInputType,
    zrpcReadInputType: zrpcReadInputType,
    showInputType: showInputType,
    eqValue: eqValue,
    genericValue: genericValue,
    consPrefixValue: consPrefixValue,
    zrpcWriteValue: zrpcWriteValue,
    zrpcReadValue: zrpcReadValue,
    showValue: showValue,
    eqEmail: eqEmail,
    genericEmail: genericEmail,
    consPrefixEmail: consPrefixEmail,
    zrpcWriteEmail: zrpcWriteEmail,
    zrpcReadEmail: zrpcReadEmail,
    showEmail: showEmail,
    eqGender: eqGender,
    genericGender: genericGender,
    consPrefixGender: consPrefixGender,
    zrpcWriteGender: zrpcWriteGender,
    zrpcReadGender: zrpcReadGender,
    showGender: showGender,
    genericUpdateProfileErr: genericUpdateProfileErr,
    consPrefixUpdateProfileErr: consPrefixUpdateProfileErr,
    zrpcWriteUpdateProfileErr: zrpcWriteUpdateProfileErr,
    zrpcReadUpdateProfileErr: zrpcReadUpdateProfileErr,
    showUpdateProfileErr: showUpdateProfileErr,
    genericOtpPurpose: genericOtpPurpose,
    consPrefixOtpPurpose: consPrefixOtpPurpose,
    zrpcWriteOtpPurpose: zrpcWriteOtpPurpose,
    zrpcReadOtpPurpose: zrpcReadOtpPurpose,
    showOtpPurpose: showOtpPurpose,
    genericFlowAction: genericFlowAction,
    showFlowAction: showFlowAction,
    consPrefixFlowAction: consPrefixFlowAction,
    zrpcWriteFlowAction: zrpcWriteFlowAction,
    zrpcReadFlowAction: zrpcReadFlowAction,
    genericRetryAction: genericRetryAction,
    showRetryAction: showRetryAction,
    consPrefixRetryAction: consPrefixRetryAction,
    zrpcWriteRetryAction: zrpcWriteRetryAction,
    zrpcReadRetryAction: zrpcReadRetryAction,
    genericFlowRef: genericFlowRef,
    showFlowRef: showFlowRef,
    consPrefixFlowRef: consPrefixFlowRef,
    zrpcWriteFlowRef: zrpcWriteFlowRef,
    zrpcReadFlowRef: zrpcReadFlowRef,
    genericInstructions: genericInstructions,
    showInstructions: showInstructions,
    consPrefixInstructions: consPrefixInstructions,
    zrpcWriteInstructions: zrpcWriteInstructions,
    zrpcReadInstructions: zrpcReadInstructions,
    genericGuestClaimReceiptAction: genericGuestClaimReceiptAction,
    showGuestClaimReceiptAction: showGuestClaimReceiptAction,
    consPrefixGuestClaimReceiptAction: consPrefixGuestClaimReceiptAction,
    zrpcWriteGuestClaimReceiptAction: zrpcWriteGuestClaimReceiptAction,
    zrpcReadGuestClaimReceiptAction: zrpcReadGuestClaimReceiptAction
};
