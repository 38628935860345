// Generated by purs version 0.13.8
"use strict";
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var ZRPC_ConsPrefix = require("../ZRPC.ConsPrefix/index.js");
var ZRPC_ZRPCRead = require("../ZRPC.ZRPCRead/index.js");
var ZRPC_ZRPCWrite = require("../ZRPC.ZRPCWrite/index.js");
var User = (function () {
    function User(value0) {
        this.value0 = value0;
    };
    User.create = function (value0) {
        return new User(value0);
    };
    return User;
})();
var InternalErr = (function () {
    function InternalErr(value0) {
        this.value0 = value0;
    };
    InternalErr.create = function (value0) {
        return new InternalErr(value0);
    };
    return InternalErr;
})();
var Unauthorized = (function () {
    function Unauthorized(value0) {
        this.value0 = value0;
    };
    Unauthorized.create = function (value0) {
        return new Unauthorized(value0);
    };
    return Unauthorized;
})();
var IncompatibleVersion = (function () {
    function IncompatibleVersion() {

    };
    IncompatibleVersion.value = new IncompatibleVersion();
    return IncompatibleVersion;
})();
var BadRequest = (function () {
    function BadRequest(value0) {
        this.value0 = value0;
    };
    BadRequest.create = function (value0) {
        return new BadRequest(value0);
    };
    return BadRequest;
})();
var NotFound = (function () {
    function NotFound(value0) {
        this.value0 = value0;
    };
    NotFound.create = function (value0) {
        return new NotFound(value0);
    };
    return NotFound;
})();
var Context = function (x) {
    return x;
};
var MalformedRes = (function () {
    function MalformedRes() {

    };
    MalformedRes.value = new MalformedRes();
    return MalformedRes;
})();
var Server = (function () {
    function Server(value0) {
        this.value0 = value0;
    };
    Server.create = function (value0) {
        return new Server(value0);
    };
    return Server;
})();
var runApiM = function (cm) {
    return Control_Monad_Except_Trans.runExceptT(cm);
};
var genericUser = new Data_Generic_Rep.Generic(function (x) {
    return x.value0;
}, function (x) {
    return new User(x);
});
var showUser = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericUser)(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "mobile";
}))(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "zapperId";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showString))(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
    return "User";
}))));
var genericServerErr = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof InternalErr) {
        return new Data_Generic_Rep.Inl(x.value0);
    };
    if (x instanceof Unauthorized) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
    };
    if (x instanceof IncompatibleVersion) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof BadRequest) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
    };
    if (x instanceof NotFound) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0))));
    };
    throw new Error("Failed pattern match at ExpressRpc.Types (line 70, column 1 - line 70, column 58): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return new InternalErr(x.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return new Unauthorized(x.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return IncompatibleVersion.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return new BadRequest(x.value0.value0.value0.value0);
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr))) {
        return new NotFound(x.value0.value0.value0.value0);
    };
    throw new Error("Failed pattern match at ExpressRpc.Types (line 70, column 1 - line 70, column 58): " + [ x.constructor.name ]);
});
var showServerErr = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericServerErr)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
    return "InternalErr";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
    return "Unauthorized";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "IncompatibleVersion";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
    return "BadRequest";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(Data_Show.showRecord()(Data_Show.showRecordFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(Data_Show.showRecordFieldsNil)(Data_Show.showString))))(new Data_Symbol.IsSymbol(function () {
    return "NotFound";
}))))))));
var genericClientErr = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof MalformedRes) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof Server) {
        return new Data_Generic_Rep.Inr(x.value0);
    };
    throw new Error("Failed pattern match at ExpressRpc.Types (line 84, column 1 - line 84, column 58): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return MalformedRes.value;
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return new Server(x.value0);
    };
    throw new Error("Failed pattern match at ExpressRpc.Types (line 84, column 1 - line 84, column 58): " + [ x.constructor.name ]);
});
var showClientErr = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericClientErr)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "MalformedRes";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsArgument(showServerErr))(new Data_Symbol.IsSymbol(function () {
    return "Server";
})))));
var consPrefixServerErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
    return "";
});
var zrpcReadServerErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixServerErr)(genericServerErr)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "InternalErr";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Unauthorized";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "IncompatibleVersion";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "BadRequest";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())))(ZRPC_ZRPCRead.readRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "NotFound";
}))(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()()))))))));
var zrpcWriteServerErr = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixServerErr)(genericServerErr)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "InternalErr";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "Unauthorized";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
    return "IncompatibleVersion";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "BadRequest";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())(ZRPC_ZRPCWrite.writeRepRecArg(new Data_Symbol.IsSymbol(function () {
    return "NotFound";
}))()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())()))))));
module.exports = {
    User: User,
    InternalErr: InternalErr,
    Unauthorized: Unauthorized,
    IncompatibleVersion: IncompatibleVersion,
    BadRequest: BadRequest,
    NotFound: NotFound,
    MalformedRes: MalformedRes,
    Server: Server,
    Context: Context,
    runApiM: runApiM,
    genericUser: genericUser,
    showUser: showUser,
    genericServerErr: genericServerErr,
    consPrefixServerErr: consPrefixServerErr,
    zrpcWriteServerErr: zrpcWriteServerErr,
    zrpcReadServerErr: zrpcReadServerErr,
    showServerErr: showServerErr,
    genericClientErr: genericClientErr,
    showClientErr: showClientErr
};
