// Generated by purs version 0.13.8
"use strict";
var Data_Function = require("../Data.Function/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var ReactNavigation = require("../ReactNavigation/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var mkNavBarContentHelper = function (f) {
    return function (navBanner) {
        return function (props) {
            var nav = ReactNavigation.getNavigator(props.navigation);
            var state = ReactNavigation.getState(nav);
            var cbi = ReactNavigation.getParam(props.navigation);
            var info = (Data_Newtype.unwrap(ReactNavigation_Types.navCbiNewtype)(cbi)).infoF(nav);
            var onAction = function (v) {
                if (v instanceof ReactNavigation_Types.NavBarSubmit) {
                    return (Data_Newtype.unwrap(ReactNavigation_Types.navCbiNewtype)(cbi)).cb(nav)(v.value0);
                };
                if (v instanceof ReactNavigation_Types.NavBarBack) {
                    return ReactNavigation.goBack(nav);
                };
                throw new Error("Failed pattern match at ReactNavigation.Utils.NavBarCreators (line 51, column 3 - line 51, column 44): " + [ v.constructor.name ]);
            };
            return f(info)({
                onAction: onAction,
                state: state,
                mode: props.mode,
                scene: props.scene,
                banner: navBanner
            });
        };
    };
};
var mkNavBarContent = function (f) {
    var $3 = mkNavBarContentHelper(f);
    return function ($4) {
        return ReactNavigation["NavBar'"]($3($4));
    };
};
var mkNavBarContent_ = function (c) {
    return mkNavBarContent(Data_Function["const"](c));
};
module.exports = {
    mkNavBarContent: mkNavBarContent,
    mkNavBarContent_: mkNavBarContent_
};
