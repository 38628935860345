// Generated by purs version 0.13.8
"use strict";
var $foreign = require("./foreign.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Show = require("../Data.Show/index.js");
var IsUnsigned = function (x) {
    return x;
};
var IsLittleEndian = function (x) {
    return x;
};
var showLong = new Data_Show.Show(function (l) {
    var suffix = (function () {
        var v = $foreign.unsigned(l);
        if (!v) {
            return "l";
        };
        if (v) {
            return "ul";
        };
        throw new Error("Failed pattern match at Data.Long.FFI (line 80, column 16 - line 82, column 34): " + [ v.constructor.name ]);
    })();
    return $foreign.toString(l)(Data_Int.decimal) + suffix;
});
var showIsUnsigned = new Data_Show.Show(function (v) {
    return "(IsUnsigned " + (Data_Show.show(Data_Show.showBoolean)(v) + ")");
});
var showIsLittleEndian = new Data_Show.Show(function (v) {
    return "(IsLittleEndian " + (Data_Show.show(Data_Show.showBoolean)(v) + ")");
});
var eqIsUnsigned = Data_Eq.eqBoolean;
var eqLong = new Data_Eq.Eq(function (a) {
    return function (b) {
        return $foreign.equals(a)(b) && Data_Eq.eq(eqIsUnsigned)($foreign.unsigned(a))($foreign.unsigned(b));
    };
});
var eqIsLittleEndian = Data_Eq.eqBoolean;
module.exports = {
    IsUnsigned: IsUnsigned,
    IsLittleEndian: IsLittleEndian,
    showLong: showLong,
    eqLong: eqLong,
    eqIsUnsigned: eqIsUnsigned,
    showIsUnsigned: showIsUnsigned,
    eqIsLittleEndian: eqIsLittleEndian,
    showIsLittleEndian: showIsLittleEndian,
    zero: $foreign.zero,
    one: $foreign.one,
    negOne: $foreign.negOne,
    uzero: $foreign.uzero,
    uone: $foreign.uone,
    maxValue: $foreign.maxValue,
    minValue: $foreign.minValue,
    maxUnsignedValue: $foreign.maxUnsignedValue,
    isLong: $foreign.isLong,
    fromBits: $foreign.fromBits,
    fromBytes: $foreign.fromBytes,
    fromBytesLE: $foreign.fromBytesLE,
    fromBytesBE: $foreign.fromBytesBE,
    fromInt: $foreign.fromInt,
    fromNumber: $foreign.fromNumber,
    fromString: $foreign.fromString,
    fromValue: $foreign.fromValue,
    unsigned: $foreign.unsigned,
    add: $foreign.add,
    and: $foreign.and,
    compare: $foreign.compare,
    divide: $foreign.divide,
    equals: $foreign.equals,
    getHighBits: $foreign.getHighBits,
    getHighBitsUnsigned: $foreign.getHighBitsUnsigned,
    getLowBits: $foreign.getLowBits,
    getLowBitsUnsigned: $foreign.getLowBitsUnsigned,
    getNumBitsAbs: $foreign.getNumBitsAbs,
    greaterThan: $foreign.greaterThan,
    greaterThanOrEqual: $foreign.greaterThanOrEqual,
    isEven: $foreign.isEven,
    isNegative: $foreign.isNegative,
    isOdd: $foreign.isOdd,
    isPositive: $foreign.isPositive,
    isZero: $foreign.isZero,
    lessThan: $foreign.lessThan,
    lessThanOrEqual: $foreign.lessThanOrEqual,
    modulo: $foreign.modulo,
    multiply: $foreign.multiply,
    negate: $foreign.negate,
    not: $foreign.not,
    notEquals: $foreign.notEquals,
    or: $foreign.or,
    shiftLeft: $foreign.shiftLeft,
    shiftRight: $foreign.shiftRight,
    shiftRightUnsigned: $foreign.shiftRightUnsigned,
    rotateLeft: $foreign.rotateLeft,
    rotateRight: $foreign.rotateRight,
    subtract: $foreign.subtract,
    toBytes: $foreign.toBytes,
    toInt: $foreign.toInt,
    toNumber: $foreign.toNumber,
    toSigned: $foreign.toSigned,
    toString: $foreign.toString,
    toUnsigned: $foreign.toUnsigned,
    xor: $foreign.xor
};
