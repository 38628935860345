// Generated by purs version 0.13.8
"use strict";
var $foreign = require("./foreign.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Record = require("../Record/index.js");
var onboardingSlides = [ {
    text: "Buy specially marked Personal Collection products",
    image: $foreign.getStarted1
}, {
    text: "Peel or scratch stickers to reveal QR codes",
    image: $foreign.getStarted2
}, {
    text: "Visit personalcollection.zap.com.ph on your phone and scan the code to earn points",
    image: $foreign.getStarted3
}, {
    text: "Exchange your points for exciting rewards",
    image: $foreign.getStarted4
} ];
var buildConfig = Record.merge()()({
    coloredLogo: $foreign.coloredLogo,
    whiteLogo: $foreign.whiteLogo,
    onboardingSlides: onboardingSlides
})($foreign.commonBuildConfig);
var hasStoreLinks = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(buildConfig.storeLinks.ios) || Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(buildConfig.storeLinks.android);
module.exports = {
    buildConfig: buildConfig,
    hasStoreLinks: hasStoreLinks
};
