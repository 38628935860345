// Generated by purs version 0.13.8
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect_Now = require("../Effect.Now/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var ZAP_Dart_Client_Components_FormSection = require("../ZAP.Dart.Client.Components.FormSection/index.js");
var ZAP_Dart_Client_Components_Input_CheckBoxGroup = require("../ZAP.Dart.Client.Components.Input.CheckBoxGroup/index.js");
var ZAP_Dart_Client_Components_Input_DatePicker = require("../ZAP.Dart.Client.Components.Input.DatePicker/index.js");
var ZAP_Dart_Client_Components_Input_Dropdown = require("../ZAP.Dart.Client.Components.Input.Dropdown/index.js");
var ZAP_Dart_Client_Components_Input_InputGroup = require("../ZAP.Dart.Client.Components.Input.InputGroup/index.js");
var ZAP_Dart_Client_Components_Input_PasswordField = require("../ZAP.Dart.Client.Components.Input.PasswordField/index.js");
var ZAP_Dart_Client_Components_Input_TextArea = require("../ZAP.Dart.Client.Components.Input.TextArea/index.js");
var ZAP_Dart_Client_Components_Input_TextField = require("../ZAP.Dart.Client.Components.Input.TextField/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var ZAP_Dart_Common = require("../ZAP.Dart.Common/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(40) ]),
    inputGroup: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(25) ])
};
var component = React_Basic.createComponent("BabyForm");
var babyForm = (function () {
    var render = function (props) {
        var nowJsDate = Data_JSDate.fromDateTime(Effect_Unsafe.unsafePerformEffect(Effect_Now.nowDateTime));
        var getSingleValue = function (v) {
            if (v instanceof ZAP_Dart_Common.Single) {
                return v.value0.value;
            };
            if (v instanceof ZAP_Dart_Common.Multi) {
                return Data_Maybe.fromMaybe("")(Data_Array.head(v.value0.value));
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Components.BabyForm (line 149, column 9 - line 149, column 42): " + [ v.constructor.name ]);
        };
        var getRegistrationData = function (id) {
            return Data_Foldable.find(Data_Foldable.foldableArray)(function (d) {
                return d.id === id;
            })(props.registrationData);
        };
        var getMultiValue = function (v) {
            if (v instanceof ZAP_Dart_Common.Single) {
                return [ v.value0.value ];
            };
            if (v instanceof ZAP_Dart_Common.Multi) {
                return v.value0.value;
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Components.BabyForm (line 153, column 9 - line 153, column 47): " + [ v.constructor.name ]);
        };
        var getDataValue = function (id) {
            return function (f) {
                return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(getRegistrationData(id))(function (d) {
                    return f(d.value);
                });
            };
        };
        var getDataValueWithDefault = function (id) {
            return function ($$default) {
                return function (f) {
                    return Data_Maybe.fromMaybe($$default)(getDataValue(id)(f));
                };
            };
        };
        var findRegData = function (allRegData) {
            return function (id) {
                return Data_Array.findIndex(function (d) {
                    return d.id === id;
                })(allRegData);
            };
        };
        var putRegistrationData = function (allRegData) {
            return function (regData) {
                var v = findRegData(allRegData)(regData.id);
                if (v instanceof Data_Maybe.Just) {
                    return Data_Maybe.fromMaybe(allRegData)(Data_Array.updateAt(v.value0)(regData)(allRegData));
                };
                if (v instanceof Data_Maybe.Nothing) {
                    return Data_Array.snoc(allRegData)(regData);
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Components.BabyForm (line 188, column 11 - line 190, column 53): " + [ v.constructor.name ]);
            };
        };
        var deleteRegistrationData = function (allRegData) {
            return function (id) {
                var idx = Data_Array.findIndex(function (d) {
                    return d.id === id;
                })(allRegData);
                return Data_Maybe.fromMaybe(allRegData)(Control_Bind.bind(Data_Maybe.bindMaybe)(idx)(function (i) {
                    return Data_Array.deleteAt(i)(allRegData);
                }));
            };
        };
        var handleRegistrationDataChange = function (id) {
            return function (name) {
                return function (v) {
                    return function (valueF) {
                        var registrationData = (function () {
                            if (v instanceof Data_Maybe.Just) {
                                return putRegistrationData(props.registrationData)({
                                    id: id,
                                    name: name,
                                    value: valueF({
                                        value: v.value0
                                    })
                                });
                            };
                            if (v instanceof Data_Maybe.Nothing) {
                                return deleteRegistrationData(props.registrationData)(id);
                            };
                            throw new Error("Failed pattern match at ZAP.Dart.Client.Components.BabyForm (line 170, column 32 - line 178, column 74): " + [ v.constructor.name ]);
                        })();
                        return props.onChange(registrationData);
                    };
                };
            };
        };
        var renderInput = function (v) {
            if (v.input instanceof ZAP_Dart_Common.TextField) {
                return ZAP_Dart_Client_Components_Input_InputGroup.inputGroup({
                    style: sheet.inputGroup,
                    label: v.name,
                    isRequired: v.isRequired,
                    input: ZAP_Dart_Client_Components_Input_TextField.textField({
                        onChangeText: function (v1) {
                            return handleRegistrationDataChange(v.id)(v.name)(v1)(ZAP_Dart_Common.Single.create);
                        },
                        value: getDataValue(v.id)(getSingleValue),
                        disabled: false
                    })
                });
            };
            if (v.input instanceof ZAP_Dart_Common.TextArea) {
                return ZAP_Dart_Client_Components_Input_InputGroup.inputGroup({
                    style: sheet.inputGroup,
                    label: v.name,
                    isRequired: v.isRequired,
                    input: ZAP_Dart_Client_Components_Input_TextArea.textArea({
                        value: getDataValue(v.id)(getSingleValue),
                        onChangeText: function (v1) {
                            return handleRegistrationDataChange(v.id)(v.name)(v1)(ZAP_Dart_Common.Single.create);
                        },
                        disabled: false
                    })
                });
            };
            if (v.input instanceof ZAP_Dart_Common.Password) {
                return ZAP_Dart_Client_Components_Input_InputGroup.inputGroup({
                    style: sheet.inputGroup,
                    label: v.name,
                    isRequired: v.isRequired,
                    input: ZAP_Dart_Client_Components_Input_PasswordField.passwordField({
                        value: getDataValue(v.id)(getSingleValue),
                        onChangeText: function (v1) {
                            return handleRegistrationDataChange(v.id)(v.name)(v1)(ZAP_Dart_Common.Single.create);
                        },
                        disabled: false
                    })
                });
            };
            if (v.input instanceof ZAP_Dart_Common.Calendar) {
                return ZAP_Dart_Client_Components_Input_InputGroup.inputGroup({
                    style: sheet.inputGroup,
                    label: v.name,
                    isRequired: v.isRequired,
                    input: ZAP_Dart_Client_Components_Input_DatePicker.datepicker({
                        value: Control_Bind.bind(Data_Maybe.bindMaybe)(getRegistrationData(v.id))(function (d) {
                            return ZAP_Dart_Client_Screens_Utils.readDate(getSingleValue(d.value));
                        }),
                        onChange: function (date) {
                            return handleRegistrationDataChange(v.id)(v.name)(Data_Functor.map(Data_Maybe.functorMaybe)(ZAP_Dart_Client_Screens_Utils.writeDate)(date))(ZAP_Dart_Common.Single.create);
                        },
                        onError: props.onError
                    })
                });
            };
            if (v.input instanceof ZAP_Dart_Common.RadioButton) {
                return ZAP_Dart_Client_Components_Input_InputGroup.inputGroup({
                    style: sheet.inputGroup,
                    label: v.name,
                    isRequired: v.isRequired,
                    input: ZAP_Dart_Client_Components_Input_Dropdown.dropdown_({
                        value: getDataValue(v.id)(getSingleValue),
                        onChange: function (v1) {
                            return handleRegistrationDataChange(v.id)(v.name)(v1)(ZAP_Dart_Common.Single.create);
                        },
                        items: v.input.value0.selection,
                        placeholder: "Select an item..."
                    })
                });
            };
            if (v.input instanceof ZAP_Dart_Common.Checkbox) {
                return ZAP_Dart_Client_Components_Input_InputGroup.inputGroup({
                    style: sheet.inputGroup,
                    label: v.name,
                    isRequired: v.isRequired,
                    input: ZAP_Dart_Client_Components_Input_CheckBoxGroup.checkBoxGroup({
                        value: getDataValueWithDefault(v.id)([  ])(getMultiValue),
                        onSelectionChange: function (value) {
                            return handleRegistrationDataChange(v.id)(v.name)(new Data_Maybe.Just(value))(ZAP_Dart_Common.Multi.create);
                        },
                        selection: v.input.value0.selection
                    })
                });
            };
            if (v.input instanceof ZAP_Dart_Common.Dropdown) {
                return ZAP_Dart_Client_Components_Input_InputGroup.inputGroup({
                    style: sheet.inputGroup,
                    label: v.name,
                    isRequired: v.isRequired,
                    input: ZAP_Dart_Client_Components_Input_Dropdown.dropdown_({
                        value: getDataValue(v.id)(getSingleValue),
                        onChange: function (v1) {
                            return handleRegistrationDataChange(v.id)(v.name)(v1)(ZAP_Dart_Common.Single.create);
                        },
                        items: v.input.value0.selection,
                        placeholder: "Select an item..."
                    })
                });
            };
            if (v.input instanceof ZAP_Dart_Common.ZAPDefault) {
                return Data_Monoid.mempty(React_Basic.monoidJSX);
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Components.BabyForm (line 51, column 11 - line 145, column 21): " + [ v.input.constructor.name ]);
        };
        return ZAP_Dart_Client_Components_FormSection.formSection({
            style: sheet.container,
            title: "Other Information",
            children: Data_Functor.map(Data_Functor.functorArray)(renderInput)(props.registrationFields)
        });
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    babyForm: babyForm
};
