// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Bounded = require("../Data.Bounded/index.js");
var Data_Date = require("../Data.Date/index.js");
var Data_Date_Component = require("../Data.Date.Component/index.js");
var Data_Enum = require("../Data.Enum/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Effect = require("../Effect/index.js");
var Effect_Now = require("../Effect.Now/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var ZAP_Dart_Client_Components_Input_Dropdown = require("../ZAP.Dart.Client.Components.Input.Dropdown/index.js");
var ZAP_Dart_Client_Components_Input_Validation = require("../ZAP.Dart.Client.Components.Input.Validation/index.js");
var yearList = (function () {
    var now = Effect_Unsafe.unsafePerformEffect(Effect_Now.nowDate);
    var currentYear = Data_Date.year(now);
    var from = Data_Enum.fromEnum(Data_Date_Component.boundedEnumYear)(currentYear);
    var getYear = (function () {
        var $30 = Data_Maybe.fromMaybe(currentYear);
        var $31 = Data_Enum.toEnum(Data_Date_Component.boundedEnumYear);
        return function ($32) {
            return $30($31($32));
        };
    })();
    var to = Data_Enum.fromEnum(Data_Date_Component.boundedEnumYear)(currentYear) - 100 | 0;
    return Data_Functor.map(Data_Functor.functorArray)(getYear)(Data_Array.range(from)(to));
})();
var showEnum = function (dictBoundedEnum) {
    var $33 = Data_Show.show(Data_Show.showInt);
    var $34 = Data_Enum.fromEnum(dictBoundedEnum);
    return function ($35) {
        return $33($34($35));
    };
};
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row) ]),
    monthContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(4), React_Basic_Native_Styles.paddingRight(React_Basic_Native_Styles.intPaddingRight)(4) ]),
    dayContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(3), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(4) ]),
    yearContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(4), React_Basic_Native_Styles.paddingLeft(React_Basic_Native_Styles.intPaddingLeft)(4) ])
};
var renderSelect = function (props) {
    return React_Basic_Native_Generated.view()({
        style: props.style,
        children: Control_Applicative.pure(Control_Applicative.applicativeArray)(ZAP_Dart_Client_Components_Input_Dropdown.dropdown({
            value: props.value,
            onChange: props.onChange,
            items: props.items,
            placeholder: props.placeholder
        }))
    });
};
var monthList = (function () {
    var getMonth = (function () {
        var $36 = Data_Maybe.fromMaybe(Data_Bounded.bottom(Data_Date_Component.boundedMonth));
        var $37 = Data_Enum.toEnum(Data_Date_Component.boundedEnumMonth);
        return function ($38) {
            return $36($37($38));
        };
    })();
    return Data_Functor.map(Data_Functor.functorArray)(getMonth)(Data_Array.range(1)(12));
})();
var fromString = function (dictBoundedEnum) {
    return Control_Bind.composeKleisli(Data_Maybe.bindMaybe)(Data_Int.fromString)(Data_Enum.toEnum(dictBoundedEnum));
};
var dayList = (function () {
    var getDay = (function () {
        var $39 = Data_Maybe.fromMaybe(Data_Bounded.bottom(Data_Date_Component.boundedDay));
        var $40 = Data_Enum.toEnum(Data_Date_Component.boundedEnumDay);
        return function ($41) {
            return $39($40($41));
        };
    })();
    return Data_Functor.map(Data_Functor.functorArray)(getDay)(Data_Array.range(1)(31));
})();
var component = React_Basic.createComponent("DateSelect");
var dateSelect = function (props) {
    var render = function (self) {
        return React_Basic_Native_Generated.view()({
            style: sheet.container,
            children: [ renderSelect({
                style: sheet.monthContainer,
                value: Data_Functor.map(Data_Maybe.functorMaybe)(showEnum(Data_Date_Component.boundedEnumMonth))(self.state.month),
                onChange: function (m) {
                    return self.setState(function (v) {
                        return {
                            month: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(fromString(Data_Date_Component.boundedEnumMonth))(m),
                            day: v.day,
                            year: v.year
                        };
                    });
                },
                items: Data_Functor.mapFlipped(Data_Functor.functorArray)(monthList)(function (month) {
                    return {
                        label: Data_String_CodePoints.take(3)(Data_Show.show(Data_Date_Component.showMonth)(month)),
                        value: showEnum(Data_Date_Component.boundedEnumMonth)(month)
                    };
                }),
                placeholder: "Month"
            }), renderSelect({
                style: sheet.dayContainer,
                value: Data_Functor.map(Data_Maybe.functorMaybe)(showEnum(Data_Date_Component.boundedEnumDay))(self.state.day),
                onChange: function (d) {
                    return self.setState(function (v) {
                        return {
                            month: v.month,
                            day: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(fromString(Data_Date_Component.boundedEnumDay))(d),
                            year: v.year
                        };
                    });
                },
                items: Data_Functor.mapFlipped(Data_Functor.functorArray)(dayList)(function (day) {
                    return {
                        label: showEnum(Data_Date_Component.boundedEnumDay)(day),
                        value: showEnum(Data_Date_Component.boundedEnumDay)(day)
                    };
                }),
                placeholder: "Day"
            }), renderSelect({
                style: sheet.yearContainer,
                value: Data_Functor.map(Data_Maybe.functorMaybe)(showEnum(Data_Date_Component.boundedEnumYear))(self.state.year),
                onChange: function (y) {
                    return self.setState(function (v) {
                        return {
                            month: v.month,
                            day: v.day,
                            year: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(fromString(Data_Date_Component.boundedEnumYear))(y)
                        };
                    });
                },
                items: Data_Functor.mapFlipped(Data_Functor.functorArray)(yearList)(function (year) {
                    return {
                        label: showEnum(Data_Date_Component.boundedEnumYear)(year),
                        value: showEnum(Data_Date_Component.boundedEnumYear)(year)
                    };
                }),
                placeholder: "Year"
            }) ]
        });
    };
    var initialState = {
        month: Data_Functor.map(Data_Maybe.functorMaybe)(Data_Date.month)(props.value),
        day: Data_Functor.map(Data_Maybe.functorMaybe)(Data_Date.day)(props.value),
        year: Data_Functor.map(Data_Maybe.functorMaybe)(Data_Date.year)(props.value)
    };
    var didUpdate = function (self) {
        return function (v) {
            var shouldUpdate = Data_Eq.notEq(Data_Maybe.eqMaybe(Data_Date_Component.eqMonth))(v.prevState.month)(self.state.month) || (Data_Eq.notEq(Data_Maybe.eqMaybe(Data_Date_Component.eqDay))(v.prevState.day)(self.state.day) || Data_Eq.notEq(Data_Maybe.eqMaybe(Data_Date_Component.eqYear))(v.prevState.year)(self.state.year));
            return Control_Applicative.when(Effect.applicativeEffect)(shouldUpdate)((function () {
                var v1 = function (v2) {
                    if (self.state.month instanceof Data_Maybe.Just && (self.state.day instanceof Data_Maybe.Just && (self.state.year instanceof Data_Maybe.Just && Data_Boolean.otherwise))) {
                        return function __do() {
                            self.props.onChange(Data_Maybe.Nothing.value)();
                            return self.props.onError(ZAP_Dart_Client_Components_Input_Validation.InvalidDate.value)();
                        };
                    };
                    return self.props.onChange(Data_Maybe.Nothing.value);
                };
                if (self.state.month instanceof Data_Maybe.Just && (self.state.day instanceof Data_Maybe.Just && self.state.year instanceof Data_Maybe.Just)) {
                    var $20 = Data_Date.exactDate(self.state.year.value0)(self.state.month.value0)(self.state.day.value0);
                    if ($20 instanceof Data_Maybe.Just) {
                        return self.props.onChange(new Data_Maybe.Just($20.value0));
                    };
                    return v1(true);
                };
                return v1(true);
            })());
        };
    };
    return React_Basic.make()(component)({
        initialState: initialState,
        didUpdate: didUpdate,
        render: render
    })(props);
};
module.exports = {
    dateSelect: dateSelect
};
