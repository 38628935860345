// Generated by purs version 0.13.8
"use strict";
var Control_Apply = require("../Control.Apply/index.js");
var Data_DateTime = require("../Data.DateTime/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ZAP_Dart_Client_Components_External_Markdown = require("../ZAP.Dart.Client.Components.External.Markdown/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var ZAP_Dart_Client_Styles_Utils = require("../ZAP.Dart.Client.Styles.Utils/index.js");
var sheet = {
    sectionContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(15), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(30) ]),
    sectionTitle: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(10), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.semiBold) ])
};
var sectionBody = function (body) {
    return ZAP_Dart_Client_Components_Typography.b2({
        style: ZAP_Dart_Client_Styles_Utils.noStyle,
        color: ZAP_Dart_Client_Styles_Colors.altFg,
        value: body
    });
};
var section = function (v) {
    return React_Basic_Native_Generated.view()({
        style: sheet.sectionContainer,
        children: [ (function () {
            if (v.title instanceof Data_Maybe.Nothing) {
                return Data_Monoid.mempty(React_Basic.monoidJSX);
            };
            if (v.title instanceof Data_Maybe.Just) {
                return ZAP_Dart_Client_Components_Typography.b1({
                    style: sheet.sectionTitle,
                    color: ZAP_Dart_Client_Styles_Colors.altFg,
                    value: v.title.value0
                });
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Components.ProductDetails (line 79, column 9 - line 86, column 16): " + [ v.title.constructor.name ]);
        })(), v.body ]
    });
};
var component = React_Basic.createComponent("ProductDetails");
var productDetails = (function () {
    var renderTermsSection = function (terms) {
        return function (expiry) {
            var exp = Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(expiry)(function (d) {
                return "* Valid until " + ZAP_Dart_Client_Screens_Utils.formatDate(Data_DateTime.date(d));
            });
            var separator = Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
                return function (v1) {
                    return "\x0a";
                };
            })(terms))(exp);
            var terms$prime = Data_Semigroup.append(Data_Maybe.semigroupMaybe(Data_Semigroup.semigroupString))(terms)(Data_Semigroup.append(Data_Maybe.semigroupMaybe(Data_Semigroup.semigroupString))(separator)(exp));
            return Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(React_Basic.monoidJSX)(function (updatedTerms) {
                return section({
                    title: new Data_Maybe.Just("Terms"),
                    body: ZAP_Dart_Client_Components_External_Markdown.markdown()({
                        value: updatedTerms
                    })
                });
            })(terms$prime);
        };
    };
    var renderRedemptionInstruction = Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(React_Basic.monoidJSX)(function (redemptionInstruction) {
        return section({
            title: new Data_Maybe.Just("How to Claim"),
            body: ZAP_Dart_Client_Components_External_Markdown.markdown()({
                value: redemptionInstruction
            })
        });
    });
    var productDetails$prime = Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(React_Basic.monoidJSX)(function (description) {
        return section({
            title: new Data_Maybe.Just("Product Details"),
            body: sectionBody(description)
        });
    });
    var render = function (v) {
        return React_Basic.fragment([ productDetails$prime(v.description), renderRedemptionInstruction(v.redemptionInstruction), renderTermsSection(v.terms)(v.expiry) ]);
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    component: component,
    productDetails: productDetails,
    section: section,
    sectionBody: sectionBody,
    sheet: sheet
};
