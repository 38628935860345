// Generated by purs version 0.13.8
"use strict";
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var WritingDirection = function (x) {
    return x;
};
var TextDecorationStyle = function (x) {
    return x;
};
var TextDecoration = function (x) {
    return x;
};
var TextAlignVertical = function (x) {
    return x;
};
var TextAlign = function (x) {
    return x;
};
var FontWeight = function (x) {
    return x;
};
var FontVariant = function (x) {
    return x;
};
var FontStyle = function (x) {
    return x;
};
var LetterSpacing = function (letterSpacing) {
    this.letterSpacing = letterSpacing;
};
var weight900 = "900";
var weight800 = "800";
var weight700 = "700";
var weight600 = "600";
var weight500 = "500";
var weight400 = "400";
var weight300 = "300";
var weight200 = "200";
var weight100 = "100";
var underlineLineThrough = "underline line-through";
var underline = "underline";
var top = "top";
var textShadowRadius = React_Basic_Native_Styles.unsafeStyleProp("textShadowRadius");
var textShadowOffset = React_Basic_Native_Styles.unsafeStyleProp("textShadowOffset");
var textShadowColor = React_Basic_Native_Styles.unsafeStyleProp("textShadowColor");
var textDecorationLine = React_Basic_Native_Styles.unsafeStyleProp("textDecorationLine");
var textAlign = React_Basic_Native_Styles.unsafeStyleProp("textAlign");
var tabularNums = "tabular-nums";
var taAuto = new React_Basic_Native_PropTypes.AutoEnum("auto");
var solid = "solid";
var smallCaps = "small-caps";
var rtl = "rtl";
var right = "right";
var proportionalNums = "proportional-nums";
var oldstyleNums = "oldstyle-nums";
var numberLetterSpacing = new LetterSpacing(React_Basic_Native_Styles.unsafeStyleProp("letterSpacing"));
var normalFontWeight = new React_Basic_Native_PropTypes.NormalEnum("normal");
var normalFontStyle = new React_Basic_Native_PropTypes.NormalEnum("normal");
var none = "none";
var ltr = "ltr";
var liningNums = "lining-nums";
var lineThrough = "line-through";
var lineHeight = React_Basic_Native_Styles.unsafeStyleProp("lineHeight");
var letterSpacing = function (dict) {
    return dict.letterSpacing;
};
var left = "left";
var justify = "justify";
var italic = "italic";
var iosWritingDirection = React_Basic_Native_Styles.unsafeStyleProp("writingDirection");
var iosTextDecorationStyle = React_Basic_Native_Styles.unsafeStyleProp("textDecorationStyle");
var iosTextDecorationColor = React_Basic_Native_Styles.unsafeStyleProp("textDecorationColor");
var iosFontVariant = React_Basic_Native_Styles.unsafeStyleProp("fontVariant");
var intLetterSpacing = new LetterSpacing(React_Basic_Native_Styles.unsafeStyleProp("letterSpacing"));
var fontWeight = React_Basic_Native_Styles.unsafeStyleProp("fontWeight");
var fontStyle = React_Basic_Native_Styles.unsafeStyleProp("fontStyle");
var fontSize = React_Basic_Native_Styles.unsafeStyleProp("fontSize");
var fontFamily = React_Basic_Native_Styles.unsafeStyleProp("fontFamily");
var $$double = "double";
var dotted = "dotted";
var dashed = "dashed";
var color = React_Basic_Native_Styles.unsafeStyleProp("color");
var centerTextAlign = new React_Basic_Native_PropTypes.CenterEnum("center");
var centerTAV = new React_Basic_Native_PropTypes.CenterEnum("center");
var bottom = "bottom";
var bold = "bold";
var autoWD = new React_Basic_Native_PropTypes.AutoEnum("auto");
var autoTAV = new React_Basic_Native_PropTypes.AutoEnum("auto");
var androidTextAlignVertical = React_Basic_Native_Styles.unsafeStyleProp("textAlignVertical");
module.exports = {
    letterSpacing: letterSpacing,
    TextAlign: TextAlign,
    TextDecoration: TextDecoration,
    FontStyle: FontStyle,
    FontWeight: FontWeight,
    TextAlignVertical: TextAlignVertical,
    FontVariant: FontVariant,
    TextDecorationStyle: TextDecorationStyle,
    WritingDirection: WritingDirection,
    smallCaps: smallCaps,
    oldstyleNums: oldstyleNums,
    liningNums: liningNums,
    tabularNums: tabularNums,
    proportionalNums: proportionalNums,
    solid: solid,
    "double": $$double,
    dotted: dotted,
    dashed: dashed,
    ltr: ltr,
    rtl: rtl,
    top: top,
    bottom: bottom,
    left: left,
    right: right,
    justify: justify,
    none: none,
    underline: underline,
    lineThrough: lineThrough,
    underlineLineThrough: underlineLineThrough,
    bold: bold,
    weight100: weight100,
    weight200: weight200,
    weight300: weight300,
    weight400: weight400,
    weight500: weight500,
    weight600: weight600,
    weight700: weight700,
    weight800: weight800,
    weight900: weight900,
    italic: italic,
    color: color,
    fontFamily: fontFamily,
    fontSize: fontSize,
    fontStyle: fontStyle,
    fontWeight: fontWeight,
    lineHeight: lineHeight,
    textAlign: textAlign,
    textDecorationLine: textDecorationLine,
    textShadowColor: textShadowColor,
    textShadowOffset: textShadowOffset,
    textShadowRadius: textShadowRadius,
    androidTextAlignVertical: androidTextAlignVertical,
    iosFontVariant: iosFontVariant,
    LetterSpacing: LetterSpacing,
    iosTextDecorationColor: iosTextDecorationColor,
    iosTextDecorationStyle: iosTextDecorationStyle,
    iosWritingDirection: iosWritingDirection,
    autoWD: autoWD,
    centerTAV: centerTAV,
    autoTAV: autoTAV,
    centerTextAlign: centerTextAlign,
    taAuto: taAuto,
    normalFontStyle: normalFontStyle,
    normalFontWeight: normalFontWeight,
    intLetterSpacing: intLetterSpacing,
    numberLetterSpacing: numberLetterSpacing
};
