// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native = require("../React.Basic.Native/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_BuildConfig = require("../ZAP.Dart.Client.BuildConfig/index.js");
var ZAP_Dart_Client_Components_Button = require("../ZAP.Dart.Client.Components.Button/index.js");
var ZAP_Dart_Client_Components_CheckBox = require("../ZAP.Dart.Client.Components.CheckBox/index.js");
var ZAP_Dart_Client_Components_Divider = require("../ZAP.Dart.Client.Components.Divider/index.js");
var ZAP_Dart_Client_Components_External_Markdown = require("../ZAP.Dart.Client.Components.External.Markdown/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Dimensions = require("../ZAP.Dart.Client.Styles.Dimensions/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var ZAP_Dart_Client_Styles_Utils = require("../ZAP.Dart.Client.Styles.Utils/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1) ]),
    scrollContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(40), ZAP_Dart_Client_Styles_Dimensions.screenMaxWidth, React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)) ]),
    title: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(52), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(22) ]),
    subtitle: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(52), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(50) ]),
    privacyConsentSubheading: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(52), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(25), React_Basic_Native_Styles_Text.fontSize(18), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.regular), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(0.7), React_Basic_Native_Styles_Text.color(ZAP_Dart_Client_Styles_Colors.altFg) ]),
    privacyConsentBody: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(52), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(35) ]),
    link: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.color(ZAP_Dart_Client_Styles_Colors.secondaryLink) ]),
    divider: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(35), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(25), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)) ]),
    tosConfirmation: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(15), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(52), React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(50), React_Basic_Native_Styles.maxWidth(React_Basic_Native_Styles.intMaxWidth)(750), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)) ]),
    actionContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.maxWidth(React_Basic_Native_Styles.intMaxWidth)(400), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(40) ]),
    secondaryButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(20) ])
};
var component = React_Basic.createComponent("PrivacyConsent");
var privacyConsent = (function () {
    var tosConfirmation = function (self) {
        return React_Basic_Native_Generated.view()({
            style: sheet.tosConfirmation,
            children: [ React_Basic_Native_Generated.view_([ ZAP_Dart_Client_Components_CheckBox.checkBox({
                isChecked: self.state.isTosChecked,
                onPress: React_Basic_Events.handler_(self.setState(function (s) {
                    return {
                        isTosChecked: !s.isTosChecked
                    };
                })),
                color: ZAP_Dart_Client_Styles_Colors.altFg
            }) ]), ZAP_Dart_Client_Components_External_Markdown.markdown()({
                value: ZAP_Dart_Client_BuildConfig.buildConfig.privacyConsentConfirmation
            }) ]
        });
    };
    var title = ZAP_Dart_Client_Components_Typography.h2({
        style: sheet.title,
        color: ZAP_Dart_Client_Styles_Colors.primary,
        value: "ACCEPT TERMS"
    });
    var subtitle = ZAP_Dart_Client_Components_Typography.b2({
        style: sheet.subtitle,
        color: ZAP_Dart_Client_Styles_Colors.altFg,
        value: "Looks like you aren't a member yet! Sign up by accepting the terms displayed below"
    });
    var privacyConsentSubheading = React_Basic_Native_Generated.text()({
        style: sheet.privacyConsentSubheading,
        children: [ React_Basic_Native.string("PH Privacy Consent") ]
    });
    var privacyConsentBody = React_Basic_Native_Generated.view()({
        style: sheet.privacyConsentBody,
        children: Control_Applicative.pure(Control_Applicative.applicativeArray)(ZAP_Dart_Client_Components_External_Markdown.markdown()({
            value: ZAP_Dart_Client_BuildConfig.buildConfig.privacyConsent
        }))
    });
    var initialState = {
        isTosChecked: false
    };
    var action = function (self) {
        return React_Basic_Native_Generated.view()({
            style: sheet.actionContainer,
            children: [ ZAP_Dart_Client_Components_Button.primaryButton({
                title: "ACCEPT",
                onPress: self.props.onAction(new ReactNavigation_Types.PgSubmit(Data_Unit.unit)),
                disabled: !self.state.isTosChecked,
                inverted: false,
                style: ZAP_Dart_Client_Styles_Utils.noStyle,
                size: ZAP_Dart_Client_Components_Button.md
            }), ZAP_Dart_Client_Components_Button.secondaryButton({
                title: "DECLINE",
                onPress: self.props.onAction(ReactNavigation_Types.PgCancel.value),
                disabled: false,
                inverted: false,
                style: sheet.secondaryButton
            }) ]
        });
    };
    var render = function (self) {
        return React_Basic_Native_Generated.safeAreaView()({
            style: sheet.container,
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_Native_Generated.scrollView()({
                contentContainerStyle: sheet.scrollContainer,
                children: [ title, subtitle, ZAP_Dart_Client_Components_Divider.divider(sheet.divider), privacyConsentSubheading, privacyConsentBody, ZAP_Dart_Client_Components_Divider.divider(sheet.divider), tosConfirmation(self), action(self) ]
            }))
        });
    };
    return React_Basic.make()(component)({
        initialState: initialState,
        render: render
    });
})();
module.exports = {
    privacyConsent: privacyConsent
};
