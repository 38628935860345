// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Aff_Class = require("../Effect.Aff.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var ExpressRpc_Types = require("../ExpressRpc.Types/index.js");
var ReactNavigation = require("../ReactNavigation/index.js");
var ReactNavigation_AuthFlow = require("../ReactNavigation.AuthFlow/index.js");
var ReactNavigation_Class_MonadNavM = require("../ReactNavigation.Class.MonadNavM/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ReactNavigation_Utils_HOCs_WithPageProps = require("../ReactNavigation.Utils.HOCs.WithPageProps/index.js");
var ReactNavigation_Utils_NavBarCreators = require("../ReactNavigation.Utils.NavBarCreators/index.js");
var ReactNavigation_Utils_PageCreators = require("../ReactNavigation.Utils.PageCreators/index.js");
var ReactNavigation_Utils_RouterConfigs = require("../ReactNavigation.Utils.RouterConfigs/index.js");
var ZAP_Dart_Client_Components_NavBar_CartMgtNavBar = require("../ZAP.Dart.Client.Components.NavBar.CartMgtNavBar/index.js");
var ZAP_Dart_Client_Flows_AuthorizedResetPin = require("../ZAP.Dart.Client.Flows.AuthorizedResetPin/index.js");
var ZAP_Dart_Client_Flows_Utils = require("../ZAP.Dart.Client.Flows.Utils/index.js");
var ZAP_Dart_Client_Screens_AdvancedOpts = require("../ZAP.Dart.Client.Screens.AdvancedOpts/index.js");
var ZAP_Dart_Client_Screens_EditProfile = require("../ZAP.Dart.Client.Screens.EditProfile/index.js");
var ZAP_Dart_Client_Screens_Profile = require("../ZAP.Dart.Client.Screens.Profile/index.js");
var ZAP_Dart_Client_Screens_Transactions = require("../ZAP.Dart.Client.Screens.Transactions/index.js");
var ZAP_Dart_Client_Screens_Types = require("../ZAP.Dart.Client.Screens.Types/index.js");
var rs = ReactNavigation.mkRoutes(ReactNavigation.mkRoutesI()(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLNil)()()(new Data_Symbol.IsSymbol(function () {
    return "transactions";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "resetPin";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "profile";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "editProfile";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "advancedOpts";
}))));
var navBar = ReactNavigation_Utils_NavBarCreators.mkNavBarContent_(ZAP_Dart_Client_Components_NavBar_CartMgtNavBar.cartMgtNavBar)(ReactNavigation_Types.NBTotalPoints.value);
var flow = (function () {
    var txsInfo = function (v) {
        return {
            apiCalls: {
                listTransactions: v.clientApi.listTransactions
            }
        };
    };
    var logoutErrMsg = function (dictMonadEffect) {
        return function (dictShow) {
            return function (a) {
                return Effect_Class_Console.log(dictMonadEffect)("Logout API Failed: " + Data_Show.show(dictShow)(a));
            };
        };
    };
    var logout = function (v) {
        return Control_Bind.bind(ReactNavigation_AuthFlow.bindAuthNavM)(Effect_Aff_Class.liftAff(ReactNavigation_AuthFlow.monadAffAuthNavM)(Effect_Aff.attempt(ExpressRpc_Types.runApiM(v.clientApi.revokeToken({
            accessToken: v.authToken
        })))))(function (v1) {
            if (v1 instanceof Data_Either.Left) {
                return logoutErrMsg(ReactNavigation_AuthFlow.monadEffAuthNavM)(Effect_Exception.showError)(v1.value0);
            };
            if (v1 instanceof Data_Either.Right) {
                if (v1.value0 instanceof Data_Either.Left) {
                    return logoutErrMsg(ReactNavigation_AuthFlow.monadEffAuthNavM)(ExpressRpc_Types.showClientErr)(v1.value0.value0);
                };
                if (v1.value0 instanceof Data_Either.Right) {
                    return Control_Applicative.pure(ReactNavigation_AuthFlow.applicativeAuthNavM)(Data_Unit.unit);
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.Profile (line 88, column 22 - line 90, column 33): " + [ v1.value0.constructor.name ]);
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.Profile (line 86, column 11 - line 90, column 33): " + [ v1.constructor.name ]);
        });
    };
    var initRouteF = function (v) {
        return ReactNavigation_AuthFlow.processInfos()(ReactNavigation_AuthFlow["processInfosRLConsInitD'"](ReactNavigation_AuthFlow.processInfosRLNil)()(new Data_Symbol.IsSymbol(function () {
            return "initD";
        }))()()())({
            initD: v.clientApi.getProfileSummary
        })(v.authToken);
    };
    var editProfileInfo = function (v) {
        return {
            initD: v.clientApi.getProfile,
            apiCalls: {
                updateProfile: v.clientApi.updateProfile
            }
        };
    };
    var defaultNavigationOptions = ReactNavigation.navigationOptions()()({
        header: Data_Nullable.notNull(navBar)
    });
    var configBuilder = ReactNavigation_Utils_RouterConfigs.stackRcBuilder(ReactNavigation.isRouterConfigOpts()()())()(ReactNavigation.isRouterConfigOpts()()())({
        defaultNavigationOptions: defaultNavigationOptions
    });
    var afterInitF = function (v) {
        if (v.value instanceof ZAP_Dart_Client_Screens_Types.EditProfile) {
            return Control_Apply.applySecond(ReactNavigation_AuthFlow.applyAuthNavM)(ReactNavigation_AuthFlow.navPushA(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                return "editProfile";
            }))())(ReactNavigation_AuthFlow.monadReaderAuthNavM)(ReactNavigation_AuthFlow.monadNMAuthNavM)(ReactNavigation_AuthFlow.plusAuthNavM)()(ReactNavigation_AuthFlow.processInfosRLConsapiCalls(ReactNavigation_AuthFlow["processInfosRLConsInitD'"](ReactNavigation_AuthFlow.processInfosRLNil)()(new Data_Symbol.IsSymbol(function () {
                return "initD";
            }))()()())(new Data_Symbol.IsSymbol(function () {
                return "apiCalls";
            }))()(ReactNavigation_AuthFlow.applyAuthRLCons(ReactNavigation_AuthFlow.applyAuthRLNil)(new Data_Symbol.IsSymbol(function () {
                return "updateProfile";
            }))()()())()()()())(rs.editProfile)(editProfileInfo(v.info)))(ReactNavigation_Class_MonadNavM.navGoBack(ReactNavigation_AuthFlow.monadNMAuthNavM));
        };
        if (v.value instanceof ZAP_Dart_Client_Screens_Types.ViewTransactions) {
            return Control_Apply.applySecond(ReactNavigation_AuthFlow.applyAuthNavM)(ReactNavigation_AuthFlow.navPushA(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                return "transactions";
            }))())(ReactNavigation_AuthFlow.monadReaderAuthNavM)(ReactNavigation_AuthFlow.monadNMAuthNavM)(ReactNavigation_AuthFlow.plusAuthNavM)()(ReactNavigation_AuthFlow.processInfosRLConsapiCalls(ReactNavigation_AuthFlow.processInfosRLNil)(new Data_Symbol.IsSymbol(function () {
                return "apiCalls";
            }))()(ReactNavigation_AuthFlow.applyAuthRLCons(ReactNavigation_AuthFlow.applyAuthRLNil)(new Data_Symbol.IsSymbol(function () {
                return "listTransactions";
            }))()()())()()()())(rs.transactions)(txsInfo(v.info)))(ReactNavigation_Class_MonadNavM.navGoBack(ReactNavigation_AuthFlow.monadNMAuthNavM));
        };
        if (v.value instanceof ZAP_Dart_Client_Screens_Types.ResetPin) {
            return Control_Bind.bind(ReactNavigation_AuthFlow.bindAuthNavM)(ReactNavigation_Class_MonadNavM.navPushIK(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                return "resetPin";
            }))())(ReactNavigation_AuthFlow.monadNMAuthNavM)(rs.resetPin)(v.info))(function (v1) {
                return ReactNavigation_Class_MonadNavM.navGoBackToKey(ReactNavigation_AuthFlow.monadNMAuthNavM)(v1.value0);
            });
        };
        if (v.value instanceof ZAP_Dart_Client_Screens_Types.Logout) {
            return Control_Apply.applySecond(ReactNavigation_AuthFlow.applyAuthNavM)(logout(v.info))(Control_Plus.empty(ReactNavigation_AuthFlow.plusAuthNavM));
        };
        if (v.value instanceof ZAP_Dart_Client_Screens_Types.AdvancedOpts) {
            return Control_Apply.applySecond(ReactNavigation_AuthFlow.applyAuthNavM)(ReactNavigation_Class_MonadNavM.navPush(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                return "advancedOpts";
            }))())(ReactNavigation_AuthFlow.monadNMAuthNavM)(rs.advancedOpts))(ReactNavigation_Class_MonadNavM.navGoBack(ReactNavigation_AuthFlow.monadNMAuthNavM));
        };
        throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.Profile (line 58, column 41 - line 69, column 57): " + [ v.value.constructor.name ]);
    };
    return ReactNavigation_AuthFlow.createAuthFlow(new Data_Symbol.IsSymbol(function () {
        return "profile";
    }))(ReactNavigation.navCreatorStack)(ReactNavigation_AuthFlow.runAuthNavMfirstRouteAsync)(rs)(rs.profile)(initRouteF)(configBuilder)(afterInitF);
})();
var profileScreen = (function () {
    var resetPin = ReactNavigation.routeUiOpts()()({
        navigationOptions: ReactNavigation.navigationOptions()()({
            header: Data_Nullable.notNull(ZAP_Dart_Client_Flows_Utils.titleNavBar("RESET PIN"))
        }),
        screen: ZAP_Dart_Client_Flows_AuthorizedResetPin.authorizedResetPinScreen
    });
    var editProfile = ReactNavigation.routeUiOpts()()({
        navigationOptions: ReactNavigation.navigationOptions()()({
            header: Data_Nullable.notNull(ZAP_Dart_Client_Flows_Utils.titleNavBar("EDIT PROFILE"))
        }),
        screen: ReactNavigation_Utils_PageCreators.routeUiInitD(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherClientErr)()(ZAP_Dart_Client_Screens_EditProfile.editProfile)
    });
    var advancedOpts = ReactNavigation.routeUiOpts()()({
        navigationOptions: ReactNavigation.navigationOptions()()({
            header: Data_Nullable.notNull(ZAP_Dart_Client_Flows_Utils.titleNavBar("Advanced Options"))
        }),
        screen: ReactNavigation_Utils_PageCreators.routeUiPage_(ZAP_Dart_Client_Screens_AdvancedOpts.advancedOpts)
    });
    return ReactNavigation.createFlowScreen()(flow)({
        profile: ReactNavigation.routeUiOpts_(ReactNavigation_Utils_PageCreators.routeUiInitD(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherClientErr)()(ZAP_Dart_Client_Screens_Profile.profile)),
        editProfile: editProfile,
        transactions: ReactNavigation.routeUiOpts_(ReactNavigation_Utils_PageCreators.routeUiPage(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherClientErr)(ZAP_Dart_Client_Screens_Transactions.transactions)),
        resetPin: resetPin,
        advancedOpts: advancedOpts
    });
})();
module.exports = {
    profileScreen: profileScreen
};
