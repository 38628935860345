// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_Components_Button = require("../ZAP.Dart.Client.Components.Button/index.js");
var ZAP_Dart_Client_Components_DashedInput = require("../ZAP.Dart.Client.Components.DashedInput/index.js");
var ZAP_Dart_Client_Components_Icon = require("../ZAP.Dart.Client.Components.Icon/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Screens_Templates_StepWrapper = require("../ZAP.Dart.Client.Screens.Templates.StepWrapper/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.primary) ]),
    scrollContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(24), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(40) ]),
    header: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)) ]),
    headerContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(32) ]),
    message: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)) ]),
    mobileInputContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(32) ]),
    mobileInputPrefix: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.ubuntuMono.regular), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.intLetterSpacing)(4), React_Basic_Native_Styles.marginHorizontal(React_Basic_Native_Styles.intMarginHorizontal)(12) ]),
    submitButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.maxWidth(React_Basic_Native_Styles.intMaxWidth)(360), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(100), React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(40) ])
};
var component = React_Basic.createComponent("EnterMobile");
var enterMobile = (function () {
    var initialState = {
        mobileNumber: "",
        isTosChecked: false
    };
    var canSubmit = function (state) {
        return Data_String_CodePoints.length(state.mobileNumber) >= 10;
    };
    var onSubmit = function (self) {
        return Control_Applicative.when(Effect.applicativeEffect)(canSubmit(self.state))(self.props.onAction(new ReactNavigation_Types.PgSubmit("63" + self.state.mobileNumber)));
    };
    var render = function (self) {
        return ZAP_Dart_Client_Screens_Templates_StepWrapper.stepWrapper({
            header: new Data_Tuple.Tuple("ENTER YOUR", "MOBILE NUMBER"),
            message: new Data_Tuple.Tuple("Join the rewards program with just your mobile number and get access to our awesome rewards", ""),
            content: [ React_Basic_Native_Generated.view()({
                style: sheet.mobileInputContainer,
                children: [ ZAP_Dart_Client_Components_Icon.icon({
                    name: "phone_outlined",
                    size: 32,
                    color: ZAP_Dart_Client_Styles_Colors.altBg
                }), ZAP_Dart_Client_Components_Typography.h2({
                    style: sheet.mobileInputPrefix,
                    value: "+63",
                    color: ZAP_Dart_Client_Styles_Colors.altBg
                }), ZAP_Dart_Client_Components_DashedInput.dashedInput({
                    value: self.state.mobileNumber,
                    length: 10,
                    fontSize: 28,
                    letterSpacing: 4,
                    inputType: ZAP_Dart_Client_Components_DashedInput.Numeric.value,
                    onChange: function (text) {
                        return self.setState(function (v) {
                            return {
                                mobileNumber: text,
                                isTosChecked: v.isTosChecked
                            };
                        });
                    },
                    onSubmitEditing: React_Basic_Events.handler_(onSubmit(self)),
                    color: ZAP_Dart_Client_Styles_Colors.altBg,
                    style: React_Basic_Native_Styles["styles'"]([  ]),
                    secureTextEntry: false,
                    autoFocus: true
                }) ]
            }), ZAP_Dart_Client_Components_Button.primaryButton({
                style: sheet.submitButton,
                disabled: !canSubmit(self.state),
                inverted: true,
                size: ZAP_Dart_Client_Components_Button.lg,
                onPress: onSubmit(self),
                title: "SUBMIT"
            }) ]
        });
    };
    return React_Basic.make()(component)({
        initialState: initialState,
        render: render
    });
})();
module.exports = {
    enterMobile: enterMobile
};
