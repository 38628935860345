// Generated by purs version 0.13.8
"use strict";
var ZAP_Dart_Client_Components_Svg = require("../ZAP.Dart.Client.Components.Svg/index.js");
var checkBoxBlank = function (props) {
    return ZAP_Dart_Client_Components_Svg.svg()({
        width: props.width,
        height: props.height,
        fill: props.color,
        viewBox: "0 0 24 24",
        children: [ ZAP_Dart_Client_Components_Svg.path()({
            fill: "none",
            d: "M0 0h24v24H0V0z"
        }), ZAP_Dart_Client_Components_Svg.path()({
            d: "M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
        }) ]
    });
};
var checkBox = function (props) {
    return ZAP_Dart_Client_Components_Svg.svg()({
        width: props.width,
        height: props.height,
        fill: props.color,
        viewBox: "0 0 24 24",
        children: [ ZAP_Dart_Client_Components_Svg.path()({
            fill: "none",
            d: "M0 0h24v24H0V0z"
        }), ZAP_Dart_Client_Components_Svg.path()({
            d: "M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM17.99 9l-1.41-1.42-6.59 6.59-2.58-2.57-1.42 1.41 4 3.99z"
        }) ]
    });
};
module.exports = {
    checkBox: checkBox,
    checkBoxBlank: checkBoxBlank
};
