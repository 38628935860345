// Generated by purs version 0.13.8
"use strict";
var Control_Category = require("../Control.Category/index.js");
var ReactNavigation = require("../ReactNavigation/index.js");
var MonadNavM = function (Monad0, liftNavM) {
    this.Monad0 = Monad0;
    this.liftNavM = liftNavM;
};
var monadNMNavM = new MonadNavM(function () {
    return ReactNavigation.navmMonad;
}, Control_Category.identity(Control_Category.categoryFn));
var liftNavM = function (dict) {
    return dict.liftNavM;
};
var navDialog = function (dictMonadNavM) {
    return function (comp) {
        return liftNavM(dictMonadNavM)(ReactNavigation.navDialog(comp));
    };
};
var navDialogI = function (dictMonadNavM) {
    return function (comp) {
        return function (info) {
            return liftNavM(dictMonadNavM)(ReactNavigation.navDialogI(comp)(info));
        };
    };
};
var navDialogIC = function (dictMonadNavM) {
    return function (comp) {
        return function (info) {
            return function (onClose) {
                return liftNavM(dictMonadNavM)(ReactNavigation.navDialogIC(comp)(info)(onClose));
            };
        };
    };
};
var navErrDialog = function (args) {
    return liftNavM(monadNMNavM)(ReactNavigation.navErrDialog(args));
};
var navGoBack = function (dictMonadNavM) {
    return liftNavM(dictMonadNavM)(ReactNavigation.navGoBack);
};
var navGoBackToKey = function (dictMonadNavM) {
    return function (key) {
        return liftNavM(dictMonadNavM)(ReactNavigation.navGoBackToKey(key));
    };
};
var navNavigate = function (dictHasRoute) {
    return function (dictMonadNavM) {
        return function (r) {
            return liftNavM(dictMonadNavM)(ReactNavigation.navNavigate(dictHasRoute)(r));
        };
    };
};
var navNavigateI = function (dictHasRoute) {
    return function (dictMonadNavM) {
        return function (r) {
            return function (info) {
                return liftNavM(dictMonadNavM)(ReactNavigation.navNavigateI(dictHasRoute)(r)(info));
            };
        };
    };
};
var navPop = function (dictMonadNavM) {
    return function (count) {
        return liftNavM(dictMonadNavM)(ReactNavigation.navPop(count));
    };
};
var navPopToTop = function (dictMonadNavM) {
    return liftNavM(dictMonadNavM)(ReactNavigation.navPopToTop);
};
var navPush = function (dictHasRoute) {
    return function (dictMonadNavM) {
        return function (r) {
            return liftNavM(dictMonadNavM)(ReactNavigation.navPush(dictHasRoute)(r));
        };
    };
};
var navPushI = function (dictHasRoute) {
    return function (dictMonadNavM) {
        return function (r) {
            return function (i) {
                return liftNavM(dictMonadNavM)(ReactNavigation.navPushI(dictHasRoute)(r)(i));
            };
        };
    };
};
var navPushIK = function (dictHasRoute) {
    return function (dictMonadNavM) {
        return function (r) {
            return function (i) {
                return liftNavM(dictMonadNavM)(ReactNavigation.navPushIK(dictHasRoute)(r)(i));
            };
        };
    };
};
var navPushK = function (dictHasRoute) {
    return function (dictMonadNavM) {
        return function (r) {
            return liftNavM(dictMonadNavM)(ReactNavigation.navPushK(dictHasRoute)(r));
        };
    };
};
var navReplace = function (dictHasRoute) {
    return function (dictMonadNavM) {
        return function (r) {
            return liftNavM(dictMonadNavM)(ReactNavigation.navReplace(dictHasRoute)(r));
        };
    };
};
var navReplaceI = function (dictHasRoute) {
    return function (dictMonadNavM) {
        return function (r) {
            return function (i) {
                return liftNavM(dictMonadNavM)(ReactNavigation.navReplaceI(dictHasRoute)(r)(i));
            };
        };
    };
};
module.exports = {
    liftNavM: liftNavM,
    MonadNavM: MonadNavM,
    navPopToTop: navPopToTop,
    navPop: navPop,
    navReplace: navReplace,
    navReplaceI: navReplaceI,
    navPush: navPush,
    navPushK: navPushK,
    navPushI: navPushI,
    navPushIK: navPushIK,
    navGoBack: navGoBack,
    navGoBackToKey: navGoBackToKey,
    navDialog: navDialog,
    navDialogI: navDialogI,
    navDialogIC: navDialogIC,
    navErrDialog: navErrDialog,
    navNavigate: navNavigate,
    navNavigateI: navNavigateI,
    monadNMNavM: monadNMNavM
};
