// Generated by purs version 0.13.8
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var ZAP_Dart_Client_Components_External_EnhancedModal = require("../ZAP.Dart.Client.Components.External.EnhancedModal/index.js");
var ZAP_Dart_Client_Components_FullPageSpinner = require("../ZAP.Dart.Client.Components.FullPageSpinner/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1) ])
};
var globalConsumer = React_Basic.consumer($foreign.globalContext);
var component = React_Basic.createComponent("GlobalProvider");
var globalProvider = (function () {
    var updateTotalPoints = function (self) {
        return function (points) {
            return self.setState(function (v) {
                return {
                    totalPoints: Data_Nullable.toNullable(points),
                    updateTotalPoints: v.updateTotalPoints,
                    setLoading: v.setLoading,
                    loading: v.loading,
                    setDialog: v.setDialog,
                    dialog: v.dialog
                };
            });
        };
    };
    var setLoading = function (self) {
        return function (bool) {
            return self.setState(function (v) {
                return {
                    totalPoints: v.totalPoints,
                    updateTotalPoints: v.updateTotalPoints,
                    setLoading: v.setLoading,
                    loading: bool,
                    setDialog: v.setDialog,
                    dialog: v.dialog
                };
            });
        };
    };
    var setDialog = function (self) {
        return function (dialog) {
            return self.setState(function (v) {
                return {
                    totalPoints: v.totalPoints,
                    updateTotalPoints: v.updateTotalPoints,
                    setLoading: v.setLoading,
                    loading: v.loading,
                    setDialog: v.setDialog,
                    dialog: dialog
                };
            });
        };
    };
    var render = function (self) {
        return React_Basic_Native_Generated.view()({
            style: sheet.container,
            children: [ ZAP_Dart_Client_Components_External_EnhancedModal.enhancedModal_()({
                animationIn: "fadeIn",
                animationOut: "fadeOut",
                isVisible: Data_Maybe.isJust(Data_Nullable.toMaybe(self.state.dialog)),
                children: Data_Maybe.maybe(Data_Monoid.mempty(Data_Monoid.monoidArray))(Control_Applicative.pure(Control_Applicative.applicativeArray))(Data_Nullable.toMaybe(self.state.dialog))
            }), React_Basic.provider($foreign.globalContext)({
                totalPoints: self.state.totalPoints,
                updateTotalPoints: updateTotalPoints(self),
                setLoading: setLoading(self),
                loading: self.state.loading,
                setDialog: setDialog(self),
                dialog: self.state.dialog
            })([ React_Basic.keyed("app")(self.props.children), ZAP_Dart_Client_Components_FullPageSpinner.fullPageSpinner({
                show: self.state.loading
            }) ]) ]
        });
    };
    return React_Basic.toReactComponent()(Control_Category.identity(Control_Category.categoryFn))(component)({
        initialState: $foreign.initialGlobal,
        render: render
    });
})();
module.exports = {
    globalProvider: globalProvider,
    globalConsumer: globalConsumer,
    globalContext: $foreign.globalContext
};
