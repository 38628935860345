// Generated by purs version 0.13.8
"use strict";
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ZAP_Dart_Client_Assets = require("../ZAP.Dart.Client.Assets/index.js");
var ZAP_Dart_Client_Components_Button = require("../ZAP.Dart.Client.Components.Button/index.js");
var ZAP_Dart_Client_Components_Image = require("../ZAP.Dart.Client.Components.Image/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1) ]),
    scrollContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(30), React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(108), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(30), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    mainText: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(20), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)) ]),
    subText: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(40), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)) ]),
    button: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.maxWidth(React_Basic_Native_Styles.intMaxWidth)(244), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)) ]),
    image: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(248), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(188) ])
};
var component = React_Basic.createComponent("NetworkErrorPage");
var networkErrorPage = (function () {
    var render = function (retry) {
        return React_Basic_Native_Generated.scrollView()({
            style: sheet.container,
            contentContainerStyle: sheet.scrollContainer,
            children: [ ZAP_Dart_Client_Components_Image.image({
                style: sheet.image,
                source: ZAP_Dart_Client_Assets.lostConnection,
                resizeMode: React_Basic_Native_Styles.contain
            }), ZAP_Dart_Client_Components_Typography.h4({
                color: ZAP_Dart_Client_Styles_Colors.fg,
                style: sheet.mainText,
                value: "We've lost connection."
            }), ZAP_Dart_Client_Components_Typography.b1({
                color: ZAP_Dart_Client_Styles_Colors.fg,
                style: sheet.subText,
                value: "Please make sure you're connected to the internet"
            }), ZAP_Dart_Client_Components_Button.primaryButton({
                style: sheet.button,
                disabled: false,
                inverted: false,
                size: ZAP_Dart_Client_Components_Button.lg,
                onPress: retry,
                title: "Try Again"
            }) ]
        });
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    networkErrorPage: networkErrorPage
};
