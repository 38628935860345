// Generated by purs version 0.13.8
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Bounded = require("../Data.Bounded/index.js");
var Data_Date_Component = require("../Data.Date.Component/index.js");
var Data_Enum = require("../Data.Enum/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Time_Duration = require("../Data.Time.Duration/index.js");
var $$Date = (function () {
    function $$Date(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    $$Date.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new $$Date(value0, value1, value2);
            };
        };
    };
    return $$Date;
})();
var year = function (v) {
    return v.value0;
};
var weekday = function (v) {
    var n = $foreign.calcWeekday(v.value0, Data_Enum.fromEnum(Data_Date_Component.boundedEnumMonth)(v.value1), v.value2);
    var $41 = n === 0;
    if ($41) {
        return Data_Maybe.fromJust()(Data_Enum.toEnum(Data_Date_Component.boundedEnumWeekday)(7));
    };
    return Data_Maybe.fromJust()(Data_Enum.toEnum(Data_Date_Component.boundedEnumWeekday)(n));
};
var showDate = new Data_Show.Show(function (v) {
    return "(Date " + (Data_Show.show(Data_Date_Component.showYear)(v.value0) + (" " + (Data_Show.show(Data_Date_Component.showMonth)(v.value1) + (" " + (Data_Show.show(Data_Date_Component.showDay)(v.value2) + ")")))));
});
var month = function (v) {
    return v.value1;
};
var isLeapYear = function (y) {
    var y$prime = Data_Enum.fromEnum(Data_Date_Component.boundedEnumYear)(y);
    return Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(y$prime)(4) === 0 && (Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(y$prime)(400) === 0 || !(Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(y$prime)(100) === 0));
};
var lastDayOfMonth = function (y) {
    return function (m) {
        var unsafeDay = (function () {
            var $108 = Data_Maybe.fromJust();
            var $109 = Data_Enum.toEnum(Data_Date_Component.boundedEnumDay);
            return function ($110) {
                return $108($109($110));
            };
        })();
        if (m instanceof Data_Date_Component.January) {
            return unsafeDay(31);
        };
        if (m instanceof Data_Date_Component.February) {
            if (isLeapYear(y)) {
                return unsafeDay(29);
            };
            if (Data_Boolean.otherwise) {
                return unsafeDay(28);
            };
        };
        if (m instanceof Data_Date_Component.March) {
            return unsafeDay(31);
        };
        if (m instanceof Data_Date_Component.April) {
            return unsafeDay(30);
        };
        if (m instanceof Data_Date_Component.May) {
            return unsafeDay(31);
        };
        if (m instanceof Data_Date_Component.June) {
            return unsafeDay(30);
        };
        if (m instanceof Data_Date_Component.July) {
            return unsafeDay(31);
        };
        if (m instanceof Data_Date_Component.August) {
            return unsafeDay(31);
        };
        if (m instanceof Data_Date_Component.September) {
            return unsafeDay(30);
        };
        if (m instanceof Data_Date_Component.October) {
            return unsafeDay(31);
        };
        if (m instanceof Data_Date_Component.November) {
            return unsafeDay(30);
        };
        if (m instanceof Data_Date_Component.December) {
            return unsafeDay(31);
        };
        throw new Error("Failed pattern match at Data.Date (line 127, column 22 - line 141, column 27): " + [ m.constructor.name ]);
    };
};
var eqDate = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(Data_Date_Component.eqYear)(x.value0)(y.value0) && Data_Eq.eq(Data_Date_Component.eqMonth)(x.value1)(y.value1) && Data_Eq.eq(Data_Date_Component.eqDay)(x.value2)(y.value2);
    };
});
var ordDate = new Data_Ord.Ord(function () {
    return eqDate;
}, function (x) {
    return function (y) {
        var v = Data_Ord.compare(Data_Date_Component.ordYear)(x.value0)(y.value0);
        if (v instanceof Data_Ordering.LT) {
            return Data_Ordering.LT.value;
        };
        if (v instanceof Data_Ordering.GT) {
            return Data_Ordering.GT.value;
        };
        var v1 = Data_Ord.compare(Data_Date_Component.ordMonth)(x.value1)(y.value1);
        if (v1 instanceof Data_Ordering.LT) {
            return Data_Ordering.LT.value;
        };
        if (v1 instanceof Data_Ordering.GT) {
            return Data_Ordering.GT.value;
        };
        return Data_Ord.compare(Data_Date_Component.ordDay)(x.value2)(y.value2);
    };
});
var enumDate = new Data_Enum.Enum(function () {
    return ordDate;
}, function (v) {
    var pm = Data_Enum.pred(Data_Date_Component.enumMonth)(v.value1);
    var pd = Data_Enum.pred(Data_Date_Component.enumDay)(v.value2);
    var y$prime = (function () {
        var $73 = Data_Maybe.isNothing(pd) && Data_Maybe.isNothing(pm);
        if ($73) {
            return Data_Enum.pred(Data_Date_Component.enumYear)(v.value0);
        };
        return new Data_Maybe.Just(v.value0);
    })();
    var m$prime = (function () {
        var $74 = Data_Maybe.isNothing(pd);
        if ($74) {
            return Data_Maybe.fromMaybe(Data_Date_Component.December.value)(pm);
        };
        return v.value1;
    })();
    var l = lastDayOfMonth(v.value0)(m$prime);
    var d$prime = (function () {
        var $75 = Data_Maybe.isNothing(pd);
        if ($75) {
            return new Data_Maybe.Just(l);
        };
        return pd;
    })();
    return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)($$Date.create)(y$prime))(Control_Applicative.pure(Data_Maybe.applicativeMaybe)(m$prime)))(d$prime);
}, function (v) {
    var sm = Data_Enum.succ(Data_Date_Component.enumMonth)(v.value1);
    var l = lastDayOfMonth(v.value0)(v.value1);
    var sd = (function () {
        var v1 = Data_Enum.succ(Data_Date_Component.enumDay)(v.value2);
        var $80 = Data_Ord.greaterThan(Data_Maybe.ordMaybe(Data_Date_Component.ordDay))(v1)(new Data_Maybe.Just(l));
        if ($80) {
            return Data_Maybe.Nothing.value;
        };
        return v1;
    })();
    var m$prime = (function () {
        var $81 = Data_Maybe.isNothing(sd);
        if ($81) {
            return Data_Maybe.fromMaybe(Data_Date_Component.January.value)(sm);
        };
        return v.value1;
    })();
    var y$prime = (function () {
        var $82 = Data_Maybe.isNothing(sd) && Data_Maybe.isNothing(sm);
        if ($82) {
            return Data_Enum.succ(Data_Date_Component.enumYear)(v.value0);
        };
        return new Data_Maybe.Just(v.value0);
    })();
    var d$prime = (function () {
        var $83 = Data_Maybe.isNothing(sd);
        if ($83) {
            return Data_Enum.toEnum(Data_Date_Component.boundedEnumDay)(1);
        };
        return sd;
    })();
    return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)($$Date.create)(y$prime))(Control_Applicative.pure(Data_Maybe.applicativeMaybe)(m$prime)))(d$prime);
});
var diff = function (dictDuration) {
    return function (v) {
        return function (v1) {
            return Data_Time_Duration.toDuration(dictDuration)($foreign.calcDiff(v.value0, Data_Enum.fromEnum(Data_Date_Component.boundedEnumMonth)(v.value1), v.value2, v1.value0, Data_Enum.fromEnum(Data_Date_Component.boundedEnumMonth)(v1.value1), v1.value2));
        };
    };
};
var day = function (v) {
    return v.value2;
};
var canonicalDate = function (y) {
    return function (m) {
        return function (d) {
            var mkDate = function (y$prime) {
                return function (m$prime) {
                    return function (d$prime) {
                        return new $$Date(y$prime, Data_Maybe.fromJust()(Data_Enum.toEnum(Data_Date_Component.boundedEnumMonth)(m$prime)), d$prime);
                    };
                };
            };
            return $foreign.canonicalDateImpl(mkDate, y, Data_Enum.fromEnum(Data_Date_Component.boundedEnumMonth)(m), d);
        };
    };
};
var exactDate = function (y) {
    return function (m) {
        return function (d) {
            var dt = new $$Date(y, m, d);
            var $99 = Data_Eq.eq(eqDate)(canonicalDate(y)(m)(d))(dt);
            if ($99) {
                return new Data_Maybe.Just(dt);
            };
            return Data_Maybe.Nothing.value;
        };
    };
};
var boundedDate = new Data_Bounded.Bounded(function () {
    return ordDate;
}, new $$Date(Data_Bounded.bottom(Data_Date_Component.boundedYear), Data_Bounded.bottom(Data_Date_Component.boundedMonth), Data_Bounded.bottom(Data_Date_Component.boundedDay)), new $$Date(Data_Bounded.top(Data_Date_Component.boundedYear), Data_Bounded.top(Data_Date_Component.boundedMonth), Data_Bounded.top(Data_Date_Component.boundedDay)));
var adjust = function (v) {
    return function (date) {
        var adj = function (v1) {
            return function (v2) {
                if (v1 === 0) {
                    return new Data_Maybe.Just(v2);
                };
                var j = v1 + Data_Enum.fromEnum(Data_Date_Component.boundedEnumDay)(v2.value2) | 0;
                var low = j < 1;
                var l = lastDayOfMonth(v2.value0)((function () {
                    if (low) {
                        return Data_Maybe.fromMaybe(Data_Date_Component.December.value)(Data_Enum.pred(Data_Date_Component.enumMonth)(v2.value1));
                    };
                    return v2.value1;
                })());
                var hi = j > Data_Enum.fromEnum(Data_Date_Component.boundedEnumDay)(l);
                var i$prime = (function () {
                    if (low) {
                        return j;
                    };
                    if (hi) {
                        return (j - Data_Enum.fromEnum(Data_Date_Component.boundedEnumDay)(l) | 0) - 1 | 0;
                    };
                    if (Data_Boolean.otherwise) {
                        return 0;
                    };
                    throw new Error("Failed pattern match at Data.Date (line 101, column 9 - line 103, column 28): " + [  ]);
                })();
                var dt$prime = (function () {
                    if (low) {
                        return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Enum.pred(enumDate))(Data_Functor.map(Data_Maybe.functorMaybe)($$Date.create(v2.value0)(v2.value1))(Data_Enum.toEnum(Data_Date_Component.boundedEnumDay)(1)));
                    };
                    if (hi) {
                        return Data_Enum.succ(enumDate)(new $$Date(v2.value0, v2.value1, l));
                    };
                    if (Data_Boolean.otherwise) {
                        return Data_Functor.map(Data_Maybe.functorMaybe)($$Date.create(v2.value0)(v2.value1))(Data_Enum.toEnum(Data_Date_Component.boundedEnumDay)(j));
                    };
                    throw new Error("Failed pattern match at Data.Date (line 104, column 9 - line 106, column 48): " + [  ]);
                })();
                return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(adj(i$prime))(dt$prime);
            };
        };
        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Int.fromNumber(v))(Data_Function.flip(adj)(date));
    };
};
module.exports = {
    canonicalDate: canonicalDate,
    exactDate: exactDate,
    year: year,
    month: month,
    day: day,
    weekday: weekday,
    diff: diff,
    isLeapYear: isLeapYear,
    lastDayOfMonth: lastDayOfMonth,
    adjust: adjust,
    eqDate: eqDate,
    ordDate: ordDate,
    boundedDate: boundedDate,
    showDate: showDate,
    enumDate: enumDate
};
