// Generated by purs version 0.13.8
"use strict";
var Control_Apply = require("../Control.Apply/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Parallel_Class = require("../Control.Parallel.Class/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var parTraverse_ = function (dictParallel) {
    return function (dictFoldable) {
        return function (f) {
            var $17 = Control_Parallel_Class.sequential(dictParallel);
            var $18 = Data_Foldable.traverse_(dictParallel.Applicative1())(dictFoldable)((function () {
                var $20 = Control_Parallel_Class.parallel(dictParallel);
                return function ($21) {
                    return $20(f($21));
                };
            })());
            return function ($19) {
                return $17($18($19));
            };
        };
    };
};
var parTraverse = function (dictParallel) {
    return function (dictTraversable) {
        return function (f) {
            var $22 = Control_Parallel_Class.sequential(dictParallel);
            var $23 = Data_Traversable.traverse(dictTraversable)(dictParallel.Applicative1())((function () {
                var $25 = Control_Parallel_Class.parallel(dictParallel);
                return function ($26) {
                    return $25(f($26));
                };
            })());
            return function ($24) {
                return $22($23($24));
            };
        };
    };
};
var parSequence_ = function (dictParallel) {
    return function (dictFoldable) {
        return parTraverse_(dictParallel)(dictFoldable)(Control_Category.identity(Control_Category.categoryFn));
    };
};
var parSequence = function (dictParallel) {
    return function (dictTraversable) {
        return parTraverse(dictParallel)(dictTraversable)(Control_Category.identity(Control_Category.categoryFn));
    };
};
var parOneOfMap = function (dictParallel) {
    return function (dictAlternative) {
        return function (dictFoldable) {
            return function (dictFunctor) {
                return function (f) {
                    var $27 = Control_Parallel_Class.sequential(dictParallel);
                    var $28 = Data_Foldable.oneOfMap(dictFoldable)(dictAlternative.Plus1())((function () {
                        var $30 = Control_Parallel_Class.parallel(dictParallel);
                        return function ($31) {
                            return $30(f($31));
                        };
                    })());
                    return function ($29) {
                        return $27($28($29));
                    };
                };
            };
        };
    };
};
var parOneOf = function (dictParallel) {
    return function (dictAlternative) {
        return function (dictFoldable) {
            return function (dictFunctor) {
                var $32 = Control_Parallel_Class.sequential(dictParallel);
                var $33 = Data_Foldable.oneOfMap(dictFoldable)(dictAlternative.Plus1())(Control_Parallel_Class.parallel(dictParallel));
                return function ($34) {
                    return $32($33($34));
                };
            };
        };
    };
};
var parApply = function (dictParallel) {
    return function (mf) {
        return function (ma) {
            return Control_Parallel_Class.sequential(dictParallel)(Control_Apply.apply((dictParallel.Applicative1()).Apply0())(Control_Parallel_Class.parallel(dictParallel)(mf))(Control_Parallel_Class.parallel(dictParallel)(ma)));
        };
    };
};
module.exports = {
    parApply: parApply,
    parTraverse: parTraverse,
    parTraverse_: parTraverse_,
    parSequence: parSequence,
    parSequence_: parSequence_,
    parOneOf: parOneOf,
    parOneOfMap: parOneOfMap
};
