/* eslint-disable */

import React, { useEffect, useState } from 'react';
import * as Font from 'expo-font';

import { app as App } from './output/ZAP.Dart.Client.App';

const fonts = {
  'ManRope-Regular': require('./assets/fonts/ManRope/ManRope-Regular.ttf'),
  'ManRope-Bold': require('./assets/fonts/ManRope/ManRope-Bold.ttf'),
  'ManRope-Semibold': require('./assets/fonts/ManRope/ManRope-SemiBold.ttf'),
  'ManRope-Extrabold': require('./assets/fonts/ManRope/ManRope-ExtraBold.ttf'),
  'ManRope-Light': require('./assets/fonts/ManRope/ManRope-Light.ttf'),
  'UbuntuMono-Regular': require('./assets/fonts/UbuntuMono/UbuntuMono-Regular.ttf'),
  'UbuntuMono-Italic': require('./assets/fonts/UbuntuMono/UbuntuMono-Italic.ttf'),
  'UbuntuMono-Bold': require('./assets/fonts/UbuntuMono/UbuntuMono-Bold.ttf'),
  darticon: require('./assets/fonts/darticon.ttf'),
};

export default () => {
  const [fontLoaded, setFontLoaded] = useState(false);
  useEffect(() => {
    // This patches a safari bug where loadAsync fails even if successful
    const loadFont = async () => {
      try {
        await Font.loadAsync(fonts);
      } catch (err) {
        console.log(err);
      } finally {
        setFontLoaded(true);
      }
    };
    loadFont();
  }, []);
  return fontLoaded && <App />;
};
