// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var ReactNavigation = require("../ReactNavigation/index.js");
var ReactNavigation_AuthFlow = require("../ReactNavigation.AuthFlow/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ReactNavigation_Utils_HOCs_WithPageProps = require("../ReactNavigation.Utils.HOCs.WithPageProps/index.js");
var ReactNavigation_Utils_NavBarCreators = require("../ReactNavigation.Utils.NavBarCreators/index.js");
var ReactNavigation_Utils_PageCreators = require("../ReactNavigation.Utils.PageCreators/index.js");
var ReactNavigation_Utils_RouterConfigs = require("../ReactNavigation.Utils.RouterConfigs/index.js");
var ZAP_Dart_Client_Components_NavBar_CartMgtNavBar = require("../ZAP.Dart.Client.Components.NavBar.CartMgtNavBar/index.js");
var ZAP_Dart_Client_Screens_RewardItem = require("../ZAP.Dart.Client.Screens.RewardItem/index.js");
var ZAP_Dart_Client_Screens_RewardList = require("../ZAP.Dart.Client.Screens.RewardList/index.js");
var ZAP_Dart_Client_Screens_Types = require("../ZAP.Dart.Client.Screens.Types/index.js");
var rewardsRs = ReactNavigation.mkRoutes(ReactNavigation.mkRoutesI()(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLNil)()()(new Data_Symbol.IsSymbol(function () {
    return "rewardList";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "rewardItem";
}))));
var navBar = ReactNavigation_Utils_NavBarCreators.mkNavBarContent_(ZAP_Dart_Client_Components_NavBar_CartMgtNavBar.cartMgtNavBar)(ReactNavigation_Types.NBTotalPoints.value);
var rewardsFlow = (function () {
    var rewardItemInfo = function (v) {
        return function (productId) {
            return function (productCodeId) {
                return {
                    initD: new ReactNavigation_AuthFlow.InitD(v.clientApi.getReward, {
                        productId: productId,
                        productCodeId: productCodeId
                    }),
                    apiCalls: {}
                };
            };
        };
    };
    var initRouteF = function (v) {
        return ReactNavigation_AuthFlow.processInfos()(ReactNavigation_AuthFlow.processInfosRLConsapiCalls(ReactNavigation_AuthFlow.processInfosRLNil)(new Data_Symbol.IsSymbol(function () {
            return "apiCalls";
        }))()(ReactNavigation_AuthFlow.applyAuthRLCons(ReactNavigation_AuthFlow.applyAuthRLCons(ReactNavigation_AuthFlow.applyAuthRLNil)(new Data_Symbol.IsSymbol(function () {
            return "getRewards";
        }))()()())(new Data_Symbol.IsSymbol(function () {
            return "getRewardCount";
        }))()()())()()()())({
            apiCalls: {
                getRewards: v.clientApi.getRewards,
                getRewardCount: v.clientApi.getRewardCount
            }
        })(v.authToken);
    };
    var defaultNavigationOptions = ReactNavigation.navigationOptions()()({
        header: Data_Nullable.notNull(navBar)
    });
    var configBuilder = ReactNavigation_Utils_RouterConfigs.stackRcBuilder(ReactNavigation.isRouterConfigOpts()()())()(ReactNavigation.isRouterConfigOpts()()())({
        defaultNavigationOptions: defaultNavigationOptions
    });
    var afterInitF = function (v) {
        return Control_Bind.discard(Control_Bind.discardUnit)(ReactNavigation_AuthFlow.bindAuthNavM)(ReactNavigation_AuthFlow.navPushA(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
            return "rewardItem";
        }))())(ReactNavigation_AuthFlow.monadReaderAuthNavM)(ReactNavigation_AuthFlow.monadNMAuthNavM)(ReactNavigation_AuthFlow.plusAuthNavM)()(ReactNavigation_AuthFlow.processInfosRLConsapiCalls(ReactNavigation_AuthFlow.processInfosRLConsInitD(ReactNavigation_AuthFlow.processInfosRLNil)()(new Data_Symbol.IsSymbol(function () {
            return "initD";
        }))()()())(new Data_Symbol.IsSymbol(function () {
            return "apiCalls";
        }))()(ReactNavigation_AuthFlow.applyAuthRLNil)()()()())(rewardsRs.rewardItem)(rewardItemInfo(v.info)(v.value.value0)(v.value.value1)))(function () {
            return Control_Applicative.pure(ReactNavigation_AuthFlow.applicativeAuthNavM)(ZAP_Dart_Client_Screens_Types.PopToTop.value);
        });
    };
    return ReactNavigation_AuthFlow.createAuthFlow(new Data_Symbol.IsSymbol(function () {
        return "rewardList";
    }))(ReactNavigation.navCreatorStack)(ReactNavigation_AuthFlow.runAuthNavMfirstRouteAsync)(rewardsRs)(rewardsRs.rewardList)(initRouteF)(configBuilder)(afterInitF);
})();
var rewardsScreen = ReactNavigation.createFlowScreen()(rewardsFlow)({
    rewardList: ReactNavigation.routeUiOpts_(ReactNavigation_Utils_PageCreators.routeUiPage(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherClientErr)(ZAP_Dart_Client_Screens_RewardList.rewardList)),
    rewardItem: ReactNavigation.routeUiOpts_(ReactNavigation_Utils_PageCreators.routeUiInitD(ReactNavigation_Utils_HOCs_WithPageProps.onAEitherClientErr)()(ZAP_Dart_Client_Screens_RewardItem.rewardItem))
});
module.exports = {
    rewardsScreen: rewardsScreen
};
