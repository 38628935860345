import React from 'react';
import { func, number } from 'prop-types';
import QrReader from 'react-qr-reader';

const QrScanner = ({ onError, delay, onScan, ...rest }) => (
  <QrReader
    {...rest}
    className="qrscanner"
    onScan={result => {
      if (result) {
        onScan(result)();
      }
    }}
    onError={error => {
      onError(error)();
    }}
    showViewFinder={false}
    delay={delay}
  />
);

QrScanner.propTypes = {
  onScan: func.isRequired,
  onError: func.isRequired,
  delay: number.isRequired,
};

export default QrScanner;
