// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Aff_Class = require("../Effect.Aff.Class/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var ReactNavigation = require("../ReactNavigation/index.js");
var ReactNavigation_Class_MonadNavM = require("../ReactNavigation.Class.MonadNavM/index.js");
var ReactNavigation_Utils_PageCreators = require("../ReactNavigation.Utils.PageCreators/index.js");
var ZAP_Dart_Client_Contexts_AdvancedOpts = require("../ZAP.Dart.Client.Contexts.AdvancedOpts/index.js");
var ZAP_Dart_Client_Flows_Main = require("../ZAP.Dart.Client.Flows.Main/index.js");
var ZAP_Dart_Client_Flows_OnboardingCF = require("../ZAP.Dart.Client.Flows.OnboardingCF/index.js");
var ZAP_Dart_Client_Flows_ScanQr = require("../ZAP.Dart.Client.Flows.ScanQr/index.js");
var ZAP_Dart_Client_Screens_Splash = require("../ZAP.Dart.Client.Screens.Splash/index.js");
var ZAP_Dart_Client_Utilities_Persistence = require("../ZAP.Dart.Client.Utilities.Persistence/index.js");
var ZAP_Dart_Common = require("../ZAP.Dart.Common/index.js");
var saveAuthToken = function (at) {
    return ZAP_Dart_Client_Utilities_Persistence.setItem(ZAP_Dart_Client_Utilities_Persistence.AuthTokenKey.value)(Data_Newtype.unwrap(ZAP_Dart_Common.newTypeAuthT)(at));
};
var rs = ReactNavigation.mkRoutes(ReactNavigation.mkRoutesI()(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLCons(ReactNavigation.mkRoutesRLNil)()()(new Data_Symbol.IsSymbol(function () {
    return "splash";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "scanQr";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "onboarding";
})))()()(new Data_Symbol.IsSymbol(function () {
    return "main";
}))));
var loadAuthToken = Data_Functor.mapFlipped(Effect_Aff.functorAff)(ZAP_Dart_Client_Utilities_Persistence.getItem(ZAP_Dart_Client_Utilities_Persistence.AuthTokenKey.value))(function (v) {
    return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(v)(Data_Newtype.wrap(ZAP_Dart_Common.newTypeAuthT));
});
var clearAuthToken = ZAP_Dart_Client_Utilities_Persistence.removeItem(ZAP_Dart_Client_Utilities_Persistence.AuthTokenKey.value);
var flow = (function () {
    var shouldGoToScan = function (isNU) {
        return function (authCtx) {
            if (isNU) {
                return Control_Apply.applySecond(ReactNavigation.navmApply)(ReactNavigation_Class_MonadNavM.navNavigateI(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
                    return "scanQr";
                }))())(ReactNavigation_Class_MonadNavM.monadNMNavM)(rs.scanQr)(authCtx))(Control_Applicative.pure(ReactNavigation.navmApplicative)(Data_Unit.unit));
            };
            if (!isNU) {
                return Control_Applicative.pure(ReactNavigation.navmApplicative)(Data_Unit.unit);
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.Top (line 55, column 33 - line 57, column 23): " + [ isNU.constructor.name ]);
        };
    };
    var goToOnboarding = function (clientApi) {
        return Control_Bind.bind(ReactNavigation.navmBind)(ReactNavigation_Class_MonadNavM.navNavigateI(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
            return "onboarding";
        }))())(ReactNavigation_Class_MonadNavM.monadNMNavM)(rs.onboarding)(clientApi))(function (v) {
            var authCtx = {
                authToken: v.authToken,
                clientApi: clientApi
            };
            return Control_Bind.discard(Control_Bind.discardUnit)(ReactNavigation.navmBind)(Effect_Aff_Class.liftAff(ReactNavigation.navmMonadAff)(saveAuthToken(v.authToken)))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(ReactNavigation.navmBind)(shouldGoToScan(v.goToClaim)(authCtx))(function () {
                    return goToMain(authCtx);
                });
            });
        });
    };
    var goToMain = function (v) {
        return Control_Bind.discard(Control_Bind.discardUnit)(ReactNavigation.navmBind)(ReactNavigation_Class_MonadNavM.navNavigateI(ReactNavigation.hasRouteI(new Data_Symbol.IsSymbol(function () {
            return "main";
        }))())(ReactNavigation_Class_MonadNavM.monadNMNavM)(rs.main)(v))(function () {
            return Control_Bind.discard(Control_Bind.discardUnit)(ReactNavigation.navmBind)(Effect_Aff_Class.liftAff(ReactNavigation.navmMonadAff)(clearAuthToken))(function () {
                return goToOnboarding(v.clientApi);
            });
        });
    };
    var afterInitF = function (v) {
        return Control_Bind.bind(ReactNavigation.navmBind)(Effect_Aff_Class.liftAff(ReactNavigation.navmMonadAff)(ZAP_Dart_Client_Contexts_AdvancedOpts.getClientApi))(function (clientApi) {
            return Control_Bind.bind(ReactNavigation.navmBind)(Effect_Aff_Class.liftAff(ReactNavigation.navmMonadAff)(loadAuthToken))(function (tokm) {
                if (tokm instanceof Data_Maybe.Just) {
                    return goToMain({
                        authToken: tokm.value0,
                        clientApi: clientApi
                    });
                };
                if (tokm instanceof Data_Maybe.Nothing) {
                    return goToOnboarding(clientApi);
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Flows.Top (line 41, column 5 - line 43, column 42): " + [ tokm.constructor.name ]);
            });
        });
    };
    return ReactNavigation.createFlow_(new Data_Symbol.IsSymbol(function () {
        return "splash";
    }))(ReactNavigation.navCreatorSwitch)(ReactNavigation.isRouterConfigOpts()()())(rs)(rs.splash)(Control_Category.identity(Control_Category.categoryFn))(afterInitF);
})();
var topScreen = ReactNavigation.createFlowRawScreen()(flow)(Data_Unit.unit)(Data_Function["const"](Effect_Class_Console.log(Effect_Class.monadEffectEffect)("DONE")))({
    splash: ReactNavigation.routeUiOpts_(ReactNavigation_Utils_PageCreators.routeUiPage_(ZAP_Dart_Client_Screens_Splash.splash)),
    onboarding: ReactNavigation.routeUiOpts_(ZAP_Dart_Client_Flows_OnboardingCF.onboardingScreen),
    main: ReactNavigation.routeUiOpts_(ZAP_Dart_Client_Flows_Main.mainScreen),
    scanQr: ReactNavigation.routeUiOpts_(ZAP_Dart_Client_Flows_ScanQr.scanQrScreen)
});
module.exports = {
    topScreen: topScreen
};
