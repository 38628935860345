// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNative_Image = require("../ReactNative.Image/index.js");
var ReactNative_Layout = require("../ReactNative.Layout/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_Assets = require("../ZAP.Dart.Client.Assets/index.js");
var ZAP_Dart_Client_Components_Button = require("../ZAP.Dart.Client.Components.Button/index.js");
var ZAP_Dart_Client_Components_EmptyPage = require("../ZAP.Dart.Client.Components.EmptyPage/index.js");
var ZAP_Dart_Client_Components_Image = require("../ZAP.Dart.Client.Components.Image/index.js");
var ZAP_Dart_Client_Components_ProductDetails = require("../ZAP.Dart.Client.Components.ProductDetails/index.js");
var ZAP_Dart_Client_Components_QtyInput = require("../ZAP.Dart.Client.Components.QtyInput/index.js");
var ZAP_Dart_Client_Components_Tile = require("../ZAP.Dart.Client.Components.Tile/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Dimensions = require("../ZAP.Dart.Client.Styles.Dimensions/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(40), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), ZAP_Dart_Client_Styles_Dimensions.screenMaxWidth, React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    headerContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(30), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(25) ]),
    headerTitle: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(10), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.semiBold) ]),
    quantityLeftContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(35), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.backgroundColor(React_Basic_Native_PropTypes_Color.rgba(38)(38)(38)(0.5)), React_Basic_Native_Styles.position(React_Basic_Native_Styles.absolute), React_Basic_Native_Styles.bottom(React_Basic_Native_Styles.intBottom)(0) ]),
    quantityLeft: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)) ]),
    tilePairContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(40), React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(20) ]),
    tileContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.height(React_Basic_Native_Styles.percentHeight)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)) ]),
    tileTitle: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(20), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(8) ]),
    addToCart: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(40), React_Basic_Native_Styles.marginHorizontal(React_Basic_Native_Styles.intMarginHorizontal)(25) ]),
    manRopeLight: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.light) ]),
    manRopeBold: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.bold) ]),
    emptyText: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(100), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)) ])
};
var component = React_Basic.createComponent("CatalogItem");
var catalogItem = function (v) {
    var willUnmount = function (self) {
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
    var renderCatalogItem = function (self) {
        return function (item) {
            var vendorName = Data_Maybe.fromMaybe("")(item.vendorName);
            var showAddedToCartAlert = self.props.showDialog(new ReactNavigation_Types.Confirm({
                title: "Item added to cart",
                message: "Continue browsing or proceed to checkout?",
                primaryActionLabel: "CHECKOUT",
                secondaryActionLabel: new Data_Maybe.Just("BROWSE")
            }))(self.props.onAction(new ReactNavigation_Types.PgSubmit(Data_Unit.unit)));
            var name = Data_Maybe.fromMaybe("")(item.name);
            var itemImage = (function () {
                var imageHeight$prime = (function () {
                    if (self.state.imageContainerWidth < 412.0) {
                        return 183.0;
                    };
                    return (self.state.imageContainerWidth * 183.0) / 412.0;
                })();
                var imageDim = React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.numberWidth)(self.state.imageContainerWidth), React_Basic_Native_Styles.height(React_Basic_Native_Styles.numberHeight)(imageHeight$prime) ]);
                return React_Basic_Native_Generated.view()({
                    onLayout: React_Basic_Events.handler(React_Basic_DOM_Events.nativeEvent)(function (e) {
                        return self.setState(function (v1) {
                            return {
                                imageContainerWidth: (ReactNative_Layout.getLayoutRect(e)).width,
                                qty: v1.qty
                            };
                        });
                    }),
                    children: [ ZAP_Dart_Client_Components_Image.image({
                        style: imageDim,
                        source: Data_Maybe.fromMaybe(ZAP_Dart_Client_Assets.defaultRewardItem)(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(item.photoUri)(ReactNative_Image.imageUri)),
                        resizeMode: React_Basic_Native_Styles.cover
                    }), Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(React_Basic.monoidJSX)(function (value) {
                        return React_Basic_Native_Generated.view()({
                            style: sheet.quantityLeftContainer,
                            children: [ ZAP_Dart_Client_Components_Typography.b2({
                                style: sheet.quantityLeft,
                                color: ZAP_Dart_Client_Styles_Colors.bg,
                                value: value
                            }) ]
                        });
                    })(item.stockCountLabel) ]
                });
            })();
            var header = React_Basic_Native_Generated.view()({
                style: sheet.headerContainer,
                children: [ ZAP_Dart_Client_Components_Typography.b1({
                    style: sheet.headerTitle,
                    color: ZAP_Dart_Client_Styles_Colors.altFg,
                    value: Data_String_Common.toUpper(vendorName)
                }), ZAP_Dart_Client_Components_Typography.h5({
                    style: sheet.manRopeLight,
                    color: ZAP_Dart_Client_Styles_Colors.altFg,
                    value: name
                }) ]
            });
            var cVal = (function () {
                if (item.price instanceof Data_Maybe.Nothing) {
                    return Data_Monoid.mempty(Data_Monoid.monoidString);
                };
                if (item.price instanceof Data_Maybe.Just) {
                    return ZAP_Dart_Client_Screens_Utils.formatPoints(item.price.value0) + " PTS";
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.CatalogItem (line 117, column 12 - line 119, column 49): " + [ item.price.constructor.name ]);
            })();
            var priceAndQty = ZAP_Dart_Client_Components_Tile.tilePair({
                style: sheet.tilePairContainer,
                left: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_Native_Generated.view()({
                    style: sheet.tileContainer,
                    children: [ ZAP_Dart_Client_Components_Typography.b2({
                        style: sheet.tileTitle,
                        color: ZAP_Dart_Client_Styles_Colors.altFg,
                        value: "UNIT PRICE"
                    }), ZAP_Dart_Client_Components_Typography.b2({
                        style: sheet.manRopeBold,
                        color: ZAP_Dart_Client_Styles_Colors.altFg,
                        value: cVal
                    }) ]
                })),
                right: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_Native_Generated.view()({
                    style: sheet.tileContainer,
                    children: [ ZAP_Dart_Client_Components_Typography.b2({
                        style: sheet.tileTitle,
                        color: ZAP_Dart_Client_Styles_Colors.altFg,
                        value: "QUANTITY"
                    }), ZAP_Dart_Client_Components_QtyInput.qtyInput({
                        value: self.state.qty,
                        onChange: function (qty) {
                            return self.setState(function (v1) {
                                return {
                                    imageContainerWidth: v1.imageContainerWidth,
                                    qty: qty
                                };
                            });
                        },
                        addButtonDisabled: false
                    }) ]
                }))
            });
            var addToCart = (function () {
                var onResolve = function (v1) {
                    if (v1 instanceof Data_Either.Right) {
                        return function __do() {
                            self.props.updateTotalPoints(v1.value0.totalPoints)();
                            return showAddedToCartAlert();
                        };
                    };
                    if (v1 instanceof Data_Either.Left) {
                        return self.props.showDialog(ZAP_Dart_Client_Screens_Utils.addToCartErr(v1.value0)(item.name))(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
                    };
                    throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.CatalogItem (line 134, column 19 - line 141, column 24): " + [ v1.constructor.name ]);
                };
                var updateCart = ZAP_Dart_Client_Screens_Utils.mkLApi(v.apiCalls.updateCart({
                    productQuantity: {
                        productId: item.productId,
                        quantity: self.state.qty
                    }
                }))(onResolve);
                return self.props.loadApiP(updateCart);
            })();
            return React_Basic_Native_Generated.scrollView()({
                contentContainerStyle: sheet.container,
                children: [ header, itemImage, priceAndQty, ZAP_Dart_Client_Components_ProductDetails.productDetails({
                    description: item.description,
                    redemptionInstruction: item.redemptionInstruction,
                    terms: item.terms,
                    expiry: item.expiry
                }), ZAP_Dart_Client_Components_Button.primaryButton({
                    style: sheet.addToCart,
                    title: "ADD TO CART",
                    onPress: addToCart,
                    disabled: false,
                    inverted: false,
                    size: ZAP_Dart_Client_Components_Button.lg
                }) ]
            });
        };
    };
    var render = function (self) {
        if (self.props.initD.catalogItem instanceof Data_Maybe.Nothing) {
            return ZAP_Dart_Client_Components_EmptyPage.emptyPage({
                image: ZAP_Dart_Client_Assets.generalError,
                message: {
                    primary: "Sorry, content not found.",
                    secondary: "We can't seem to find the content you're looking for"
                }
            });
        };
        if (self.props.initD.catalogItem instanceof Data_Maybe.Just) {
            return renderCatalogItem(self)(self.props.initD.catalogItem.value0);
        };
        throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.CatalogItem (line 78, column 17 - line 87, column 45): " + [ self.props.initD.catalogItem.constructor.name ]);
    };
    var initialState = {
        qty: 1,
        imageContainerWidth: 0.0
    };
    var dimHandler = function (self) {
        return function (v1) {
            return self.setState(function (v2) {
                var $17 = {};
                for (var $18 in v2) {
                    if ({}.hasOwnProperty.call(v2, $18)) {
                        $17[$18] = v2[$18];
                    };
                };
                $17.deviceWidth = v1.window.width;
                return $17;
            });
        };
    };
    var didMount = function (self) {
        return self.props.updateTotalPoints(self.props.initD.totalPoints);
    };
    return React_Basic.make()(component)({
        initialState: initialState,
        didMount: didMount,
        willUnmount: willUnmount,
        render: render
    });
};
module.exports = {
    catalogItem: catalogItem
};
