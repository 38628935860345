// Generated by purs version 0.13.8
"use strict";
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var styleDisabled = function (disabled) {
    return React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.opacity((function () {
        if (disabled) {
            return 0.4;
        };
        if (!disabled) {
            return 1.0;
        };
        throw new Error("Failed pattern match at ZAP.Dart.Client.Styles.Utils (line 16, column 17 - line 18, column 21): " + [ disabled.constructor.name ]);
    })()) ]);
};
var noStyle = React_Basic_Native_Styles.staticStyles([  ]);
var fromColor = function (color) {
    return color;
};
module.exports = {
    styleDisabled: styleDisabled,
    noStyle: noStyle,
    fromColor: fromColor
};
