// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_Components_Button = require("../ZAP.Dart.Client.Components.Button/index.js");
var ZAP_Dart_Client_Components_DashedInput = require("../ZAP.Dart.Client.Components.DashedInput/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Screens_Templates_StepWrapper = require("../ZAP.Dart.Client.Screens.Templates.StepWrapper/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var ZAP_Dart_Common = require("../ZAP.Dart.Common/index.js");
var sheet = {
    input: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    labelResend: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(48) ]),
    btnResend: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(4) ]),
    errorMsg: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.semiBold), React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(24), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(18) ])
};
var defaultErrorMsg = "Unable to process your request";
var getResendOtpErrMsg = function (e) {
    return {
        title: "Resend Failed",
        message: (function () {
            if (e instanceof ZAP_Dart_Common.ResendOtpErrSessionExpired) {
                return "Session expired";
            };
            return defaultErrorMsg;
        })()
    };
};
var component = React_Basic.createComponent("EnterOtp");
var enterOtpF = function (config) {
    var resendOtp = function (self) {
        var onResolve = function (v) {
            if (v instanceof Data_Either.Right) {
                return self.setState(function (v1) {
                    return {
                        isResendAllowed: v.value0.isResendAllowed,
                        error: v1.error,
                        otp: v1.otp
                    };
                });
            };
            if (v instanceof Data_Either.Left) {
                if (v.value0 instanceof ZAP_Dart_Common.ResendOtpErrMaxResendCountExceeded) {
                    return self.setState(function (v1) {
                        return {
                            isResendAllowed: v1.isResendAllowed,
                            error: new Data_Maybe.Just("Max Resend Count Exceeded"),
                            otp: v1.otp
                        };
                    });
                };
                return self.props.onAction(ReactNavigation_Types.PgSubmit.create(Data_Either.Left.create(getResendOtpErrMsg(v.value0))));
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.EnterOtpF (line 51, column 17 - line 55, column 74): " + [ v.constructor.name ]);
        };
        var apiCall = ZAP_Dart_Client_Screens_Utils.mkLApi(config.resendOtp);
        return self.props.loadApiP(apiCall(onResolve));
    };
    var onSubmit = function (self) {
        return function (otp) {
            return Control_Applicative.when(Effect.applicativeEffect)(Data_String_CodeUnits.length(otp) === 4)(self.props.onAction(ReactNavigation_Types.PgSubmit.create(new Data_Either.Right(otp))));
        };
    };
    var onResend = function (self) {
        return resendOtp(self);
    };
    var render = function (self) {
        return ZAP_Dart_Client_Screens_Templates_StepWrapper.stepWrapper({
            header: new Data_Tuple.Tuple("VERIFY YOUR", "MOBILE NUMBER"),
            message: (function () {
                if (config.mobile instanceof Data_Maybe.Just) {
                    return new Data_Tuple.Tuple("Enter the 4-digit OTP sent to", "+" + config.mobile.value0);
                };
                if (config.mobile instanceof Data_Maybe.Nothing) {
                    return new Data_Tuple.Tuple("", "");
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.EnterOtpF (line 69, column 9 - line 71, column 33): " + [ config.mobile.constructor.name ]);
            })(),
            content: [ ZAP_Dart_Client_Components_DashedInput.dashedInput({
                value: self.state.otp,
                length: 4,
                fontSize: 32,
                letterSpacing: 8,
                onChange: function (text) {
                    return Control_Apply.applySecond(Effect.applyEffect)(self.setState(function (v) {
                        return {
                            otp: text,
                            error: Data_Maybe.Nothing.value,
                            isResendAllowed: v.isResendAllowed
                        };
                    }))(onSubmit(self)(text));
                },
                inputType: ZAP_Dart_Client_Components_DashedInput.Numeric.value,
                onSubmitEditing: React_Basic_Events.handler_(onSubmit(self)(self.state.otp)),
                color: ZAP_Dart_Client_Styles_Colors.altBg,
                style: sheet.input,
                secureTextEntry: false,
                autoFocus: true
            }), ZAP_Dart_Client_Components_Typography.b2({
                color: ZAP_Dart_Client_Styles_Colors.altBg,
                style: sheet.errorMsg,
                value: Data_Maybe.fromMaybe("")(self.state.error)
            }), ZAP_Dart_Client_Components_Typography.b2({
                color: ZAP_Dart_Client_Styles_Colors.altBg,
                style: sheet.labelResend,
                value: "Didn't get the code?"
            }), ZAP_Dart_Client_Components_Button.linkButton({
                onPress: onResend(self),
                title: "RESEND CODE",
                disabled: !self.state.isResendAllowed,
                size: ZAP_Dart_Client_Components_Button.sm,
                style: sheet.btnResend
            }) ]
        });
    };
    var initialState = {
        otp: "",
        error: config.error,
        isResendAllowed: true
    };
    return React_Basic.make()(component)({
        initialState: initialState,
        render: render
    });
};
module.exports = {
    enterOtpF: enterOtpF
};
