// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Monad_Except = require("../Control.Monad.Except/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_DateTime = require("../Data.DateTime/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Effect_Now = require("../Effect.Now/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNative_Clipboard = require("../ReactNative.Clipboard/index.js");
var ReactNative_Image = require("../ReactNative.Image/index.js");
var ReactNative_Layout = require("../ReactNative.Layout/index.js");
var ZAP_Dart_Client_Assets = require("../ZAP.Dart.Client.Assets/index.js");
var ZAP_Dart_Client_Components_EmptyPage = require("../ZAP.Dart.Client.Components.EmptyPage/index.js");
var ZAP_Dart_Client_Components_External_Grayscale = require("../ZAP.Dart.Client.Components.External.Grayscale/index.js");
var ZAP_Dart_Client_Components_External_Markdown = require("../ZAP.Dart.Client.Components.External.Markdown/index.js");
var ZAP_Dart_Client_Components_Image = require("../ZAP.Dart.Client.Components.Image/index.js");
var ZAP_Dart_Client_Components_ProductDetails = require("../ZAP.Dart.Client.Components.ProductDetails/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Dimensions = require("../ZAP.Dart.Client.Styles.Dimensions/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var ZAP_Dart_Common = require("../ZAP.Dart.Common/index.js");
var ZRPC_Types_ZJson = require("../ZRPC.Types.ZJson/index.js");
var ZRPC_ZRPCRead = require("../ZRPC.ZRPCRead/index.js");
var productCodeStyle = [ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.bold), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(2.0), React_Basic_Native_Styles_Text.color(ZAP_Dart_Client_Styles_Colors.primary) ];
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(40), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), ZAP_Dart_Client_Styles_Dimensions.screenMaxWidth, React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    headerContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(30), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(25) ]),
    headerTitle: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(10), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.semiBold) ]),
    manRopeLight: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.light) ]),
    manRopeBold: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.bold) ]),
    itemImageContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(30), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(30) ]),
    redemptionContainerHeader: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.semiBold), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(5), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)) ]),
    redemptionContainerBody: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.regular), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(5), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)) ]),
    redemptionCodeButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.borderRadius(5), React_Basic_Native_Styles.backgroundColor(React_Basic_Native_PropTypes_Color.rgb(247)(247)(249)), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(30), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(30) ]),
    codeDisplay: React_Basic_Native_Styles.staticStyles(Data_Array.snoc(productCodeStyle)(React_Basic_Native_Styles_Text.fontSize(28))),
    markdownCodeContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    imageCodeContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(150) ]),
    expiryLabel: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.semiBold), React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(8), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(4) ]),
    expiryDate: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)) ])
};
var expiryDetails = function (item) {
    return function (now) {
        var label = function (value) {
            return function (color) {
                return ZAP_Dart_Client_Components_Typography.b1({
                    style: sheet.expiryLabel,
                    color: color,
                    value: value
                });
            };
        };
        var expiryDate = function (value) {
            return ZAP_Dart_Client_Components_Typography.b1({
                style: sheet.expiryDate,
                color: React_Basic_Native_PropTypes_Color.black,
                value: value
            });
        };
        return ZAP_Dart_Client_Screens_Utils.matchRewardExpiry(item)(new Data_Maybe.Just(now))({
            valid: function (expiry) {
                return React_Basic_Native_Generated.view_([ label("Reward is valid until")(ZAP_Dart_Client_Styles_Colors.secondary), expiryDate(ZAP_Dart_Client_Screens_Utils.formatDate(Data_DateTime.date(expiry))) ]);
            },
            expired: function (expiry) {
                return React_Basic_Native_Generated.view_([ label("Reward expired on")(React_Basic_Native_PropTypes_Color.black), expiryDate(ZAP_Dart_Client_Screens_Utils.formatDate(Data_DateTime.date(expiry))) ]);
            },
            redeemed: function (redeemedAt) {
                return React_Basic_Native_Generated.view_([ label("Successfully redeemed on")(React_Basic_Native_PropTypes_Color.rgb(90)(166)(75)), expiryDate(ZAP_Dart_Client_Screens_Utils.formatDate(Data_DateTime.date(redeemedAt))) ]);
            },
            "default": React_Basic.fragment([  ])
        });
    };
};
var component = React_Basic.createComponent("RewardItem");
var rewardItem = function (v) {
    var now = Effect_Unsafe.unsafePerformEffect(Effect_Now.nowDateTime);
    var renderRewardItem = function (self) {
        return function (reward) {
            var vendorName = Data_Maybe.fromMaybe("")(reward.vendorName);
            var readRenderType = (function () {
                var $19 = ZRPC_ZRPCRead.zrpcRead(ZAP_Dart_Common.zrpcReadRenderType);
                var $20 = Data_Newtype.unwrap(ZRPC_Types_ZJson.newtypeZJson);
                return function ($21) {
                    return Data_Either.hush(Control_Monad_Except.runExcept($19($20($21))));
                };
            })();
            var redemptionCode = React_Basic_Native_Generated.touchableOpacity()({
                onPress: React_Basic_Events.handler_(ReactNative_Clipboard.setString(reward.productCode)),
                disabled: !ZAP_Dart_Client_Screens_Utils.isRewardValid(reward)(new Data_Maybe.Just(now)),
                style: sheet.redemptionCodeButton,
                children: [ ZAP_Dart_Client_Components_Typography.b2({
                    style: sheet.redemptionContainerHeader,
                    color: ZAP_Dart_Client_Styles_Colors.altFg,
                    value: Data_String_Common.toUpper("Redemption Code")
                }), ZAP_Dart_Client_Components_Typography.b2({
                    style: sheet.redemptionContainerBody,
                    color: ZAP_Dart_Client_Styles_Colors.altFg,
                    value: "Use this code when claiming your reward!\x0aRead instructions on how to claim below.\x0a\x0a\x0a" + reward.codeLabel
                }), (function () {
                    var v1 = readRenderType(reward.renderType);
                    if (v1 instanceof Data_Maybe.Just) {
                        if (v1.value0 instanceof ZAP_Dart_Common.Text) {
                            return ZAP_Dart_Client_Components_Typography.h2({
                                style: sheet.codeDisplay,
                                color: (function () {
                                    var v2 = ZAP_Dart_Client_Screens_Utils.isRewardValid(reward)(new Data_Maybe.Just(now));
                                    if (v2) {
                                        return ZAP_Dart_Client_Styles_Colors.primary;
                                    };
                                    if (!v2) {
                                        return React_Basic_Native_PropTypes_Color.rgb(132)(146)(155);
                                    };
                                    throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.RewardItem (line 169, column 30 - line 171, column 49): " + [ v2.constructor.name ]);
                                })(),
                                value: reward.productCode
                            });
                        };
                        if (v1.value0 instanceof ZAP_Dart_Common.Markdown) {
                            return React_Basic_Native_Generated.view()({
                                style: sheet.markdownCodeContainer,
                                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(ZAP_Dart_Client_Components_External_Markdown.markdown()({
                                    textStyle: Data_Array.snoc(productCodeStyle)(React_Basic_Native_Styles_Text.fontSize(20)),
                                    value: reward.productCode
                                }))
                            });
                        };
                        throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.RewardItem (line 165, column 34 - line 183, column 22): " + [ v1.value0.constructor.name ]);
                    };
                    if (v1 instanceof Data_Maybe.Nothing) {
                        return ZAP_Dart_Client_Components_Image.image({
                            style: sheet.imageCodeContainer,
                            source: ReactNative_Image.imageUri(reward.fallbackImgUrl),
                            resizeMode: React_Basic_Native_Styles.contain
                        });
                    };
                    throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.RewardItem (line 164, column 13 - line 189, column 20): " + [ v1.constructor.name ]);
                })() ]
            });
            var name = Data_Maybe.fromMaybe("")(reward.name);
            var itemImage = (function () {
                var imageHeight$prime = (function () {
                    if (self.state.imageContainerWidth < 412.0) {
                        return 183.0;
                    };
                    return (self.state.imageContainerWidth * 183.0) / 412.0;
                })();
                var imageDim = React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.numberWidth)(self.state.imageContainerWidth), React_Basic_Native_Styles.height(React_Basic_Native_Styles.numberHeight)(imageHeight$prime), React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(24), React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(12), React_Basic_Native_Styles.opacity((function () {
                    var $11 = ZAP_Dart_Client_Screens_Utils.isRewardValid(reward)(new Data_Maybe.Just(now));
                    if ($11) {
                        return 1.0;
                    };
                    return 0.5;
                })()) ]);
                var rewardImage = ZAP_Dart_Client_Components_Image.image({
                    style: imageDim,
                    source: Data_Maybe.fromMaybe(ZAP_Dart_Client_Assets.defaultRewardItem)(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(reward.photoUri)(ReactNative_Image.imageUri)),
                    resizeMode: React_Basic_Native_Styles.cover
                });
                return React_Basic_Native_Generated.view()({
                    style: sheet.itemImageContainer,
                    onLayout: React_Basic_Events.handler(React_Basic_DOM_Events.nativeEvent)(function (e) {
                        return self.setState(function (v1) {
                            return {
                                imageContainerWidth: (ReactNative_Layout.getLayoutRect(e)).width
                            };
                        });
                    }),
                    children: [ (function () {
                        var v1 = ZAP_Dart_Client_Screens_Utils.isRewardValid(reward)(new Data_Maybe.Just(now));
                        if (v1) {
                            return rewardImage;
                        };
                        if (!v1) {
                            return ZAP_Dart_Client_Components_External_Grayscale.grayscale(rewardImage);
                        };
                        throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.RewardItem (line 141, column 15 - line 143, column 47): " + [ v1.constructor.name ]);
                    })(), redemptionCode ]
                });
            })();
            var header = React_Basic_Native_Generated.view()({
                style: sheet.headerContainer,
                children: [ ZAP_Dart_Client_Components_Typography.b1({
                    style: sheet.headerTitle,
                    color: ZAP_Dart_Client_Styles_Colors.altFg,
                    value: Data_String_Common.toUpper(vendorName)
                }), ZAP_Dart_Client_Components_Typography.h5({
                    style: sheet.manRopeLight,
                    color: ZAP_Dart_Client_Styles_Colors.altFg,
                    value: name
                }) ]
            });
            return React_Basic_Native_Generated.scrollView()({
                contentContainerStyle: sheet.container,
                children: [ header, expiryDetails(reward)(now), itemImage, ZAP_Dart_Client_Components_ProductDetails.productDetails({
                    description: reward.description,
                    redemptionInstruction: reward.redemptionInstruction,
                    terms: reward.terms,
                    expiry: reward.expiry
                }) ]
            });
        };
    };
    var render = function (v1) {
        if (v1.props.initD.rewardItem instanceof Data_Maybe.Nothing) {
            return ZAP_Dart_Client_Components_EmptyPage.emptyPage({
                image: ZAP_Dart_Client_Assets.generalError,
                message: {
                    primary: "Sorry, content not found.",
                    secondary: "We can't seem to find the content you're looking for"
                }
            });
        };
        if (v1.props.initD.rewardItem instanceof Data_Maybe.Just) {
            return renderRewardItem(v1)(v1.props.initD.rewardItem.value0);
        };
        throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.RewardItem (line 65, column 62 - line 74, column 38): " + [ v1.props.initD.rewardItem.constructor.name ]);
    };
    var initialState = {
        imageContainerWidth: 0.0
    };
    return React_Basic.make()(component)({
        initialState: initialState,
        render: render
    });
};
module.exports = {
    rewardItem: rewardItem
};
