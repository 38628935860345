// Generated by purs version 0.13.8
"use strict";
var ConsPrefix = function (consPrefix) {
    this.consPrefix = consPrefix;
};
var consPrefix = function (dict) {
    return dict.consPrefix;
};
module.exports = {
    ConsPrefix: ConsPrefix,
    consPrefix: consPrefix
};
