// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Gen_Class = require("../Control.Monad.Gen.Class/index.js");
var Control_Monad_Gen_Common = require("../Control.Monad.Gen.Common/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Array_ST = require("../Data.Array.ST/index.js");
var Data_Bounded = require("../Data.Bounded/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Enum = require("../Data.Enum/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Lazy = require("../Data.Lazy/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_NonEmpty = require("../Data.NonEmpty/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_String_NonEmpty_CodeUnits = require("../Data.String.NonEmpty.CodeUnits/index.js");
var Data_String_NonEmpty_Internal = require("../Data.String.NonEmpty.Internal/index.js");
var Data_String_Pattern = require("../Data.String.Pattern/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Record = require("../Record/index.js");
var Test_QuickCheck_Gen = require("../Test.QuickCheck.Gen/index.js");
var Type_Data_RowList = require("../Type.Data.RowList/index.js");
var Coarbitrary = function (coarbitrary) {
    this.coarbitrary = coarbitrary;
};
var ArbitraryRowList = function (arbitraryRecord) {
    this.arbitraryRecord = arbitraryRecord;
};
var ArbitraryGenericSum = function (arbitraryGenericSum) {
    this.arbitraryGenericSum = arbitraryGenericSum;
};
var Arbitrary = function (arbitrary) {
    this.arbitrary = arbitrary;
};
var coarbitraryNoArguments = new Coarbitrary(function (v) {
    return Control_Category.identity(Control_Category.categoryFn);
});
var coarbitrary = function (dict) {
    return dict.coarbitrary;
};
var coarbitraryArgument = function (dictCoarbitrary) {
    return new Coarbitrary(function (v) {
        return coarbitrary(dictCoarbitrary)(v);
    });
};
var coarbitraryConstructor = function (dictCoarbitrary) {
    return new Coarbitrary(function (v) {
        return coarbitrary(dictCoarbitrary)(v);
    });
};
var coarbitraryProduct = function (dictCoarbitrary) {
    return function (dictCoarbitrary1) {
        return new Coarbitrary(function (v) {
            var $98 = coarbitrary(dictCoarbitrary1)(v.value1);
            var $99 = coarbitrary(dictCoarbitrary)(v.value0);
            return function ($100) {
                return $98($99($100));
            };
        });
    };
};
var coarbitrarySum = function (dictCoarbitrary) {
    return function (dictCoarbitrary1) {
        return new Coarbitrary(function (v) {
            if (v instanceof Data_Generic_Rep.Inl) {
                return coarbitrary(dictCoarbitrary)(v.value0);
            };
            if (v instanceof Data_Generic_Rep.Inr) {
                return coarbitrary(dictCoarbitrary1)(v.value0);
            };
            throw new Error("Failed pattern match at Test.QuickCheck.Arbitrary (line 216, column 1 - line 218, column 38): " + [ v.constructor.name ]);
        });
    };
};
var genericCoarbitrary = function (dictGeneric) {
    return function (dictCoarbitrary) {
        return function (x) {
            return function (g) {
                return Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Generic_Rep.to(dictGeneric))(coarbitrary(dictCoarbitrary)(Data_Generic_Rep.from(dictGeneric)(x))(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Generic_Rep.from(dictGeneric))(g)));
            };
        };
    };
};
var coarbUnit = new Coarbitrary(function (v) {
    return Test_QuickCheck_Gen.perturbGen(1.0);
});
var coarbTuple = function (dictCoarbitrary) {
    return function (dictCoarbitrary1) {
        return new Coarbitrary(function (v) {
            var $101 = coarbitrary(dictCoarbitrary1)(v.value1);
            var $102 = coarbitrary(dictCoarbitrary)(v.value0);
            return function ($103) {
                return $101($102($103));
            };
        });
    };
};
var coarbOrdering = new Coarbitrary(function (v) {
    if (v instanceof Data_Ordering.LT) {
        return Test_QuickCheck_Gen.perturbGen(1.0);
    };
    if (v instanceof Data_Ordering.EQ) {
        return Test_QuickCheck_Gen.perturbGen(2.0);
    };
    if (v instanceof Data_Ordering.GT) {
        return Test_QuickCheck_Gen.perturbGen(3.0);
    };
    throw new Error("Failed pattern match at Test.QuickCheck.Arbitrary (line 114, column 1 - line 117, column 34): " + [ v.constructor.name ]);
});
var coarbNumber = new Coarbitrary(Test_QuickCheck_Gen.perturbGen);
var coarbNonEmpty = function (dictCoarbitrary) {
    return function (dictCoarbitrary1) {
        return new Coarbitrary(function (v) {
            var $104 = coarbitrary(dictCoarbitrary)(v.value1);
            var $105 = coarbitrary(dictCoarbitrary1)(v.value0);
            return function ($106) {
                return $104($105($106));
            };
        });
    };
};
var coarbMaybe = function (dictCoarbitrary) {
    return new Coarbitrary(function (v) {
        if (v instanceof Data_Maybe.Nothing) {
            return Test_QuickCheck_Gen.perturbGen(1.0);
        };
        if (v instanceof Data_Maybe.Just) {
            return coarbitrary(dictCoarbitrary)(v.value0);
        };
        throw new Error("Failed pattern match at Test.QuickCheck.Arbitrary (line 154, column 1 - line 156, column 39): " + [ v.constructor.name ]);
    });
};
var coarbList = function (dictCoarbitrary) {
    return new Coarbitrary(Data_Foldable.foldl(Data_List_Types.foldableList)(function (f) {
        return function (x) {
            var $107 = coarbitrary(dictCoarbitrary)(x);
            return function ($108) {
                return f($107($108));
            };
        };
    })(Control_Category.identity(Control_Category.categoryFn)));
};
var coarbNonEmptyList = function (dictCoarbitrary) {
    return new Coarbitrary(function (v) {
        return coarbitrary(coarbNonEmpty(coarbList(dictCoarbitrary))(dictCoarbitrary))(v);
    });
};
var coarbLazy = function (dictCoarbitrary) {
    return new Coarbitrary(function (a) {
        return coarbitrary(dictCoarbitrary)(Data_Lazy.force(a));
    });
};
var coarbInt = new Coarbitrary(function ($109) {
    return Test_QuickCheck_Gen.perturbGen(Data_Int.toNumber($109));
});
var coarbIdentity = function (dictCoarbitrary) {
    return new Coarbitrary(function (v) {
        return coarbitrary(dictCoarbitrary)(v);
    });
};
var coarbEither = function (dictCoarbitrary) {
    return function (dictCoarbitrary1) {
        return new Coarbitrary(function (v) {
            if (v instanceof Data_Either.Left) {
                return coarbitrary(dictCoarbitrary)(v.value0);
            };
            if (v instanceof Data_Either.Right) {
                return coarbitrary(dictCoarbitrary1)(v.value0);
            };
            throw new Error("Failed pattern match at Test.QuickCheck.Arbitrary (line 161, column 1 - line 163, column 40): " + [ v.constructor.name ]);
        });
    };
};
var coarbChar = new Coarbitrary(function (c) {
    return coarbitrary(coarbInt)(Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(c));
});
var coarbBoolean = new Coarbitrary(function (v) {
    if (v) {
        return Test_QuickCheck_Gen.perturbGen(1.0);
    };
    if (!v) {
        return Test_QuickCheck_Gen.perturbGen(2.0);
    };
    throw new Error("Failed pattern match at Test.QuickCheck.Arbitrary (line 71, column 1 - line 73, column 37): " + [ v.constructor.name ]);
});
var coarbArray = function (dictCoarbitrary) {
    return new Coarbitrary(Data_Foldable.foldl(Data_Foldable.foldableArray)(function (f) {
        return function (x) {
            var $110 = coarbitrary(dictCoarbitrary)(x);
            return function ($111) {
                return f($110($111));
            };
        };
    })(Control_Category.identity(Control_Category.categoryFn)));
};
var coarbNonEmptyArray = function (dictCoarbitrary) {
    return new Coarbitrary((function () {
        var $112 = coarbitrary(coarbArray(dictCoarbitrary));
        return function ($113) {
            return $112(Data_Array_NonEmpty.toArray($113));
        };
    })());
};
var coarbString = new Coarbitrary(function (s) {
    return coarbitrary(coarbArray(coarbMaybe(coarbChar)))(Data_Functor.map(Data_Functor.functorArray)(Data_String_CodeUnits.charAt(0))(Data_String_Common.split(Data_Newtype.wrap(Data_String_Pattern.newtypePattern)(""))(s)));
});
var coarbNonEmptyString = new Coarbitrary((function () {
    var $114 = coarbitrary(coarbString);
    return function ($115) {
        return $114(Data_String_NonEmpty_Internal.toString($115));
    };
})());
var arbitraryRowListNil = new ArbitraryRowList(function (v) {
    return Control_Applicative.pure(Test_QuickCheck_Gen.applicativeGen)({});
});
var arbitraryRecord = function (dict) {
    return dict.arbitraryRecord;
};
var arbitraryRecordInstance = function (dictRowToList) {
    return function (dictArbitraryRowList) {
        return new Arbitrary(arbitraryRecord(dictArbitraryRowList)(Type_Data_RowList.RLProxy.value));
    };
};
var arbitraryNoArguments = new Arbitrary(Control_Applicative.pure(Test_QuickCheck_Gen.applicativeGen)(Data_Generic_Rep.NoArguments.value));
var arbitraryGenericSum = function (dict) {
    return dict.arbitraryGenericSum;
};
var arbitrary = function (dict) {
    return dict.arbitrary;
};
var arbitraryArgument = function (dictArbitrary) {
    return new Arbitrary(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Generic_Rep.Argument)(arbitrary(dictArbitrary)));
};
var arbitraryConstructor = function (dictArbitrary) {
    return new Arbitrary(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Generic_Rep.Constructor)(arbitrary(dictArbitrary)));
};
var arbitraryIdentity = function (dictArbitrary) {
    return new Arbitrary(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Identity.Identity)(arbitrary(dictArbitrary)));
};
var arbitraryLazy = function (dictArbitrary) {
    return new Arbitrary(Control_Bind.bind(Test_QuickCheck_Gen.bindGen)(arbitrary(dictArbitrary))((function () {
        var $116 = Control_Applicative.pure(Test_QuickCheck_Gen.applicativeGen);
        return function ($117) {
            return $116(Data_Lazy.defer(Data_Function["const"]($117)));
        };
    })()));
};
var arbitraryList = function (dictArbitrary) {
    return new Arbitrary(Test_QuickCheck_Gen.sized(function (n) {
        return Control_Bind.bind(Test_QuickCheck_Gen.bindGen)(Test_QuickCheck_Gen.chooseInt(0)(n))(Data_Function.flip(Test_QuickCheck_Gen.listOf)(arbitrary(dictArbitrary)));
    }));
};
var arbitraryProduct = function (dictArbitrary) {
    return function (dictArbitrary1) {
        return new Arbitrary(Control_Apply.apply(Test_QuickCheck_Gen.applyGen)(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Generic_Rep.Product.create)(arbitrary(dictArbitrary)))(arbitrary(dictArbitrary1)));
    };
};
var arbitraryRowListCons = function (dictArbitrary) {
    return function (dictArbitraryRowList) {
        return function (dictLacks) {
            return function (dictCons) {
                return function (dictRowToList) {
                    return function (dictIsSymbol) {
                        return new ArbitraryRowList(function (v) {
                            return Control_Bind.bind(Test_QuickCheck_Gen.bindGen)(arbitrary(dictArbitrary))(function (value) {
                                return Control_Bind.bind(Test_QuickCheck_Gen.bindGen)(arbitraryRecord(dictArbitraryRowList)(Type_Data_RowList.RLProxy.value))(function (previous) {
                                    return Control_Applicative.pure(Test_QuickCheck_Gen.applicativeGen)(Record.insert(dictIsSymbol)()()(Data_Symbol.SProxy.value)(value)(previous));
                                });
                            });
                        });
                    };
                };
            };
        };
    };
};
var arbitrarySum = function (dictArbitrary) {
    return function (dictArbitraryGenericSum) {
        return new Arbitrary(Test_QuickCheck_Gen.oneOf(new Data_NonEmpty.NonEmpty(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Generic_Rep.Inl.create)(arbitrary(dictArbitrary)), Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Generic_Rep.Inr.create))(arbitraryGenericSum(dictArbitraryGenericSum)))));
    };
};
var genericArbitrary = function (dictGeneric) {
    return function (dictArbitrary) {
        return Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Generic_Rep.to(dictGeneric))(arbitrary(dictArbitrary));
    };
};
var arbUnit = new Arbitrary(Control_Applicative.pure(Test_QuickCheck_Gen.applicativeGen)(Data_Unit.unit));
var arbTuple = function (dictArbitrary) {
    return function (dictArbitrary1) {
        return new Arbitrary(Control_Apply.apply(Test_QuickCheck_Gen.applyGen)(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Tuple.Tuple.create)(arbitrary(dictArbitrary)))(arbitrary(dictArbitrary1)));
    };
};
var arbOrdering = new Arbitrary(Test_QuickCheck_Gen.elements(new Data_NonEmpty.NonEmpty(Data_Ordering.LT.value, [ Data_Ordering.EQ.value, Data_Ordering.GT.value ])));
var arbNumber = new Arbitrary(Test_QuickCheck_Gen.uniform);
var arbNonEmpty = function (dictArbitrary) {
    return function (dictArbitrary1) {
        return new Arbitrary(Control_Apply.apply(Test_QuickCheck_Gen.applyGen)(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_NonEmpty.NonEmpty.create)(arbitrary(dictArbitrary1)))(arbitrary(dictArbitrary)));
    };
};
var arbNonEmptyList = function (dictArbitrary) {
    return new Arbitrary(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_List_Types.NonEmptyList)(arbitrary(arbNonEmpty(arbitraryList(dictArbitrary))(dictArbitrary))));
};
var arbMaybe = function (dictArbitrary) {
    return new Arbitrary(Control_Monad_Gen_Common.genMaybe(Test_QuickCheck_Gen.monadGenGen)(arbitrary(dictArbitrary)));
};
var arbInt = new Arbitrary(Test_QuickCheck_Gen.chooseInt(-1000000 | 0)(1000000));
var arbGenSumSum = function (dictArbitrary) {
    return function (dictArbitraryGenericSum) {
        return new ArbitraryGenericSum(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Generic_Rep.Inl.create)(arbitrary(dictArbitrary)) ])(Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Generic_Rep.Inr.create))(arbitraryGenericSum(dictArbitraryGenericSum))));
    };
};
var arbGenSumConstructor = function (dictArbitrary) {
    return new ArbitraryGenericSum([ arbitrary(arbitraryConstructor(dictArbitrary)) ]);
};
var arbFunction = function (dictCoarbitrary) {
    return function (dictArbitrary) {
        return new Arbitrary(Test_QuickCheck_Gen.repeatable(function (a) {
            return coarbitrary(dictCoarbitrary)(a)(arbitrary(dictArbitrary));
        }));
    };
};
var arbEither = function (dictArbitrary) {
    return function (dictArbitrary1) {
        return new Arbitrary(Control_Monad_Gen_Common.genEither(Test_QuickCheck_Gen.monadGenGen)(arbitrary(dictArbitrary))(arbitrary(dictArbitrary1)));
    };
};
var arbChar = new Arbitrary(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_Enum.toEnumWithDefaults(Data_Enum.boundedEnumChar)(Data_Bounded.bottom(Data_Bounded.boundedChar))(Data_Bounded.top(Data_Bounded.boundedChar)))(Test_QuickCheck_Gen.chooseInt(0)(65536)));
var arbBoolean = new Arbitrary(Control_Monad_Gen_Class.chooseBool(Test_QuickCheck_Gen.monadGenGen));
var arbArray = function (dictArbitrary) {
    return new Arbitrary(Test_QuickCheck_Gen.arrayOf(arbitrary(dictArbitrary)));
};
var arbNonEmptyArray = function (dictArbitrary) {
    return new Arbitrary(Control_Bind.bind(Test_QuickCheck_Gen.bindGen)(arbitrary(dictArbitrary))(function (x) {
        return Control_Bind.bind(Test_QuickCheck_Gen.bindGen)(arbitrary(arbArray(dictArbitrary)))(function (xs) {
            return Control_Applicative.pure(Test_QuickCheck_Gen.applicativeGen)(Data_Maybe.fromJust()(Data_Array_NonEmpty.fromArray((function __do() {
                var mxs = Data_Array_ST.unsafeThaw(xs)();
                Data_Array_ST.push(x)(mxs)();
                return Data_Array_ST.unsafeFreeze(mxs)();
            })())));
        });
    }));
};
var arbString = new Arbitrary(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_String_CodeUnits.fromCharArray)(arbitrary(arbArray(arbChar))));
var arbNonEmptyString = new Arbitrary(Control_Apply.apply(Test_QuickCheck_Gen.applyGen)(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(Data_String_NonEmpty_CodeUnits.cons)(arbitrary(arbChar)))(arbitrary(arbString)));
var coarbFunction = function (dictArbitrary) {
    return function (dictCoarbitrary) {
        return new Coarbitrary(function (f) {
            return function (gen) {
                return Control_Bind.bind(Test_QuickCheck_Gen.bindGen)(arbitrary(arbArray(dictArbitrary)))(function (xs) {
                    return coarbitrary(coarbArray(dictCoarbitrary))(Data_Functor.map(Data_Functor.functorArray)(f)(xs))(gen);
                });
            };
        });
    };
};
module.exports = {
    Arbitrary: Arbitrary,
    arbitrary: arbitrary,
    Coarbitrary: Coarbitrary,
    coarbitrary: coarbitrary,
    genericArbitrary: genericArbitrary,
    genericCoarbitrary: genericCoarbitrary,
    ArbitraryGenericSum: ArbitraryGenericSum,
    arbitraryGenericSum: arbitraryGenericSum,
    ArbitraryRowList: ArbitraryRowList,
    arbitraryRecord: arbitraryRecord,
    arbBoolean: arbBoolean,
    coarbBoolean: coarbBoolean,
    arbNumber: arbNumber,
    coarbNumber: coarbNumber,
    arbInt: arbInt,
    coarbInt: coarbInt,
    arbString: arbString,
    coarbString: coarbString,
    arbNonEmptyString: arbNonEmptyString,
    coarbNonEmptyString: coarbNonEmptyString,
    arbChar: arbChar,
    coarbChar: coarbChar,
    arbUnit: arbUnit,
    coarbUnit: coarbUnit,
    arbOrdering: arbOrdering,
    coarbOrdering: coarbOrdering,
    arbArray: arbArray,
    coarbArray: coarbArray,
    arbNonEmptyArray: arbNonEmptyArray,
    coarbNonEmptyArray: coarbNonEmptyArray,
    arbFunction: arbFunction,
    coarbFunction: coarbFunction,
    arbTuple: arbTuple,
    coarbTuple: coarbTuple,
    arbMaybe: arbMaybe,
    coarbMaybe: coarbMaybe,
    arbEither: arbEither,
    coarbEither: coarbEither,
    arbitraryList: arbitraryList,
    coarbList: coarbList,
    arbitraryIdentity: arbitraryIdentity,
    coarbIdentity: coarbIdentity,
    arbitraryLazy: arbitraryLazy,
    coarbLazy: coarbLazy,
    arbNonEmpty: arbNonEmpty,
    coarbNonEmpty: coarbNonEmpty,
    arbNonEmptyList: arbNonEmptyList,
    coarbNonEmptyList: coarbNonEmptyList,
    arbitraryNoArguments: arbitraryNoArguments,
    coarbitraryNoArguments: coarbitraryNoArguments,
    arbGenSumSum: arbGenSumSum,
    arbGenSumConstructor: arbGenSumConstructor,
    arbitrarySum: arbitrarySum,
    coarbitrarySum: coarbitrarySum,
    arbitraryProduct: arbitraryProduct,
    coarbitraryProduct: coarbitraryProduct,
    arbitraryConstructor: arbitraryConstructor,
    coarbitraryConstructor: coarbitraryConstructor,
    arbitraryArgument: arbitraryArgument,
    coarbitraryArgument: coarbitraryArgument,
    arbitraryRowListNil: arbitraryRowListNil,
    arbitraryRowListCons: arbitraryRowListCons,
    arbitraryRecordInstance: arbitraryRecordInstance
};
