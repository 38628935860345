// Generated by purs version 0.13.8
"use strict";
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var ReactNative_AsyncStorage = require("../ReactNative.AsyncStorage/index.js");
var AuthTokenKey = (function () {
    function AuthTokenKey() {

    };
    AuthTokenKey.value = new AuthTokenKey();
    return AuthTokenKey;
})();
var AdvancedOptsKey = (function () {
    function AdvancedOptsKey() {

    };
    AdvancedOptsKey.value = new AdvancedOptsKey();
    return AdvancedOptsKey;
})();
var genericPersistenceKeys = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof AuthTokenKey) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof AdvancedOptsKey) {
        return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Client.Utilities.Persistence (line 20, column 1 - line 20, column 68): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return AuthTokenKey.value;
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return AdvancedOptsKey.value;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Client.Utilities.Persistence (line 20, column 1 - line 20, column 68): " + [ x.constructor.name ]);
});
var showASK = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericPersistenceKeys)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "AuthTokenKey";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "AdvancedOptsKey";
})))));
var getItem = function (pk) {
    return Data_Functor.mapFlipped(Effect_Aff.functorAff)(ReactNative_AsyncStorage.getItem(Data_Show.show(showASK)(pk)))(Data_Nullable.toMaybe);
};
var removeItem = function (pk) {
    return ReactNative_AsyncStorage.removeItem(Data_Show.show(showASK)(pk));
};
var setItem = function (pk) {
    return function (value) {
        return ReactNative_AsyncStorage.setItem(Data_Show.show(showASK)(pk))(value);
    };
};
module.exports = {
    setItem: setItem,
    getItem: getItem,
    removeItem: removeItem,
    AuthTokenKey: AuthTokenKey,
    AdvancedOptsKey: AdvancedOptsKey,
    genericPersistenceKeys: genericPersistenceKeys,
    showASK: showASK
};
