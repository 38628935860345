// Generated by purs version 0.13.8
"use strict";
var React_Basic_Native_Internal = require("../React.Basic.Native.Internal/index.js");
var webView = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("WebView")(props);
    };
};
var webView_ = function (children) {
    return webView()({
        children: children
    });
};
var viewPagerAndroid = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("ViewPagerAndroid")(props);
    };
};
var viewPagerAndroid_ = function (children) {
    return viewPagerAndroid()({
        children: children
    });
};
var view = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("View")(props);
    };
};
var view_ = function (children) {
    return view()({
        children: children
    });
};
var touchableWithoutFeedback = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("TouchableWithoutFeedback")(props);
    };
};
var touchableWithoutFeedback_ = function (children) {
    return touchableWithoutFeedback()({
        children: children
    });
};
var touchableOpacity = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("TouchableOpacity")(props);
    };
};
var touchableOpacity_ = function (children) {
    return touchableOpacity()({
        children: children
    });
};
var touchableNativeFeedback = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("TouchableNativeFeedback")(props);
    };
};
var touchableNativeFeedback_ = function (children) {
    return touchableNativeFeedback()({
        children: children
    });
};
var touchableHighlight = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("TouchableHighlight")(props);
    };
};
var touchableHighlight_ = function (children) {
    return touchableHighlight()({
        children: children
    });
};
var toolbarAndroid = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("ToolbarAndroid")(props);
    };
};
var toolbarAndroid_ = function (children) {
    return toolbarAndroid()({
        children: children
    });
};
var textInput = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("TextInput")(props);
    };
};
var textInput_ = function (children) {
    return textInput()({
        children: children
    });
};
var text = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("Text")(props);
    };
};
var text_ = function (children) {
    return text()({
        children: children
    });
};
var tabBarIOSItem = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("TabBarIOSItem")(props);
    };
};
var tabBarIOSItem_ = function (children) {
    return tabBarIOSItem()({
        children: children
    });
};
var tabBarIOS = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("TabBarIOS")(props);
    };
};
var tabBarIOS_ = function (children) {
    return tabBarIOS()({
        children: children
    });
};
var switchIOS = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("SwitchIOS")(props);
    };
};
var switchIOS_ = function (children) {
    return switchIOS()({
        children: children
    });
};
var $$switch = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("Switch")(props);
    };
};
var switch_ = function (children) {
    return $$switch()({
        children: children
    });
};
var swipeableListView = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("SwipeableListView")(props);
    };
};
var surface = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("Surface")(props);
    };
};
var statusBar = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("StatusBar")(props);
    };
};
var statusBar_ = function (children) {
    return statusBar()({
        children: children
    });
};
var snapshotViewIOS = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("SnapshotViewIOS")(props);
    };
};
var slider = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("Slider")(props);
    };
};
var slider_ = function (children) {
    return slider()({
        children: children
    });
};
var shape = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("Shape")(props);
    };
};
var segmentedControlIOS = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("SegmentedControlIOS")(props);
    };
};
var segmentedControlIOS_ = function (children) {
    return segmentedControlIOS()({
        children: children
    });
};
var scrollView = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("ScrollView")(props);
    };
};
var scrollView_ = function (children) {
    return scrollView()({
        children: children
    });
};
var safeAreaView = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("SafeAreaView")(props);
    };
};
var safeAreaView_ = function (children) {
    return safeAreaView()({
        children: children
    });
};
var refreshControl = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("RefreshControl")(props);
    };
};
var recyclerViewBackedScrollView = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("RecyclerViewBackedScrollView")(props);
    };
};
var recyclerViewBackedScrollView_ = function (children) {
    return recyclerViewBackedScrollView()({
        children: children
    });
};
var progressViewIOS = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("ProgressViewIOS")(props);
    };
};
var progressViewIOS_ = function (children) {
    return progressViewIOS()({
        children: children
    });
};
var progressBarAndroid = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("ProgressBarAndroid")(props);
    };
};
var progressBarAndroid_ = function (children) {
    return progressBarAndroid()({
        children: children
    });
};
var pickerItem = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("PickerItem")(props);
    };
};
var pickerIOSItem = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("PickerIOSItem")(props);
    };
};
var pickerIOSItem_ = function (children) {
    return pickerIOSItem()({
        children: children
    });
};
var pickerIOS = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("PickerIOS")(props);
    };
};
var pickerIOS_ = function (children) {
    return pickerIOS()({
        children: children
    });
};
var picker = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("Picker")(props);
    };
};
var picker_ = function (children) {
    return picker()({
        children: children
    });
};
var navigatorIOS = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("NavigatorIOS")(props);
    };
};
var maskedView = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("MaskedView")(props);
    };
};
var mapView = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("MapView")(props);
    };
};
var mapView_ = function (children) {
    return mapView()({
        children: children
    });
};
var listView = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("ListView")(props);
    };
};
var keyboardAvoidingView = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("KeyboardAvoidingView")(props);
    };
};
var keyboardAvoidingView_ = function (children) {
    return keyboardAvoidingView()({
        children: children
    });
};
var inputAccessoryView = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("InputAccessoryView")(props);
    };
};
var inputAccessoryView_ = function (children) {
    return inputAccessoryView()({
        children: children
    });
};
var imageBackground = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("ImageBackground")(props);
    };
};
var image = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("Image")(props);
    };
};
var group = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("Group")(props);
    };
};
var group_ = function (children) {
    return group()({
        children: children
    });
};
var flatList = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("FlatList")(props);
    };
};
var drawerLayoutAndroid = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("DrawerLayoutAndroid")(props);
    };
};
var datePickerIOS = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("DatePickerIOS")(props);
    };
};
var clippingRectangle = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("ClippingRectangle")(props);
    };
};
var clippingRectangle_ = function (children) {
    return clippingRectangle()({
        children: children
    });
};
var button = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("Button")(props);
    };
};
var activityIndicatorIOS = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("ActivityIndicatorIOS")(props);
    };
};
var activityIndicatorIOS_ = function (children) {
    return activityIndicatorIOS()({
        children: children
    });
};
var activityIndicator = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("ActivityIndicator")(props);
    };
};
var activityIndicator_ = function (children) {
    return activityIndicator()({
        children: children
    });
};
var aRTText = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("ARTText")(props);
    };
};
var aRTText_ = function (children) {
    return aRTText()({
        children: children
    });
};
module.exports = {
    clippingRectangle: clippingRectangle,
    clippingRectangle_: clippingRectangle_,
    group: group,
    group_: group_,
    shape: shape,
    surface: surface,
    aRTText: aRTText,
    aRTText_: aRTText_,
    activityIndicatorIOS: activityIndicatorIOS,
    activityIndicatorIOS_: activityIndicatorIOS_,
    activityIndicator: activityIndicator,
    activityIndicator_: activityIndicator_,
    button: button,
    datePickerIOS: datePickerIOS,
    drawerLayoutAndroid: drawerLayoutAndroid,
    flatList: flatList,
    imageBackground: imageBackground,
    image: image,
    inputAccessoryView: inputAccessoryView,
    inputAccessoryView_: inputAccessoryView_,
    keyboardAvoidingView: keyboardAvoidingView,
    keyboardAvoidingView_: keyboardAvoidingView_,
    listView: listView,
    mapView: mapView,
    mapView_: mapView_,
    maskedView: maskedView,
    navigatorIOS: navigatorIOS,
    pickerIOSItem: pickerIOSItem,
    pickerIOSItem_: pickerIOSItem_,
    pickerIOS: pickerIOS,
    pickerIOS_: pickerIOS_,
    pickerItem: pickerItem,
    picker: picker,
    picker_: picker_,
    progressBarAndroid: progressBarAndroid,
    progressBarAndroid_: progressBarAndroid_,
    progressViewIOS: progressViewIOS,
    progressViewIOS_: progressViewIOS_,
    recyclerViewBackedScrollView: recyclerViewBackedScrollView,
    recyclerViewBackedScrollView_: recyclerViewBackedScrollView_,
    refreshControl: refreshControl,
    scrollView: scrollView,
    scrollView_: scrollView_,
    segmentedControlIOS: segmentedControlIOS,
    segmentedControlIOS_: segmentedControlIOS_,
    slider: slider,
    slider_: slider_,
    snapshotViewIOS: snapshotViewIOS,
    statusBar: statusBar,
    statusBar_: statusBar_,
    swipeableListView: swipeableListView,
    switchIOS: switchIOS,
    switchIOS_: switchIOS_,
    "switch": $$switch,
    switch_: switch_,
    tabBarIOSItem: tabBarIOSItem,
    tabBarIOSItem_: tabBarIOSItem_,
    tabBarIOS: tabBarIOS,
    tabBarIOS_: tabBarIOS_,
    textInput: textInput,
    textInput_: textInput_,
    text: text,
    text_: text_,
    toolbarAndroid: toolbarAndroid,
    toolbarAndroid_: toolbarAndroid_,
    touchableHighlight: touchableHighlight,
    touchableHighlight_: touchableHighlight_,
    touchableNativeFeedback: touchableNativeFeedback,
    touchableNativeFeedback_: touchableNativeFeedback_,
    touchableOpacity: touchableOpacity,
    touchableOpacity_: touchableOpacity_,
    touchableWithoutFeedback: touchableWithoutFeedback,
    touchableWithoutFeedback_: touchableWithoutFeedback_,
    viewPagerAndroid: viewPagerAndroid,
    viewPagerAndroid_: viewPagerAndroid_,
    view: view,
    view_: view_,
    safeAreaView: safeAreaView,
    safeAreaView_: safeAreaView_,
    webView: webView,
    webView_: webView_
};
