// Generated by purs version 0.13.8
"use strict";
var React_Basic_Native_Internal = require("../React.Basic.Native.Internal/index.js");
var StatusBarStyle = function (x) {
    return x;
};
var statusBar = function (dictUnion) {
    return function (props) {
        return React_Basic_Native_Internal.unsafeCreateNativeElement("StatusBar")(props);
    };
};
var lightContent = "light-content";
var $$default = "default";
var darkContent = "dark-content";
module.exports = {
    "default": $$default,
    lightContent: lightContent,
    darkContent: darkContent,
    statusBar: statusBar
};
