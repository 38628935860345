import React from 'react';
import PropTypes from 'prop-types';

const CustomWebView = ({ uri, defaultTitle }) => (
  <iframe
    src={uri}
    title={defaultTitle}
    height="100%"
    width="100%"
    style={{ border: 'none' }} // eslint-disable-line
  />
);

CustomWebView.propTypes = {
  uri: PropTypes.string.isRequired,
  defaultTitle: PropTypes.string.isRequired,
};

export default CustomWebView;
