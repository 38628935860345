// Generated by purs version 0.13.8
"use strict";
var imageUri = function (uri) {
    return {
        uri: uri
    };
};
module.exports = {
    imageUri: imageUri
};
