import React from 'react';

export const initialGlobal = {
  totalPoints: null,
  updateTotalPoints: () => () => {},
  setLoading: () => () => {},
  loading: false,
  setDialog: () => () => {},
  dialog: null,
};

export const GlobalContext = React.createContext(initialGlobal);
