// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native = require("../React.Basic.Native/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNative_Dimensions = require("../ReactNative.Dimensions/index.js");
var ZAP_Dart_Client_Assets = require("../ZAP.Dart.Client.Assets/index.js");
var ZAP_Dart_Client_BuildConfig = require("../ZAP.Dart.Client.BuildConfig/index.js");
var ZAP_Dart_Client_Components_Image = require("../ZAP.Dart.Client.Components.Image/index.js");
var ZAP_Dart_Client_Constants = require("../ZAP.Dart.Client.Constants/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var privacyLinksFg = React_Basic_Native_PropTypes_Color.rgb(159)(159)(159);
var privacyLinksBg = React_Basic_Native_PropTypes_Color.rgb(241)(241)(241);
var sheet = {
    privacyLinksContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.backgroundColor(privacyLinksBg), React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(2), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(8) ]),
    privacyLinkDivider: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.borderRightWidth(1), React_Basic_Native_Styles.borderColor(privacyLinksFg), React_Basic_Native_Styles.marginVertical(React_Basic_Native_Styles.intMarginVertical)(3) ]),
    privacyLinkContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(8) ]),
    privacyLink: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.color(privacyLinksFg), React_Basic_Native_Styles_Text.fontSize(12), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.manRope.semiBold) ]),
    privacyLinkIcon: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(10), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(10), React_Basic_Native_Styles.marginLeft(React_Basic_Native_Styles.intMarginLeft)(4) ])
};
var component = React_Basic.createComponent("PrivacyLinks");
var privacyLinks = (function () {
    var privacyLinksAlignment = React_Basic_Native_Styles.styles(Control_Applicative.pure(Control_Applicative.applicativeArray)((function () {
        if (ReactNative_Dimensions.getWindow.width > ZAP_Dart_Client_Constants.maxScreenWidth) {
            return React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_Styles_Flex.flexEnd(React_Basic_Native_Styles_Flex.justifyContentFlexSE));
        };
        return React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter));
    })()));
    var privacyLinkDivider = React_Basic_Native_Generated.view()({
        style: sheet.privacyLinkDivider
    });
    var privacyLink = function (v) {
        return React_Basic_Native_Generated.touchableOpacity()({
            style: sheet.privacyLinkContainer,
            onPress: React_Basic_Events.handler_(ZAP_Dart_Client_Screens_Utils.openUrl(v.link)),
            children: [ React_Basic_Native_Generated.text()({
                style: sheet.privacyLink,
                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_Native.string(v.title))
            }), (function () {
                if (v.icon instanceof Data_Maybe.Just) {
                    return ZAP_Dart_Client_Components_Image.image({
                        style: sheet.privacyLinkIcon,
                        source: v.icon.value0,
                        resizeMode: React_Basic_Native_Styles.contain
                    });
                };
                if (v.icon instanceof Data_Maybe.Nothing) {
                    return Data_Monoid.mempty(React_Basic.monoidJSX);
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Components.PrivacyLinks (line 99, column 11 - line 106, column 30): " + [ v.icon.constructor.name ]);
            })() ]
        });
    };
    var render = function (props) {
        return Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(ZAP_Dart_Client_BuildConfig.buildConfig.privacyLinks.privacy) && (Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(ZAP_Dart_Client_BuildConfig.buildConfig.privacyLinks.termsAndConditions) && Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(ZAP_Dart_Client_BuildConfig.buildConfig.privacyLinks.adChoices)))(React_Basic_Native_Generated.view())({
            style: React_Basic_Native_Styles["styles'"]([ sheet.privacyLinksContainer, privacyLinksAlignment ]),
            children: [ Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(ZAP_Dart_Client_BuildConfig.buildConfig.privacyLinks.privacy))(privacyLink)({
                link: ZAP_Dart_Client_BuildConfig.buildConfig.privacyLinks.privacy,
                title: "Privacy",
                icon: Data_Maybe.Nothing.value
            }), Data_Monoid.guard(React_Basic.monoidJSX)(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(ZAP_Dart_Client_BuildConfig.buildConfig.privacyLinks.privacy) && (Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(ZAP_Dart_Client_BuildConfig.buildConfig.privacyLinks.termsAndConditions) || Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(ZAP_Dart_Client_BuildConfig.buildConfig.privacyLinks.adChoices)))(privacyLinkDivider), Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(ZAP_Dart_Client_BuildConfig.buildConfig.privacyLinks.termsAndConditions))(privacyLink)({
                link: ZAP_Dart_Client_BuildConfig.buildConfig.privacyLinks.termsAndConditions,
                title: "Terms & Conditions",
                icon: Data_Maybe.Nothing.value
            }), Data_Monoid.guard(React_Basic.monoidJSX)(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(ZAP_Dart_Client_BuildConfig.buildConfig.privacyLinks.termsAndConditions) && Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(ZAP_Dart_Client_BuildConfig.buildConfig.privacyLinks.adChoices))(privacyLinkDivider), Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(ZAP_Dart_Client_BuildConfig.buildConfig.privacyLinks.adChoices))(privacyLink)({
                link: ZAP_Dart_Client_BuildConfig.buildConfig.privacyLinks.adChoices,
                title: "AdChoices",
                icon: new Data_Maybe.Just(ZAP_Dart_Client_Assets.adchoices)
            }) ]
        });
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    privacyLinks: privacyLinks
};
