// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Bounded = require("../Data.Bounded/index.js");
var Data_DateTime = require("../Data.DateTime/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HugeNum = require("../Data.HugeNum/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var ZAP_Dart_Client_ApiClient_Mock = require("../ZAP.Dart.Client.ApiClient.Mock/index.js");
var ZAP_Dart_Client_BuildConfig = require("../ZAP.Dart.Client.BuildConfig/index.js");
var ZAP_Dart_Common = require("../ZAP.Dart.Common/index.js");
var ZRPC_Clients_Trove = require("../ZRPC.Clients.Trove/index.js");
var ZRPC_Types_ZJson = require("../ZRPC.Types.ZJson/index.js");
var ZRPC_ZRPCWrite = require("../ZRPC.ZRPCWrite/index.js");
var verifyOtp = function (dictMonad) {
    return function (r) {
        if (r.otp === "1111") {
            return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Right({
                refId: "12345678",
                isVerified: true
            }));
        };
        if (r.otp === "2222") {
            return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Left(ZAP_Dart_Common.VerifyOtpErrSessionExpired.value));
        };
        if (r.otp === "3333") {
            return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Left(ZAP_Dart_Common.VerifyOtpErrMaxAttemptCountExceeded.value));
        };
        return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Right({
            refId: "12345678",
            isVerified: false
        }));
    };
};
var updateProfile = function (dictMonad) {
    return function (v) {
        return function (v1) {
            return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Right(Data_Unit.unit));
        };
    };
};
var updateCart = function (dictMonad) {
    return function (v) {
        return function (v1) {
            return Control_Applicative.pure(dictMonad.Applicative0())(Data_Either.Right.create({
                cart: {
                    items: [ {
                        productId: "Angz-01",
                        name: new Data_Maybe.Just("Steak"),
                        availableQuantity: new Data_Maybe.Just(10),
                        photoUri: new Data_Maybe.Just("steak.jpg"),
                        vendorColoredLogoUri: Data_Maybe.Nothing.value,
                        vendorName: new Data_Maybe.Just("Angus Steakhouse"),
                        vendorShortName: Data_Maybe.Nothing.value,
                        price: new Data_Maybe.Just(Data_HugeNum.fromNumber(100.0)),
                        description: Data_Maybe.Nothing.value,
                        redemptionInstruction: Data_Maybe.Nothing.value,
                        terms: Data_Maybe.Nothing.value,
                        expiry: Data_Maybe.Nothing.value,
                        codeLabel: "Click to copy",
                        codeType: ZRPC_Clients_Trove.CodeTypeAlphanum.value,
                        quantity: new Data_Maybe.Just(10),
                        stockCountLabel: new Data_Maybe.Just("Out of stock")
                    }, {
                        productId: "Angz-02",
                        name: new Data_Maybe.Just("Rice"),
                        availableQuantity: new Data_Maybe.Just(10),
                        photoUri: new Data_Maybe.Just("rice.jpg"),
                        vendorColoredLogoUri: Data_Maybe.Nothing.value,
                        vendorName: new Data_Maybe.Just("Angus Steakhouse"),
                        vendorShortName: Data_Maybe.Nothing.value,
                        price: new Data_Maybe.Just(Data_HugeNum.fromNumber(50.0)),
                        description: Data_Maybe.Nothing.value,
                        redemptionInstruction: Data_Maybe.Nothing.value,
                        terms: Data_Maybe.Nothing.value,
                        expiry: Data_Maybe.Nothing.value,
                        codeLabel: "Click to copy",
                        codeType: ZRPC_Clients_Trove.CodeTypeAlphanum.value,
                        quantity: new Data_Maybe.Just(5),
                        stockCountLabel: new Data_Maybe.Just("Out of stock")
                    } ]
                },
                totalPoints: new Data_Maybe.Just(Data_HugeNum.fromNumber(100.0))
            }));
        };
    };
};
var transactions = function (prefix) {
    return [ {
        id: prefix + "0",
        refNo: "Z12345678910",
        transactionDate: Data_Bounded.top(Data_DateTime.boundedDateTime),
        status: ZAP_Dart_Common.TxRowStatusCleared.value,
        txType: ZAP_Dart_Common.TxRowTypeCatalogPurchase.value,
        txDetails: {
            skus: [ {
                name: new Data_Maybe.Just("Krispy Kreme 1 Dozen Original Glazed Donuts"),
                quantity: Data_Maybe.Nothing.value
            }, {
                name: Data_Maybe.Nothing.value,
                quantity: Data_Maybe.Just.create(Data_HugeNum.fromNumber(2.0))
            } ],
            rewardItems: [ {
                name: Data_Maybe.Nothing.value,
                vendorName: new Data_Maybe.Just("Netflix")
            }, {
                name: Data_Maybe.Nothing.value,
                vendorName: Data_Maybe.Nothing.value
            } ],
            notes: Data_Maybe.Nothing.value
        },
        allotments: [ new ZAP_Dart_Common.PointsAllotment({
            earned: Data_HugeNum.fromNumber(200.0),
            redeemed: Data_HugeNum.fromNumber(10.0),
            currencyName: "Angus Points"
        }), new ZAP_Dart_Common.CouponAllotment({
            earned: Data_HugeNum.fromNumber(0.0),
            redeemed: Data_HugeNum.fromNumber(1.0)
        }) ]
    }, {
        id: prefix + "1",
        refNo: "Z12345678910",
        transactionDate: Data_Bounded.top(Data_DateTime.boundedDateTime),
        status: ZAP_Dart_Common.TxRowStatusCleared.value,
        txType: ZAP_Dart_Common.TxRowTypeCatalogPurchase.value,
        txDetails: {
            skus: [ {
                name: new Data_Maybe.Just("Krispy Kreme 1 Dozen Original Glazed Donuts"),
                quantity: Data_Maybe.Just.create(Data_HugeNum.fromNumber(2.0))
            } ],
            rewardItems: [ {
                name: new Data_Maybe.Just("One Month Subscription"),
                vendorName: new Data_Maybe.Just("Netflix")
            } ],
            notes: Data_Maybe.Nothing.value
        },
        allotments: [ new ZAP_Dart_Common.PointsAllotment({
            earned: Data_HugeNum.fromNumber(200.0),
            redeemed: Data_HugeNum.fromNumber(50.0),
            currencyName: "Angus Points"
        }) ]
    }, {
        id: prefix + "2",
        refNo: "Z12345678910",
        transactionDate: Data_Bounded.top(Data_DateTime.boundedDateTime),
        status: ZAP_Dart_Common.TxRowStatusCleared.value,
        txType: ZAP_Dart_Common.TxRowTypeCatalogPurchase.value,
        txDetails: {
            skus: [ {
                name: new Data_Maybe.Just("Krispy Kreme 1 Dozen Original Glazed Donuts"),
                quantity: Data_Maybe.Just.create(Data_HugeNum.fromNumber(2.0))
            } ],
            rewardItems: [ {
                name: new Data_Maybe.Just("One Month Subscription"),
                vendorName: new Data_Maybe.Just("Netflix")
            } ],
            notes: Data_Maybe.Nothing.value
        },
        allotments: [ new ZAP_Dart_Common.PointsAllotment({
            earned: Data_HugeNum.fromNumber(0.0),
            redeemed: Data_HugeNum.fromNumber(200.5),
            currencyName: "Angus Points"
        }) ]
    }, {
        id: prefix + "3",
        refNo: "Z12345678910",
        transactionDate: Data_Bounded.top(Data_DateTime.boundedDateTime),
        status: ZAP_Dart_Common.TxRowStatusCleared.value,
        txType: ZAP_Dart_Common.TxRowTypeCatalogPurchase.value,
        txDetails: {
            skus: [  ],
            rewardItems: [ {
                name: new Data_Maybe.Just("One Month Subscription"),
                vendorName: new Data_Maybe.Just("Netflix")
            } ],
            notes: Data_Maybe.Nothing.value
        },
        allotments: [ new ZAP_Dart_Common.PointsAllotment({
            earned: Data_HugeNum.fromNumber(50.0),
            redeemed: Data_HugeNum.fromNumber(200.0),
            currencyName: "Angus Points"
        }), new ZAP_Dart_Common.CouponAllotment({
            earned: Data_HugeNum.fromNumber(1.0),
            redeemed: Data_HugeNum.fromNumber(0.0)
        }) ]
    }, {
        id: prefix + "4",
        refNo: "Z12345678910",
        transactionDate: Data_Bounded.top(Data_DateTime.boundedDateTime),
        status: ZAP_Dart_Common.TxRowStatusCleared.value,
        txType: ZAP_Dart_Common.TxRowTypeCatalogPurchase.value,
        txDetails: {
            skus: [ {
                name: new Data_Maybe.Just("Krispy Kreme 1 Dozen Original Glazed Donuts"),
                quantity: Data_Maybe.Just.create(Data_HugeNum.fromNumber(3.0))
            } ],
            rewardItems: [  ],
            notes: Data_Maybe.Nothing.value
        },
        allotments: [ new ZAP_Dart_Common.PointsAllotment({
            earned: Data_HugeNum.fromNumber(300.0),
            redeemed: Data_HugeNum.fromNumber(100.0),
            currencyName: "Angus Points"
        }) ]
    }, {
        id: prefix + "5",
        refNo: "Z12345678910",
        transactionDate: Data_Bounded.top(Data_DateTime.boundedDateTime),
        status: ZAP_Dart_Common.TxRowStatusCleared.value,
        txType: ZAP_Dart_Common.TxRowTypeCatalogPurchase.value,
        txDetails: {
            skus: [  ],
            rewardItems: [ {
                name: new Data_Maybe.Just("One Month Subscription"),
                vendorName: new Data_Maybe.Just("Netflix")
            }, {
                name: new Data_Maybe.Just("One Month Subscription"),
                vendorName: new Data_Maybe.Just("Netflix")
            } ],
            notes: Data_Maybe.Nothing.value
        },
        allotments: [ new ZAP_Dart_Common.PointsAllotment({
            earned: Data_HugeNum.fromNumber(200.0),
            redeemed: Data_HugeNum.fromNumber(1000.25),
            currencyName: "Angus Points"
        }), new ZAP_Dart_Common.CouponAllotment({
            earned: Data_HugeNum.fromNumber(1.0),
            redeemed: Data_HugeNum.fromNumber(0.0)
        }) ]
    }, {
        id: prefix + "6",
        refNo: "Z12345678910",
        transactionDate: Data_Bounded.top(Data_DateTime.boundedDateTime),
        status: ZAP_Dart_Common.TxRowStatusCleared.value,
        txType: ZAP_Dart_Common.TxRowTypeCatalogPurchase.value,
        txDetails: {
            skus: [ {
                name: new Data_Maybe.Just("Krispy Kreme 1 Dozen Original Glazed Donuts"),
                quantity: Data_Maybe.Just.create(Data_HugeNum.fromNumber(2.0))
            } ],
            rewardItems: [ {
                name: new Data_Maybe.Just("One Month Subscription"),
                vendorName: new Data_Maybe.Just("Netflix")
            } ],
            notes: Data_Maybe.Nothing.value
        },
        allotments: [ new ZAP_Dart_Common.PointsAllotment({
            earned: Data_HugeNum.fromNumber(200.0),
            redeemed: Data_HugeNum.fromNumber(10.0),
            currencyName: "Angus Points"
        }) ]
    }, {
        id: prefix + "7",
        refNo: "Z12345678910",
        transactionDate: Data_Bounded.top(Data_DateTime.boundedDateTime),
        status: ZAP_Dart_Common.TxRowStatusCleared.value,
        txType: ZAP_Dart_Common.TxRowTypeCatalogPurchase.value,
        txDetails: {
            skus: [  ],
            rewardItems: [ {
                name: new Data_Maybe.Just("One Month Subscription"),
                vendorName: new Data_Maybe.Just("Netflix")
            } ],
            notes: Data_Maybe.Nothing.value
        },
        allotments: [ new ZAP_Dart_Common.PointsAllotment({
            earned: Data_HugeNum.fromNumber(200.0),
            redeemed: Data_HugeNum.fromNumber(50.75),
            currencyName: "Angus Points"
        }), new ZAP_Dart_Common.CouponAllotment({
            earned: Data_HugeNum.fromNumber(0.0),
            redeemed: Data_HugeNum.fromNumber(1.0)
        }) ]
    }, {
        id: prefix + "8",
        refNo: "Z12345678910",
        transactionDate: Data_Bounded.top(Data_DateTime.boundedDateTime),
        status: ZAP_Dart_Common.TxRowStatusCleared.value,
        txType: ZAP_Dart_Common.TxRowTypeCatalogPurchase.value,
        txDetails: {
            skus: [ {
                name: new Data_Maybe.Just("Krispy Kreme 1 Dozen Original Glazed Donuts"),
                quantity: Data_Maybe.Just.create(Data_HugeNum.fromNumber(2.0))
            } ],
            rewardItems: [  ],
            notes: Data_Maybe.Nothing.value
        },
        allotments: [ new ZAP_Dart_Common.PointsAllotment({
            earned: Data_HugeNum.fromNumber(200.0),
            redeemed: Data_HugeNum.fromNumber(0.0),
            currencyName: "Angus Points"
        }) ]
    }, {
        id: prefix + "9",
        refNo: "Z12345678910",
        transactionDate: Data_Bounded.top(Data_DateTime.boundedDateTime),
        status: ZAP_Dart_Common.TxRowStatusCleared.value,
        txType: ZAP_Dart_Common.TxRowTypeCatalogPurchase.value,
        txDetails: {
            skus: [ {
                name: new Data_Maybe.Just("Krispy Kreme 1 Dozen Original Glazed Donuts"),
                quantity: Data_Maybe.Just.create(Data_HugeNum.fromNumber(5.0))
            } ],
            rewardItems: [ {
                name: new Data_Maybe.Just("One Month Subscription"),
                vendorName: new Data_Maybe.Just("Netflix")
            } ],
            notes: Data_Maybe.Nothing.value
        },
        allotments: [ new ZAP_Dart_Common.PointsAllotment({
            earned: Data_HugeNum.fromNumber(200.5),
            redeemed: Data_HugeNum.fromNumber(10.0),
            currencyName: "Angus Points"
        }) ]
    } ];
};
var sendOtp = function (dictMonad) {
    return function (v) {
        return function (v1) {
            return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Right({
                refId: "refId"
            }));
        };
    };
};
var revokeToken = function (dictMonad) {
    return function (v) {
        return Control_Applicative.pure(dictMonad.Applicative0())(Data_Unit.unit);
    };
};
var resetPinSendOtp = function (dictMonad) {
    return function (v) {
        return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Right({
            refId: "refId"
        }));
    };
};
var resetPin = function (dictMonad) {
    return function (v) {
        return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Right(Data_Unit.unit));
    };
};
var resendOtp = function (dictMonad) {
    return function (r) {
        if (r.refId === "invalid") {
            return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Left(ZAP_Dart_Common.ResendOtpErrMaxResendCountExceeded.value));
        };
        return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Right({
            refId: "12345678",
            isResendAllowed: false
        }));
    };
};
var onboardingFlow = function (dictMonad) {
    return function (v) {
        return Control_Applicative.pure(dictMonad.Applicative0())(new ZAP_Dart_Common.Restart({
            title: Control_Applicative.pure(Data_Maybe.applicativeMaybe)("mock"),
            message: Control_Applicative.pure(Data_Maybe.applicativeMaybe)("restart this")
        }));
    };
};
var listTransactions = function (dictMonad) {
    return function (tok) {
        return function (v) {
            return Control_Applicative.pure(dictMonad.Applicative0())((function () {
                if (v.marker instanceof Data_Maybe.Nothing) {
                    return {
                        transactions: transactions("1"),
                        marker: new Data_Maybe.Just("page1")
                    };
                };
                if (v.marker instanceof Data_Maybe.Just && v.marker.value0 === "page1") {
                    return {
                        transactions: transactions("2"),
                        marker: new Data_Maybe.Just("page2")
                    };
                };
                if (v.marker instanceof Data_Maybe.Just && v.marker.value0 === "page2") {
                    return {
                        transactions: transactions("3"),
                        marker: new Data_Maybe.Just("page3")
                    };
                };
                return {
                    transactions: transactions("4"),
                    marker: Data_Maybe.Nothing.value
                };
            })());
        };
    };
};
var getRewardCount = function (dictMonad) {
    return function (v) {
        return Control_Applicative.pure(dictMonad.Applicative0())(5);
    };
};
var getProfileSummary = function (dictMonad) {
    return function (v) {
        return Control_Applicative.pure(dictMonad.Applicative0())({
            profile: {
                firstName: new Data_Maybe.Just("Jean"),
                lastName: new Data_Maybe.Just("Dela Cruz"),
                avatarUrl: Data_Maybe.Nothing.value,
                redeemable: {
                    currencyId: 1,
                    currencyName: new Data_Maybe.Just(ZAP_Dart_Client_BuildConfig.buildConfig.name + " Points"),
                    value: Data_Semiring.zero(Data_HugeNum.semiringHugeNum)
                },
                rewardsPurchasedCount: 2
            }
        });
    };
};
var getProfile = function (dictMonad) {
    return function (v) {
        return Control_Applicative.pure(dictMonad.Applicative0())({
            profile: {
                firstName: new Data_Maybe.Just("Jean"),
                lastName: new Data_Maybe.Just("Dela Cruz"),
                mobile: "639171111111",
                avatarUrl: Data_Maybe.Nothing.value,
                email: Data_Maybe.Just.create(new ZAP_Dart_Common.Confirmed({
                    value: "jcruz@email.com"
                })),
                location: new Data_Maybe.Just("1"),
                gender: new Data_Maybe.Just(ZAP_Dart_Common.Female.value),
                registrationData: [ {
                    id: "1",
                    name: "Baby #1's Birthday",
                    value: new ZAP_Dart_Common.Single({
                        value: "2018-07-03"
                    })
                } ]
            },
            locations: [ {
                id: "1",
                name: "NCR",
                cities: [ {
                    id: "2",
                    name: "Pasig"
                }, {
                    id: "3",
                    name: "Makati"
                } ]
            }, {
                id: "4",
                name: "Bicol",
                cities: [ {
                    id: "5",
                    name: "Legazpi"
                }, {
                    id: "6",
                    name: "Masbate"
                } ]
            }, {
                id: "5",
                name: "Cebu",
                cities: [  ]
            } ],
            registrationFields: [ {
                id: "1",
                name: "Baby #1's Birthday",
                input: ZAP_Dart_Common.Calendar.value,
                isRequired: false
            }, {
                id: "2",
                name: "Baby #1's Gender",
                input: new ZAP_Dart_Common.Dropdown({
                    selection: [ "Female", "Male" ]
                }),
                isRequired: false
            } ]
        });
    };
};
var getCart = function (dictMonad) {
    return function (v) {
        return Control_Applicative.pure(dictMonad.Applicative0())(Data_Either.Right.create({
            cart: {
                items: [ {
                    productId: "Angz-01",
                    name: new Data_Maybe.Just("Steak"),
                    availableQuantity: new Data_Maybe.Just(10),
                    photoUri: new Data_Maybe.Just("steak.jpg"),
                    vendorColoredLogoUri: Data_Maybe.Nothing.value,
                    vendorName: new Data_Maybe.Just("Angus Steakhouse"),
                    vendorShortName: Data_Maybe.Nothing.value,
                    price: new Data_Maybe.Just(Data_HugeNum.fromNumber(100.0)),
                    description: Data_Maybe.Nothing.value,
                    redemptionInstruction: Data_Maybe.Nothing.value,
                    terms: Data_Maybe.Nothing.value,
                    expiry: Data_Maybe.Nothing.value,
                    codeLabel: "Click to copy",
                    codeType: ZRPC_Clients_Trove.CodeTypeAlphanum.value,
                    quantity: new Data_Maybe.Just(10),
                    stockCountLabel: Data_Maybe.Nothing.value
                }, {
                    productId: "Angz-02",
                    name: new Data_Maybe.Just("Rice"),
                    availableQuantity: new Data_Maybe.Just(10),
                    photoUri: new Data_Maybe.Just("rice.jpg"),
                    vendorColoredLogoUri: Data_Maybe.Nothing.value,
                    vendorName: new Data_Maybe.Just("Angus Steakhouse"),
                    vendorShortName: Data_Maybe.Nothing.value,
                    price: new Data_Maybe.Just(Data_HugeNum.fromNumber(50.0)),
                    description: Data_Maybe.Nothing.value,
                    redemptionInstruction: Data_Maybe.Nothing.value,
                    terms: Data_Maybe.Nothing.value,
                    expiry: Data_Maybe.Nothing.value,
                    codeLabel: "Click to copy",
                    codeType: ZRPC_Clients_Trove.CodeTypeAlphanum.value,
                    quantity: new Data_Maybe.Just(5),
                    stockCountLabel: new Data_Maybe.Just("Out of stock")
                }, {
                    productId: "Angz-03",
                    name: new Data_Maybe.Just("Rice"),
                    availableQuantity: new Data_Maybe.Just(10),
                    photoUri: new Data_Maybe.Just("rice.jpg"),
                    vendorColoredLogoUri: Data_Maybe.Nothing.value,
                    vendorName: new Data_Maybe.Just("Angus Steakhouse"),
                    vendorShortName: Data_Maybe.Nothing.value,
                    price: new Data_Maybe.Just(Data_HugeNum.fromNumber(50.0)),
                    description: Data_Maybe.Nothing.value,
                    redemptionInstruction: Data_Maybe.Nothing.value,
                    terms: Data_Maybe.Nothing.value,
                    expiry: Data_Maybe.Nothing.value,
                    codeLabel: "Click to copy",
                    codeType: ZRPC_Clients_Trove.CodeTypeAlphanum.value,
                    quantity: new Data_Maybe.Just(5),
                    stockCountLabel: new Data_Maybe.Just("1 left in stock")
                } ]
            },
            totalPoints: new Data_Maybe.Just(Data_HugeNum.fromNumber(100.0)),
            isProfileComplete: false
        }));
    };
};
var fakeFallbackImgUrl = "https://coderender.zap.com.ph/?code=ZNDWH054&renderType=%7B%22type%22%3A%22Text%22%7D";
var fakeReward = {
    productId: "AngzZ-002",
    productCode: "ZNDWH054",
    vendorName: new Data_Maybe.Just("ZAP"),
    vendorShortName: new Data_Maybe.Just("ZAP"),
    name: new Data_Maybe.Just("Free Pizza"),
    photoUri: new Data_Maybe.Just("https://zap-trove-resources.s3.amazonaws.com/prod/products/AngzZ-002/photo.jpg"),
    vendorColoredLogoUri: Data_Maybe.Nothing.value,
    expiry: Data_Maybe.Nothing.value,
    description: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."),
    redemptionInstruction: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
    terms: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id. 1. Ut vitae ullamcorper libero 2.Donec interdum orci placerat mi commodo commodo id"),
    productCodeId: 12,
    codeLabel: "Click to copy",
    codeType: ZRPC_Clients_Trove.CodeTypeAlphanum.value,
    renderType: ZRPC_Types_ZJson.ZJson(ZRPC_ZRPCWrite.zrpcWrite(ZAP_Dart_Common.zrpcWriteRenderType)(ZAP_Dart_Common.Text.value)),
    fallbackImgUrl: fakeFallbackImgUrl,
    redeemedAt: Data_Maybe.Nothing.value
};
var getReward = function (dictMonad) {
    return function (v) {
        return function (v1) {
            return Control_Applicative.pure(dictMonad.Applicative0())({
                rewardItem: new Data_Maybe.Just(fakeReward)
            });
        };
    };
};
var fakeRewards = {
    page1: {
        rewards: [ {
            productId: "1",
            productCode: "SQ45BD",
            vendorName: new Data_Maybe.Just("Krispy Kreme"),
            vendorShortName: new Data_Maybe.Just("Krispy Kreme"),
            name: new Data_Maybe.Just("1 Dozen Original Glazed Donuts"),
            photoUri: Data_Maybe.Nothing.value,
            vendorColoredLogoUri: Data_Maybe.Nothing.value,
            expiry: new Data_Maybe.Just(Data_Bounded.top(Data_DateTime.boundedDateTime)),
            description: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
            redemptionInstruction: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
            terms: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id. 1. Ut vitae ullamcorper libero 2.Donec interdum orci placerat mi commodo commodo id"),
            productCodeId: 1,
            codeLabel: "Click to copy",
            codeType: ZRPC_Clients_Trove.CodeTypeAlphanum.value,
            renderType: ZRPC_Types_ZJson.ZJson(ZRPC_ZRPCWrite.zrpcWrite(ZAP_Dart_Common.zrpcWriteRenderType)(ZAP_Dart_Common.Text.value)),
            fallbackImgUrl: fakeFallbackImgUrl,
            redeemedAt: Data_Maybe.Nothing.value
        }, {
            productId: "2",
            productCode: "AAHERC",
            vendorName: new Data_Maybe.Just("Krispy Kreme"),
            vendorShortName: new Data_Maybe.Just("Krispy Kreme"),
            name: new Data_Maybe.Just("1 Dozen Original Glazed Donuts"),
            photoUri: Data_Maybe.Nothing.value,
            vendorColoredLogoUri: Data_Maybe.Nothing.value,
            expiry: new Data_Maybe.Just(Data_Bounded.top(Data_DateTime.boundedDateTime)),
            description: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
            redemptionInstruction: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
            terms: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id. 1. Ut vitae ullamcorper libero 2.Donec interdum orci placerat mi commodo commodo id"),
            productCodeId: 2,
            codeLabel: "Click to copy",
            codeType: ZRPC_Clients_Trove.CodeTypeAlphanum.value,
            renderType: ZRPC_Types_ZJson.ZJson(ZRPC_ZRPCWrite.zrpcWrite(ZAP_Dart_Common.zrpcWriteRenderType)(ZAP_Dart_Common.Text.value)),
            fallbackImgUrl: fakeFallbackImgUrl,
            redeemedAt: Data_Maybe.Nothing.value
        }, {
            productId: "3",
            productCode: "3HGS3G",
            vendorName: new Data_Maybe.Just("7-Eleven"),
            vendorShortName: new Data_Maybe.Just("Krispy Kreme"),
            name: new Data_Maybe.Just("1 7-oz Slurpee"),
            photoUri: Data_Maybe.Nothing.value,
            vendorColoredLogoUri: Data_Maybe.Nothing.value,
            expiry: new Data_Maybe.Just(Data_Bounded.top(Data_DateTime.boundedDateTime)),
            description: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
            redemptionInstruction: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
            terms: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id. 1. Ut vitae ullamcorper libero 2.Donec interdum orci placerat mi commodo commodo id"),
            productCodeId: 3,
            codeLabel: "Click to copy",
            codeType: ZRPC_Clients_Trove.CodeTypeAlphanum.value,
            renderType: ZRPC_Types_ZJson.ZJson(ZRPC_ZRPCWrite.zrpcWrite(ZAP_Dart_Common.zrpcWriteRenderType)(ZAP_Dart_Common.Text.value)),
            fallbackImgUrl: fakeFallbackImgUrl,
            redeemedAt: Data_Maybe.Nothing.value
        }, {
            productId: "4",
            productCode: "AHK67B",
            vendorName: new Data_Maybe.Just("Krispy Kreme"),
            vendorShortName: new Data_Maybe.Just("Krispy Kreme"),
            name: new Data_Maybe.Just("1 Dozen Original Glazed Donuts"),
            photoUri: Data_Maybe.Nothing.value,
            vendorColoredLogoUri: Data_Maybe.Nothing.value,
            expiry: new Data_Maybe.Just(Data_Bounded.top(Data_DateTime.boundedDateTime)),
            description: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
            redemptionInstruction: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
            terms: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id. 1. Ut vitae ullamcorper libero 2.Donec interdum orci placerat mi commodo commodo id"),
            productCodeId: 4,
            codeLabel: "Click to copy",
            codeType: ZRPC_Clients_Trove.CodeTypeAlphanum.value,
            renderType: ZRPC_Types_ZJson.ZJson(ZRPC_ZRPCWrite.zrpcWrite(ZAP_Dart_Common.zrpcWriteRenderType)(ZAP_Dart_Common.Text.value)),
            fallbackImgUrl: fakeFallbackImgUrl,
            redeemedAt: Data_Maybe.Nothing.value
        } ],
        marker: new Data_Maybe.Just("page2")
    },
    page2: {
        rewards: [ {
            productId: "5",
            productCode: "GXFHWD",
            vendorName: new Data_Maybe.Just("Krispy Kreme"),
            vendorShortName: new Data_Maybe.Just("Krispy Kreme"),
            name: new Data_Maybe.Just("1 Dozen Original Glazed Donuts"),
            photoUri: Data_Maybe.Nothing.value,
            vendorColoredLogoUri: Data_Maybe.Nothing.value,
            expiry: new Data_Maybe.Just(Data_Bounded.top(Data_DateTime.boundedDateTime)),
            description: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
            redemptionInstruction: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
            terms: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id. 1. Ut vitae ullamcorper libero 2.Donec interdum orci placerat mi commodo commodo id"),
            productCodeId: 5,
            codeLabel: "Click to copy",
            codeType: ZRPC_Clients_Trove.CodeTypeAlphanum.value,
            renderType: ZRPC_Types_ZJson.ZJson(ZRPC_ZRPCWrite.zrpcWrite(ZAP_Dart_Common.zrpcWriteRenderType)(ZAP_Dart_Common.Text.value)),
            fallbackImgUrl: fakeFallbackImgUrl,
            redeemedAt: Data_Maybe.Nothing.value
        }, {
            productId: "6",
            productCode: "JEWGJU",
            vendorName: new Data_Maybe.Just("Krispy Kreme"),
            vendorShortName: new Data_Maybe.Just("Krispy Kreme"),
            name: new Data_Maybe.Just("1 Dozen Original Glazed Donuts"),
            photoUri: Data_Maybe.Nothing.value,
            vendorColoredLogoUri: Data_Maybe.Nothing.value,
            expiry: new Data_Maybe.Just(Data_Bounded.top(Data_DateTime.boundedDateTime)),
            description: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
            redemptionInstruction: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
            terms: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id. 1. Ut vitae ullamcorper libero 2.Donec interdum orci placerat mi commodo commodo id"),
            productCodeId: 6,
            codeLabel: "Click to copy",
            codeType: ZRPC_Clients_Trove.CodeTypeAlphanum.value,
            renderType: ZRPC_Types_ZJson.ZJson(ZRPC_ZRPCWrite.zrpcWrite(ZAP_Dart_Common.zrpcWriteRenderType)(ZAP_Dart_Common.Text.value)),
            fallbackImgUrl: fakeFallbackImgUrl,
            redeemedAt: Data_Maybe.Nothing.value
        }, {
            productId: "7",
            productCode: "LJOVLC",
            vendorName: new Data_Maybe.Just("7-Eleven"),
            vendorShortName: new Data_Maybe.Just("Krispy Kreme"),
            name: new Data_Maybe.Just("1 7-oz Slurpee"),
            photoUri: Data_Maybe.Nothing.value,
            vendorColoredLogoUri: Data_Maybe.Nothing.value,
            expiry: new Data_Maybe.Just(Data_Bounded.top(Data_DateTime.boundedDateTime)),
            description: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
            redemptionInstruction: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
            terms: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id. 1. Ut vitae ullamcorper libero 2.Donec interdum orci placerat mi commodo commodo id"),
            productCodeId: 7,
            codeLabel: "Click to copy",
            codeType: ZRPC_Clients_Trove.CodeTypeAlphanum.value,
            renderType: ZRPC_Types_ZJson.ZJson(ZRPC_ZRPCWrite.zrpcWrite(ZAP_Dart_Common.zrpcWriteRenderType)(ZAP_Dart_Common.Text.value)),
            fallbackImgUrl: fakeFallbackImgUrl,
            redeemedAt: Data_Maybe.Nothing.value
        }, {
            productId: "8",
            productCode: "QHJNZI",
            vendorName: new Data_Maybe.Just("Krispy Kreme"),
            vendorShortName: new Data_Maybe.Just("Krispy Kreme"),
            name: new Data_Maybe.Just("1 Dozen Original Glazed Donuts"),
            photoUri: Data_Maybe.Nothing.value,
            vendorColoredLogoUri: Data_Maybe.Nothing.value,
            expiry: new Data_Maybe.Just(Data_Bounded.top(Data_DateTime.boundedDateTime)),
            description: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
            redemptionInstruction: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
            terms: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id. 1. Ut vitae ullamcorper libero 2.Donec interdum orci placerat mi commodo commodo id"),
            productCodeId: 8,
            codeLabel: "Click to copy",
            codeType: ZRPC_Clients_Trove.CodeTypeAlphanum.value,
            renderType: ZRPC_Types_ZJson.ZJson(ZRPC_ZRPCWrite.zrpcWrite(ZAP_Dart_Common.zrpcWriteRenderType)(ZAP_Dart_Common.Text.value)),
            fallbackImgUrl: fakeFallbackImgUrl,
            redeemedAt: Data_Maybe.Nothing.value
        } ],
        marker: new Data_Maybe.Just("page3")
    },
    page3: {
        rewards: [ {
            productId: "9",
            productCode: "HAAHCN",
            vendorName: new Data_Maybe.Just("Krispy Kreme"),
            vendorShortName: new Data_Maybe.Just("Krispy Kreme"),
            name: new Data_Maybe.Just("1 Dozen Original Glazed Donuts"),
            photoUri: Data_Maybe.Nothing.value,
            vendorColoredLogoUri: Data_Maybe.Nothing.value,
            expiry: new Data_Maybe.Just(Data_Bounded.top(Data_DateTime.boundedDateTime)),
            description: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
            redemptionInstruction: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
            terms: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id. 1. Ut vitae ullamcorper libero 2.Donec interdum orci placerat mi commodo commodo id"),
            productCodeId: 9,
            codeLabel: "Click to copy",
            codeType: ZRPC_Clients_Trove.CodeTypeAlphanum.value,
            renderType: ZRPC_Types_ZJson.ZJson(ZRPC_ZRPCWrite.zrpcWrite(ZAP_Dart_Common.zrpcWriteRenderType)(ZAP_Dart_Common.Text.value)),
            fallbackImgUrl: fakeFallbackImgUrl,
            redeemedAt: Data_Maybe.Nothing.value
        }, {
            productId: "10",
            productCode: "UYIGRU",
            vendorName: new Data_Maybe.Just("Krispy Kreme"),
            vendorShortName: new Data_Maybe.Just("Krispy Kreme"),
            name: new Data_Maybe.Just("1 Dozen Original Glazed Donuts"),
            photoUri: Data_Maybe.Nothing.value,
            vendorColoredLogoUri: Data_Maybe.Nothing.value,
            expiry: new Data_Maybe.Just(Data_Bounded.top(Data_DateTime.boundedDateTime)),
            description: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
            redemptionInstruction: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
            terms: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id. 1. Ut vitae ullamcorper libero 2.Donec interdum orci placerat mi commodo commodo id"),
            productCodeId: 10,
            codeLabel: "Click to copy",
            codeType: ZRPC_Clients_Trove.CodeTypeAlphanum.value,
            renderType: ZRPC_Types_ZJson.ZJson(ZRPC_ZRPCWrite.zrpcWrite(ZAP_Dart_Common.zrpcWriteRenderType)(ZAP_Dart_Common.Text.value)),
            fallbackImgUrl: fakeFallbackImgUrl,
            redeemedAt: Data_Maybe.Nothing.value
        } ],
        marker: new Data_Maybe.Just("page4")
    },
    page4: {
        rewards: [  ],
        marker: Data_Maybe.Nothing.value
    }
};
var getRewards = function (dictMonad) {
    return function (v) {
        return function (v1) {
            return Control_Applicative.pure(dictMonad.Applicative0())((function () {
                if (v1.marker instanceof Data_Maybe.Nothing) {
                    return fakeRewards.page1;
                };
                if (v1.marker instanceof Data_Maybe.Just && v1.marker.value0 === "page2") {
                    return fakeRewards.page2;
                };
                if (v1.marker instanceof Data_Maybe.Just && v1.marker.value0 === "page3") {
                    return fakeRewards.page3;
                };
                if (v1.marker instanceof Data_Maybe.Just) {
                    return fakeRewards.page4;
                };
                throw new Error("Failed pattern match at ZAP.Dart.Mock (line 329, column 7 - line 333, column 36): " + [ v1.marker.constructor.name ]);
            })());
        };
    };
};
var fakeCatalogItem = {
    productId: "99",
    name: new Data_Maybe.Just("1-month subscription"),
    availableQuantity: new Data_Maybe.Just(10),
    stockCountLabel: Data_Maybe.Nothing.value,
    photoUri: new Data_Maybe.Just("https://springboard-cdn.appadvice.com/wp-content/appadvice-v2-media/2016/11/Netflix-background_860c8ece6b34fb4f43af02255ca8f225-xl.jpg"),
    vendorColoredLogoUri: new Data_Maybe.Just("https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Netflix_2015_logo.svg/1280px-Netflix_2015_logo.svg.png"),
    vendorName: new Data_Maybe.Just("Netflix"),
    vendorShortName: new Data_Maybe.Just("Netflix"),
    price: new Data_Maybe.Just(Data_HugeNum.fromNumber(100.0)),
    description: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
    redemptionInstruction: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
    terms: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id. 1. Ut vitae ullamcorper libero 2.Donec interdum orci placerat mi commodo commodo id"),
    expiry: new Data_Maybe.Just(ZAP_Dart_Client_ApiClient_Mock.mkDateTime_(2019)(12)(1)),
    codeLabel: "Click to copy",
    codeType: ZRPC_Clients_Trove.CodeTypeAlphanum.value
};
var getCatalogItem = function (dictMonad) {
    return function (v) {
        return function (v1) {
            return Control_Applicative.pure(dictMonad.Applicative0())({
                catalogItem: new Data_Maybe.Just(fakeCatalogItem),
                totalPoints: new Data_Maybe.Just(Data_HugeNum.fromNumber(100.0))
            });
        };
    };
};
var fakeBasicCatalogItem = function (id) {
    return {
        productId: Data_Show.show(Data_Show.showInt)(id),
        name: new Data_Maybe.Just("1-month subscription"),
        availableQuantity: new Data_Maybe.Just(10),
        photoUri: new Data_Maybe.Just("https://springboard-cdn.appadvice.com/wp-content/appadvice-v2-media/2016/11/Netflix-background_860c8ece6b34fb4f43af02255ca8f225-xl.jpg"),
        vendorColoredLogoUri: new Data_Maybe.Just("https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Netflix_2015_logo.svg/1280px-Netflix_2015_logo.svg.png"),
        vendorName: new Data_Maybe.Just("Netflix"),
        vendorShortName: new Data_Maybe.Just("Netflix"),
        price: new Data_Maybe.Just(Data_HugeNum.fromNumber(100.0)),
        description: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
        redemptionInstruction: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
        terms: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id. 1. Ut vitae ullamcorper libero 2.Donec interdum orci placerat mi commodo commodo id"),
        expiry: new Data_Maybe.Just(ZAP_Dart_Client_ApiClient_Mock.mkDateTime_(2019)(12)(1)),
        stockCountLabel: new Data_Maybe.Just("Out of stock"),
        codeLabel: "Click to copy",
        codeType: ZRPC_Clients_Trove.CodeTypeAlphanum.value
    };
};
var fakeBasicCatalogItems = {
    page1: {
        items: [ fakeBasicCatalogItem(1), fakeBasicCatalogItem(2), fakeBasicCatalogItem(3), fakeBasicCatalogItem(4), fakeBasicCatalogItem(5) ],
        marker: new Data_Maybe.Just("page2"),
        totalPoints: new Data_Maybe.Just(Data_HugeNum.fromNumber(100.0))
    },
    page2: {
        items: [ fakeBasicCatalogItem(6), fakeBasicCatalogItem(7), fakeBasicCatalogItem(8), fakeBasicCatalogItem(9), fakeBasicCatalogItem(10) ],
        marker: new Data_Maybe.Just("page3"),
        totalPoints: new Data_Maybe.Just(Data_HugeNum.fromNumber(100.0))
    },
    page3: {
        items: [  ],
        marker: Data_Maybe.Nothing.value,
        totalPoints: new Data_Maybe.Just(Data_HugeNum.fromNumber(100.0))
    }
};
var listCatalogItems = function (dictMonad) {
    return function (v) {
        return function (v1) {
            return Control_Applicative.pure(dictMonad.Applicative0())((function () {
                if (v1.marker instanceof Data_Maybe.Nothing) {
                    return fakeBasicCatalogItems.page1;
                };
                if (v1.marker instanceof Data_Maybe.Just && v1.marker.value0 === "page2") {
                    return fakeBasicCatalogItems.page2;
                };
                if (v1.marker instanceof Data_Maybe.Just) {
                    return fakeBasicCatalogItems.page3;
                };
                throw new Error("Failed pattern match at ZAP.Dart.Mock (line 317, column 7 - line 320, column 46): " + [ v1.marker.constructor.name ]);
            })());
        };
    };
};
var deleteFromCart = function (dictMonad) {
    return function (v) {
        return function (v1) {
            return Control_Applicative.pure(dictMonad.Applicative0())(Data_Either.Right.create({
                cart: {
                    items: [ {
                        productId: "Angz-01",
                        name: new Data_Maybe.Just("Steak"),
                        availableQuantity: new Data_Maybe.Just(10),
                        photoUri: new Data_Maybe.Just("steak.jpg"),
                        vendorColoredLogoUri: Data_Maybe.Nothing.value,
                        vendorName: new Data_Maybe.Just("Angus Steakhouse"),
                        vendorShortName: Data_Maybe.Nothing.value,
                        price: new Data_Maybe.Just(Data_HugeNum.fromNumber(100.0)),
                        description: Data_Maybe.Nothing.value,
                        redemptionInstruction: Data_Maybe.Nothing.value,
                        terms: Data_Maybe.Nothing.value,
                        expiry: Data_Maybe.Nothing.value,
                        quantity: new Data_Maybe.Just(10),
                        stockCountLabel: new Data_Maybe.Just("Out of stock"),
                        codeLabel: "Click to copy",
                        codeType: ZRPC_Clients_Trove.CodeTypeAlphanum.value
                    } ]
                },
                totalPoints: new Data_Maybe.Just(Data_HugeNum.fromNumber(100.0))
            }));
        };
    };
};
var claim = function (dictMonad) {
    return function (v) {
        return function (v1) {
            return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Right({
                isProfileComplete: false,
                claims: [ new ZAP_Dart_Common.Points({
                    currencyName: new Data_Maybe.Just("Test"),
                    earned: Data_HugeNum.fromNumber(20.0)
                }) ],
                totalPoints: new Data_Maybe.Just(Data_HugeNum.fromNumber(100.0))
            }));
        };
    };
};
var checkout = function (dictMonad) {
    return function (v) {
        return function (req) {
            var tx = {
                refNo: "123",
                transactionDate: Data_Bounded.top(Data_DateTime.boundedDateTime)
            };
            var products = Data_Functor.mapFlipped(Data_Functor.functorArray)(req.items)(function (item) {
                return {
                    productId: item.productId,
                    vendorName: new Data_Maybe.Just("Angus Steakhouse"),
                    name: Data_Maybe.Just.create("Name for " + item.productId),
                    photoUri: Data_Maybe.Nothing.value,
                    productCode: "CodeFor" + item.productId,
                    expiry: Data_Maybe.Just.create(ZAP_Dart_Client_ApiClient_Mock.mkDateTime_(2019)(7)(17)),
                    description: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
                    redemptionInstruction: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id."),
                    terms: new Data_Maybe.Just("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ullamcorper libero. Donec interdum orci placerat mi commodo commodo id. 1. Ut vitae ullamcorper libero 2.Donec interdum orci placerat mi commodo commodo id"),
                    vendorShortName: new Data_Maybe.Just("Angus Steakhouse"),
                    vendorColoredLogoUri: Data_Maybe.Nothing.value,
                    productCodeId: 1,
                    codeLabel: "Click to copy",
                    codeType: ZRPC_Clients_Trove.CodeTypeAlphanum.value,
                    renderType: ZRPC_Types_ZJson.ZJson(ZRPC_ZRPCWrite.zrpcWrite(ZAP_Dart_Common.zrpcWriteRenderType)(ZAP_Dart_Common.Text.value)),
                    fallbackImgUrl: fakeFallbackImgUrl,
                    redeemedAt: Data_Maybe.Nothing.value
                };
            });
            var earnTx = new ZAP_Dart_Common.Earned({
                amount: 200.0,
                skuItems: [  ]
            });
            if (req.pin === "111111") {
                return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Right({
                    transaction: tx,
                    purchasedProducts: products,
                    totalPoints: new Data_Maybe.Just(Data_HugeNum.fromNumber(100.0))
                }));
            };
            if (req.pin === "222222") {
                return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Left(ZAP_Dart_Common.CheckoutErrReservationFailed.value));
            };
            return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Left(ZAP_Dart_Common.CheckoutErrInvalidPin.value));
        };
    };
};
var authorizedResetPin = function (dictMonad) {
    return function (v) {
        return function (v1) {
            return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Right(Data_Unit.unit));
        };
    };
};
module.exports = {
    claim: claim,
    verifyOtp: verifyOtp,
    resendOtp: resendOtp,
    getProfileSummary: getProfileSummary,
    getProfile: getProfile,
    updateProfile: updateProfile,
    getCart: getCart,
    updateCart: updateCart,
    deleteFromCart: deleteFromCart,
    fakeBasicCatalogItem: fakeBasicCatalogItem,
    fakeBasicCatalogItems: fakeBasicCatalogItems,
    fakeFallbackImgUrl: fakeFallbackImgUrl,
    fakeCatalogItem: fakeCatalogItem,
    listCatalogItems: listCatalogItems,
    getCatalogItem: getCatalogItem,
    getRewards: getRewards,
    getRewardCount: getRewardCount,
    getReward: getReward,
    fakeReward: fakeReward,
    fakeRewards: fakeRewards,
    listTransactions: listTransactions,
    transactions: transactions,
    checkout: checkout,
    resetPin: resetPin,
    authorizedResetPin: authorizedResetPin,
    sendOtp: sendOtp,
    resetPinSendOtp: resetPinSendOtp,
    onboardingFlow: onboardingFlow,
    revokeToken: revokeToken
};
